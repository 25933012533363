import { User } from "../auth/user";
import { Exchange } from "../exchange/exchange";

export interface InfoRequestForm {
  invoice: boolean;
  picture: boolean;
  video: boolean;
  weproov: boolean;
  message: string;
}

export interface InfoRequest {
  id: number;
  invoice: boolean;
  picture: boolean;
  video: boolean;
  message: string | null;
  weproov: boolean;
  InfoRequestDocuments: InfoRequestDocument[];
  Exchange: Exchange;
  UserId: User["id"];
  weproovCode: string | null;
  updatedAt: Date;
}

export interface InfoRequestDocument {
  id?: number;
  file: string;
  type: InfoRequestType;
  name: string;
}

export enum InfoRequestType {
  "invoice" = "invoice",
  "picture" = "picture",
  "video" = "video",
}

export interface AnswerInfoRequestForm {
  invoiceDocuments: InfoRequestDocument[];
  pictureDocuments: InfoRequestDocument[];
  videoDocuments: InfoRequestDocument[];
}

export interface AnswerInfoRequestParams {
  InfoRequestDocuments: InfoRequestDocument[];
}

export const hasOnlyWeProovAndAnswered = (infoRequest: InfoRequest): boolean =>
  infoRequest.weproov &&
  !infoRequest.invoice &&
  !infoRequest.picture &&
  !infoRequest.video &&
  infoRequest.weproovCode !== null;
