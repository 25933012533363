import { FunctionComponent, useMemo } from "react";
import { usePlan } from "../planProvider";
import CardBody from "../../ui/block/CardBody";
import CardHead from "../../ui/block/CardHead";
import Dialog from "../../ui/block/Dialog";
import { User } from "../../auth/user";
import Section from "../../ui/block/Section";
import Form from "../../forms/Form";
import { string, object } from "yup";
import AutoField from "../../forms/AutoField";
import ValidationsErrors from "../../forms/ValidationsErrors";
import SubmitButton from "../../forms/SubmitButton";

const PlanAssignationDialog: FunctionComponent<{
  onClose: () => void;
  selectedUser: User;
}> = ({ onClose, selectedUser }) => {
  const { plans, assignPlan } = usePlan();

  const userAssignedPlan = useMemo(
    () =>
      plans.find((plan) =>
        plan.Assignations?.find((user) => user.id === selectedUser.id),
      ),
    [plans, selectedUser.id],
  );

  const plansEnum = plans.reduce(
    (obj, item) => ({ ...obj, [item.id]: item.label }),
    {},
  );

  const PlanAssignationSchema = useMemo(
    () =>
      object()
        .shape({
          planId: string()
            .label("Plan")
            .nullable()
            .autocomplete(plansEnum, "plan", "plan-select", {
              active: false,
              hasSelectAll: false,
            })
            .required(),
        })
        .defined(),
    [plansEnum],
  );

  return (
    <Dialog onClose={onClose}>
      <CardHead title={"Assignation d'un plan"} />
      <CardBody>
        <p>
          {userAssignedPlan
            ? "Plan assigné actuel : "
            : "L'utilisateur n'a aucun plan assigné."}
          {userAssignedPlan && (
            <strong>{userAssignedPlan.label.replaceAll("_", " ")}</strong>
          )}
        </p>

        <Section>
          <Form
            initialValues={{}}
            onSubmit={({ planId }) => assignPlan(planId, selectedUser.id)}
            schema={PlanAssignationSchema}
          >
            <Section>
              <AutoField name={"planId"} />
            </Section>

            <div className={"form-footer"}>
              <ValidationsErrors />
              <SubmitButton>Assigner le plan</SubmitButton>
            </div>
          </Form>
        </Section>
      </CardBody>
    </Dialog>
  );
};

export default PlanAssignationDialog;
