import { FunctionComponent, useMemo } from "react";
import { ConfigKey } from "../../auth/config";
import { toFixed } from "../../numbers/formatting";
import { frenchTVA } from "../../payment/payment";
import { useOfferFeesFromAmount } from "../offer";

export interface Props {
  amount: number;
  buyingOption: ConfigKey;
  paymentOption: ConfigKey;
  showExchangeTotalPrice?: boolean;
}

const OfferFeeBlock: FunctionComponent<Props> = ({
  amount,
  buyingOption,
  paymentOption,
  showExchangeTotalPrice = false,
}) => {
  const {
    autolityFees,
    fileFees,
    additionnalFileFees,
  } = useOfferFeesFromAmount(amount, buyingOption, paymentOption);

  const totalPrice = useMemo(
    () =>
      (amount || 0) +
      (autolityFees + fileFees + additionnalFileFees) * frenchTVA,
    [amount, autolityFees, fileFees, additionnalFileFees],
  );

  return amount ? (
    <div>
      <p>
        Frais de dossier de base: {fileFees}€<sup>HT</sup>
      </p>
      <p>
        Frais de dossier supplémentaire: {additionnalFileFees}€<sup>HT</sup>
      </p>
      <p>
        Frais Autolity: {autolityFees}€<sup>HT</sup>
      </p>
      {showExchangeTotalPrice && (
        <p>
          Montant total à payer: <strong>{toFixed(totalPrice, 2)}</strong>€
          <sup>TTC</sup>
        </p>
      )}
    </div>
  ) : null;
};

export default OfferFeeBlock;
