export function pickKeys<O, K extends keyof O>(obj: O, keys: K[]): Pick<O, K> {
  return Object.fromEntries(
    Object.entries(
      obj as Record<string | symbol | number, unknown>,
    ).filter(([k]) => keys.includes(k as K)),
  ) as Pick<O, K>;
}

export function omitKeys<O, K extends keyof O>(obj: O, keys: K[]): Omit<O, K> {
  return Object.fromEntries(
    Object.entries(obj as Record<string | symbol | number, unknown>).filter(
      ([k]) => !keys.includes(k as K),
    ),
  ) as Omit<O, K>;
}
