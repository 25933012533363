import {
  ExchangeStatus,
  getBestOfferStatus,
} from "src/services/exchange/exchange";
import { Offer, OfferStatus, PriceOrigin } from "../offer";
import { t } from "i18next";

const OfferStatusDisplay = ({ offer }: { offer: Offer }): JSX.Element => {
  const status = getBestOfferStatus(offer);
  const isPending =
    status === OfferStatus.STATUS_PENDING &&
    offer.Prices[0].origin === PriceOrigin.ORIGIN_BUYER;
  const isCounter =
    status === OfferStatus.STATUS_PENDING &&
    offer.Prices[0].origin === PriceOrigin.ORIGIN_SELLER;
  const isDeclined = status === OfferStatus.STATUS_DECLINED;
  const isCanceled = status === OfferStatus.STATUS_CANCELED;
  const isAccepted = status === OfferStatus.STATUS_ACCEPTED;
  const isValidated = status === OfferStatus.STATUS_VALIDATED;
  const isSold = status === ExchangeStatus.STATUS_SOLD;

  return (
    <>
      {isPending && (
        <div className={"offer-status --pending"}>
          {t("offer:offer_status.STATUS_PENDING")}
        </div>
      )}
      {isCounter && (
        <div className={"offer-status --pending"}>
          {t("offer:offer_status.STATUS_COUNTER")}
        </div>
      )}
      {isDeclined && (
        <div className={"offer-status --danger"}>
          {t("offer:offer_status.STATUS_DECLINED")}
        </div>
      )}
      {isCanceled && (
        <div className={"offer-status --warning"}>
          {t("offer:offer_status.STATUS_CANCELED")}
        </div>
      )}
      {isAccepted && (
        <div className={"offer-status --success"}>
          {t("offer:offer_status.STATUS_ACCEPTED")}
        </div>
      )}
      {isValidated && (
        <div className={"offer-status --success"}>
          {t("offer:offer_status.STATUS_VALIDATED")}
        </div>
      )}
      {isSold && (
        <div className={"offer-status --success"}>
          {t("offer:offer_status.STATUS_SOLD")}
        </div>
      )}
    </>
  );
};

export default OfferStatusDisplay;
