import { cx } from "@emotion/css";
import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { ADMIN_EXCHANGE_LISTING_LINK } from "../../../routes/admin";
import { ADMIN_CREATE_EXCHANGE_LINK } from "../../../routes/admin/accounts";
import {
  AccountsApiLoadedAccount,
  useAccounts,
} from "../../../services/accounts/accountsProvider";
import AttachmentForms from "../../../services/auth/components/AttachmentForms";
import ProfileForm from "../../../services/auth/components/ProfileForm";
import { getFullName } from "../../../services/auth/user";
import { useExchange } from "../../../services/exchange/exchangeProvider";
import SubmitButton from "../../../services/forms/SubmitButton";
import generatePath from "../../../services/routing/generatePath";
import { withLoader } from "../../../services/routing/useLoader";
import { useParams } from "../../../services/routing/useParams";
import { useToastsWithIntl } from "../../../services/toast-notifications";
import { useConfirmationWithIntl } from "../../../services/ui/ConfirmationDialog";
import AccountValidationInfo from "../../components/AccountValidationInfo";
import iconTrashPurple from "../../../assets/img/icons/icon-trash-purple.svg";
import { useDate } from "../../../services/date/DateContext";

const AccountProfile = (): JSX.Element => {
  const {
    account,
    updateKyc,
    updateUser,
    toggleAccountSuperAdmin,
    toggleAccountValidation,
    toggleAccountInvalidation,
  } = useAccounts() as AccountsApiLoadedAccount;
  const { exchanges, deleteImportExchanges } = useExchange();
  const { confirm } = useConfirmationWithIntl("auth");
  const { archiveUser } = useAccounts();
  const { toastSuccess, toastError } = useToastsWithIntl(["auth", "exchange"]);
  const { formatDate } = useDate();

  const userExchanges = useMemo(
    () => exchanges.filter((e) => e.UserId === account.id),
    [account, exchanges],
  );

  return (
    <div className={"container page-content"}>
      <div className={"page_head"}>
        <h1 className={"page-title"}>{getFullName(account)}</h1>
        {account.deletedAt ? (
          <p>Compte supprimé le {formatDate(account.deletedAt)}</p>
        ) : (
          <button
            className={"link"}
            onClick={() =>
              confirm(
                "archive_account_content",
                "archive_account_title",
                () =>
                  archiveUser(account.id).then(
                    () => toastSuccess("auth:archive-account.SUCCESS"),
                    () => toastError("auth:archive-account.ERROR"),
                  ),
                true,
                {
                  closable: false,
                },
              )
            }
          >
            <img alt={"trash"} className={"icon"} src={iconTrashPurple} />
          </button>
        )}
      </div>
      {!account.superAdmin && (
        <div className={"section-l"}>
          <div className={"card"}>
            <div className={"card-body"}>
              <h5>{userExchanges.length} annonces</h5>
              <div>
                <Link
                  to={generatePath(ADMIN_EXCHANGE_LISTING_LINK, undefined, {
                    userId: account.id,
                  })}
                >
                  Voir toutes ses annonces {">"}
                </Link>
              </div>
              <div>
                <Link
                  to={generatePath(ADMIN_CREATE_EXCHANGE_LINK, {
                    id: account.id,
                  })}
                >
                  Créer une annonce +
                </Link>
              </div>

              <div>
                <SubmitButton
                  className={"btn-1"}
                  onClick={() =>
                    deleteImportExchanges(account.id).then(
                      () =>
                        toastSuccess(
                          "exchange:delete-import-exchanges.SUCCESS",
                        ),
                      () =>
                        toastError("exchange:delete-import-exchanges.ERROR"),
                    )
                  }
                >
                  Supprimer les annonces importées
                </SubmitButton>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={"section-l lay-row --gap"}>
        <SubmitButton
          className={cx("btn-toggle", account.isValid && "toggle-on")}
          onClick={toggleAccountValidation}
        >
          {account.isValid ? "Compte validé" : "Validation du compte"}
        </SubmitButton>
        <SubmitButton
          className={cx("btn-toggle", account.superAdmin && "toggle-on")}
          onClick={toggleAccountSuperAdmin}
        >
          Super administrateur
        </SubmitButton>
        {!account.validationDate ? (
          <SubmitButton className={"btn-1"} onClick={toggleAccountInvalidation}>
            Invalider le compte
          </SubmitButton>
        ) : (
          !account.isValid && <p>Compte invalidé</p>
        )}
      </div>
      <AccountValidationInfo linkToAccount={false} user={account} />
      <div className={"section"}>
        <h2 className={"section-title"}>PIÈCES JUSTIFICATIVES</h2>
        <div className={"section"}>
          <AttachmentForms onUpdateKyc={updateKyc} user={account} />
        </div>
      </div>

      <div className={"section"}>
        <h2 className={"section-title"}>INFORMATIONS DU COMPTE</h2>
        <div className={"section"}>
          <ProfileForm account={account} onUpdateUser={updateUser} />
        </div>
      </div>
    </div>
  );
};

const useLoad = () => {
  const { loadAccount } = useAccounts();
  const accountId = parseInt(useParams().id || "");
  const { loadExchanges } = useExchange();

  return useCallback(
    () => Promise.all([loadAccount(accountId), loadExchanges()]),
    [accountId, loadAccount, loadExchanges],
  );
};

export default withLoader(AccountProfile, useLoad);
