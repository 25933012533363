import React from "react";
import { Routes, Route } from "react-router-dom";
import {
  ADMIN_NEW_PLAN,
  ADMIN_PLAN_DETAIL,
} from "../../../../routes/admin/plan";
import { usePlan } from "../../../../services/plan/planProvider";
import { withLoader } from "../../../../services/routing/useLoader";
import NewPlan from "./NewPlan";
import PlanDetail from "./PlanDetail";
import PlansManagement from "./PlansManagement";

const Plan = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<PlansManagement />} index />
      <Route element={<NewPlan />} path={`${ADMIN_NEW_PLAN}`} />
      <Route element={<PlanDetail />} path={`${ADMIN_PLAN_DETAIL}`} />
    </Routes>
  );
};

const useLoad = () => {
  const { loadPlans } = usePlan();
  return loadPlans;
};

export default withLoader(Plan, useLoad);
