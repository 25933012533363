import FSelectParse from "./FSelectParse";
import { useYupField } from "./Form";
import { AnySchema, BaseSchema } from "yup";
import { Namespace, TFuncKey, useTranslation } from "react-i18next";
import { getNumericEnumEntries } from "../data-structures/enum";
import RadioSelect from "./RadioSelect";
import FSelectWithoutParse from "./FSelectFSelectWithoutParse";

const FSelect = ({
  name,
  disabled,
  className,
  id,
  placeholder,
  radio,
}: {
  name: string;
  disabled?: boolean;
  className?: string;
  id?: string;
  placeholder?: string;
  radio?: boolean;
}): JSX.Element => {
  const fieldSchema = useYupField(name) as AnySchema;

  const {
    enum: enumList,
    translate,
    withoutParse,
  } = fieldSchema.meta() as NonNullable<BaseSchema["metaInterface"]>;
  const { t } = useTranslation(translate ? ([translate[0]] as Namespace) : []);

  return withoutParse ? (
    <FSelectWithoutParse
      className={className}
      disabled={disabled}
      id={id}
      name={name}
    >
      {fieldSchema.spec.nullable && <option>{placeholder}</option>}
      {!Array.isArray(enumList) && enumList
        ? Object.entries(enumList)
            .filter((value) => value !== null)
            .map((value) => {
              return (
                <option key={value[0]} value={value[0] as string | number}>
                  {translate
                    ? (t(
                        `${translate[1]}.${value[0] as string}` as TFuncKey,
                      ) as string)
                    : (value[1] as string | number)}
                </option>
              );
            })
        : (enumList as Array<string>)
            .filter((value): value is string => value !== null)
            .map((value) => (
              <option key={value} value={value}>
                {translate
                  ? (t(`${translate[1]}.${value}` as TFuncKey) as string)
                  : (value as string)}
              </option>
            ))}
    </FSelectWithoutParse>
  ) : radio ? (
    <RadioSelect
      className={className}
      disabled={disabled}
      id={id}
      name={name}
      nullable={fieldSchema.spec.nullable}
      options={
        Array.isArray(enumList)
          ? enumList
              .filter((value): value is string | number => value !== null)
              .map((value) => [
                value,
                translate
                  ? (t(`${translate[1]}.${value}` as TFuncKey) as string)
                  : (value as string),
              ])
          : getNumericEnumEntries(enumList).map(([id, name]) => [
              id,
              t(
                `${
                  (translate as NonNullable<typeof translate>)[1]
                }.${name}` as TFuncKey,
              ) as string,
            ])
      }
    />
  ) : (
    <FSelectParse className={className} disabled={disabled} id={id} name={name}>
      {fieldSchema.spec.nullable && <option>{placeholder}</option>}
      {Array.isArray(enumList)
        ? (enumList as (string | number | null)[])
            .filter((value): value is string | number => value !== null)
            .map((value) => (
              <option key={value} value={value}>
                {translate ? t(`${translate[1]}.${value}` as TFuncKey) : value}
              </option>
            ))
        : getNumericEnumEntries(enumList).map(([id, name]) => (
            <option key={id} value={id}>
              {t(
                `${
                  (translate as NonNullable<typeof translate>)[1]
                }.${name}` as TFuncKey,
              )}
            </option>
          ))}
    </FSelectParse>
  );
};

export default FSelect;
