import { Elements } from "@stripe/react-stripe-js";
import { ReactElement } from "react";
import LoaderErrors from "../../routing/components/LoaderErrors";
import Loading from "../../routing/components/Loading";
import useLoader from "../../routing/useLoader";
import CardBody from "../../ui/block/CardBody";
import CardHead from "../../ui/block/CardHead";
import Dialog from "../../ui/block/Dialog";
import { PaymentIntentForm } from "../payment";
import { usePayment, withProvidePayment } from "../paymentProvider";
import { stripe } from "../stripeConfig";
import StripeForm from "./StripeForm";

interface Props {
  onClose: () => void;
  paymentIntentForm: PaymentIntentForm;
  redirectUrl?: string;
  redirectAction?: () => Promise<void>;
  paymentInfo?: ReactElement;
}

const StripePaymentDialog = ({
  onClose,
  paymentIntentForm,
  redirectUrl,
  redirectAction,
  paymentInfo,
}: Props): JSX.Element => {
  const { createPaymentIntent, paymentIntent } = usePayment();

  const { loading, error, reload } = useLoader(
    () => createPaymentIntent(paymentIntentForm),
    [createPaymentIntent, paymentIntentForm],
  );

  return (
    <Dialog onClose={onClose}>
      <CardHead title={"Paiement"} />
      <CardBody>
        {loading ? (
          <Loading text={"Nous préparons votre paiement"} />
        ) : error ? (
          error === 409 ? (
            <>
              <p>Le paiement est déjà en cours.</p>
              <button onClick={onClose}>Quitter</button>
            </>
          ) : (
            <LoaderErrors error={error} reload={reload} />
          )
        ) : (
          <Elements
            options={{ clientSecret: paymentIntent?.clientSecret }}
            stripe={stripe}
          >
            <StripeForm
              paymentInfo={paymentInfo}
              redirectAction={redirectAction}
              redirectUrl={redirectUrl}
            />
          </Elements>
        )}
      </CardBody>
    </Dialog>
  );
};

export default withProvidePayment(StripePaymentDialog);
