import useFileAsObjectURL from "../../files/useFileAsObjectURL";
import { InfoRequestDocument } from "../infoRequest";

const DocumentListItem = ({
  document,
}: {
  document: InfoRequestDocument;
}): JSX.Element => {
  const objectUrl = useFileAsObjectURL({
    url: document.file,
  });

  return (
    <div className={"file"}>
      <a
        className={"file-name"}
        href={objectUrl?.url ?? "#"}
        rel={"noreferrer"}
        target={"_blank"}
      >
        {objectUrl !== null && `${document.name}`}
      </a>
    </div>
  );
};

export default DocumentListItem;
