import { AxiosPromise } from "axios";
import baseAPI from "../auth/api";
import { User } from "../auth/user";
import { FullOfferRaw, Offer } from "./offer";

export function getReceivedOffers(
  userId: User["id"],
): AxiosPromise<FullOfferRaw[]> {
  return baseAPI.get(`/users/${userId}/offers/received`);
}

export function getSentOffers(
  userId: User["id"],
): AxiosPromise<FullOfferRaw[]> {
  return baseAPI.get(`/users/${userId}/offers/sent`);
}

export function getAllOffers(): AxiosPromise<FullOfferRaw[]> {
  return baseAPI.get(`/offers`);
}

export function cancelOffer(offerId: Offer["id"]): AxiosPromise<FullOfferRaw> {
  return baseAPI.put(`/offers/${offerId}/cancel`);
}
