import React, { ReactElement, useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import SubmitButton from "../../forms/SubmitButton";
import { useTranslation } from "react-i18next";
import { usePayment } from "../paymentProvider";

interface Props {
  redirectUrl?: string;
  redirectAction?: () => Promise<void>;
  paymentInfo?: ReactElement;
  priceWithoutDiscount?: number;
  isSubscription?: boolean;
  trialDuration?: number;
  actionBeforeCharge?: () => Promise<void>;
}

const StripeForm = ({
  redirectUrl,
  redirectAction,
  paymentInfo,
  priceWithoutDiscount,
  isSubscription,
  trialDuration,
  actionBeforeCharge,
}: Props): JSX.Element => {
  const stripe = useStripe();
  const elements = useElements();
  const { paymentIntent, confirmPayment } = usePayment();

  const [fakeLoading, setFakeLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation(["payment"]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (fakeLoading) {
      timer = setTimeout(() => {
        if (!redirectUrl && redirectAction)
          redirectAction().then(() => setFakeLoading(false));
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [fakeLoading, redirectAction, redirectUrl]);

  const isTrial = trialDuration !== undefined && trialDuration > 0;

  useEffect(() => {
    if (stripe && paymentIntent?.clientSecret) {
      if (isTrial) {
        stripe
          .retrieveSetupIntent(paymentIntent.clientSecret)
          .then(({ setupIntent }) => {
            switch (setupIntent?.status || "") {
              case "succeeded":
                setMessage("Votre période d'essai commence !");
                break;
              case "processing":
                setMessage("Nous vérifions votre moyen de paiement");
                break;
              case "requires_payment_method":
                break;
              default:
                setMessage("Une erreur s'est produite");
                break;
            }
          });
      } else {
        stripe
          .retrievePaymentIntent(paymentIntent.clientSecret)
          .then(({ paymentIntent }) => {
            switch (paymentIntent?.status || "") {
              case "succeeded":
                setMessage("Succès du paiement");
                break;
              case "processing":
                setMessage("Votre paiement est en cours de traitement");
                break;
              case "requires_payment_method":
                break;
              default:
                setMessage("Une erreur s'est produite");
                break;
            }
          });
      }
    }
  }, [trialDuration, paymentIntent?.clientSecret, stripe, isTrial]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (actionBeforeCharge) await actionBeforeCharge();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await confirmPayment(
      stripe,
      elements,
      {
        return_url: redirectUrl,
      },
      isTrial,
    );

    setIsLoading(false);
    if (error) {
      switch (error?.type) {
        case "card_error":
          setMessage(t("payment:send-stripe-payment.CARD_ERROR"));
          break;
        case "validation_error":
          break;
        default:
          setMessage(t("payment:send-stripe-payment.GENERIC_ERROR"));
          break;
      }
    } else {
      setFakeLoading(true);
    }
  };

  return (
    <form id={"payment-form"} onSubmit={handleSubmit}>
      {fakeLoading ? (
        <p>
          Nous vérifions{" "}
          {isTrial ? "les coordonnées fournies" : "votre paiement"}
          ...
        </p>
      ) : (
        <>
          <div className={"kpi"}>
            <div className={"kpi_label"}>Montant dû</div>
            {paymentIntent?.price.toFixed(2)}€
            <sup className={"body-xs"}>TTC</sup>
            {isSubscription &&
              priceWithoutDiscount !== undefined &&
              (priceWithoutDiscount === paymentIntent?.price ? (
                <span className={"body-xs"}>/mois</span>
              ) : (
                <>
                  {isTrial && <p>Gratuit pendant {trialDuration} mois</p>}
                  <p>
                    puis {priceWithoutDiscount.toFixed(2)}€
                    <sup className={"body-xs"}>TTC</sup>
                    <span className={"body-xs"}>/mois</span>
                  </p>
                </>
              ))}
          </div>

          <div className={"section"}>{paymentInfo}</div>

          <div className={"section"}>
            <PaymentElement id={"payment-element"} />
          </div>

          <div className={"form-footer"}>
            <SubmitButton
              className={"btn-1 btn-block"}
              disabled={isLoading || !stripe || !elements}
            >
              {isTrial ? "S'abonner" : "Payer"}
            </SubmitButton>

            {message && (
              <ul className={"form-errors"}>
                <li>{message}</li>
              </ul>
            )}
          </div>
        </>
      )}
    </form>
  );
};

export default StripeForm;
