import React, { FunctionComponent } from "react";
import Login from "./public/Login";
import {
  LOGIN,
  LOGIN_LINK,
  LOST_PASSWORD,
  PUBLIC_CONTACT,
  REGISTER,
  RESET_PASSWORD,
  USER_PLANS_PUBLIC,
} from "../routes/public";
import Register from "./public/Register";
import LostPassword from "./public/LostPassword";
import ResetPassword from "./public/ResetPassword";
import Routes from "src/services/routing/Routes";
import { Navigate, Route, Outlet } from "react-router-dom";
import Footer from "./components/Footer";

/* IMAGES AND ICONS */
import HeaderPublic from "./components/HeaderPublic";
import PublicPlansTable from "./public/PublicPlansTable";
import PublicContact from "./public/PublicContact";

const Public: FunctionComponent = () => {
  return (
    <div className={"site-layout"}>
      <HeaderPublic />
      <Routes>
        <Route element={<Navigate to={LOGIN_LINK} />} index />
        <Route
          element={
            <div className={"auth-screen"}>
              <Outlet />
            </div>
          }
        >
          <Route element={<Login />} path={LOGIN} />
          <Route element={<Register />} path={REGISTER} />
          <Route element={<LostPassword />} path={LOST_PASSWORD} />
          <Route element={<ResetPassword />} path={RESET_PASSWORD} />
        </Route>
        <Route element={<PublicContact />} path={PUBLIC_CONTACT} />
        <Route element={<PublicPlansTable />} path={`${USER_PLANS_PUBLIC}`} />
      </Routes>

      <Footer />
    </div>
  );
};

export default Public;
