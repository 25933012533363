import { Link, NavLink } from "react-router-dom";
import {
  PRIVATE,
  DASHBOARD_LINK,
  EXCHANGE_LINK,
  PROFILE_LINK,
} from "../../routes/private";
import { AuthAPIConnected, useAuth } from "../../services/auth/apiProvider";
import { LOGOUT_BUTTON_ID } from "../../fixtures/auth";
import {
  ADMIN_ACCOUNTS_MANAGEMENT_LINK,
  ADMIN_ACCOUNT_LISTING_LINK,
  ADMIN_EXCHANGE_LISTING_LINK,
  ADMIN_OFFERS_LINK,
  ADMIN_PLANS_LINK,
  ADMIN_SUMMARY_LINK,
} from "../../routes/admin";
import { useState } from "react";
import { cx } from "@emotion/css";

/* IMAGES AND ICONS */
import HeaderLogo from "../../assets/img/logo-white.svg";
import LogoutIcon from "../../assets/img/icons/icon-logout-purple.svg";
import BurgerMenuIcon from "../../assets/img/icons/icon-burger-white.svg";
import IconUser from "../../assets/img/icons/icon-user-purple.svg";

const HeaderPrivate = (): JSX.Element => {
  const { user, logout } = useAuth() as AuthAPIConnected;
  const [openedSidebar, setOpenedSidebar] = useState(false);

  const toggleSidebarMenu = (onoff: boolean) => {
    onoff
      ? document.body.classList.add("modal-open")
      : document.body.classList.remove("modal-open");
    setOpenedSidebar(onoff);
  };

  return (
    <div className={"header --private"}>
      <button className={"menu-btn"} onClick={() => toggleSidebarMenu(true)}>
        <img alt={"menu icon"} className={"icon"} src={BurgerMenuIcon} />
      </button>
      <Link to={PRIVATE}>
        <img alt={"logo"} className={"logo"} src={HeaderLogo} />
      </Link>
      {openedSidebar && (
        <div
          className={"modal-overlay"}
          onClick={() => toggleSidebarMenu(false)}
        />
      )}
      <nav className={cx("main-nav", !!openedSidebar && "--opened")}>
        <button
          className={"close-btn"}
          onClick={() => toggleSidebarMenu(false)}
        >
          <img alt={"menu icon"} className={"icon"} src={BurgerMenuIcon} />
        </button>
        <img alt={"logo"} className={"logo"} src={HeaderLogo} />
        {user.superAdmin ? (
          <>
            <NavLink
              className={"nav-link"}
              onClick={() => toggleSidebarMenu(false)}
              to={ADMIN_SUMMARY_LINK}
            >
              Tableau de bord (admin)
            </NavLink>
            <NavLink
              className={"nav-link"}
              onClick={() => toggleSidebarMenu(false)}
              to={ADMIN_ACCOUNTS_MANAGEMENT_LINK}
            >
              Comptes
            </NavLink>
            <NavLink
              className={"nav-link"}
              onClick={() => toggleSidebarMenu(false)}
              to={ADMIN_ACCOUNT_LISTING_LINK}
            >
              Suivi utilisateurs
            </NavLink>
            <NavLink className={"nav-link"} to={ADMIN_PLANS_LINK}>
              Abonnements
            </NavLink>
            <NavLink className={"nav-link"} to={ADMIN_OFFERS_LINK}>
              Offres
            </NavLink>
            <NavLink
              className={"nav-link"}
              onClick={() => toggleSidebarMenu(false)}
              to={ADMIN_EXCHANGE_LISTING_LINK}
            >
              Bourse d'échange
            </NavLink>
          </>
        ) : (
          <>
            <NavLink
              className={"nav-link"}
              onClick={() => toggleSidebarMenu(false)}
              to={DASHBOARD_LINK}
            >
              Tableau de bord
            </NavLink>
            <NavLink
              className={"nav-link"}
              onClick={() => toggleSidebarMenu(false)}
              to={EXCHANGE_LINK}
            >
              Bourse d'échange
            </NavLink>
          </>
        )}
      </nav>
      <nav className={"nav-right"}>
        <NavLink className={"header_btn"} to={PROFILE_LINK}>
          <img alt={"user account"} className={"icon"} src={IconUser} />
          <span className={"hide-xs"}>Mon compte</span>
        </NavLink>
        <button
          className={"header_btn"}
          data-testid={LOGOUT_BUTTON_ID}
          onClick={logout}
          type={"button"}
        >
          <img alt={"logout"} className={"icon"} src={LogoutIcon} />
        </button>
      </nav>
    </div>
  );
};

export default HeaderPrivate;
