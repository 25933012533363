import { useCallback, useMemo } from "react";
import { object, number, string } from "yup";
import { AuthAPIConnected, useAuth } from "../../auth/apiProvider";
import { ConfigKey } from "../../auth/config";
import { FullExchange, FullExchangeRaw } from "../../exchange/exchange";
import AutoField from "../../forms/AutoField";
import Form from "../../forms/Form";
import SubmitButton from "../../forms/SubmitButton";
import ValidationsErrors from "../../forms/ValidationsErrors";
import PlanChangeProposalBlock from "../../plan/components/PlanChangeProposalBlock";
import { withLoader } from "../../routing/useLoader";
import Dialog from "../../ui/block/Dialog";
import { useConfirmationWithIntl } from "../../ui/ConfirmationDialog";
import { OfferForm } from "../offer";
import OfferFeeFormBlock from "./OfferFeeFormBlock";
import OfferConditionsDetailsButton from "src/services/files/OfferConditionsDetailsButton";

/** @param props.exchange - If it's a new offer, can contains any offer in Offers. If it's an update offer, exchange.Offers[0] must be the offer to update */
const OfferDialog = ({
  onClose,
  exchange,
  onSubmit,
  isBuyer = false,
  isNewOffer = true,
}: {
  onClose: () => void;
  exchange: FullExchange | FullExchangeRaw;
  onSubmit: (offerForm: OfferForm) => Promise<void>;
  isBuyer?: boolean;
  isNewOffer?: boolean;
}): JSX.Element => {
  const { user } = useAuth() as AuthAPIConnected;
  const { confirm } = useConfirmationWithIntl("offer");

  const biggestOffer = useMemo(
    () => exchange.Offers.length > 0 && exchange.Offers[0].bestOfferAmount,
    [exchange],
  );

  const CreateOfferSchema = useMemo(
    () =>
      object()
        .shape({
          amount: number()
            .label("Votre offre (€ TTC):")
            .required()
            .test(
              "",
              () => ({ key: "check-amount-error" }),
              (val) =>
                isBuyer && !isNewOffer && val
                  ? val >
                    exchange.Offers[0].Prices[
                      exchange.Offers[0].Prices.length - 1
                    ].amount
                  : true,
            ),
          buyingOption: string()
            .label("Option d'achat")
            .meta({
              select: true,
              enum: [
                ConfigKey.BUYING_OPTION_NO_CONDITION,
                ConfigKey.BUYING_OPTION_EXPENSES_LIST,
                ConfigKey.BUYING_OPTION_WEPROOV,
                ConfigKey.BUYING_OPTION_INSPECTION,
              ],
              translate: ["offer", "offer:buying_option"],
              withoutParse: true,
            })
            .notVisible(!isNewOffer)
            .radio(),
          paymentOption: string()
            .label("Option de paiement")
            .meta({
              select: true,
              enum: [
                ConfigKey.PAYMENT_OPTION_DIRECT,
                ConfigKey.PAYMENT_OPTION_SECURE,
                ConfigKey.PAYMENT_OPTIONS_RECEPTION,
              ],
              translate: ["offer", "offer:payment_option"],
              withoutParse: true,
            })
            .notVisible(!isNewOffer)
            .radio(),
        })
        .defined(),
    [exchange.Offers, isBuyer, isNewOffer],
  );

  const leftCredits = useMemo(
    () =>
      user.Plan!.maxExchangeOfferDailyNb !== -1 &&
      !user.Plan!.Subscription.isBuyer
        ? user.Plan!.maxExchangeOfferDailyNb - user.dailyOffersNb
        : Infinity,
    [user.Plan, user.dailyOffersNb],
  );
  const hasNoCreditsLeft = useMemo(
    () =>
      user.Plan!.maxExchangeOfferDailyNb !== -1 &&
      !user.Plan!.Subscription.isBuyer &&
      leftCredits === 0,
    [leftCredits, user.Plan],
  );

  return (
    <Dialog className={"dialog-card-m"} onClose={onClose}>
      <div className={"card-head"}>
        <h2 className={"card-title"}>Faire une offre</h2>
      </div>
      <div className={"card-body"}>
        <div className={"grid --s"}>
          <div>
            <p>Prix de vente directe</p>
            <strong>{exchange.vehicleSalePrice} €</strong>
          </div>
          <div>
            <p>Meilleure offre en cours</p>
            {biggestOffer ? (
              <strong>{biggestOffer} €</strong>
            ) : (
              <span className={"chip --outline"}>Pas encore d'offre</span>
            )}
          </div>
        </div>

        <div>
          <Form
            initialValues={{
              buyingOption: !isNewOffer
                ? exchange.Offers[0].buyingOption
                : ConfigKey.BUYING_OPTION_NO_CONDITION,
              paymentOption: !isNewOffer
                ? exchange.Offers[0].paymentOption
                : ConfigKey.PAYMENT_OPTION_DIRECT,
            }}
            onSubmit={async (values: OfferForm) =>
              confirm(
                "confirm_new_offer_content",
                "confirm_new_offer_title",
                () => onSubmit(values),
                true,
                { closable: false },
              )
            }
            schema={CreateOfferSchema}
          >
            <div className={"section"}>
              <AutoField
                name={"amount"}
                otherProps={{ step: 500 }}
                placeholder={"offre"}
              />

              <div className={"form-block grid --s --2-cols --gap-s"}>
                <AutoField name={"buyingOption"} />

                <AutoField name={"paymentOption"} />
              </div>
              <div className={"--txt-center"}>
                <OfferConditionsDetailsButton offer={null} />
              </div>
            </div>

            {isBuyer && <OfferFeeFormBlock />}

            {isNewOffer && (
              <div className={"plan-cta"}>
                <strong className={"plan-name"}>
                  Abonnement « {user.Plan!.label} »
                </strong>
                <p className={"body-s"}>
                  {leftCredits === Infinity
                    ? "Offres illimitées"
                    : `${leftCredits} crédit(s) restant(s) aujourd'hui`}
                  {!hasNoCreditsLeft && user.Plan!.type === 0 && (
                    <div className={"m-top"}>
                      <p className={"body-s"}>
                        Prenez un abonnement premium pour économiser 20% sur les
                        frais Autolity
                      </p>

                      <PlanChangeProposalBlock />
                    </div>
                  )}
                </p>
                {hasNoCreditsLeft && (
                  <div className={"m-top"}>
                    <PlanChangeProposalBlock />
                  </div>
                )}
              </div>
            )}

            <div className={"form-footer"}>
              <ValidationsErrors />
              <SubmitButton disabled={isNewOffer && hasNoCreditsLeft}>
                Déposer l’offre
              </SubmitButton>
            </div>
          </Form>
        </div>
      </div>
    </Dialog>
  );
};

const useLoad = () => {
  const { reloadUser } = useAuth() as AuthAPIConnected;

  return useCallback(() => reloadUser(), [reloadUser]);
};

export default withLoader(OfferDialog, useLoad);
