import React, {
  FunctionComponent,
  useState,
  useMemo,
  useCallback,
} from "react";
import { deleteFromArrayAndReturn } from "../../../data-structures/array";
import FileInput, { Props as FileInputProps } from "../../../files/FileInput";
import LightboxCarousel from "../../../files/LightboxCarousel";
import useFileAsObjectURL from "../../../files/useFileAsObjectURL";
import placeholderImage from "../../../../assets/img/img-placeholder.jpeg";
import { readFile } from "../../../files/utils";
import { VehiclePicture, VehiclePictureBase } from "../../exchange";
import { useField } from "formik";
import { useToastsWithIntl } from "../../../toast-notifications";

interface Props extends Omit<FileInputProps, "onChange"> {
  name: string;
  readonly?: boolean;
}

const VehiclePictureZone: FunctionComponent<Props> = ({ name, readonly }) => {
  const { toastError } = useToastsWithIntl(["exchange"]);
  const [field, , helper] = useField<(VehiclePicture | VehiclePictureBase)[]>(
    name,
  );

  const [displayedFile, setDisplayedFile] = useState<null | number>(null);
  const [dragItemIndex, updateDragItemIndex] = useState<null | number>(null);

  const sortedMedia = useMemo(() => {
    if (!field.value) return [];
    const list = [...field.value];

    list.sort((a, b) => a.order - b.order);
    return list;
  }, [field.value]);

  const handleDragEnter = useCallback(
    (index: number) => {
      if (dragItemIndex !== null) {
        const newList = [...sortedMedia];
        const item = newList[dragItemIndex];
        newList.splice(dragItemIndex, 1);
        newList.splice(index, 0, item);
        updateDragItemIndex(index);
        helper.setValue(
          newList.map((value, index) => ({ ...value, order: index })),
        );
      }
    },
    [dragItemIndex, helper, sortedMedia],
  );

  const mediaList = useMemo(() => {
    if (!sortedMedia.length) return [];

    return sortedMedia.map((file, index, array) => (
      <MediaEdit
        key={index}
        draggable={{
          handleDragEnter: () => handleDragEnter(index),
          handleDragStart: () => updateDragItemIndex(index),
        }}
        file={file}
        onDelete={() =>
          helper.setValue(
            deleteFromArrayAndReturn(array, index).map((picture, index) => ({
              ...picture,
              order: index,
            })),
          )
        }
        onShow={() => setDisplayedFile(index)}
      />
    ));
  }, [handleDragEnter, helper, sortedMedia]);

  return (
    <div>
      {!readonly && (
        <FileInput
          accept={"image/*"}
          multiple={true}
          onChange={(addedFiles) => {
            if (addedFiles[0].size / 1024 > 10000)
              toastError("exchange:upload-exchange-picture.TOO_LARGE");
            else
              readFile(addedFiles[0]).then(async (file) => {
                return Promise.all(
                  [...addedFiles].map((f, index) =>
                    readFile(f).then((dataUrl) => ({
                      file: dataUrl,
                      order: field.value.length + index,
                    })),
                  ),
                ).then((newMedia) => {
                  helper.setValue((field.value || []).concat(newMedia));
                });
              });
          }}
        />
      )}

      <div className={"media-grid"}>{mediaList}</div>

      {displayedFile !== null && (
        <LightboxCarousel
          defaultFileDisplayed={displayedFile}
          files={sortedMedia.map((m) => ({ url: m.file }))}
          onClose={() => setDisplayedFile(null)}
        />
      )}
    </div>
  );
};

interface MediaEditProps {
  file: VehiclePicture | VehiclePictureBase;
  onDelete?(): void;
  onShow(): void;
  draggable?: {
    handleDragEnter: () => void;
    handleDragStart: () => void;
  };
}

const MediaEdit: FunctionComponent<MediaEditProps> = ({
  file,
  onDelete,
  onShow,
  draggable,
}) => {
  const objectURL = useFileAsObjectURL({ url: file.file });

  return (
    <div
      className={"media-item"}
      draggable={draggable !== undefined}
      onDragEnter={(e) => draggable?.handleDragEnter()}
      onDragOver={(e) => e.preventDefault()}
      onDragStart={() => draggable?.handleDragStart()}
    >
      <button className={"btn-lightbox"} onClick={onShow} type={"button"}>
        <canvas
          height={"3"}
          style={
            objectURL
              ? {
                  backgroundImage: `url(${objectURL.url})`,
                  backgroundSize: "cover",
                }
              : {
                  backgroundImage: `url(${placeholderImage})`,
                  backgroundSize: "contain",
                }
          }
          width={"4"}
        />
      </button>
      {onDelete && (
        <button className={"delete-img-btn"} onClick={onDelete} type={"button"}>
          -
        </button>
      )}
    </div>
  );
};

export default VehiclePictureZone;
