import { FunctionComponent } from "react";
import { useFormikContext } from "formik";
import { OfferForm } from "../offer";
import OfferFeeBlock from "./OfferFeeBlock";

const OfferFeeFormBlock: FunctionComponent = () => {
  const { values } = useFormikContext<OfferForm>();

  return <OfferFeeBlock {...values} showExchangeTotalPrice />;
};

export default OfferFeeFormBlock;
