import Link from "../../routing/components/Link";
import generatePath from "../../routing/generatePath";

import iconEdit from "../../../assets/img/icons/icon-pencil-purple.svg";
import { Plan } from "../plan";
import { ADMIN_PLAN_DETAIL_LINK } from "../../../routes/admin/plan";

const PlansManagementTable = ({ plans }: { plans: Plan[] }): JSX.Element => {
  return (
    <div className={"table-container"}>
      <table className={"table"}>
        <thead>
          <tr>
            <th>Nom</th>
            <th>Prix par mois</th>
            <th>Offre par jour</th>
            <th>Publication</th>
            <th>Demande de renseignement</th>
            <th>Mois d'essai</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {plans.map((plan) => (
            <tr key={plan.id}>
              <td>{plan.label.replaceAll("_", " ")}</td>
              <td>{plan.monthlyPrice}</td>
              <td>{plan.maxExchangeOfferDailyNb}</td>
              <td>{plan.maxExchangePostNb}</td>
              <td>{plan.maxInfoRequestDailyNb}</td>
              <td>{plan.monthsOfTrial ?? 0}</td>
              <td>
                <Link
                  to={generatePath(ADMIN_PLAN_DETAIL_LINK, {
                    planId: plan.id,
                  })}
                >
                  <img alt={"pencil"} className={"icon"} src={iconEdit} />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PlansManagementTable;
