import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  LoggedUser,
  User,
  UserToRegister,
  UserToLogin,
  UserToUpdateForm,
  UserPasswordForm,
  UserKyc,
  mapUserRawToUser,
} from "./user";
import baseAPI, {
  deleteLocalUser,
  getLocalUser,
  getUserById,
  isSetLocalUser,
  login as apiLogin,
  logout as apiLogout,
  lostPassword as apiLostPassword,
  register as apiRegister,
  resetPassword as apiResetPassword,
  setLocalUser,
  updateUserById,
  updateUserPasswordById,
  validateUserRegistration as apiValidateUserRegistration,
  askAccountDeletion as apiAskAccountDeletion,
  updateKyc as apiUpdateKyc,
  getConfigs,
  askLinkUbiflowAccount as apiAskLinkUbiflowAccount,
} from "./api";
import { AxiosPromise } from "axios";
import { useNavigate } from "react-router-dom";
import { LOGIN_LINK } from "../../routes/public";
import { Configs } from "./config";

const defaultUser = isSetLocalUser() ? getLocalUser() : null;

export interface AuthAPI {
  user: User | null;
  loggedUser: LoggedUser | null;
  configs: Configs | null;

  reloadUser(): Promise<void>;

  login(user: UserToLogin): Promise<LoggedUser>;

  logout(): Promise<void>;

  checkUserValidity(): Promise<void>;

  register(user: UserToRegister): AxiosPromise<void>;

  validateUserRegistration(guid: string): AxiosPromise<void>;

  updateUser(newUser: UserToUpdateForm): Promise<void>;
  updateUserPassword(form: UserPasswordForm): AxiosPromise<void>;

  lostPassword(email: User["email"]): AxiosPromise<void>;

  resetPassword(
    guid: string,
    password: UserToLogin["password"],
  ): AxiosPromise<void>;

  updateKyc(kycType: keyof UserKyc, form: Partial<UserKyc>): Promise<void>;
  loadConfigs(): Promise<void>;

  askAccountDeletion(): AxiosPromise<void>;
  sendLinkUbiflowMail(): AxiosPromise<void>;
}

export interface AuthAPIConnected extends AuthAPI {
  user: User;
  loggedUser: LoggedUser;
  configs: Configs;
}

/**
 * Default value should never be used
 */
export const AuthContext = createContext<AuthAPI | null>(null);

export function useProvideAuth(): AuthAPI {
  const [user, setUser] = useState<User | null>(null);
  const [configs, setConfigs] = useState<Configs | null>(null);
  const [loggedUser, setLoggedUser] = useState<LoggedUser | null>(defaultUser);
  const navigate = useNavigate();

  useMemo(() => {
    if (loggedUser !== null) {
      setLocalUser(loggedUser);
      baseAPI.defaults.headers[
        "Authorization"
      ] = `Bearer ${loggedUser.xsrfToken}`;
    } else {
      deleteLocalUser();
      delete baseAPI.defaults.headers["Authorization"];
    }
  }, [loggedUser]);

  useEffect(() => {
    const interceptor = baseAPI.interceptors.response.use(
      (res) => res,
      (error) => {
        if (error?.response?.status === 401) {
          setUser(null);
          setLoggedUser(null);
        }
        return Promise.reject(error?.response?.status);
      },
    );

    return () => {
      baseAPI.interceptors.response.eject(interceptor);
    };
  }, []);

  const login: AuthAPI["login"] = useCallback((u: UserToLogin) => {
    return apiLogin(u).then((res) => {
      const newUser = mapUserRawToUser(res.data.user) as User & LoggedUser;
      setLoggedUser(newUser);
      setUser(newUser);
      return newUser;
    });
  }, []);

  const logout: AuthAPI["logout"] = useCallback(() => {
    if (user !== null) {
      setUser(null);
      setLoggedUser(null);
      navigate(LOGIN_LINK);
    }
    return apiLogout().then(() => {
      return Promise.resolve();
    });
  }, [user, navigate]);

  const checkUserValidity: AuthAPI["checkUserValidity"] = useCallback(() => {
    if (isSetLocalUser()) {
      const u = getLocalUser();
      return getUserById(u.id).then(
        (res) => {
          const newUser = { ...u, ...mapUserRawToUser(res.data) };
          setUser(newUser);
          setLocalUser({
            ...newUser,
            xsrfToken: u.xsrfToken,
          });
        },
        (err) => {
          if (err === 401) {
            setUser(null);
            setLoggedUser(null);
            deleteLocalUser();
          }
        },
      );
    }
    return Promise.resolve();
  }, []);

  const reloadUser = useCallback(() => {
    if (loggedUser?.id) {
      return getUserById(loggedUser.id).then((res) => {
        const newUser = mapUserRawToUser(res.data) as User & LoggedUser;
        setUser(newUser);
      });
    }
    return Promise.resolve();
  }, [loggedUser?.id]);

  const register: AuthAPI["register"] = useCallback((user) => {
    return apiRegister(user);
  }, []);

  const validateUserRegistration: AuthAPI["validateUserRegistration"] = useCallback(
    (guid) => {
      return apiValidateUserRegistration(guid);
    },
    [],
  );

  const updateUser: AuthAPI["updateUser"] = useCallback((newUser) => {
    return updateUserById(newUser).then(() => {
      setUser((prevUser) =>
        prevUser
          ? {
              ...prevUser,
              ...newUser,
            }
          : null,
      );
    });
  }, []);

  const updateUserPassword: AuthAPI["updateUserPassword"] = useCallback(
    (form) =>
      updateUserPasswordById((user as NonNullable<typeof user>).id, form),
    [user],
  );

  const lostPassword: AuthAPI["lostPassword"] = useCallback((email) => {
    return apiLostPassword(email);
  }, []);

  const resetPassword: AuthAPI["resetPassword"] = useCallback(
    (guid, password) => {
      return apiResetPassword(guid, password);
    },
    [],
  );

  const updateKyc: AuthAPI["updateKyc"] = useCallback(
    (kycType, form) =>
      apiUpdateKyc((user as NonNullable<typeof user>).id, kycType, form).then(
        (res) => {
          setUser((prevUser) => ({
            ...prevUser,
            ...mapUserRawToUser(res.data),
          }));
        },
      ),
    [user],
  );

  const loadConfigs: AuthAPI["loadConfigs"] = useCallback(
    () =>
      getConfigs().then(({ data }) => {
        const configToObject = data.reduce(
          (acc: Partial<Configs>, keyValue) => {
            acc[keyValue.key] = keyValue.value;
            return acc;
          },
          {},
        ) as Configs;
        setConfigs(configToObject);
      }),
    [],
  );

  const askAccountDeletion: AuthAPI["askAccountDeletion"] = useCallback(
    () => apiAskAccountDeletion(user!.id),
    [user],
  );

  const sendLinkUbiflowMail: AuthAPI["sendLinkUbiflowMail"] = useCallback(() => {
    return apiAskLinkUbiflowAccount(user!.id);
  }, [user]);

  return {
    loggedUser,
    user,
    configs,
    reloadUser,
    login,
    logout,
    checkUserValidity,
    register,
    validateUserRegistration,
    updateUser,
    updateUserPassword,
    lostPassword,
    resetPassword,
    updateKyc,
    loadConfigs,
    askAccountDeletion,
    sendLinkUbiflowMail,
  };
}

export function useAuth(): AuthAPI {
  return useContext(AuthContext) as AuthAPI;
}
