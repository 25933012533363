import React, { useCallback, useState } from "react";
import { withLoader } from "../../routing/useLoader";
import { usePlan } from "../planProvider";
import PlanChoiceDialog from "./PlanChoiceDialog";

interface Props {
  redirectPath?: string;
  className?: string;
}

const PlanChangeProposalBlock = ({
  redirectPath,
  className,
}: Props): JSX.Element => {
  const [showPlanChoice, setShowPlanChoice] = useState(false);

  return (
    <>
      <button
        className={"btn-1 btn-block"}
        onClick={() => setShowPlanChoice(true)}
        type={"button"}
      >
        Changer mon abonnement
      </button>
      {showPlanChoice && (
        <PlanChoiceDialog
          onClose={() => setShowPlanChoice(false)}
          redirectAction={() => setShowPlanChoice(false)}
          redirectPath={redirectPath}
        />
      )}
    </>
  );
};

const useLoad = () => {
  const { loadPlans } = usePlan();

  return useCallback(() => loadPlans(), [loadPlans]);
};

export default withLoader(PlanChangeProposalBlock, useLoad);
