import { parseISO } from "date-fns";
import { User } from "../auth/user";
import { orderByField } from "../data-structures/array";
import { FullOffer, Offer, OfferStatus } from "../offer/offer";

export interface ExchangeBase {
  type: ExchangeType;
  otherInformation?: string;
  vehicleMileage: number;
  vatCode: ExchangeVatCode;
  vehicleSalePrice: number | null;
  vehicleCountryCode: string;
  storageCountryCode: string;
  vehicleVin?: string;
  vehicleBrand: string;
  vehicleMetaModel?: string;
  vehicleModel: string;
  vehicleSubModel: string;
  vehicleEngine: string;
  vehicleEntryIntoService?: Date;
  vehiclePower: number;
  vehicleFiscalHorsepower?: number;
  vehicleEnergy: ExchangeVehicleEnergy;
  vehicleCo2Emission?: number;
  vehicleType: ExchangeVehicleType;
  vehicleColor: ExchangeVehicleColor;
  vehicleGearbox: ExchangeVehicleGearbox;
  vehicleTransmission: ExchangeVehicleTransmission;
  vehicleOriginCode: string;
  vehicleInnerColor: ExchangeVehicleInnerColor;
  vehicleSeatMaterial: ExchangeVehicleSeatMaterial;
  isVehicleDamaged: boolean;
  DetailedFeatures?: string[];
  Equipments?: string[];
  paymentValidationDate?: Date;
  soldDate?: Date;
  standalone: boolean;
  publicationEndDate?: Date;
  weproovReportLink?: string;
  adOrigin?: string;
}

export interface Exchange extends ExchangeBase {
  id: number;
  VehiclePictures: VehiclePicture[];
  VehicleDocuments?: VehicleDocument[];
  createdAt: Date;
  UserId: User["id"];
  isFavorite?: boolean;
  acceptedOffersCount?: number;
}

export interface FullExchange extends Exchange {
  User: User;
  Offers: Offer[];
}

export interface FullExchangeWithFullOffer extends Exchange {
  User: User;
  Offers: FullOffer[];
}

export interface FormatedExchange
  extends Omit<
    Exchange,
    | "vehicleSeatMaterial"
    | "vehicleInnerColor"
    | "vehicleTransmission"
    | "vehicleGearbox"
    | "vehicleColor"
    | "vehicleType"
    | "vehicleEnergy"
    | "type"
  > {
  vehicleSeatMaterial: string;
  vehicleInnerColor: string;
  vehicleTransmission: string;
  vehicleGearbox: string;
  vehicleColor: string;
  vehicleType: string;
  vehicleEnergy: string;
  vatPercentage: number;
  vehicleOriginCountry: string;
  vehicleCountry: string;
  storageCountry: string;
  type: string;
  description?: string;
}

export interface ExchangeRaw
  extends Omit<
    Exchange,
    | "vehicleEntryIntoService"
    | "createdAt"
    | "paymentValidationDate"
    | "soldDate"
    | "publicationEndDate"
  > {
  vehicleEntryIntoService: string;
  createdAt: string;
  paymentValidationDate?: string;
  soldDate?: string;
  publicationEndDate?: string;
}

export interface FullExchangeRaw extends ExchangeRaw {
  Offers: FullOffer[];
}

export interface ExchangeForm extends ExchangeBase {
  id?: Exchange["id"];
  confirmInformation: boolean;
  VehiclePictures: VehiclePicture[];
  VehicleDocuments: VehicleDocument[];
}

export interface ExchangeVinForm {
  vehicleVin?: Exchange["vehicleVin"];
  vehicleOriginCode?: Exchange["vehicleOriginCode"];
}

export interface ExchangeVinResult {
  vehicleBrand?: string;
  vehicleModel?: string;
  vehicleSubModel?: string;
  vehicleEngine?: string;
  vehiclePower?: number;
  vehicleFiscalHorsepower?: number;
  vehicleEnergy?: ExchangeVehicleEnergy;
  vehicleCo2Emission?: number;
  vehicleType?: ExchangeVehicleType;
  vehicleColor?: ExchangeVehicleColor;
  vehicleGearbox?: ExchangeVehicleGearbox;
  vehicleTransmission?: ExchangeVehicleTransmission;
  vehicleInnerColor?: ExchangeVehicleInnerColor;
}

export interface VehiclePictureBase {
  id?: number;
  file: string;
  order: number;
}

export interface VehiclePicture extends VehiclePictureBase {
  id: number;
}

export interface VehicleDocumentBase {
  file: string;
  name?: string;
}

export interface VehicleDocument extends VehicleDocumentBase {
  id: number;
}

export enum ExchangeType {
  "IN_STOCK",
  "RECOVERY_COVERAGE",
  "DEPOSIT_SALE",
}

export enum ExchangeVehicleEnergy {
  "DI" = "DI",
  "EL" = "EL",
  "GA" = "GA",
  "LP" = "LP",
  "HY" = "HY",
  "OT" = "OT",
}

export enum ExchangeVehicleType {
  "FOURXFOUR_SUV_CROSSOVER",
  "CITY_CAR",
  "SEDAN",
  "STATION_WAGON",
  "CONVERTIBLE",
  "COUPE",
  "MINIVAN",
  "BUS",
  "SMALL_VAN",
  "VAN",
  "PICK_UP",
  "COMPANY_CAR",
  "LICENSE_FREE",
  "TRUCK",
}

export enum ExchangeVehicleColor {
  "WHITE",
  "YELLOW/BEIGE",
  "ORANGE",
  "RED",
  "PURPLE",
  "BLUE",
  "GREEN",
  "SILVER/GREY",
  "BROWN",
  "BLACK",
  "BURGUNDY",
  "IVORY",
  "PINK",
}

export enum ExchangeVehicleInnerColor {
  "WHITE",
  "YELLOW/BEIGE",
  "ORANGE",
  "RED",
  "PURPLE",
  "BLUE",
  "GREEN",
  "SILVER/GREY",
  "BROWN",
  "BLACK",
  "IVORY",
  "FAWN",
}

export enum ExchangeVehicleGearbox {
  "manual" = "manual",
  "automatic" = "automatic",
  "other" = "other",
}

export enum ExchangeVehicleTransmission {
  "FA" = "FA",
  "HA" = "HA",
  "A" = "A",
}

export enum ExchangeVehicleSeatMaterial {
  "ALCANTARA",
  "LEATHER",
  "LEATHER_ALCANTARA",
  "LEATHER_FABRIC",
  "SHEET",
  "JEAN",
  "FAUX_LEATHER",
  "FABRIC",
  "TWEED",
  "VELVET",
}

export enum ExchangeVatCode {
  "Z" = 0,
  "DE-19" = 0.19,
  "AT-20" = 0.2,
  "BE-21" = 0.21,
  "BG-20" = 0.2,
  "CY-19" = 0.19,
  "HR-25" = 0.25,
  "DK-25" = 0.25,
  "ES-21" = 0.21,
  "EE-20" = 0.2,
  "FI-24" = 0.24,
  "FR-20" = 0.2,
  "GR-24" = 0.24,
  "HU-27" = 0.27,
  "IE-23" = 0.23,
  "IT-22" = 0.22,
  "LV-21" = 0.21,
  "LT-21" = 0.21,
  "LU-17" = 0.17,
  "MT-18" = 0.18,
  "NL-21" = 0.21,
  "PL-23" = 0.23,
  "PT-23" = 0.23,
  "CZ-21" = 0.21,
  "RO-19" = 0.19,
  "GB-20" = 0.2,
  "SK-20" = 0.2,
  "SI-22" = 0.22,
  "SE-25" = 0.25,
}

export function mapRawExchangeToExchange(
  exchangeRaw: FullExchangeRaw,
): FullExchange;
export function mapRawExchangeToExchange(exchangeRaw: ExchangeRaw): Exchange;
export function mapRawExchangeToExchange(
  exchangeRaw: ExchangeRaw | FullExchangeRaw,
): Exchange | FullExchange {
  return {
    ...exchangeRaw,
    soldDate: exchangeRaw.soldDate ? parseISO(exchangeRaw.soldDate) : undefined,
    vehicleEntryIntoService: exchangeRaw.vehicleEntryIntoService
      ? parseISO(exchangeRaw.vehicleEntryIntoService)
      : undefined,
    createdAt: parseISO(exchangeRaw.createdAt),
    VehiclePictures: [...exchangeRaw.VehiclePictures].sort(
      orderByField("order", false),
    ),
    paymentValidationDate: exchangeRaw.paymentValidationDate
      ? parseISO(exchangeRaw.paymentValidationDate)
      : undefined,
    publicationEndDate: exchangeRaw.publicationEndDate
      ? parseISO(exchangeRaw.publicationEndDate)
      : undefined,
  };
}

export enum ExchangeStatus {
  STATUS_PUBLISHED = "P",
  STATUS_UNPUBLISHED = "U",
  STATUS_SOLD = "S",
}

export function getBestOfferStatus(offer: Offer): OfferStatus | ExchangeStatus {
  if (
    offer.status === OfferStatus.STATUS_VALIDATED &&
    offer.Exchange.soldDate
  ) {
    return ExchangeStatus.STATUS_SOLD;
  }

  return offer.status;
}

export function getBestExchangeOffersStatus(
  exchange: FullExchange,
): OfferStatus | ExchangeStatus {
  const statusPriority = {
    [OfferStatus.STATUS_VALIDATED]: 3,
    [OfferStatus.STATUS_ACCEPTED]: 2,
    [OfferStatus.STATUS_CANCELED]: 1,
    [OfferStatus.STATUS_DECLINED]: 1,
    [OfferStatus.STATUS_PENDING]: 1,
  };

  if (exchange.soldDate) {
    return ExchangeStatus.STATUS_SOLD;
  }

  return exchange.Offers.reduce((statusAcc, offer) => {
    if (statusPriority[offer.status] > statusPriority[statusAcc]) {
      return offer.status;
    }
    return statusAcc;
  }, OfferStatus.STATUS_PENDING);
}

export const vinLength = 17;
export const maxVinSubmit = 2;
