import PlanForm from "../../../../services/plan/components/PlanForm";

const NewPlan = (): JSX.Element => {
  return (
    <div className={"container page-content"}>
      <div className={"page_head"}>
        <h1 className={"page-title"}>Nouveau plan</h1>
        <div className={"section-l"}>
          <PlanForm />
        </div>
      </div>
    </div>
  );
};

export default NewPlan;
