import {
  getSentOffers,
  getReceivedOffers,
  getAllOffers,
  cancelOffer as apiCancelOffer,
} from "./api";
import {
  createContext,
  useContext,
  useState,
  useCallback,
  ComponentType,
} from "react";
import { FullOffer, Offer, mapRawOfferToOffer } from "./offer";
import { AuthAPIConnected, useAuth } from "../auth/apiProvider";

export interface OfferAPI {
  sentOffers: Offer[];
  receivedOffers: Offer[];
  offers: FullOffer[];

  loadSentOffers(): Promise<void>;
  loadReceivedOffers(): Promise<void>;
  loadAllOffers(): Promise<void>;
  cancelOffer(offerId: Offer["id"]): Promise<void>;
}

export const OfferContext = createContext<OfferAPI | null>(null);

export function ProvideOffer({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const [sentOffers, setSentOffers] = useState<Offer[]>([]);
  const [receivedOffers, setReceivedOffers] = useState<Offer[]>([]);
  const [offers, setOffers] = useState<FullOffer[]>([]);
  const { user } = useAuth() as AuthAPIConnected;

  const loadSentOffers: OfferAPI["loadSentOffers"] = useCallback(
    () =>
      getSentOffers(user.id).then(({ data }) =>
        setSentOffers(data.map(mapRawOfferToOffer)),
      ),
    [user.id],
  );

  const loadReceivedOffers: OfferAPI["loadReceivedOffers"] = useCallback(
    () =>
      getReceivedOffers(user.id).then(({ data }) => {
        setReceivedOffers(data.map(mapRawOfferToOffer));
      }),
    [user.id],
  );

  const loadAllOffers: OfferAPI["loadAllOffers"] = useCallback(
    () =>
      getAllOffers().then(({ data }) => {
        setOffers(data.map(mapRawOfferToOffer));
      }),
    [],
  );

  const cancelOffer: OfferAPI["cancelOffer"] = useCallback(
    (offerId) =>
      apiCancelOffer(offerId).then(async ({ data: newOffer }) => {
        setOffers((prevOffers) => {
          const offers = [...prevOffers];
          offers[
            prevOffers.findIndex((offer) => offer.id === newOffer.id)
          ] = mapRawOfferToOffer(newOffer) as FullOffer;
          return offers;
        });
      }),
    [],
  );

  return (
    <OfferContext.Provider
      value={{
        sentOffers,
        receivedOffers,
        offers,
        loadSentOffers,
        loadReceivedOffers,
        loadAllOffers,
        cancelOffer,
      }}
    >
      {children}
    </OfferContext.Provider>
  );
}

export function useOffer(): OfferAPI {
  return useContext(OfferContext) as OfferAPI;
}
export function withProvideOffer<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function withProvideOffer(props: P) {
    return (
      <ProvideOffer>
        <WrappedComponent {...props} />
      </ProvideOffer>
    );
  }

  withProvideOffer.displayName = `withProvideOffer(${displayName})`;

  return withProvideOffer;
}
