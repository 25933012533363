export const PUBLIC = "/public" as const;

export const LOGIN = "/login" as const;
export const LOGIN_LINK = `${PUBLIC}${LOGIN}` as const;

export const REGISTER = "/register" as const;
export const REGISTER_LINK = `${PUBLIC}${REGISTER}` as const;

export const LOST_PASSWORD = "/lost-password" as const;
export const LOST_PASSWORD_LINK = `${PUBLIC}${LOST_PASSWORD}` as const;

export const RESET_PASSWORD = "/reset-password/:guid" as const;
export const RESET_PASSWORD_LINK = `${PUBLIC}${RESET_PASSWORD}` as const;

export const USER_PLANS_PUBLIC = "/user-plans" as const;
export const USER_PLANS_PUBLIC_LINK = `${PUBLIC}${USER_PLANS_PUBLIC}` as const;

export const PUBLIC_CONTACT = "/contact" as const;
export const PUBLIC_CONTACT_LINK = `${PUBLIC}${PUBLIC_CONTACT}` as const;
