import React, { useCallback } from "react";
import { Navigate, Route } from "react-router-dom";
import {
  ADMIN_ACCOUNTS_MANAGEMENT,
  ADMIN_ACCOUNT_LISTING,
  ADMIN_EXCHANGE_LISTING,
  ADMIN_OFFERS,
  ADMIN_PLANS,
  ADMIN_SUMMARY,
  ADMIN_SUMMARY_LINK,
} from "../../../routes/admin";
import {
  ADMIN_ACCOUNT_PROFILE,
  ADMIN_CREATE_EXCHANGE,
} from "../../../routes/admin/accounts";
import {
  ADMIN_EXCHANGE_DETAIL,
  ADMIN_EXCHANGE_EDIT,
} from "../../../routes/admin/exchange-listing";
import { withProvideExchangeListFilters } from "../../../services/exchange/exchangeListFiltersProvider";
import {
  useExchange,
  withProvideExchange,
} from "../../../services/exchange/exchangeProvider";
import Routes from "../../../services/routing/Routes";
import { withLoader } from "../../../services/routing/useLoader";
import ExchangeDetail from "../exchange/ExchangeDetail";
import ExchangeEdit from "../exchange/ExchangeEdit";
import AccountProfile from "./AccountProfile";
import AccountsManagement from "./AccountsManagement";
import AdminCreateExchange from "./AdminCreateExchange";
import AdminDashboard from "./AdminDashboard";
import AdminExchangeListing from "./AdminExchangeListing";
import AdminOffersListing from "./AdminOffersListing";
import Plans from "./plans/Plans";
import AccountsListing from "./AccountsListing";

const Admin = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<Navigate to={ADMIN_SUMMARY_LINK} replace />} index />
      <Route element={<AdminDashboard />} path={`${ADMIN_SUMMARY}/*`} />

      <Route element={<Plans />} path={`${ADMIN_PLANS}/*`} />
      <Route element={<AdminOffersListing />} path={`${ADMIN_OFFERS}/*`} />

      <Route path={`${ADMIN_EXCHANGE_LISTING}`}>
        <Route element={<AdminExchangeListing />} index />
        <Route
          element={<ExchangeDetail />}
          path={`${ADMIN_EXCHANGE_LISTING}/${ADMIN_EXCHANGE_DETAIL}`}
        />
        <Route
          element={<ExchangeEdit />}
          path={`${ADMIN_EXCHANGE_LISTING}/${ADMIN_EXCHANGE_EDIT}`}
        />
      </Route>

      <Route path={`${ADMIN_ACCOUNTS_MANAGEMENT}`}>
        <Route element={<AccountsManagement />} index />
        <Route
          element={<AccountProfile />}
          path={`${ADMIN_ACCOUNTS_MANAGEMENT}${ADMIN_ACCOUNT_PROFILE}`}
        />
        <Route
          element={<AdminCreateExchange />}
          path={`${ADMIN_ACCOUNTS_MANAGEMENT}${ADMIN_CREATE_EXCHANGE}`}
        />
      </Route>
      <Route path={`${ADMIN_ACCOUNT_LISTING}`}>
        <Route element={<AccountsListing />} index />
      </Route>
    </Routes>
  );
};

const useLoad = () => {
  const { loadFormOptions } = useExchange();

  return useCallback(() => loadFormOptions(), [loadFormOptions]);
};

export default withProvideExchangeListFilters(
  withProvideExchange(withLoader(Admin, useLoad)),
);
