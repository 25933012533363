import { EXCHANGE_LINK } from "./private";

export const EXCHANGE_LISTING = "/listing" as const;
export const EXCHANGE_LISTING_LINK = `${EXCHANGE_LINK}${EXCHANGE_LISTING}` as const;

export const NEW_EXCHANGE_VEHICLE = "/new-vehicle" as const;
export const NEW_EXCHANGE_VEHICLE_LINK = `${EXCHANGE_LINK}${NEW_EXCHANGE_VEHICLE}` as const;

export const EXCHANGE_DETAIL = "/:exchangeId" as const;
export const EXCHANGE_DETAIL_LINK = `${EXCHANGE_LINK}${EXCHANGE_DETAIL}` as const;

export const EDIT_EXCHANGE = `${EXCHANGE_DETAIL}/edit` as const;
export const EDIT_EXCHANGE_LINK = `${EXCHANGE_DETAIL_LINK}/edit` as const;
