import AccountValidationInfo from "../components/AccountValidationInfo";

/* images and icons */
import DashboardImage from "../../assets/img/dashboard-image.svg";
import { AuthAPIConnected, useAuth } from "../../services/auth/apiProvider";
import Routes from "../../services/routing/Routes";
import { Link, Navigate, Route } from "react-router-dom";
import Favorites from "./dashboard/Favorites";
import NavLink from "../../services/routing/components/NavLink";
import MyExchanges from "./dashboard/MyExchanges";
import {
  FAVORITES,
  FAVORITES_LINK,
  INFO_REQUESTS,
  INFO_REQUESTS_LINK,
  MY_EXCHANGES,
  MY_EXCHANGES_LINK,
  RECEIVED_OFFERS,
  RECEIVED_OFFERS_LINK,
  SENT_OFFERS,
  SENT_OFFERS_LINK,
} from "../../routes/dashboard";
import SentOffers from "./dashboard/SentOffers";
import ReceivedOffers from "./dashboard/ReceivedOffers";
import { withLoader } from "../../services/routing/useLoader";
import { useCallback } from "react";
import { useExchange } from "../../services/exchange/exchangeProvider";
import InfoRequestListing from "./dashboard/InfoRequestListing";
import { withProvideInfoRequest } from "../../services/info-request/infoRequestProvider";
import { PROFILE_LINK } from "src/routes/private";
import { EXCHANGE_LISTING_LINK } from "src/routes/exchange";

const Dashboard: React.FC = () => {
  const { user } = useAuth() as AuthAPIConnected;

  return (
    <div>
      <div className={"dashboard-header"}>
        <AccountValidationInfo linkToAccount={true} user={user} />
        {user.Plan?.type === 0 && (
          <div className={"section container-wrap"}>
            <div className={"section --bg --light --txt-center body-s"}>
              Votre plan actuel est un plan gratuit{" "}
              <strong>{user.Plan.label}</strong>.
              <br />
              Souhaitez-vous{" "}
              <Link to={PROFILE_LINK}>
                <strong>souscrire à un abonnement</strong>
              </Link>{" "}
              pour profiter pleinement de la plateforme ?
            </div>
          </div>
        )}

        <div className={"section-l --txt-center"}>
          <img
            alt={"electric car"}
            className={"central-image"}
            src={DashboardImage}
          />
          <div className={"m-top"}>
            <Link className={"btn"} to={EXCHANGE_LISTING_LINK}>
              Voir toutes les annonces
            </Link>
          </div>
        </div>

        <div className={"tabs"}>
          <NavLink className={"tab-item"} to={SENT_OFFERS_LINK}>
            Offres émises
          </NavLink>
          <NavLink className={"tab-item"} to={RECEIVED_OFFERS_LINK}>
            Offres reçues
          </NavLink>
          <NavLink className={"tab-item"} to={MY_EXCHANGES_LINK}>
            Mes annonces
          </NavLink>
          <NavLink className={"tab-item"} to={FAVORITES_LINK}>
            Mes favoris
          </NavLink>
          <NavLink className={"tab-item"} to={INFO_REQUESTS_LINK}>
            Demandes de renseignements
          </NavLink>
        </div>
      </div>

      <div className={"section-xl container"}>
        <Routes>
          <Route element={<Navigate to={MY_EXCHANGES_LINK} />} index />
          <Route element={<SentOffers />} path={SENT_OFFERS} />
          <Route element={<ReceivedOffers />} path={RECEIVED_OFFERS} />
          <Route element={<MyExchanges />} path={MY_EXCHANGES} />
          <Route element={<Favorites />} path={FAVORITES} />
          <Route element={<InfoRequestListing />} path={INFO_REQUESTS} />
        </Routes>
      </div>
    </div>
  );
};

const useLoad = () => {
  const { loadFormOptions } = useExchange();

  return useCallback(() => loadFormOptions(), [loadFormOptions]);
};

export default withLoader(withProvideInfoRequest(Dashboard), useLoad);
