import { useMemo, useState } from "react";
import { NEW_EXCHANGE_VEHICLE_LINK } from "../../../routes/exchange";
import { AuthAPIConnected, useAuth } from "../../../services/auth/apiProvider";
import {
  filterOnPrecondition,
  orderByField,
} from "../../../services/data-structures/array";
import ExchangeList from "../../../services/exchange/components/ExchangeList";
import Link from "../../../services/routing/components/Link";

enum Filter {
  All = "all",
  Published = "published",
  Unpublished = "unpublished",
}

const MyExchanges = (): JSX.Element => {
  const { user } = useAuth() as AuthAPIConnected;
  const [filter, setFilter] = useState(Filter.All);

  const filteredExchanges = useMemo(() => {
    let exchangeList = [...user.Exchanges];
    exchangeList = filterOnPrecondition(
      exchangeList,
      filter !== null,
      (exchange) =>
        filter === Filter.Published
          ? !!exchange.publicationEndDate &&
            exchange.publicationEndDate > new Date(Date.now())
          : filter === Filter.Unpublished
          ? !exchange.publicationEndDate ||
            exchange.publicationEndDate < new Date(Date.now())
          : true,
    );
    return exchangeList;
  }, [filter, user.Exchanges]);

  const sortedExchanges = useMemo(
    () => [...filteredExchanges].sort(orderByField("createdAt")),
    [filteredExchanges],
  );

  return (
    <div>
      <div className={"page_head"}>
        <h2 id={"myExchanges"}>Mes annonces</h2>
        {user.isValid && (
          <Link className={"btn"} to={NEW_EXCHANGE_VEHICLE_LINK}>
            Déposer une annonce
          </Link>
        )}
      </div>

      <div className={"section my-exchanges"}>
        {user.Exchanges.length ? (
          <>
            <div className={"--pos-l"}>
              <div className={"form-block --inline"}>
                <label className={"input-label --s --secondary"}>
                  Annonces :{" "}
                </label>
                <select
                  className={"select --w-l"}
                  onChange={(ev) => setFilter(ev.target.value as Filter)}
                  value={filter}
                >
                  <option value={Filter.All}>Toutes</option>
                  <option value={Filter.Published}>Publiées</option>
                  <option value={Filter.Unpublished}>Non Publiées</option>
                </select>
              </div>
            </div>
            <ExchangeList exchanges={sortedExchanges} />
          </>
        ) : (
          <div className={"info"}>Vous n'avez encore déposé aucune annonce</div>
        )}
      </div>
    </div>
  );
};

export default MyExchanges;
