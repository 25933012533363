import { AxiosPromise } from "axios";
import {
  ComponentType,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import {
  getUserById,
  updateUserById,
  updateUserPasswordById,
  updateKyc as apiUpdateKyc,
  archiveUserById,
} from "../auth/api";
import {
  mapUserRawToUser,
  User,
  UserKyc,
  UserPasswordForm,
  UserToUpdateForm,
} from "../auth/user";
import {
  downgradeAccount,
  getAccounts,
  unvalidateAccount,
  upgradeAccount,
  validateAccount,
  invalidateAccount,
} from "./api";

export interface AccountsApi {
  account: User | null;
  accounts: User[];

  loadAccount: (accountId: User["id"]) => Promise<void>;
  loadAccounts: () => Promise<void>;

  updateUser(newUser: UserToUpdateForm): Promise<void>;
  updateUserPassword(form: UserPasswordForm): AxiosPromise<void>;
  updateKyc(kycType: keyof UserKyc, form: Partial<UserKyc>): Promise<void>;

  toggleAccountValidation: () => Promise<void>;
  toggleAccountInvalidation: () => Promise<void>;
  toggleAccountSuperAdmin: () => Promise<void>;

  archiveUser: (userId: User["id"]) => Promise<void>;
}

export interface AccountsApiLoadedAccount extends AccountsApi {
  account: NonNullable<AccountsApi["account"]>;
}

export const AccountsContext = createContext<AccountsApi | null>(null);

export const ProvideAccounts = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [account, setAccount] = useState<AccountsApi["account"]>(null);
  const [accounts, setAccounts] = useState<AccountsApi["accounts"]>([]);

  const loadAccount: AccountsApi["loadAccount"] = useCallback(
    (id) =>
      getUserById(id).then(({ data }) => {
        setAccount(mapUserRawToUser(data));
      }),
    [],
  );

  const loadAccounts = useCallback(
    () =>
      getAccounts().then(({ data }) => {
        setAccounts(data.map(mapUserRawToUser));
      }),
    [],
  );

  const updateUser: AccountsApi["updateUser"] = useCallback((newUser) => {
    return updateUserById(newUser).then(() => {
      setAccount((prevUser) =>
        prevUser
          ? {
              ...prevUser,
              ...newUser,
            }
          : null,
      );
    });
  }, []);

  const updateUserPassword: AccountsApi["updateUserPassword"] = useCallback(
    (form) =>
      updateUserPasswordById((account as NonNullable<typeof account>).id, form),
    [account],
  );

  const updateKyc: AccountsApi["updateKyc"] = useCallback(
    (kycType, form) =>
      apiUpdateKyc(
        (account as NonNullable<typeof account>).id,
        kycType,
        form,
      ).then((res) => {
        setAccount((prevUser: User) => ({
          ...prevUser,
          ...mapUserRawToUser(res.data),
        }));
      }),
    [account],
  );

  const toggleAccountValidation: AccountsApi["toggleAccountValidation"] = useCallback(
    () =>
      (!account?.isValid ? validateAccount : unvalidateAccount)(
        account?.id as User["id"],
      ).then(({ data }) => {
        setAccount(mapUserRawToUser(data));
      }),
    [account?.id, account?.isValid],
  );

  const toggleAccountInvalidation: AccountsApi["toggleAccountInvalidation"] = useCallback(
    () =>
      invalidateAccount(account?.id as User["id"]).then(({ data }) => {
        setAccount(mapUserRawToUser(data));
      }),
    [account?.id],
  );

  const toggleAccountSuperAdmin: AccountsApi["toggleAccountSuperAdmin"] = useCallback(
    () =>
      (account?.superAdmin ? downgradeAccount : upgradeAccount)(
        account?.id as User["id"],
      ).then(({ data }) => {
        setAccount(mapUserRawToUser(data));
      }),
    [account?.id, account?.superAdmin],
  );

  const archiveUser: AccountsApi["archiveUser"] = useCallback((userId) => {
    return archiveUserById(userId).then(({ data: newUser }) => {
      setAccounts((prevUsers: User[]) => [
        ...prevUsers.filter((user) => user.id !== newUser.id),
        mapUserRawToUser(newUser),
      ]);
    });
  }, []);

  return (
    <AccountsContext.Provider
      value={{
        account,
        accounts,
        loadAccount,
        loadAccounts,
        updateUser,
        updateUserPassword,
        updateKyc,
        toggleAccountValidation,
        toggleAccountInvalidation,
        toggleAccountSuperAdmin,
        archiveUser,
      }}
    >
      {children}
    </AccountsContext.Provider>
  );
};

export function withProvideAccounts<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideAccounts(props: P) {
    return (
      <ProvideAccounts>
        <WrappedComponent {...props} />
      </ProvideAccounts>
    );
  }

  WithProvideAccounts.displayName = `withProvideAccounts(${displayName})`;

  return WithProvideAccounts;
}

export function useAccounts(): AccountsApi {
  return useContext(AccountsContext) as AccountsApi;
}
