import { cx } from "@emotion/css";
import { USER_PLANS_PRIVATE_LINK } from "../../../routes/private";
import { USER_PLANS_PUBLIC_LINK } from "../../../routes/public";
import { useAuth } from "../../auth/apiProvider";
import PlanChangeProposalBlock from "./PlanChangeProposalBlock";
import { FullPlan, getSubscibedPlanPrice } from "../plan";
import Link from "../../routing/components/Link";

const PlanCard = ({
  plan,
  active,
}: {
  plan: FullPlan;
  active?: boolean;
}): JSX.Element => {
  const { user, configs } = useAuth();

  return (
    <div className={cx("plan-card", active && "active")}>
      <div className={"card-body"}>
        <h2 className={"plan-name"}>{plan.label.replaceAll("_", " ")}</h2>
        <div className={"plan-price"}>
          {getSubscibedPlanPrice(plan, configs!["PLAN_OPTION_BUYER_PRICE"])}
          <sup>€ {plan.type !== 0 ? "HT / mois" : ""}</sup>
        </div>
        <ul className={"plan-features"}>
          {plan.maxExchangePostNb === 0 ? (
            <li>dépôt d’annonces payant</li>
          ) : plan.maxExchangePostNb < 0 ? (
            <li>dépôt d’annonces illimité</li>
          ) : (
            <li>{plan.maxExchangePostNb} annonces actives</li>
          )}
          {plan.maxExchangeOfferDailyNb === 0 ? (
            <li>offres payantes</li>
          ) : plan.maxExchangeOfferDailyNb < 0 || plan.Subscription.isBuyer ? (
            <li>offres illimitées</li>
          ) : (
            <li>{plan.maxExchangeOfferDailyNb} offres / jour</li>
          )}
        </ul>
        <div className={"plan-actions"}>
          <PlanChangeProposalBlock />
          <Link
            className={"link"}
            rel={"noopener noreferrer"}
            target={"_blank"}
            to={user ? USER_PLANS_PRIVATE_LINK : USER_PLANS_PUBLIC_LINK}
          >
            en savoir plus
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
