import { Field } from "formik";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { object, boolean, string } from "yup";
import { AuthAPIConnected, useAuth } from "../../auth/apiProvider";
import AutoField from "../../forms/AutoField";
import Form from "../../forms/Form";
import SubmitButton from "../../forms/SubmitButton";
import ValidationsErrors from "../../forms/ValidationsErrors";
import { InfoRequestForm } from "../infoRequest";
import PlanChoiceDialog from "../../plan/components/PlanChoiceDialog";
import Dialog from "../../ui/block/Dialog";

const InfoRequestDialog = ({
  showWeproov,
  onClose,
  onSubmit,
}: {
  showWeproov: boolean;
  onClose: () => void;
  onSubmit: (infoRequestForm: InfoRequestForm) => Promise<void>;
}): JSX.Element => {
  const { user } = useAuth() as AuthAPIConnected;
  const { t } = useTranslation(["info_request"]);
  const [showOtherMessage, setShowOtherMessage] = useState(false);
  const [showPlanChoice, setShowPlanChoice] = useState(false);

  const leftCredits = useMemo(
    () =>
      user.Plan!.maxInfoRequestDailyNb !== -1
        ? user.Plan!.maxInfoRequestDailyNb - user.dailyRequestsNb
        : Infinity,
    [user.Plan, user.dailyRequestsNb],
  );

  const InfoRequestSchema = useMemo(
    () =>
      object().shape({
        invoice: boolean()
          .label(t("info_request:invoice_request"))
          .default(false),
        picture: boolean()
          .label(t("info_request:picture_request"))
          .default(false),
        video: boolean().label(t("info_request:video_request")).default(false),
        weproov: boolean()
          .label(t("info_request:weproov_request"))
          .default(false),
        message: string().multiline().nullable(),
      }),
    [t],
  );

  return (
    <Dialog onClose={onClose}>
      <div className={"card"}>
        <div className={"card-head"}>
          <h2 className={"card-title"}>Demander des informations</h2>
        </div>
        <div className={"card-body lay-col --gap-v"}>
          <p className={"info"}>
            Vous êtes intéressé par ce véhicule mais avez besoin d’informations
            complémentaires pour vous décider. Sélectionnez parmi la liste les
            éléments que vous souhaitez parcourir :
          </p>
          <Form
            initialValues={{}}
            onSubmit={(values: InfoRequestForm) => {
              if (
                values.invoice ||
                values.picture ||
                values.video ||
                values.weproov ||
                (showOtherMessage && values.message)
              ) {
                return onSubmit(values);
              } else {
                return Promise.resolve();
              }
            }}
            schema={InfoRequestSchema}
          >
            <div className={"form-block --stretch"}>
              <label className={"input-label"}>
                <Field name={"invoice"} type={"checkbox"} />
                {t("info_request:invoice_request")}
              </label>
            </div>
            <div className={"form-block --stretch"}>
              <label className={"input-label"}>
                <Field name={"picture"} type={"checkbox"} />
                {t("info_request:picture_request")}
              </label>
            </div>
            <div className={"form-block --stretch"}>
              <label className={"input-label"}>
                <Field name={"video"} type={"checkbox"} />
                {t("info_request:video_request")}
              </label>
            </div>
            {showWeproov && (
              <div className={"form-block --stretch"}>
                <label className={"input-label"}>
                  <Field name={"weproov"} type={"checkbox"} />
                  {t("info_request:weproov_request")}
                </label>
              </div>
            )}
            <div className={"form-block --stretch"}>
              <label className={"input-label"}>
                <input
                  name={"video"}
                  onChange={() =>
                    setShowOtherMessage((prevOtherMessage) => !prevOtherMessage)
                  }
                  type={"checkbox"}
                />
                {t("info_request:other_request")}
              </label>
              {showOtherMessage && (
                <AutoField
                  name={"message"}
                  placeholder={
                    "Ce message ne sera pas envoyé au vendeur mais à un administrateur Autolity qui étudiera votre demande."
                  }
                />
              )}
            </div>

            <div className={"plan-cta content-block"}>
              <h2 className={"plan-name"}>Abonnement « {user.Plan!.label} »</h2>
              <p>
                {user.Plan!.maxInfoRequestDailyNb === -1
                  ? "Demandes illimitées"
                  : `${leftCredits} crédit(s) restant(s) aujourd'hui`}
              </p>
              {leftCredits === 0 && (
                <div>
                  <button
                    className={"link --accent"}
                    onClick={() => setShowPlanChoice(true)}
                    type={"button"}
                  >
                    voir les abonnements premium
                  </button>
                </div>
              )}
            </div>

            <div className={"form-footer"}>
              <ValidationsErrors />
              <SubmitButton disabled={leftCredits === 0}>
                Envoyer la demande{" "}
                {leftCredits > 0 && leftCredits !== Infinity && "(1 crédit)"}
              </SubmitButton>
            </div>
          </Form>
        </div>
      </div>
      {showPlanChoice && (
        <PlanChoiceDialog
          onClose={() => setShowPlanChoice(false)}
          redirectAction={() => setShowPlanChoice(false)}
        />
      )}
    </Dialog>
  );
};

export default InfoRequestDialog;
