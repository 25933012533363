import React, { useCallback } from "react";
import { usePlan, PlanApiLoaded } from "../../../../services/plan/planProvider";
import { withLoader } from "../../../../services/routing/useLoader";
import { useParams } from "../../../../services/routing/useParams";
import iconTrash from "../../../../assets/img/icons/icon-trash-white.svg";
import { useConfirmationWithIntl } from "../../../../services/ui/ConfirmationDialog";

const PlanDetail = (): JSX.Element => {
  const { plan, unassignPlan } = usePlan() as PlanApiLoaded;

  const { confirm } = useConfirmationWithIntl("plan");

  return (
    <div className={"container page-content"}>
      <h1 className={"page-title --underlined"}>
        Plan {plan.label.replaceAll("_", " ")}
      </h1>

      <div className={"section-l"}>
        <h2 className={"section-title"}>Contenu</h2>
        <div className={"section"}>
          <p>
            Prix mensuel : <strong>{plan.monthlyPrice} €</strong>
          </p>
        </div>
      </div>

      <div className={"section-l"}>
        <h2 className={"section-title"}>Utilisateurs assignés</h2>
        <div className={"section"}>
          <div className={"table-container"}>
            <table className={"table"}>
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Nom</th>
                  <th>Prénom</th>
                  <th>Entreprise</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {plan.Assignations!.map((user) => (
                  <tr key={user.id}>
                    <td>{user.email}</td>
                    <td>{user.lastname}</td>
                    <td>{user.firstname}</td>
                    <td>{user.companyName}</td>
                    <td>
                      <button
                        className={"btn-danger"}
                        onClick={() =>
                          confirm(
                            "confirm_unassign_plan_content",
                            "confirm_unassign_plan_title",
                            () => unassignPlan(plan.id, user.id!),
                            true,
                            { closable: false },
                          )
                        }
                      >
                        <img alt={"trash"} className={"icon"} src={iconTrash} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const useLoad = () => {
  const { loadPlanById } = usePlan();
  const { planId } = useParams();

  return useCallback(async () => loadPlanById(Number(planId)), [
    loadPlanById,
    planId,
  ]);
};

export default withLoader(PlanDetail, useLoad);
