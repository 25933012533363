export const baseURL = process.env.REACT_APP_API_HOST;
export const hostBaseURL = process.env.REACT_APP_HOST;

export interface ConfigRaw {
  key: ConfigKey;
  value: string;
}
export type Configs = Record<ConfigKey, string>;

export enum ConfigKey {
  "BUYING_OPTION_NO_CONDITION" = "BUYING_OPTION_NO_CONDITION",
  "BUYING_OPTION_WEPROOV" = "BUYING_OPTION_WEPROOV",
  "BUYING_OPTION_INSPECTION" = "BUYING_OPTION_INSPECTION",
  "BUYING_OPTION_EXPENSES_LIST" = "BUYING_OPTION_EXPENSES_LIST",
  "PAYMENT_OPTION_DIRECT" = "PAYMENT_OPTION_DIRECT",
  "PAYMENT_OPTION_SECURE" = "PAYMENT_OPTION_SECURE",
  "PAYMENT_OPTIONS_RECEPTION" = "PAYMENT_OPTIONS_RECEPTION",
  "FILE_FEES_RANGE1" = "FILE_FEES_RANGE1",
  "FILE_FEES_RANGE2" = "FILE_FEES_RANGE2",
  "FILE_FEES_RANGE3" = "FILE_FEES_RANGE3",
  "FILE_FEES_RANGE4" = "FILE_FEES_RANGE4",
  "FILE_FEES_RANGE5" = "FILE_FEES_RANGE5",
  "FILE_FEES_RANGE6" = "FILE_FEES_RANGE6",
  "FILE_FEES_RANGE7" = "FILE_FEES_RANGE7",
  "FILE_FEES_RANGE8" = "FILE_FEES_RANGE8",
  "SELLING_FEES_RANGE1" = "SELLING_FEES_RANGE1",
  "SELLING_FEES_RANGE2" = "SELLING_FEES_RANGE2",
  "SELLING_FEES_RANGE3" = "SELLING_FEES_RANGE3",
  "SELLING_FEES_RANGE4" = "SELLING_FEES_RANGE4",
  "SELLING_FEES_RANGE5" = "SELLING_FEES_RANGE5",
  "SELLING_FEES_RANGE6" = "SELLING_FEES_RANGE6",
  "SELLING_FEES_RANGE7" = "SELLING_FEES_RANGE7",
  "SELLING_FEES_RANGE8" = "SELLING_FEES_RANGE8",
  "SELLING_FEES_SUBSCRIPTION_RANGE1" = "SELLING_FEES_SUBSCRIPTION_RANGE1",
  "SELLING_FEES_SUBSCRIPTION_RANGE2" = "SELLING_FEES_SUBSCRIPTION_RANGE2",
  "SELLING_FEES_SUBSCRIPTION_RANGE3" = "SELLING_FEES_SUBSCRIPTION_RANGE3",
  "SELLING_FEES_SUBSCRIPTION_RANGE4" = "SELLING_FEES_SUBSCRIPTION_RANGE4",
  "SELLING_FEES_SUBSCRIPTION_RANGE5" = "SELLING_FEES_SUBSCRIPTION_RANGE5",
  "SELLING_FEES_SUBSCRIPTION_RANGE6" = "SELLING_FEES_SUBSCRIPTION_RANGE6",
  "SELLING_FEES_SUBSCRIPTION_RANGE7" = "SELLING_FEES_SUBSCRIPTION_RANGE7",
  "SELLING_FEES_SUBSCRIPTION_RANGE8" = "SELLING_FEES_SUBSCRIPTION_RANGE8",
  "PLAN_OPTION_BUYER_PRICE" = "PLAN_OPTION_BUYER_PRICE",
  "PLAN_OPTION_ANNUAL_BUYER_PRICE" = "PLAN_OPTION_ANNUAL_BUYER_PRICE",
}
