import { cx } from "@emotion/css";
import { Field, FieldProps } from "formik";
import React, { FunctionComponent } from "react";

interface Props
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  className?: string;
  name: string;
}

const FSelectWithoutParse: FunctionComponent<Props> = ({
  className,
  name,
  children,
  ...otherProps
}) => {
  return (
    <Field name={name}>
      {({
        field: { value },
        form: { setFieldValue, setFieldTouched },
      }: FieldProps) => (
        <select
          className={cx("select", className)}
          onBlur={() => setFieldTouched(name)}
          onChange={(event) => {
            setFieldValue(name, event.target.value);
          }}
          value={value === null ? "" : value}
          {...otherProps}
        >
          {children}
        </select>
      )}
    </Field>
  );
};

export default FSelectWithoutParse;
