import { AuthAPIConnected, useAuth } from "../../../services/auth/apiProvider";
import ExchangeForm from "../../../services/exchange/components/exchangeForm/ExchangeForm";

const NewVehicle = (): JSX.Element => {
  const { user } = useAuth() as AuthAPIConnected;

  return (
    <div className={"container-wrap page-content"}>
      <div className={"page_head"}>
        <h1 className={"page-title"}>Nouvelle annonce bourse d'échange</h1>
      </div>
      <div className={"section"}>
        <p className={"info"}>
          {user.Plan
            ? user.Plan.maxExchangePostNb
              ? `Vous avez ${user.Plan.maxExchangePostNb - user.postsNb}/${
                  user.Plan.maxExchangePostNb
                } crédit(s)`
              : "Vous n'avez plus de crédit de dépot d'annonce. Vous devrez payer pour la publier."
            : "Le dépôt d'annonce est payant si vous n'avez pas d'abonnement premium"}
        </p>
      </div>

      <div className={"section-l"}>
        <ExchangeForm />
      </div>
    </div>
  );
};

export default NewVehicle;
