import { useMemo } from "react";
import { useAccounts } from "../../../services/accounts/accountsProvider";
import { User } from "../../../services/auth/user";
import { withLoader } from "../../../services/routing/useLoader";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { useDate } from "src/services/date/DateContext";

const AccountsListing = (): JSX.Element => {
  const { accounts } = useAccounts();
  const { formatDate } = useDate();

  const customStyles = {
    headCells: {
      style: {
        background: "#7166ee",
        color: "white",
        fontSize: "14px",
      },
    },
    cells: {
      style: {
        fontSize: "14px",
      },
    },
  };

  const columns = useMemo(
    () => [
      {
        name: "e-mail",
        selector: (row: User) => row.email,
        cellExport: (row: User) => row.email,
        sortable: true,
      },
      {
        name: "Prénom",
        selector: (row: User) => row.firstname,
        cellExport: (row: User) => row.firstname,
        sortable: true,
      },
      {
        name: "Nom",
        selector: (row: User) => row.lastname,
        cellExport: (row: User) => row.lastname,
        sortable: true,
      },
      {
        name: "Société",
        selector: (row: User) => row.companyName,
        cellExport: (row: User) => row.companyName,
        sortable: true,
      },
      {
        name: "Siret",
        selector: (row: User) => row.siret,
        cellExport: (row: User) => row.siret,
        sortable: true,
      },
      {
        name: "Date création",
        selector: (row: User) => formatDate(row.createdAt),
        cellExport: (row: User) => formatDate(row.createdAt, "dd/MM/yyyy"),
        sortFunction: (a: User, b: User) => {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        },
      },
      {
        name: "Date 1ère con.",
        selector: (row: User) =>
          (row.firstConnectionDate && formatDate(row.firstConnectionDate)) ||
          "",
        cellExport: (row: User) =>
          (row.firstConnectionDate &&
            formatDate(row.firstConnectionDate, "dd/MM/yyyy")) ||
          "",
        sortFunction: (a: User, b: User) => {
          return (
            new Date(
              b.firstConnectionDate ? b.firstConnectionDate : "1970-01-01",
            ).getTime() -
            new Date(
              a.firstConnectionDate ? a.firstConnectionDate : "1970-01-01",
            ).getTime()
          );
        },
      },
      {
        name: "Date dernière utilisation",
        selector: (row: User) =>
          (row.lastUseDate && formatDate(row.lastUseDate)) || "",
        cellExport: (row: User) =>
          (row.lastUseDate && formatDate(row.lastUseDate, "dd/MM/yyyy")) || "",
        sortFunction: (a: User, b: User) => {
          return (
            new Date(b.lastUseDate ? b.lastUseDate : "1970-01-01").getTime() -
            new Date(a.lastUseDate ? a.lastUseDate : "1970-01-01").getTime()
          );
        },
      },
    ],
    [formatDate],
  );

  const tableData = useMemo(() => {
    return {
      columns,
      data: accounts,
    };
  }, [columns, accounts]);

  return (
    <div className={"container page-content"}>
      <div className={"page_head"}>
        <h1 className={"page-title"}>Suivi des connexions</h1>
      </div>
      <div className={"section-l lay-col --gap-v--xs"}>
        <div className={"section --stretch-h"}>
          <DataTableExtensions {...tableData} exportHeaders>
            <DataTable
              columns={columns}
              customStyles={customStyles}
              data={accounts}
              theme={"light"}
              highlightOnHover
              pagination
            />
          </DataTableExtensions>
        </div>
      </div>
    </div>
  );
};

const useLoad = () => {
  const { loadAccounts } = useAccounts();
  return loadAccounts;
};

export default withLoader(AccountsListing, useLoad);
