import { FullExchangeWithFullOffer } from "../../exchange/exchange";
import Dialog from "../../ui/block/Dialog";
import AdminOfferListItem from "./AdminOfferListItem";

const AdminOfferListingDialog = ({
  onClose,
  exchange,
}: {
  onClose: () => void;
  exchange: FullExchangeWithFullOffer;
}): JSX.Element => {
  return (
    <Dialog className={"dialog-card-full"} onClose={onClose}>
      <div className={"card-head"}>
        <h2>Liste des offres</h2>
      </div>
      <div className={"card-body"}>
        {exchange.Offers.length ? (
          <>
            {exchange.Offers.map((offer) => (
              <AdminOfferListItem
                key={offer.id}
                offer={{ ...offer, Exchange: exchange }}
                inExchangeDetail
              />
            ))}
          </>
        ) : (
          <div className={"info"}>Aucune offre</div>
        )}
      </div>
    </Dialog>
  );
};

export default AdminOfferListingDialog;
