import { cx } from "@emotion/css";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ADMIN_ACCOUNT_PROFILE_LINK } from "../../../routes/admin/accounts";
import { ADMIN_EXCHANGE_DETAIL_LINK } from "../../../routes/admin/exchange-listing";
import { ExchangeStatus, getBestOfferStatus } from "../../exchange/exchange";
import { useExchange } from "../../exchange/exchangeProvider";
import useFileAsObjectURL from "../../files/useFileAsObjectURL";
import { toFixed } from "../../numbers/formatting";
import Link from "../../routing/components/Link";
import { useConfirmationWithIntl } from "../../ui/ConfirmationDialog";
import { FullOffer, OfferStatus } from "../offer";
import { useOffer } from "../offerProvider";
import iconInfo from "../../../assets/img/icons/icon-info.svg";
import OfferFeeBlockDialog from "./OfferDetailDialog";

const AdminOfferListItem = ({
  offer,
  inExchangeDetail,
}: {
  offer: FullOffer;
  inExchangeDetail?: boolean;
}): JSX.Element => {
  const { formatVehicleOptions, loadExchangeById } = useExchange();
  const { cancelOffer } = useOffer();
  const [showOfferPriceDetail, setShowOfferPriceDetail] = useState(false);
  const { t } = useTranslation(["offer"]);
  const { confirm } = useConfirmationWithIntl("offer");

  const formatedExchange = useMemo(() => formatVehicleOptions(offer.Exchange), [
    formatVehicleOptions,
    offer.Exchange,
  ]);

  const objectUrl = useFileAsObjectURL({
    url: formatedExchange.VehiclePictures[0]?.file,
  });

  const status = getBestOfferStatus(offer);
  const isPending = status === OfferStatus.STATUS_PENDING;
  const isDeclined = status === OfferStatus.STATUS_DECLINED;
  const isCanceled = status === OfferStatus.STATUS_CANCELED;
  const isAccepted = status === OfferStatus.STATUS_ACCEPTED;
  const isValidated = status === OfferStatus.STATUS_VALIDATED;
  const isSold = status === ExchangeStatus.STATUS_SOLD;

  return (
    <div className={"offer-item"}>
      <div className={"offer-item_photo"}>
        <div
          className={"offer-item_photo_image"}
          style={{
            backgroundImage: objectUrl ? `url(${objectUrl.url})` : undefined,
          }}
        />
      </div>
      <div className={"offer-body"}>
        <div className={"offer-body_main"}>
          <Link
            className={"offer-title"}
            params={{ exchangeId: formatedExchange.id }}
            to={ADMIN_EXCHANGE_DETAIL_LINK}
          >
            {formatedExchange.vehicleBrand} {formatedExchange.vehicleModel}{" "}
            {formatedExchange.vehicleSubModel}
          </Link>

          <div className={"lay-row --center-v"}>
            <div className={"offer-price"}>
              <div>
                <div className={"offer-price_ttc"}>
                  {toFixed(offer.Prices[0].amount, 2)}€<sup>TTC</sup>
                </div>
              </div>
            </div>
            <button
              className={"link-with-icon"}
              onClick={() => setShowOfferPriceDetail(true)}
              type={"button"}
            >
              <img alt={"info"} className={"icon"} src={iconInfo} /> Consulter
              les conditions
            </button>
          </div>
          <p className={"data-row"}>
            <span>
              <strong>Acquéreur : </strong>
              {offer.User.email} -{" "}
              <Link
                className={"link"}
                params={{ id: offer.User.id }}
                to={ADMIN_ACCOUNT_PROFILE_LINK}
              >
                en savoir plus
              </Link>
            </span>
            <span>
              <strong>Vendeur : </strong>
              {offer.Exchange.User.email} -{" "}
              <Link
                className={"link"}
                params={{ id: offer.Exchange.User.id }}
                to={ADMIN_ACCOUNT_PROFILE_LINK}
              >
                en savoir plus
              </Link>
            </span>
          </p>
        </div>
        <div className={cx("offer-status", "--c-red" && false)}>
          <span>
            {isPending && t("offer:offer_status.STATUS_PENDING")}
            {isDeclined && t("offer:offer_status.STATUS_DECLINED")}
            {isCanceled && t("offer:offer_status.STATUS_CANCELED")}
            {isAccepted && t("offer:offer_status.STATUS_ACCEPTED")}
            {isValidated && t("offer:offer_status.STATUS_VALIDATED")}
            {isSold && t("offer:offer_status.STATUS_SOLD")}
          </span>
        </div>
      </div>
      {(isPending || isDeclined) && (
        <>
          <div className={"offer-body_separator"} />
          <div className={"offer-action"}>
            <div>
              <button
                className={"btn-light"}
                onClick={() =>
                  confirm(
                    "confirm_cancel_content",
                    "confirm_cancel_title",
                    () =>
                      cancelOffer(offer.id).then(async () => {
                        if (inExchangeDetail)
                          await loadExchangeById(offer.Exchange.id);
                      }),
                    true,
                    {
                      closable: false,
                    },
                  )
                }
              >
                Annuler l'offre
              </button>
            </div>
          </div>
        </>
      )}
      {showOfferPriceDetail && (
        <OfferFeeBlockDialog
          offer={offer}
          onClose={() => setShowOfferPriceDetail(false)}
        />
      )}
    </div>
  );
};
export default AdminOfferListItem;
