import { isUserMissingKyc, User } from "../../auth/user";
import Link from "../../routing/components/Link";
import generatePath from "../../routing/generatePath";

import iconCheck from "../../../assets/img/icons/icon-check-purple.svg";
import iconEdit from "../../../assets/img/icons/icon-pencil-purple.svg";
import { useDate } from "../../date/DateContext";
import { useCallback, useMemo, useState } from "react";
import PlanAssignationDialog from "../../plan/components/PlanAssignationDialog";
import { withLoader } from "../../routing/useLoader";
import { usePlan } from "../../plan/planProvider";
import { ADMIN_ACCOUNT_PROFILE_LINK } from "../../../routes/admin/accounts";
import { orderByField } from "src/services/data-structures/array";

const AccountsManagementTable = ({
  accounts,
}: {
  accounts: User[];
}): JSX.Element => {
  const { formatDate } = useDate();

  const orderedAccounts = useMemo(
    () => [...accounts].sort(orderByField("createdAt", false)),
    [accounts],
  );

  const [
    userShowPlanAssignation,
    setUserShowPlanAssignation,
  ] = useState<User | null>(null);

  return (
    <div className={"table-container"}>
      <table className={"table"}>
        <thead>
          <tr>
            <th>Email</th>
            <th>Nom</th>
            <th>Prénom</th>
            <th>Entreprise</th>
            <th>Date de création</th>
            <th>Adm.</th>
            <th>Statut</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {orderedAccounts.map((account) => (
            <tr key={account.id}>
              <td>{account.email}</td>
              <td>{account.lastname}</td>
              <td>{account.firstname}</td>
              <td>{account.companyName}</td>
              <td>{formatDate(account.createdAt)}</td>
              <td>
                {account.superAdmin && (
                  <img alt={"check"} className={"icon"} src={iconCheck} />
                )}
              </td>
              <td>
                {!account.superAdmin &&
                  (account.isValid ? (
                    <span className={"--txt-success body-s"}>validé</span>
                  ) : !account.validationDate ? (
                    isUserMissingKyc(account) ? (
                      <span className={"--txt-error body-s"}>
                        pièces manquantes
                      </span>
                    ) : (
                      <span className={"--txt-warning body-s"}>à valider</span>
                    )
                  ) : (
                    <span className={"--txt-error body-s"}>invalidé</span>
                  ))}
              </td>
              <td>
                <button
                  className={"link --s --accent"}
                  onClick={() => setUserShowPlanAssignation(account)}
                >
                  Plans custo.
                </button>
              </td>
              <td>
                <Link
                  to={generatePath(ADMIN_ACCOUNT_PROFILE_LINK, {
                    id: account.id,
                  })}
                >
                  <img alt={"pencil"} className={"icon"} src={iconEdit} />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {userShowPlanAssignation !== null && (
        <PlanAssignationDialog
          onClose={() => setUserShowPlanAssignation(null)}
          selectedUser={userShowPlanAssignation}
        />
      )}
    </div>
  );
};

const useLoad = () => {
  const { loadPlans } = usePlan();

  return useCallback(() => loadPlans(), [loadPlans]);
};

export default withLoader(AccountsManagementTable, useLoad);
