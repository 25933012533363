import { addMethod, date, mixed, string } from "yup";
import { getNumericEnumEntries } from "../data-structures/enum";
import { Namespace, TFuncKey } from "react-i18next";
import Reference from "yup/lib/Reference";
import { phoneNumberRegex } from "./phone";

addMethod(
  string,
  "password",
  function (regex: RegExp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/) {
    return this.required()
      .meta({ password: true })
      .matches(regex, {
        message: { key: "user.password.matches" },
      });
  },
);

addMethod(mixed, "showEmptyIndicator", function (showEmptyIndicator: boolean) {
  return this.meta({ showEmptyIndicator });
});

addMethod(string, "passwordConfirmation", function (ref: Reference) {
  return this.required()
    .meta({ password: true })
    .equals([ref], { key: "user.password.equals" }) as any;
});

addMethod(date, "time", function () {
  return this.meta({ time: true });
});

addMethod(mixed, "disabled", function () {
  return this.test("disabled", () => true);
});

addMethod(mixed, "notEditable", function (isNotEditable: boolean = true) {
  return isNotEditable
    ? this.nullable().optional().meta({ disabled: true })
    : this;
});

addMethod(mixed, "notVisible", function (isNotVisible: boolean = true) {
  return isNotVisible
    ? this.nullable()
        .optional()
        .meta({ notVisible: true })
        .transform(() => null)
    : this;
});

addMethod(string, "multiline", function () {
  return this.meta({ multiline: true });
});

addMethod(mixed, "radio", function () {
  return this.meta({ radio: true });
});

addMethod(string, "phoneNumber", function () {
  return this.meta({ phoneNumber: true }).matches(phoneNumberRegex);
});

addMethod(
  mixed,
  "autocomplete",
  function (
    values: Record<string | number | symbol, unknown> | undefined,
    namespace: Namespace,
    baseKey: TFuncKey<Namespace>,
    multiselect: {
      active: boolean;
      hasSelectAll?: boolean;
      isCreatable?: boolean;
    },
  ) {
    return this.meta({
      enum: values,
      autocomplete: true,
      multiselect,
      translate: [namespace, baseKey],
    });
  },
);

addMethod(
  mixed,
  "oneOfEnum",
  function (
    values: Record<string | number | symbol, unknown> | Array<unknown>,
    namespace?: Namespace,
    baseKey?: TFuncKey<Namespace>,
    nullValue?: boolean,
  ) {
    if (Array.isArray(values)) {
      return this.clone({ nullable: nullValue ? true : this.spec.nullable })
        .oneOf(values.concat(nullValue ? [null] : []))
        .meta({
          translate: namespace ? [namespace, baseKey] : undefined,
          enum: values,
          select: true,
        });
    } else {
      const numericEntries = getNumericEnumEntries(values);
      return this.clone({ nullable: nullValue ? true : this.spec.nullable })
        .oneOf(
          (numericEntries as (number | null)[][])
            .map(([id]) => id)
            .concat(nullValue ? [null] : []),
        )
        .meta({
          translate: namespace ? [namespace, baseKey] : undefined,
          enum: values,
          select: true,
        });
    }
  },
);
