import React, { FunctionComponent, useState } from "react";
import { useToastsWithIntl } from "../../services/toast-notifications";
import { LOGIN_LINK } from "../../routes/public";
import RegisterForm from "../../services/auth/components/RegisterForm";
import { useAuth } from "../../services/auth/apiProvider";
import { useNavigate } from "react-router-dom";
import withRedirectToPrivate from "../../services/auth/hocs/withRedirectToPrivate";
import Dialog from "../../services/ui/block/Dialog";
import Button from "../../services/ui/reactive/Button";
import { useTranslation } from "react-i18next";

const Register: FunctionComponent = () => {
  const auth = useAuth();
  const { toastError } = useToastsWithIntl(["auth"]);
  const navigate = useNavigate();
  const { t } = useTranslation("auth");

  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  return (
    <>
      <h1 className={"page-title"}>Demande d'inscription</h1>

      <p className={"--txt-center"}>
        Saisissez vos coordonnées pour créer votre compte et accéder à votre
        espace personnel Autolity
      </p>

      <div className={"auth-form --l"}>
        <RegisterForm
          onSubmit={(registerInfos) =>
            auth.register(registerInfos).then(
              () => {
                setShowSuccessDialog(true);
              },
              (response: number) => {
                if (response === 409)
                  toastError("auth:register.TOAST_USER_ALREADY_EXIST");
                else toastError("auth:register.TOAST_ERROR");
              },
            )
          }
        />
      </div>
      {showSuccessDialog && (
        <Dialog
          onClose={() => {
            setShowSuccessDialog(false);
            navigate(LOGIN_LINK);
          }}
        >
          <div className={"card-head"}>
            <div className={"card-title"}>Validation du compte</div>
          </div>
          <div className={"card-body"}>{t("register.MSG_SUCCESS")}</div>
          <div className={"card-footer"}>
            <Button
              className={"btn-1"}
              onClick={() => {
                setShowSuccessDialog(false);
                navigate(LOGIN_LINK);
              }}
              type={"button"}
            >
              OK
            </Button>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default withRedirectToPrivate(Register);
