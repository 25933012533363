import { Link } from "react-router-dom";
import { PROFILE_LINK } from "../../routes/private";
import { User } from "../../services/auth/user";

const AccountValidationInfo = ({
  user,
  linkToAccount,
}: {
  user: User;
  linkToAccount: boolean;
}): JSX.Element => {
  return (
    <>
      {!user.superAdmin && !user.isValid && (
        <div className={"section"}>
          <div className={"info info--error"}>
            <div className={"info_body"}>
              <h4 className={"info_title"}>COMPTE EN ATTENTE DE VALIDATION</h4>
              <p>
                {user.Kyc.kbis === null ||
                user.Kyc.identity === null ||
                user.Kyc.bank === null
                  ? "Il nous manque des pièces justificatives afin de pouvoir valider le compte :"
                  : "Un conseiller Autolity va prendre contact avec vous très prochainement pour valider votre compte."}
              </p>
              <ul>
                {user.Kyc.identity === null && (
                  <li>
                    Pièce d'identité du gérant (Passeport ou carte d'identité
                    nationale)
                  </li>
                )}
                {user.Kyc.kbis === null && <li>KBIS de moins de 3 mois</li>}
                {user.Kyc.bank === null && <li>RIB ou IBAN</li>}
              </ul>
            </div>
            {linkToAccount && (
              <div className={"info_actions"}>
                <Link className={"btn"} to={PROFILE_LINK}>
                  Envoyer les documents
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AccountValidationInfo;
