import React, { FunctionComponent, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EXCHANGE_DETAIL_LINK } from "../../../routes/exchange";
import { AuthAPIConnected, useAuth } from "../../auth/apiProvider";
import { hostBaseURL } from "../../auth/config";
import { User } from "../../auth/user";
import StripePaymentDialog from "../../payment/components/StripePaymentDialog";
import generatePath from "../../routing/generatePath";
import Dialog from "../../ui/block/Dialog";
import { ExchangeAPILoaded, useExchange } from "../exchangeProvider";
import PlanChangeProposalBlock from "../../plan/components/PlanChangeProposalBlock";
import { ADMIN_EXCHANGE_DETAIL_LINK } from "../../../routes/admin/exchange-listing";
import ReplaceExchangeDialog from "./ReplaceExchangeDialog";

const ExchangePublicationDialog: FunctionComponent<{
  onClose: () => void;
  user?: User;
  tips?: string;
}> = ({ onClose, tips, user: userProps }) => {
  const authApi = useAuth() as AuthAPIConnected;
  const { exchange, publishExchange } = useExchange() as ExchangeAPILoaded;
  const [showStripePayment, setShowStripePayment] = useState(false);
  const [showReplaceExchange, setShowReplaceExchange] = useState(false);
  const navigate = useNavigate();

  const user = useMemo(() => userProps || authApi.user, [
    authApi.user,
    userProps,
  ]);

  const leftCredits = useMemo(
    () => (user.Plan !== null ? user.Plan.maxExchangePostNb - user.postsNb : 0),
    [user.Plan, user.postsNb],
  );

  const redirectToExchange = () => {
    onClose();
    navigate(
      generatePath(
        authApi.user.superAdmin
          ? ADMIN_EXCHANGE_DETAIL_LINK
          : EXCHANGE_DETAIL_LINK,
        {
          exchangeId: exchange.id,
        },
      ),
      {
        state: {
          previous: "/create",
        },
        replace: false,
      },
    );
  };

  const handleExchangePublication = () => {
    if (leftCredits > 0) {
      publishExchange(exchange.id, false, userProps?.id).then(
        redirectToExchange,
      );
    } else {
      setShowStripePayment(true);
    }
  };

  return (
    <>
      <Dialog onClose={redirectToExchange}>
        <div className={"card-head"}>
          <div className={"card-title"}>Publier votre annonce ?</div>
        </div>
        <div className={"card-body"}>
          <div className={"lay-col --gap --center-h"}>
            {tips && <p>{tips}</p>}
            <p>
              {user.Plan?.maxExchangePostNb !== -1 ? (
                <div className={"kpi"}>
                  {Math.max(0, leftCredits)}
                  <div className={"kpi_label"}>annonce(s) restante(s)</div>
                </div>
              ) : (
                "Votre plan n'inclut pas de dépôt d'annonce"
              )}
            </p>

            {leftCredits <= 0 && (
              <PlanChangeProposalBlock
                redirectPath={generatePath(EXCHANGE_DETAIL_LINK, {
                  exchangeId: exchange.id,
                })}
              />
            )}
          </div>
        </div>
        <div className={"card-footer"}>
          <div className={"lay-row --stretch --gap--s"}>
            <button
              className={"btn-1"}
              onClick={() => handleExchangePublication()}
              type={"button"}
            >
              {`Publier (${
                leftCredits > 0
                  ? "1 crédit"
                  : user.Plan?.postEmergencyPrice + "€ HT"
              })`}
            </button>
            {leftCredits <= 0 && user.Plan!.type >= 2 && (
              <button
                className={"btn-1"}
                onClick={() => setShowReplaceExchange(true)}
                type={"button"}
              >
                Remplacer une annonce
              </button>
            )}
            {authApi.user.superAdmin && (
              <button
                className={"btn-1"}
                onClick={() =>
                  publishExchange(exchange.id, true, user.id).then(
                    redirectToExchange,
                  )
                }
                type={"button"}
              >
                Publier sans paiement
              </button>
            )}
            <button
              className={"btn-2"}
              onClick={redirectToExchange}
              type={"button"}
            >
              Plus tard
            </button>
          </div>
        </div>
      </Dialog>
      {showStripePayment && (
        <StripePaymentDialog
          onClose={() => setShowStripePayment(false)}
          paymentIntentForm={{ referenceId: exchange.id, item: "post" }}
          redirectUrl={`${hostBaseURL}${generatePath(EXCHANGE_DETAIL_LINK, {
            exchangeId: exchange.id,
          })}`}
        />
      )}
      {showReplaceExchange && (
        <ReplaceExchangeDialog
          onClose={() => setShowReplaceExchange(false)}
          onPublish={() =>
            publishExchange(exchange.id, false, userProps?.id).then(() => {
              onClose();
            })
          }
        />
      )}
    </>
  );
};

export default ExchangePublicationDialog;
