import React, { useMemo } from "react";
import { useToastsWithIntl } from "../../services/toast-notifications";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line no-restricted-imports
import { Form, Formik } from "formik";
import withRedirectToPrivate from "../../services/auth/hocs/withRedirectToPrivate";
import { object, string } from "yup";
import Link from "../../services/routing/components/Link";
import { LOGIN_LINK } from "../../routes/public";
import { useAuth } from "../../services/auth/apiProvider";
import Button from "../../services/ui/reactive/Button";
import Input from "../../services/ui/reactive/Input";

const LostPassword: React.FC = () => {
  const { lostPassword } = useAuth();
  const { toastError, toastSuccess } = useToastsWithIntl(["auth"]);
  const { t } = useTranslation(["auth"]);

  const LostPasswordSchema = useMemo(
    () =>
      object().shape({
        email: string()
          .label(t("auth:email"))
          .required("L'adresse e-mail est requise")
          .email("L'adresse e-mail est requise"),
      }),
    [t],
  );

  return (
    <>
      <h1 className={"page-title"}>Mot de passe oublié</h1>
      <p className={"--txt-center"}>
        Saisissez votre adresse e-mail.
        <br />
        Si le compte existe dans notre base, vous recevrez un mail contenant un
        lien qui vous permettra de définir un nouveau de mot de passe.
      </p>

      <div className={"auth-form"}>
        <Formik
          initialValues={{ email: "" }}
          onSubmit={(values, { setSubmitting }) => {
            lostPassword(values.email).then(
              () => {
                setSubmitting(false);
                toastSuccess("auth:lost-password.SUCCESS");
              },
              () => {
                setSubmitting(false);
                toastError("auth:lost-password.ERROR");
              },
            );
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={LostPasswordSchema}
        >
          {({ handleChange, errors, isSubmitting }) => (
            <Form>
              <div className={"form-block"}>
                <label className={"input-label"}>{t("auth:email")} *</label>
                <Input
                  name={"email"}
                  onChange={handleChange}
                  placeholder={"votre.mail@email.fr"}
                  type={"email"}
                />
              </div>

              <div>
                <ul>
                  {Object.values(errors).map((value, index) => (
                    <li key={index}>{value}</li>
                  ))}
                </ul>
              </div>

              <div className={"form-footer"}>
                <Button disabled={isSubmitting} block>
                  Envoyer
                </Button>
                <Link className={"link"} to={LOGIN_LINK}>
                  Retour à la connexion
                </Link>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default withRedirectToPrivate(LostPassword);
