import React, { useCallback, useEffect, useMemo, useState } from "react";
import PicturesGallery from "src/services/ui/PicturesGallery";
import { AuthAPIConnected, useAuth } from "../../../services/auth/apiProvider";
import { useDate } from "../../../services/date/DateContext";
import OfferDialog from "../../../services/offer/components/OfferDialog";
import {
  ExchangeAPILoaded,
  useExchange,
} from "../../../services/exchange/exchangeProvider";
import LightboxCarousel from "../../../services/files/LightboxCarousel";
import { withLoader } from "../../../services/routing/useLoader";
import { useParams } from "../../../services/routing/useParams";
import { useToastsWithIntl } from "../../../services/toast-notifications";
import iconFilledReadHeart from "../../../assets/img/icons/icon-red-heart-filled.svg";
import iconOutlinedGreyHeart from "../../../assets/img/icons/icon-grey-heart-outlined.svg";
import iconPurplePencil from "../../../assets/img/icons/icon-pencil-purple.svg";
import {
  EDIT_EXCHANGE_LINK,
  EXCHANGE_LISTING_LINK,
} from "../../../routes/exchange";
import Link from "../../../services/routing/components/Link";
import useQueryParams from "../../../services/routing/useQueryParams";
import ExchangePublicationDialog from "../../../services/exchange/components/ExchangePublicationDialog";
import { Offer, OfferStatus } from "../../../services/offer/offer";
import { toFixed } from "../../../services/numbers/formatting";
import { useTranslation } from "react-i18next";
import InfoRequestDialog from "../../../services/info-request/components/InfoRequestDialog";
import FileItem from "../../../services/ui/FileItem";
import { ADMIN_EXCHANGE_EDIT_LINK } from "../../../routes/admin/exchange-listing";
import SubmitButton from "../../../services/forms/SubmitButton";
import { cx } from "@emotion/css";
import ExchangeOfferListingDialog from "../../../services/offer/components/ExchangeOfferListingDialog";
import { useNavigate, useLocation } from "react-router-dom";
import { useConfirmationWithIntl } from "../../../services/ui/ConfirmationDialog";
import { isFuture } from "date-fns";
import { ADMIN_ACCOUNT_PROFILE_LINK } from "src/routes/admin/accounts";
import generatePath from "../../../services/routing/generatePath";
import { MY_EXCHANGES_LINK } from "../../../routes/dashboard";
import {
  ADMIN_EXCHANGE_LISTING_LINK,
  ADMIN_OFFERS_LINK,
} from "../../../routes/admin";
import trashIcon from "../../../assets/img/icons/icon-trash-white.svg";
import { AxiosError } from "axios";

const ExchangeDetail = (): JSX.Element => {
  const { user } = useAuth() as AuthAPIConnected;
  const {
    exchange,
    formatedExchange,
    deleteExchange,
    makeOffer,
    addToFavorites,
    removeFromFavorites,
    unpublishExchange,
    publishExchange,
    createInfoRequest,
    manageExchangeStatus,
  } = useExchange() as ExchangeAPILoaded;
  const { formatDate } = useDate();
  const { toastSuccess, toastError } = useToastsWithIntl(["exchange"]);
  const { t } = useTranslation(["common", "offer"]);
  const location = useLocation();

  const [showNewOffer, setShowNewOffer] = useState(false);
  const [showInfoRequest, setShowInfoRequest] = useState(false);
  const [showOffers, setShowOffers] = useState(false);
  const [displayedFile, setDisplayedFile] = useState<null | number>(null);
  const sortedPictures = useMemo(
    () => formatedExchange.VehiclePictures.sort((a, b) => a.order - b.order),
    [formatedExchange.VehiclePictures],
  );

  const [{ subscribeRedirect }] = useQueryParams();
  const [showPublicationDialog, setShowPublicationDialog] = useState(false);
  useEffect(
    () => (subscribeRedirect ? setShowPublicationDialog(true) : undefined),
    [subscribeRedirect],
  );

  const myOffer = useMemo<Offer | undefined>(
    () => exchange.Offers.find((offer) => offer.UserId === user.id),
    [exchange.Offers, user.id],
  );

  const navigate = useNavigate();
  const hasValidateOffer = useMemo(
    () =>
      exchange.Offers.find(
        (offer) => offer.status === OfferStatus.STATUS_VALIDATED,
      )
        ? true
        : false,
    [exchange.Offers],
  );

  const { confirm } = useConfirmationWithIntl("exchange");

  const isVehicleDamaged = useMemo(
    () => (formatedExchange.isVehicleDamaged ? "Oui" : "Non"),
    [formatedExchange],
  );

  return (
    <div className={"exchange-detail"}>
      <PicturesGallery
        onShow={setDisplayedFile}
        pictures={formatedExchange.VehiclePictures}
      />
      <div className={"container-wrap page-content"}>
        <div className={"page_head"}>
          <div>
            <div className={"section-xs"}>
              <button
                className={"link --s"}
                onClick={() => {
                  !location.state
                    ? user.superAdmin
                      ? navigate(ADMIN_OFFERS_LINK)
                      : navigate(-1)
                    : (location.state as { previous: string }).previous ===
                        "/create" ||
                      (location.state as { previous: string }).previous ===
                        "edit"
                    ? user.superAdmin
                      ? navigate(ADMIN_EXCHANGE_LISTING_LINK)
                      : navigate(MY_EXCHANGES_LINK)
                    : user.superAdmin
                    ? navigate(
                        ADMIN_EXCHANGE_LISTING_LINK +
                          `?page=${(location.state as { page: number }).page}`,
                      )
                    : (location.state as { previous: string }).previous ===
                      MY_EXCHANGES_LINK
                    ? navigate(
                        MY_EXCHANGES_LINK +
                          `?page=${(location.state as { page: number }).page}`,
                      )
                    : navigate(
                        EXCHANGE_LISTING_LINK +
                          `?page=${(location.state as { page: number }).page}`,
                      );
                }}
              >
                &larr; Retour à la bourse d'échange
              </button>
            </div>
            <h1
              className={"page-title"}
              dangerouslySetInnerHTML={{
                __html:
                  formatedExchange.adOrigin === null
                    ? `${formatedExchange.vehicleBrand} ${
                        formatedExchange.vehicleModel
                      } ${
                        formatedExchange.vehicleSubModel !== "NR" &&
                        formatedExchange.vehicleSubModel
                      }`
                    : `${
                        formatedExchange.description
                          ? formatedExchange.description
                          : formatedExchange.vehicleBrand +
                            " " +
                            formatedExchange.vehicleMetaModel
                      }`,
              }}
            />
          </div>

          <div className={"page_head-actions"}>
            {exchange.UserId !== user.id && (
              <button
                className={"exchange_favorite-button"}
                onClick={(ev) => {
                  ev.preventDefault();
                  if (formatedExchange.isFavorite)
                    removeFromFavorites(formatedExchange);
                  else addToFavorites(formatedExchange);
                }}
                type={"button"}
              >
                {formatedExchange.isFavorite ? (
                  <img
                    alt={"remove from favorites"}
                    className={"icon"}
                    src={iconFilledReadHeart}
                  />
                ) : (
                  <img
                    alt={"add to favorites"}
                    className={"icon"}
                    src={iconOutlinedGreyHeart}
                  />
                )}
              </button>
            )}
            {(exchange.UserId === user.id || user.superAdmin) && (
              <Link
                className={"exchange_edit-button"}
                params={{ exchangeId: formatedExchange.id }}
                state={
                  location.state
                    ? {
                        previous: (location.state as { previous: string })
                          .previous,
                        page: (location.state as { page: string }).page,
                      }
                    : null
                }
                to={
                  user.superAdmin
                    ? ADMIN_EXCHANGE_EDIT_LINK
                    : EDIT_EXCHANGE_LINK
                }
              >
                <img
                  alt={"edit exchange"}
                  className={"icon"}
                  src={iconPurplePencil}
                />
              </Link>
            )}
            {(exchange.UserId === user.id || user.superAdmin) && (
              <button
                className={
                  formatedExchange.publicationEndDate &&
                  isFuture(formatedExchange.publicationEndDate)
                    ? "btn--outlined btn-warning"
                    : "btn-1"
                }
                disabled={
                  exchange.Offers.filter(
                    (o) =>
                      o.status !== OfferStatus.STATUS_DECLINED &&
                      o.status !== OfferStatus.STATUS_CANCELED,
                  ).length > 0
                }
                onClick={() =>
                  formatedExchange.publicationEndDate &&
                  isFuture(formatedExchange.publicationEndDate)
                    ? unpublishExchange(exchange.id)
                    : formatedExchange.paymentValidationDate
                    ? publishExchange(exchange.id)
                    : setShowPublicationDialog(true)
                }
                type={"button"}
              >
                {formatedExchange.publicationEndDate &&
                isFuture(formatedExchange.publicationEndDate) ? (
                  <span>Dépublier</span>
                ) : (
                  <span>Publier</span>
                )}
              </button>
            )}
            {user.superAdmin && (
              <button
                className={"btn-danger"}
                onClick={() =>
                  deleteExchange(exchange.id).then(
                    () => {
                      toastSuccess("exchange:delete-exchange.SUCCESS");
                      navigate(generatePath(ADMIN_EXCHANGE_LISTING_LINK), {
                        replace: false,
                      });
                    },
                    (err: AxiosError) => {
                      toastError("exchange:delete-exchange.ERROR");
                    },
                  )
                }
              >
                <img alt={"trash-icon"} className={"icon"} src={trashIcon} />
              </button>
            )}
          </div>
        </div>
        <form className={"section"}>
          <div
            className={
              "section-xs lay-row --center-v --gap--s body-s --bg --grey"
            }
          >
            {(exchange.UserId === user.id || user.superAdmin) && (
              <div className={"lay-row --gap--xs"}>
                {exchange.standalone &&
                  (exchange.paymentValidationDate ? (
                    <span className={"chip --outline --success"}>Payée</span>
                  ) : (
                    <span className={"chip --outline --warning"}>
                      En cours de paiement
                    </span>
                  ))}

                {formatedExchange.publicationEndDate &&
                isFuture(formatedExchange.publicationEndDate) ? (
                  <span className={"chip --outline --success"}>Publiée</span>
                ) : (
                  <span className={"chip --outline --warning"}>
                    Non publiée
                  </span>
                )}

                {exchange.soldDate && (
                  <span className={"chip --outline --success"}>
                    {t("offer:offer_status.STATUS_SOLD")}
                  </span>
                )}
              </div>
            )}
            {user.superAdmin && (
              <Link
                target={"_blank"}
                to={generatePath(ADMIN_ACCOUNT_PROFILE_LINK, {
                  id: formatedExchange.UserId,
                })}
              >
                {exchange.User.email} [id: {formatedExchange.UserId}]
              </Link>
            )}
            <span>
              ID de l'annonce : <strong>{formatedExchange.id}</strong>
            </span>
            <span>
              Annonce créée le{" "}
              <strong>{formatDate(formatedExchange.createdAt)}</strong>
            </span>
            {formatedExchange.vehicleVin && (
              <span>
                VIN : <strong>{formatedExchange.vehicleVin}</strong>
              </span>
            )}
            <span>
              Pays de stockage :{" "}
              <strong>{formatedExchange.storageCountry}</strong>
            </span>
            <span className={"chip --outline --success --pos-r"}>
              {formatedExchange.type}
            </span>
          </div>
          {(user.superAdmin ||
            (exchange.UserId === user.id && hasValidateOffer)) && (
            <div className={"section"}>
              <SubmitButton
                className={cx("btn-toggle", exchange.soldDate && "toggle-on")}
                onClick={async () =>
                  !exchange.soldDate
                    ? confirm(
                        "selling_information_content",
                        "selling_information_title",
                        () =>
                          manageExchangeStatus(
                            exchange.id,
                            exchange.soldDate === undefined,
                          ),
                        true,
                        { closable: false },
                      )
                    : manageExchangeStatus(
                        exchange.id,
                        exchange.soldDate === undefined,
                      )
                }
                type={"button"}
              >
                {exchange.soldDate
                  ? "Véhicule vendu"
                  : "Déclarer le véhicule vendu"}
              </SubmitButton>
            </div>
          )}

          <div className={"section"}>
            <div className={"lay-row --center-v --gap"}>
              <fieldset
                className={"exchange_data-display"}
                disabled={true ? true : undefined} // si besoin d'un mode édition dans le futur
              >
                <div className={"form-block"}>
                  {/* Pays de stockage = pays du vendeur */}
                  <label className={"input-label"}>Localisation</label>
                  <input
                    className={"input"}
                    type={"text"}
                    value={formatedExchange.storageCountry}
                  />
                </div>
                <div className={"form-block"}>
                  <label className={"input-label"}>
                    Puissance (DIN en chevaux)
                  </label>
                  <input
                    className={"input"}
                    type={"number"}
                    value={formatedExchange.vehiclePower}
                  />
                </div>
                <div className={"form-block"}>
                  <label className={"input-label"}>Kilométrage</label>
                  <input
                    className={"input"}
                    type={"number"}
                    value={formatedExchange.vehicleMileage}
                  />
                </div>
                <div className={"form-block"}>
                  <label className={"input-label"}>
                    Pays d'immatriculation actuel
                  </label>
                  <select className={"select"}>
                    <option value={"ID_XXX"}>
                      {formatedExchange.vehicleCountry}
                    </option>
                  </select>
                </div>
              </fieldset>

              <div className={"exchange_offer"}>
                <div className={"exchange_price"}>
                  {formatedExchange.vehicleSalePrice ? (
                    <div>
                      <div className={"exchange_price_ttc"}>
                        {t("common:number", {
                          val: formatedExchange.vehicleSalePrice,
                        })}{" "}
                        €<sup>TTC</sup>
                      </div>
                      {formatedExchange.vatPercentage !== undefined && (
                        <div className={"exchange_price_ht"}>
                          {t("common:number", {
                            val:
                              formatedExchange.vehicleSalePrice /
                              (1 + formatedExchange.vatPercentage),
                          })}{" "}
                          € HT (TVA : {formatedExchange.vatPercentage * 100}%)
                        </div>
                      )}
                      {exchange.UserId !== user.id && (
                        <div className={"exchange_actions"}>
                          {exchange.acceptedOffersCount !== 0 && (
                            <p className={"btn-block --txt-center"}>
                              Le vendeur a déjà accepté une offre
                            </p>
                          )}
                          {!user.superAdmin && (
                            <button
                              className={"btn btn-block"}
                              disabled={exchange.acceptedOffersCount !== 0}
                              onClick={() => setShowNewOffer(true)}
                              type={"button"}
                            >
                              Faire une offre
                            </button>
                          )}
                          <button
                            className={"btn-light btn-block"}
                            onClick={() => setShowInfoRequest(true)}
                            type={"button"}
                          >
                            Demander un document
                          </button>
                        </div>
                      )}
                      {user.superAdmin && (
                        <div className={"exchange_actions"}>
                          <button
                            className={"link btn-block"}
                            onClick={() => setShowOffers(true)}
                            type={"button"}
                          >
                            Voir les offres
                          </button>
                        </div>
                      )}
                    </div>
                  ) : (
                    <p>Votre compte n'est pas encore validé</p>
                  )}
                </div>
                {myOffer && (
                  <p className={"--txt-danger --txt-center"}>
                    {`Vous avez une offre en cours de ${toFixed(
                      myOffer.Prices[0].amount,
                      2,
                    )}€`}
                    <sup>TTC</sup>
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className={"section"}>
            <h2 className={"section-title"}>INFORMATIONS GÉNÉRALES</h2>
            <div className={"section"}>
              <fieldset
                className={"exchange_data-display"}
                disabled={true ? true : undefined} // si besoin d'un mode édition dans le futur
              >
                <div className={"form-block"}>
                  <label className={"input-label"}>Marque</label>
                  <input
                    className={"input"}
                    type={"text"}
                    value={formatedExchange.vehicleBrand}
                  />
                </div>
                <div className={"form-block"}>
                  <label className={"input-label"}>Mise en circulation</label>
                  <input
                    className={"input"}
                    type={"text"} // TODO : date input => styling
                    value={
                      formatedExchange.vehicleEntryIntoService &&
                      formatDate(formatedExchange.vehicleEntryIntoService)
                    }
                  />
                </div>
                <div className={"form-block"}>
                  <label className={"input-label"}>Modèle</label>
                  <input
                    className={"input"}
                    type={"text"}
                    value={
                      formatedExchange.adOrigin === null
                        ? formatedExchange.vehicleModel
                        : formatedExchange.vehicleMetaModel
                    }
                  />
                </div>
                <div className={"form-block"}>
                  <label className={"input-label"}>Kilométrage</label>
                  <input
                    className={"input"}
                    type={"number"}
                    value={formatedExchange.vehicleMileage}
                  />
                </div>
                <div className={"form-block"}>
                  <label className={"input-label"}>Finition</label>
                  <input
                    className={"input"}
                    type={"text"}
                    value={
                      formatedExchange.vehicleSubModel !== "NR"
                        ? formatedExchange.vehicleSubModel
                        : "Non Renseigné"
                    }
                  />
                </div>
                <div className={"form-block"}>
                  <label className={"input-label"}>Carburant</label>
                  <select className={"select"}>
                    <option value={"ID_XXX"}>
                      {formatedExchange.vehicleEnergy}
                    </option>
                  </select>
                </div>
                <div className={"form-block"}>
                  <label className={"input-label"}>Motorisation</label>
                  <select className={"select"}>
                    <option value={"ID_XXX"}>
                      {formatedExchange.vehicleEngine !== "NR"
                        ? formatedExchange.vehicleEngine
                        : "Non Renseigné"}
                    </option>
                  </select>
                </div>
                <div className={"form-block"}>
                  <label className={"input-label"}>Puissance fiscale</label>
                  <input
                    className={"input"}
                    type={"text"}
                    value={
                      formatedExchange.vehicleFiscalHorsepower ||
                      "Non Renseigné"
                    }
                  />
                </div>
                <div className={"form-block"}>
                  <label className={"input-label"}>Boîte de vitesse</label>
                  <input
                    className={"input"}
                    type={"text"}
                    value={formatedExchange.vehicleGearbox}
                  />
                </div>
                <div className={"form-block"}>
                  <label className={"input-label"}>
                    Puissance (DIN en chevaux)
                  </label>
                  <input
                    className={"input"}
                    type={"number"}
                    value={formatedExchange.vehiclePower}
                  />
                </div>
                <div className={"form-block"}>
                  <label className={"input-label"}>Émission CO2 (g/km)</label>
                  <input
                    className={"input"}
                    type={"text"}
                    value={
                      formatedExchange.vehicleCo2Emission || "Non Renseigné"
                    }
                  />
                </div>
                <div className={"form-block"}>
                  <label className={"input-label"}>Couleur extérieure</label>
                  <select className={"select"}>
                    <option value={"ID_XXX"}>
                      {formatedExchange.vehicleColor}
                    </option>
                  </select>
                </div>
                <div className={"form-block"}>
                  <label className={"input-label"}>Type de véhicule</label>
                  <select className={"select"}>
                    <option value={"ID_XXX"}>
                      {formatedExchange.vehicleType}
                    </option>
                  </select>
                </div>
                <div className={"form-block"}>
                  <label className={"input-label"}>Couleur intérieure</label>
                  <select className={"select"}>
                    <option value={"ID_XXX"}>
                      {formatedExchange.vehicleInnerColor}
                    </option>
                  </select>
                </div>
                <div className={"form-block"}>
                  <label className={"input-label"}>
                    Pays d'immatriculation actuel
                  </label>
                  <select className={"select"}>
                    <option value={"ID_XXX"}>
                      {formatedExchange.vehicleCountry}
                    </option>
                  </select>
                </div>
                <div className={"form-block"}>
                  <label className={"input-label"}>Matière des sièges</label>
                  <select className={"select"}>
                    <option value={"ID_XXX"}>
                      {formatedExchange.vehicleSeatMaterial}
                    </option>
                  </select>
                </div>
                <div className={"form-block"}>
                  <label className={"input-label"}>
                    Pays de 1<sup>ère</sup> immatriculation
                  </label>
                  <select className={"select"}>
                    <option value={"ID_XXX"}>
                      {formatedExchange.vehicleOriginCountry}
                    </option>
                  </select>
                </div>

                <div className={"form-block"}>
                  <label className={"input-label"}>Transmission</label>
                  <select className={"select"}>
                    <option value={"ID_XXX"}>
                      {formatedExchange.vehicleTransmission}
                    </option>
                  </select>
                </div>

                <div className={"form-block"}>
                  <label className={"input-label"}>Véhicule accidenté</label>
                  <input
                    className={"input"}
                    type={"text"}
                    value={isVehicleDamaged}
                  />
                </div>
              </fieldset>
            </div>
          </div>
          <div className={"grid --2 --s"}>
            {formatedExchange.Equipments &&
              formatedExchange.Equipments.length > 0 && (
                <div className={"section"}>
                  <h2 className={"section-title"}>ÉQUIPEMENT ET OPTIONS</h2>
                  <div className={"block section body-s"}>
                    <ul>
                      {formatedExchange.Equipments.map((equipment, index) => (
                        <li key={index + equipment}>{equipment}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            {formatedExchange.DetailedFeatures &&
              formatedExchange.DetailedFeatures.length > 0 && (
                <div className={"section"}>
                  <h2 className={"section-title"}>
                    CARACTÉRISTIQUES TECHNIQUES
                  </h2>
                  <div className={"block section body-s"}>
                    <ul>
                      {formatedExchange.DetailedFeatures.map(
                        (detailedFeature, index) => (
                          <li key={index + detailedFeature}>
                            {detailedFeature}
                          </li>
                        ),
                      )}
                    </ul>
                  </div>
                </div>
              )}
          </div>

          {formatedExchange.otherInformation && (
            <div className={"section"}>
              <h2 className={"section-title"}>AUTRES INFORMATIONS</h2>
              <div className={"section"}>
                <div className={"rich-text-display"}>
                  {formatedExchange.otherInformation}
                </div>
              </div>
            </div>
          )}
          {((formatedExchange.VehicleDocuments &&
            formatedExchange.VehicleDocuments.length > 0) ||
            formatedExchange.weproovReportLink) && (
            <div className={"section"}>
              <h2 className={"section-title"}>
                DOCUMENTS COMPLÉMENTAIRES ET HISTORIQUE DU VÉHICULE
              </h2>

              <div className={"section lay-row --gap--s"}>
                {formatedExchange.VehicleDocuments!.map((document) => (
                  <FileItem key={document.id} file={document} />
                ))}
                {formatedExchange.weproovReportLink && (
                  <div className={"media-item"}>
                    <div className={"file"}>
                      <a
                        className={"file-name"}
                        href={formatedExchange.weproovReportLink}
                        rel={"noreferrer"}
                        target={"_blank"}
                      >
                        Rapport WeProov
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </form>
      </div>
      {displayedFile !== null && (
        <LightboxCarousel
          defaultFileDisplayed={displayedFile}
          files={sortedPictures.map((picture) => ({ url: picture.file }))}
          onClose={() => setDisplayedFile(null)}
        />
      )}
      {showNewOffer && (
        <OfferDialog
          exchange={exchange}
          isBuyer={true}
          onClose={() => setShowNewOffer(false)}
          onSubmit={(offerForm) => {
            return makeOffer(offerForm, formatedExchange.id).then(
              () => {
                toastSuccess("exchange:make-new-offer.SUCCESS");
                setShowNewOffer(false);
              },
              () => {
                toastError("exchange:make-new-offer.ERROR");
              },
            );
          }}
        />
      )}
      {showInfoRequest && (
        <InfoRequestDialog
          onClose={() => setShowInfoRequest(false)}
          onSubmit={(infoRequestForm) => {
            return createInfoRequest(exchange.id, infoRequestForm).then(
              () => {
                toastSuccess("exchange:create-info-request.SUCCESS");
                setShowInfoRequest(false);
              },
              () => {
                toastError("exchange:create-info-request.ERROR");
              },
            );
          }}
          showWeproov={!exchange.weproovReportLink}
        />
      )}
      {showPublicationDialog && (
        <ExchangePublicationDialog
          onClose={() => setShowPublicationDialog(false)}
          tips={
            subscribeRedirect
              ? "Si votre abonnement n'est pas modifié, veuillez rafraichir la page."
              : undefined
          }
        />
      )}
      {showOffers && (
        <ExchangeOfferListingDialog
          exchange={exchange}
          onClose={() => setShowOffers(false)}
        />
      )}
    </div>
  );
};

const useLoad = () => {
  const { loadExchangeById, loadFormatedVehicleOptionsDetails } = useExchange();
  const accountId = parseInt(useParams().exchangeId || "");

  return useCallback(
    () => loadExchangeById(accountId).then(loadFormatedVehicleOptionsDetails),
    [accountId, loadExchangeById, loadFormatedVehicleOptionsDetails],
  );
};

export default withLoader(ExchangeDetail, useLoad);
