import { AxiosPromise } from "axios";
import baseAPI from "../auth/api";
import {
  PaymentIntent,
  PaymentIntentForm,
  SubscriptionIntentForm,
} from "./payment";

export function createPaymentIntent(
  paymentIntentForm: PaymentIntentForm,
): AxiosPromise<PaymentIntent> {
  return baseAPI.post("/payments/create-payment-intent", paymentIntentForm);
}

export function createSubscriptionIntent(
  subscriptionIntentForm: SubscriptionIntentForm,
): AxiosPromise<PaymentIntent> {
  return baseAPI.post(
    "/payments/create-subscription-intent",
    subscriptionIntentForm,
  );
}

export function createSubscriptionWithTrial(
  subscriptionIntentForm: SubscriptionIntentForm,
): AxiosPromise<PaymentIntent> {
  return baseAPI.post(
    "/payments/create-subscription-with-trial",
    subscriptionIntentForm,
  );
}

export function unsubscribeStripePlan(): AxiosPromise<void> {
  return baseAPI.put("/payments/unsubscribe-stripe-plan");
}

export function startPaidSubscriptionIntent(
  subscriptionId: NonNullable<PaymentIntent["subscriptionId"]>,
): AxiosPromise<void> {
  return baseAPI.post(
    `/payments/${subscriptionId}/start-paid-subscription-intent`,
  );
}
