import Form from "../../../forms/Form";
import { useMemo, useState } from "react";
import { object, string, number, date, boolean, array } from "yup";
import { useTranslation } from "react-i18next";
import AutoField from "../../../forms/AutoField";
import { AxiosError } from "axios";
import { useToastsWithIntl } from "../../../toast-notifications";
import ValidationsErrors from "../../../forms/ValidationsErrors";
import SubmitButton from "src/services/forms/SubmitButton";
import {
  Exchange,
  ExchangeForm as IExchangeForm,
  ExchangeType,
  ExchangeVatCode,
  ExchangeVehicleColor,
  ExchangeVehicleEnergy,
  ExchangeVehicleGearbox,
  ExchangeVehicleInnerColor,
  ExchangeVehicleSeatMaterial,
  ExchangeVehicleTransmission,
  ExchangeVehicleType,
  FullExchange,
  maxVinSubmit,
} from "../../exchange";
import { useExchange } from "../../exchangeProvider";
import { Field } from "formik";
import { useCountry } from "../../../countries/useCountry";
import { useFetchExchangeOptionToEnum } from "../../useFetchExchangeOptionToEnum";
import ExchangePublicationDialog from "../ExchangePublicationDialog";
import VehicleDocumentZone from "./VehicleDocumentZone";
import VehiclePictureZone from "./VehiclePictureZone";
import VinSubmitButton from "./VinSubmitButton";
import { User } from "../../../auth/user";
import { useLocation, useNavigate } from "react-router-dom";
import generatePath from "../../../routing/generatePath";
import { EXCHANGE_DETAIL_LINK } from "../../../../routes/exchange";
import { useAuth, AuthAPIConnected } from "../../../auth/apiProvider";
import { ADMIN_EXCHANGE_DETAIL_LINK } from "../../../../routes/admin/exchange-listing";
import BlockingLoader from "../../../forms/BlockingLoader";
import Dialog from "../../../ui/block/Dialog";
import infoIcon from "src/assets/img/icons/icon-info-white.svg";
import Button from "../../../ui/reactive/Button";
import { OfferStatus } from "src/services/offer/offer";

const ExchangeForm = ({
  initialExchange,
  userExchange,
}: {
  initialExchange?: FullExchange;
  userExchange?: User;
}): JSX.Element => {
  const { user } = useAuth() as AuthAPIConnected;
  const { countriesList } = useCountry();
  const {
    createExchange,
    updateExchange,
    getFormOptionsBrand,
    getFormOptionsModel,
    getFormOptionsSubModel,
    getFormOptionsEngine,
  } = useExchange();
  const { t } = useTranslation(["exchange"]);
  const { toastError, toastSuccess } = useToastsWithIntl(["exchange"]);
  const [showPublicationDialog, setShowPublicationDialog] = useState(false);
  const [showEmptyField, setShowEmptyField] = useState(false);
  const [vinSubmitCount, setVinSubmitCount] = useState(0);
  const navigate = useNavigate();
  const [showInformationDialog, setShowInformationDialog] = useState(false);
  const location = useLocation();

  const [
    vehicleBrandOptionsSelected,
    setVehicleBrandOptionsSelected,
  ] = useState<Exchange["vehicleBrand"] | undefined>(
    initialExchange?.vehicleBrand,
  );
  const [
    vehicleModelOptionsSelected,
    setVehicleModelOptionsSelected,
  ] = useState<Exchange["vehicleModel"] | undefined>(
    initialExchange?.vehicleModel,
  );

  const [
    vehicleSubModelOptionsSelected,
    setVehicleSubModelOptionsSelected,
  ] = useState<Exchange["vehicleSubModel"] | undefined>(
    initialExchange?.vehicleSubModel,
  );

  const [brandMultiSelectorEnum] = useFetchExchangeOptionToEnum(
    getFormOptionsBrand,
  );
  const [
    modelMultiSelectorEnum,
    setModelMultiSelectorEnum,
  ] = useFetchExchangeOptionToEnum(
    getFormOptionsModel,
    vehicleBrandOptionsSelected,
    true,
  );
  const [
    subModelMultiSelectorEnum,
    setSubModelMultiSelectorEnum,
  ] = useFetchExchangeOptionToEnum(
    getFormOptionsSubModel,
    vehicleModelOptionsSelected,
    true,
  );

  const [
    engineMultiSelectorEnum,
    setEngineMultiSelectorEnum,
  ] = useFetchExchangeOptionToEnum(
    getFormOptionsEngine,
    vehicleSubModelOptionsSelected,
    true,
  );

  const vatCodeFormated = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(ExchangeVatCode)
          .filter((vatOption) => typeof vatOption[1] !== "string")
          .sort((a, b) => (a[1] as number) - (b[1] as number))
          .map(([vatCountry, vatValue]) => [
            vatCountry,
            vatValue !== 0
              ? `${Number(vatValue) * 100}% (${
                  countriesList[vatCountry.slice(0, 2)]
                })`
              : "TVA sur marge (0%)",
          ]),
      ),
    [countriesList],
  );

  const handleFormSubmit = (values: IExchangeForm) => {
    return !!initialExchange
      ? updateExchange(values, userExchange?.id).then(
          (exchange) => {
            toastSuccess("exchange:update-exchange.SUCCESS");
            navigate(
              generatePath(
                user.superAdmin
                  ? ADMIN_EXCHANGE_DETAIL_LINK
                  : EXCHANGE_DETAIL_LINK,
                {
                  exchangeId: exchange.id,
                },
              ),
              {
                state: {
                  previous: location.state
                    ? (location.state as { previous: string }).previous
                    : "/edit",
                  page: location.state
                    ? (location.state as { page: string }).page
                    : null,
                },
                replace: false,
              },
            );
          },
          (err: AxiosError) => {
            toastError("exchange:update-exchange.ERROR");
          },
        )
      : createExchange(values, userExchange?.id).then(
          (exchange) => {
            if (user.superAdmin) {
              navigate(
                generatePath(
                  user.superAdmin
                    ? ADMIN_EXCHANGE_DETAIL_LINK
                    : EXCHANGE_DETAIL_LINK,
                  {
                    exchangeId: exchange.id,
                  },
                ),
                {
                  state: {
                    previous: "/create",
                  },
                  replace: false,
                },
              );
            } else setShowPublicationDialog(true);
          },
          (err: AxiosError) => {
            toastError("exchange:create-exchange.ERROR");
          },
        );
  };

  const exchangeHasOffer =
    !!initialExchange &&
    !!initialExchange.Offers.find(
      (offer) =>
        offer.status !== OfferStatus.STATUS_DECLINED &&
        offer.status !== OfferStatus.STATUS_CANCELED,
    );

  const ExchangeSchema = useMemo(
    () =>
      object()
        .shape({
          vehicleMileage: number()
            .label(t("exchange:vehicle_mileage"))
            .showEmptyIndicator(showEmptyField)
            .nullable()
            .required()
            .notEditable(exchangeHasOffer),
          vehicleSalePrice: number()
            .label(t("exchange:vehicle_sale_price"))
            .showEmptyIndicator(showEmptyField)
            .nullable()
            .required()
            .min(1, () => ({ key: "check-price-error" }))
            .notEditable(exchangeHasOffer),
          vatCode: string()
            .label(t("exchange:vat_percentage"))
            .showEmptyIndicator(showEmptyField)
            .meta({
              select: true,
              enum: vatCodeFormated,
              withoutParse: true,
            })
            .nullable()
            .required()
            .notEditable(exchangeHasOffer),
          vehicleCountryCode: string()
            .label(t("exchange:vehicle_country_code"))
            .showEmptyIndicator(showEmptyField)
            .nullable()
            .autocomplete(countriesList, "exchange", "country-select", {
              active: false,
              hasSelectAll: false,
            })
            .min(2)
            .max(2)
            .notEditable(exchangeHasOffer),
          storageCountryCode: string()
            .label(t("exchange:storage_country_code"))
            .showEmptyIndicator(showEmptyField)
            .nullable()
            .required()
            .autocomplete(countriesList, "exchange", "country-select", {
              active: false,
              hasSelectAll: false,
            })
            .min(2)
            .max(2)
            .notEditable(exchangeHasOffer),
          vehicleVin: string()
            .label(t("exchange:vehicle_vin"))
            .showEmptyIndicator(showEmptyField)
            .nullable()
            .notEditable(!!initialExchange),
          vehicleBrand: string()
            .label(t("exchange:vehicle_brand"))
            .showEmptyIndicator(showEmptyField)
            .nullable()
            .autocomplete(
              brandMultiSelectorEnum,
              "exchange",
              "brand-create-or-select",
              {
                active: false,
                hasSelectAll: false,
                isCreatable: true,
              },
            )
            .required()
            .notEditable(!!initialExchange),
          vehicleModel: string()
            .label(t("exchange:vehicle_model"))
            .showEmptyIndicator(showEmptyField)
            .meta({ disabled: vehicleBrandOptionsSelected === undefined })
            .nullable()
            .autocomplete(
              modelMultiSelectorEnum,
              "exchange",
              "model-create-or-select",
              {
                active: false,
                hasSelectAll: false,
                isCreatable: true,
              },
            )
            .required()
            //edutable only for superAdmin on update
            .notEditable(
              !!initialExchange &&
                initialExchange.adOrigin === null &&
                !user.superAdmin,
            ),
          vehicleSubModel: string()
            .label(t("exchange:vehicle_sub_model"))
            .showEmptyIndicator(showEmptyField)
            .meta({ disabled: vehicleModelOptionsSelected === undefined })
            .nullable()
            .autocomplete(
              subModelMultiSelectorEnum,
              "exchange",
              "sub-model-create-or-select",
              { active: false, hasSelectAll: false, isCreatable: true },
            )
            .required()
            .notEditable(exchangeHasOffer),
          vehicleEngine: string()
            .label(t("exchange:vehicle_engine"))
            .showEmptyIndicator(showEmptyField)
            .meta({ disabled: vehicleSubModelOptionsSelected === undefined })
            .autocomplete(
              engineMultiSelectorEnum,
              "exchange",
              "engine-create-or-select",
              { active: false, hasSelectAll: false, isCreatable: true },
            )
            .nullable()
            .required()
            .notEditable(exchangeHasOffer),
          vehicleEntryIntoService: date()
            .label(t("exchange:vehicle_entry_into_service"))
            .showEmptyIndicator(showEmptyField)
            .nullable()
            .required()
            .notEditable(exchangeHasOffer),
          vehiclePower: number()
            .label(t("exchange:vehicle_power"))
            .showEmptyIndicator(showEmptyField)
            .nullable()
            .required()
            .notEditable(exchangeHasOffer),
          vehicleFiscalHorsepower: number()
            .label(t("exchange:vehicle_fiscal_horsepower"))
            .showEmptyIndicator(showEmptyField)
            .nullable()
            .notEditable(exchangeHasOffer),
          vehicleEnergy: string()
            .label(t("exchange:vehicle_energy_label"))
            .showEmptyIndicator(showEmptyField)
            .meta({
              select: true,
              enum: ExchangeVehicleEnergy,
              translate: ["exchange", "exchange:vehicle_energy"],
              withoutParse: true,
            })
            .nullable()
            .required()
            .notEditable(exchangeHasOffer),
          vehicleCo2Emission: number()
            .label(t("exchange:vehicle_co2_emission"))
            .showEmptyIndicator(showEmptyField)
            .nullable()
            .notEditable(exchangeHasOffer),
          vehicleType: number()
            .label(t("exchange:vehicle_type_label"))
            .showEmptyIndicator(showEmptyField)
            .oneOfEnum(ExchangeVehicleType, "exchange", "vehicle_type", true)
            .nullable()
            .notEditable(exchangeHasOffer),
          vehicleColor: number()
            .label(t("exchange:vehicle_color_label"))
            .showEmptyIndicator(showEmptyField)
            .oneOfEnum(ExchangeVehicleColor, "exchange", "vehicle_color", true)
            .nullable()
            .notEditable(exchangeHasOffer),
          vehicleInnerColor: number()
            .label(t("exchange:vehicle_inner_color_label"))
            .showEmptyIndicator(showEmptyField)
            .oneOfEnum(
              ExchangeVehicleInnerColor,
              "exchange",
              "vehicle_inner_color",
              true,
            )
            .nullable()
            .notEditable(exchangeHasOffer),
          vehicleGearbox: string()
            .label(t("exchange:vehicle_gearbox_label"))
            .showEmptyIndicator(showEmptyField)
            .meta({
              select: true,
              enum: ExchangeVehicleGearbox,
              translate: ["exchange", "exchange:vehicle_gearbox"],
              withoutParse: true,
            })
            .nullable()
            .notEditable(exchangeHasOffer),
          vehicleTransmission: string()
            .label(t("exchange:vehicle_transmission_label"))
            .showEmptyIndicator(showEmptyField)
            .meta({
              select: true,
              enum: ExchangeVehicleTransmission,
              translate: ["exchange", "exchange:vehicle_transmission"],
              withoutParse: true,
            })
            .nullable()
            .notEditable(exchangeHasOffer),
          type: number()
            .label(t("exchange:type_label"))
            .showEmptyIndicator(showEmptyField)
            .oneOfEnum(ExchangeType, "exchange", "type", true)
            .nullable()
            .notEditable(exchangeHasOffer),
          isVehicleDamaged: boolean()
            .label(t("exchange:is_vehicle_damaged"))
            .showEmptyIndicator(showEmptyField)
            .default(false)
            .nullable()
            .required()
            .notEditable(exchangeHasOffer),
          vehicleOriginCode: string()
            .label(t("exchange:vehicle_origin_code"))
            .showEmptyIndicator(showEmptyField)
            .nullable()
            .autocomplete(
              { UK: "Non connu", ...countriesList },
              "exchange",
              "country-select",
              {
                active: false,
                hasSelectAll: false,
              },
            )
            .required()
            .min(2)
            .max(2)
            .notEditable(exchangeHasOffer),
          vehicleSeatMaterial: number()
            .label(t("exchange:vehicle_seat_material_label"))
            .showEmptyIndicator(showEmptyField)
            .oneOfEnum(
              ExchangeVehicleSeatMaterial,
              "exchange",
              "vehicle_seat_material",
              true,
            )
            .nullable()
            .notEditable(exchangeHasOffer),
          otherInformation: string()
            .multiline()
            .label(t("exchange:other_information"))
            .nullable()
            .notEditable(exchangeHasOffer),
          confirmInformation: boolean()
            .label(t("exchange:confirm_information"))
            .nullable()
            .default(false)
            .required()
            .oneOf([true], () => ({ key: "check-info-error" })),
          VehiclePictures: array()
            .label(t("exchange:vehicle_pictures"))
            .of(
              object()
                .shape({
                  file: string().required(),
                  order: number().required(),
                  id: number(),
                })
                .defined(),
            )
            .defined()
            .min(1),
          VehicleDocuments: array()
            .label(t("exchange:vehicle_documents"))
            .of(object().shape({ file: string(), id: number() }).defined())
            .defined(),
        })
        .defined(),
    [
      t,
      showEmptyField,
      exchangeHasOffer,
      vatCodeFormated,
      countriesList,
      initialExchange,
      brandMultiSelectorEnum,
      vehicleBrandOptionsSelected,
      modelMultiSelectorEnum,
      user.superAdmin,
      vehicleModelOptionsSelected,
      subModelMultiSelectorEnum,
      vehicleSubModelOptionsSelected,
      engineMultiSelectorEnum,
    ],
  );

  return (
    <div>
      <Form
        initialValues={
          !!initialExchange
            ? initialExchange
            : {
                VehiclePictures: [],
                VehicleDocuments: [],
              }
        }
        onSubmit={(values: IExchangeForm) => handleFormSubmit(values)}
        schema={ExchangeSchema}
      >
        {!exchangeHasOffer && (
          <>
            <div className={"container-wrap--s --no-padding"}>
              <div className={"grid --l --2-cols"}>
                <AutoField name={"vehicleMileage"} />
                <div className={"grid --s"}>
                  <div className={"col-2-3"}>
                    <AutoField name={"vehicleSalePrice"} />
                  </div>
                  <AutoField name={"vatCode"} />
                </div>

                <AutoField name={"vehicleOriginCode"} ignoreSpecialChar />
                <AutoField name={"vehicleVin"} />
              </div>

              <div className={"form-footer"}>
                {!initialExchange && (
                  <>
                    <VinSubmitButton
                      className={"btn-light"}
                      disabled={vinSubmitCount >= maxVinSubmit}
                      onSuccess={(vinResult) => {
                        setVinSubmitCount((prevCount) => prevCount + 1);
                        setShowEmptyField(true);
                        if (
                          vinResult.vehicleModel &&
                          vinResult.vehicleSubModel &&
                          vinResult.vehicleEngine
                        ) {
                          setModelMultiSelectorEnum({
                            [vinResult.vehicleModel]: "",
                          });
                          setSubModelMultiSelectorEnum({
                            [vinResult.vehicleSubModel]: "",
                          });
                          setEngineMultiSelectorEnum({
                            [vinResult.vehicleEngine]: "",
                          });
                        }
                      }}
                    >
                      Rechercher le véhicule
                    </VinSubmitButton>
                    <p className={"info"}>
                      La recherche de requête VIN est une démarche payante
                      incluse dans la publication de l'annonce. Nous autorisons
                      deux recherches VIN par publication d'annonce. Toute
                      requête supplémentaire sera facturée 3€ HT en fin de mois.
                    </p>
                  </>
                )}
              </div>
            </div>
            <div className={"section-xl --bg --light grid --m --2-cols"}>
              <AutoField
                afterValueChanged={(value: string) =>
                  setVehicleBrandOptionsSelected(value)
                }
                name={"vehicleBrand"}
              />
              <AutoField
                afterValueChanged={(value: string) =>
                  setVehicleModelOptionsSelected(value)
                }
                name={"vehicleModel"}
              />
              <AutoField
                afterValueChanged={(value: string) =>
                  setVehicleSubModelOptionsSelected(value)
                }
                name={"vehicleSubModel"}
              />
              <AutoField name={"vehicleEngine"} />
              <AutoField
                name={"vehicleEntryIntoService"}
                otherProps={{
                  scrollableYearDropdown: true,
                  showYearDropdown: true,
                  yearDropdownItemNumber: 150,
                }}
              />
              <AutoField name={"vehiclePower"} />
              <AutoField name={"vehicleFiscalHorsepower"} />
              <AutoField name={"vehicleEnergy"} />
              <AutoField name={"vehicleCo2Emission"} />
              <AutoField name={"vehicleType"} />
              <AutoField name={"vehicleColor"} />
              <AutoField name={"vehicleInnerColor"} />
              <AutoField name={"vehicleSeatMaterial"} />

              <AutoField name={"vehicleGearbox"} />
              <AutoField name={"vehicleTransmission"} />
              <AutoField name={"type"} />

              <AutoField name={"vehicleCountryCode"} ignoreSpecialChar />
              <AutoField name={"storageCountryCode"} ignoreSpecialChar />
              <div className={"--stretch"}>
                <Button
                  className={"info_btn"}
                  onClick={() => setShowInformationDialog(true)}
                  type={"button"}
                >
                  <img alt={"info"} className={"icon"} src={infoIcon} />
                </Button>
                <AutoField name={"otherInformation"} />
              </div>

              <div className={"form-block --stretch"}>
                <label className={"input-label"}>
                  <Field
                    disabled={exchangeHasOffer}
                    name={"isVehicleDamaged"}
                    type={"checkbox"}
                  />
                  {t("exchange:is_vehicle_damaged")}
                </label>
              </div>
            </div>
          </>
        )}

        <div className={"content-block section-xl --bg --light"}>
          <div className={"form-block"}>
            <label className={"input-label"}>
              {t("exchange:vehicle_pictures")}*
            </label>
            <VehiclePictureZone name={"VehiclePictures"} />
          </div>
        </div>

        <div className={"content-block section-xl --bg --light"}>
          <div className={"form-block"}>
            <label className={"input-label"}>
              {t("exchange:vehicle_documents")}
            </label>
            <VehicleDocumentZone name={"VehicleDocuments"} />
          </div>
        </div>

        <div className={"section --bg --grey"}>
          <div className={"form-block --stretch"}>
            <label className={"input-label"}>
              <Field name={"confirmInformation"} type={"checkbox"} />
              {t("exchange:confirm_information")}
            </label>
          </div>
        </div>

        <div className={"form-footer"}>
          <ValidationsErrors />
          <BlockingLoader
            message={"La publication peut prendre quelques minutes"}
          />
          <SubmitButton>
            {!!initialExchange ? "Mettre à jour l'annonce" : "Créer l'annonce"}
          </SubmitButton>
        </div>
      </Form>
      {showInformationDialog && (
        <Dialog onClose={() => setShowInformationDialog(false)}>
          <div className={"card-head"}>
            <div className={"card-title"}>Information</div>
          </div>
          <div className={"card-body"}>
            Seules les informations sur le véhicule sont attendues. La diffusion
            d’informations sur le vendeur est interdite et entrainera
            automatiquement la suppression de l’annonce.
          </div>
          <div className={"card-footer"}>
            <Button
              className={"btn-1"}
              onClick={() => setShowInformationDialog(false)}
              type={"button"}
            >
              Fermer
            </Button>
          </div>
        </Dialog>
      )}
      {showPublicationDialog && (
        <ExchangePublicationDialog
          onClose={() => setShowPublicationDialog(false)}
          user={user}
        />
      )}
    </div>
  );
};

export default ExchangeForm;
