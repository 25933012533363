import React, { ReactElement } from "react";
import { PRIVATE } from "../../../routes/private";
import { useAuth } from "../apiProvider";
import { Navigate, useLocation } from "react-router-dom";
import generatePath from "../../routing/generatePath";
import { useQueryParam } from "../../routing/useQueryParam";

function withRedirectToPrivate<P, C extends React.ComponentType<P>>(
  WrappedComponent: C,
): C {
  return ((props: P) => {
    const auth = useAuth();
    const [redirectTo] = useQueryParam("redirectTo", "string");

    if (auth.loggedUser !== null && !redirectTo)
      return <Navigate to={PRIVATE} />;
    else if (auth.loggedUser !== null && redirectTo)
      return <Navigate to={redirectTo} />;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <WrappedComponent {...(props as P)} />;
  }) as C;
}

export default withRedirectToPrivate;

export function RequiresAuth({
  redirectTo,
  children,
}: {
  redirectTo: string;
  children: ReactElement;
}): JSX.Element {
  const { loggedUser } = useAuth();
  const location = useLocation();

  if (loggedUser) return children;

  const link = generatePath(redirectTo, {}, { redirectTo: location.pathname });

  return <Navigate to={link} />;
}
