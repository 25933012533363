import { FunctionComponent, useCallback } from "react";
import { withLoader } from "../../routing/useLoader";
import CardBody from "../../ui/block/CardBody";
import Dialog from "../../ui/block/Dialog";
import { usePlan } from "../planProvider";
import PlansTable from "./PlansTable";

const PlanComparaisonTableDialog: FunctionComponent<{
  onClose: () => void;
}> = ({ onClose }) => {
  const { plans } = usePlan();
  return (
    <Dialog className={"dialog-card-full"} onClose={onClose}>
      <CardBody>
        <PlansTable plans={plans} />
      </CardBody>
    </Dialog>
  );
};

const useLoad = () => {
  const { loadPlans } = usePlan();

  return useCallback(() => loadPlans(), [loadPlans]);
};

export default withLoader(PlanComparaisonTableDialog, useLoad);
