import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { number, object, string } from "yup";
import { ADMIN_PLAN_DETAIL_LINK } from "../../../routes/admin/plan";
import AutoField from "../../forms/AutoField";
import Form from "../../forms/Form";
import SubmitButton from "../../forms/SubmitButton";
import ValidationsErrors from "../../forms/ValidationsErrors";
import generatePath from "../../routing/generatePath";
import { useToastsWithIntl } from "../../toast-notifications";
import { PlanForm as IPlanForm } from "../plan";
import { usePlan } from "../planProvider";

const PlanForm = (): JSX.Element => {
  const { createPlan } = usePlan();
  const { t } = useTranslation(["plan"]);
  const navigate = useNavigate();
  const { toastError } = useToastsWithIntl(["plan"]);

  const PlanSchema = useMemo(
    () =>
      object()
        .shape({
          label: string().label(t("plan:label")).nullable().required(),
          monthlyPrice: number().label(t("plan:monthlyPrice")).nullable(),
          annualMonthlyPrice: number()
            .label(t("plan:annualMonthlyPrice"))
            .nullable(),
          maxExchangeOfferDailyNb: number()
            .label(t("plan:maxExchangeOfferDailyNb"))
            .nullable()
            .required(),
          maxInfoRequestDailyNb: number()
            .label(t("plan:maxInfoRequestDailyNb"))
            .nullable()
            .required(),
          maxExchangePostNb: number()
            .label(t("plan:maxExchangePostNb"))
            .nullable()
            .required(),
          postVinPrice: number()
            .label(t("plan:postVinPrice"))
            .nullable()
            .required(),
          postStdPrice: number().nullable().required(),
          postEmergencyPrice: number()
            .label(t("plan:postEmergencyPrice"))
            .nullable()
            .required(),
          monthsOfTrial: number()
            .label(t("plan:monthsOfTrial"))
            .min(0)
            .max(11)
            .nullable(),
        })
        .defined(),
    [t],
  );

  return (
    <Form
      initialValues={{ postStdPrice: 0 }}
      onSubmit={(values: IPlanForm) =>
        createPlan(values).then(
          (plan) =>
            navigate(
              generatePath(ADMIN_PLAN_DETAIL_LINK, {
                planId: plan.id,
              }),
            ),
          () => toastError("plan:create-plan.ERROR"),
        )
      }
      schema={PlanSchema}
    >
      <div className={"section-xl --bg --light grid --m --2-cols"}>
        <AutoField name={"label"} />
        <AutoField name={"monthlyPrice"} />
        <AutoField name={"annualMonthlyPrice"} />
        <AutoField name={"maxExchangeOfferDailyNb"} />
        <AutoField name={"maxInfoRequestDailyNb"} />
        <AutoField name={"maxExchangePostNb"} />
        <AutoField name={"postVinPrice"} />
        <AutoField name={"postEmergencyPrice"} />
        <AutoField name={"monthsOfTrial"} />
      </div>

      <div className={"form-footer"}>
        <ValidationsErrors />
        <SubmitButton>Créer le plan</SubmitButton>
      </div>
    </Form>
  );
};

export default PlanForm;
