import { Plan, Subscription } from "../plan/plan";

export interface PaymentIntentForm {
  item: IntentType;
  referenceId: number;
}

export interface SubscriptionIntentForm {
  planId: Plan["id"];
  isBuyer: Subscription["isBuyer"];
  isAnnual: boolean;
  paymentMethodId?: string;
}

export interface PaymentIntent {
  clientSecret: string;
  price: number;
  subscriptionId?: string;
  isTrial?: boolean;
}

export type IntentType = "post" | "offer";

export const frenchTVA = 1.2;
