import { useState } from "react";
import PlanChoiceDialog from "../../services/plan/components/PlanChoiceDialog";
import PlansTable from "../../services/plan/components/PlansTable";
import { usePlan } from "../../services/plan/planProvider";
import { withLoader } from "../../services/routing/useLoader";

const PrivatePlansTable = (): JSX.Element => {
  const { plans } = usePlan();
  const [showPlanChoiceDialog, setShowPlanChoiceDialog] = useState<boolean>(
    false,
  );

  return (
    <div className={"container page-content"}>
      <PlansTable
        plans={plans}
        setShowPlanChoiceDialog={setShowPlanChoiceDialog}
      />

      {showPlanChoiceDialog && (
        <PlanChoiceDialog onClose={() => setShowPlanChoiceDialog(false)} />
      )}
    </div>
  );
};

const useLoad = () => {
  const { loadPlans } = usePlan();

  return loadPlans;
};

export default withLoader(PrivatePlansTable, useLoad);
