import React from "react";
import { ADMIN_NEW_PLAN_LINK } from "../../../../routes/admin/plan";
import PlansManagementTable from "../../../../services/plan/components/PlansManagementTable";
import { usePlan } from "../../../../services/plan/planProvider";
import Link from "../../../../services/routing/components/Link";

const PlansManagement = (): JSX.Element => {
  const { plans } = usePlan();

  return (
    <div className={"container page-content"}>
      <div className={"page_head"}>
        <h1 className={"page-title"}>Tous les plans</h1>
        <Link className={"btn"} to={ADMIN_NEW_PLAN_LINK}>
          Créer un plan
        </Link>
      </div>
      <div className={"section-l lay-col --gap-v--xs"}>
        <div className={"section --stretch-h"}>
          <PlansManagementTable plans={plans} />
        </div>
      </div>
    </div>
  );
};

export default PlansManagement;
