import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { EXCHANGE_DETAIL_LINK } from "src/routes/exchange";
import useFileAsObjectURL from "src/services/files/useFileAsObjectURL";
import iconFilledReadHeart from "../../../assets/img/icons/icon-red-heart-filled.svg";
import iconOutlinedWhiteHeart from "../../../assets/img/icons/icon-white-heart-outlined.svg";
import { AuthAPIConnected, useAuth } from "../../auth/apiProvider";
import { useDate } from "../../date/DateContext";
import generatePath from "../../routing/generatePath";
import {
  ExchangeStatus,
  FullExchange,
  getBestExchangeOffersStatus,
} from "../exchange";
import { useExchange } from "../exchangeProvider";
import { cx } from "@emotion/css";
import "flag-icons/css/flag-icons.min.css";
import { ADMIN_EXCHANGE_DETAIL_LINK } from "../../../routes/admin/exchange-listing";
import { OfferStatus } from "../../offer/offer";
import { useQueryParam } from "../../routing/useQueryParam";

const ExchangeListItem = ({
  exchange,
  asAdmin,
}: {
  exchange: FullExchange;
  asAdmin?: boolean;
}): JSX.Element => {
  const { user } = useAuth() as AuthAPIConnected;
  const { formatDate } = useDate();
  const {
    formatVehicleOptions,
    addToFavorites,
    removeFromFavorites,
  } = useExchange();
  const { t } = useTranslation(["common", "offer"]);
  const [pageUrl] = useQueryParam("page", "number");
  const location = useLocation();

  const formatedVehicle = useMemo(() => formatVehicleOptions(exchange), [
    exchange,
    formatVehicleOptions,
  ]);

  const objectUrl = useFileAsObjectURL({
    url: formatedVehicle.VehiclePictures[0]?.file,
  });

  const bestStatusOffer = useMemo(() => getBestExchangeOffersStatus(exchange), [
    exchange,
  ]);

  return (
    <Link
      className={"exchange-item"}
      state={{
        previous: location.pathname,
        page: pageUrl,
      }}
      to={generatePath(
        asAdmin ? ADMIN_EXCHANGE_DETAIL_LINK : EXCHANGE_DETAIL_LINK,
        {
          exchangeId: exchange.id,
        },
      )}
    >
      <div className={"exchange-item_photo"}>
        <div
          className={"exchange-item_photo_image"}
          style={{
            backgroundImage: objectUrl ? `url(${objectUrl.url})` : undefined,
          }}
        />
        {exchange.UserId !== user.id && (
          <button
            className={"exchange_favorite-button"}
            onClick={(ev) => {
              ev.preventDefault();
              if (exchange.isFavorite) removeFromFavorites(exchange);
              else addToFavorites(exchange);
            }}
            type={"button"}
          >
            {exchange.isFavorite ? (
              <img
                alt={"remove from favorites"}
                className={"icon"}
                src={iconFilledReadHeart}
              />
            ) : (
              <img
                alt={"add to favorites"}
                className={"icon"}
                src={iconOutlinedWhiteHeart}
              />
            )}
          </button>
        )}
      </div>
      <div className={"exchange_body"}>
        <div className={"exchange_body_main"}>
          <div
            className={"exchange_title"}
            dangerouslySetInnerHTML={{
              __html:
                formatedVehicle.adOrigin === null
                  ? `${formatedVehicle.vehicleBrand} ${
                      formatedVehicle.vehicleModel
                    } ${
                      formatedVehicle.vehicleSubModel !== "NR" &&
                      formatedVehicle.vehicleSubModel
                    }`
                  : `${
                      formatedVehicle.description ||
                      formatedVehicle.vehicleBrand +
                        " " +
                        formatedVehicle.vehicleMetaModel
                    }`,
            }}
          />
          <div className={"vehicle-data"}>
            <p>
              <span
                className={`exchange_country-flag fi-${exchange.storageCountryCode.toLowerCase()}`}
              ></span>{" "}
              {exchange.storageCountryCode}
            </p>
            <p className={"data-row"}>
              <strong>{formatedVehicle.vehicleMileage} km</strong>
              <span>
                Mise en circulation :{" "}
                {formatedVehicle.vehicleEntryIntoService &&
                  formatDate(
                    formatedVehicle.vehicleEntryIntoService,
                    "MM/yyyy",
                  )}
              </span>
              <span>{formatedVehicle.vehicleEnergy}</span>
              <span>{formatedVehicle.vehicleTransmission}</span>
              {formatedVehicle.vehiclePower && (
                <span>{formatedVehicle.vehiclePower} Ch</span>
              )}
              <span>ID : {" " + formatedVehicle.id}</span>
            </p>
          </div>
        </div>
        <div className={"exchange_statuses"}>
          {exchange.publicationEndDate &&
            (exchange.UserId === user.id || user.superAdmin) && (
              <div className={"published-status"}>Publiée</div>
            )}
          {!!exchange.Offers.length &&
            bestStatusOffer !== ExchangeStatus.STATUS_SOLD && (
              <div className={cx("exchange_status", "--c-red" && false)}>
                {bestStatusOffer === OfferStatus.STATUS_PENDING &&
                  t("offer:offer_status.STATUS_PENDING")}
                {bestStatusOffer === OfferStatus.STATUS_ACCEPTED &&
                  t("offer:offer_status.STATUS_ACCEPTED")}
                {bestStatusOffer === OfferStatus.STATUS_VALIDATED &&
                  t("offer:offer_status.STATUS_VALIDATED")}
              </div>
            )}
          {(bestStatusOffer === ExchangeStatus.STATUS_SOLD ||
            exchange.soldDate) && (
            <div className={cx("exchange_status", "--c-red" && false)}>
              {t("offer:offer_status.STATUS_SOLD")}
            </div>
          )}
        </div>
      </div>
      {formatedVehicle.vehicleSalePrice && (
        <div className={"exchange_price"}>
          <div>
            <div className={"exchange_price_ttc"}>
              {t("common:number", { val: formatedVehicle.vehicleSalePrice })}€
              <sup>TTC</sup>
            </div>
            <div className={"exchange_price_ht"}>
              {t("common:number", {
                val:
                  formatedVehicle.vehicleSalePrice /
                  (1 + formatedVehicle.vatPercentage),
              })}
              € HT (TVA {formatedVehicle.vatPercentage * 100}%)
            </div>
          </div>
        </div>
      )}
    </Link>
  );
};

export default ExchangeListItem;
