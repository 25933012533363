import React, { ReactElement } from "react";
import { LinkProps as RRLinkProps, Link as RRLink } from "react-router-dom";
import { ExtractRouteParams } from "../types";
import generatePath from "../generatePath";

interface BaseProps extends React.PropsWithoutRef<RRLinkProps> {
  queryParams?: Record<string, unknown>;
}

interface OwnProps<T extends string> extends BaseProps {
  to: T;
  params?: never;
}
interface OwnPropsWithParams<T extends string> extends BaseProps {
  to: T;
  params: ExtractRouteParams<T>;
}

export type Props<T extends string> = (T extends
  | `${string}:${string}/${string}`
  | `${string}:${string}`
  ? OwnPropsWithParams<T>
  : OwnProps<T>) &
  React.RefAttributes<HTMLAnchorElement>;

const Link = <T extends string>({
  to,
  params,
  queryParams,
  state,
  ...restProps
}: Props<T>): ReactElement => {
  return (
    <RRLink
      {...restProps}
      state={state}
      to={generatePath(to, params, queryParams)}
    />
  );
};

export default Link;
