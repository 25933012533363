import useFileAsObjectURL from "../files/useFileAsObjectURL";

const Picture = ({
  pictureFile,
  forwardedRef,
  onClick,
}: {
  pictureFile: string;
  onClick?: () => void;
  forwardedRef?: React.LegacyRef<HTMLButtonElement>;
}): JSX.Element => {
  const pictureUrl = useFileAsObjectURL({
    url: pictureFile,
  });

  return (
    <button
      ref={forwardedRef}
      className={"pic --main"}
      onClick={onClick}
      style={{
        backgroundImage: `url(${pictureUrl?.url})`,
      }}
    ></button>
  );
};

export default Picture;
