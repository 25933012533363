import { useMemo } from "react";
import { AuthAPIConnected, useAuth } from "../../services/auth/apiProvider";
import ContactForm from "../../services/auth/components/ContactForm";
import { ContactFormValues } from "../../services/auth/user";
import useQueryParams from "../../services/routing/useQueryParams";

const PrivateContact = (): JSX.Element => {
  const [queryParams] = useQueryParams();
  const { user } = useAuth() as AuthAPIConnected;

  const initialValues: Partial<ContactFormValues> = useMemo(
    () => ({
      ...queryParams,
      lastName: user.lastname,
      firstName: user.firstname,
      mail: user.email,
      phone: user.phone,
      companyName: user.companyName,
      jobName: user.position,
    }),
    [queryParams, user],
  );

  return (
    <div className={"container-wrap page-content"}>
      <div className={"page_head"}>
        <h1 className={"page-title"}>
          Entrer <span className={"kpi"}>en contact !</span>
        </h1>
      </div>

      <div className={"section"}>
        <ContactForm initialValues={initialValues} />
      </div>
    </div>
  );
};

export default PrivateContact;
