import React, { useContext, useEffect, useState } from "react";
import { FormikContextType, FormikContext } from "formik";
import Dialog from "../ui/block/Dialog";

interface BlockingLoaderProps {
  message: string;
}

const BlockingLoader = ({
  message,
}: BlockingLoaderProps): JSX.Element | null => {
  const formik: FormikContextType<any> | undefined = useContext(FormikContext);

  const [dots, setDots] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((prevDots) => {
        return prevDots.length >= 3 ? "" : prevDots + ".";
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return formik?.isSubmitting ? (
    <Dialog>
      <div className={"card-head"}>
        <div className={"card-title"}>Veuillez patienter</div>
      </div>
      <div className={"card-body"}>
        <p>
          {message} {dots}
        </p>
      </div>
    </Dialog>
  ) : null;
};

export default BlockingLoader;
