import {
  getInfoRequests,
  answerInfoRequest as apiAnswerInfoRequest,
  startWeProoveInspection as apiStartWeProoveInspection,
} from "./api";
import {
  createContext,
  useContext,
  useState,
  useCallback,
  ComponentType,
} from "react";
import { User } from "../auth/user";
import { AnswerInfoRequestParams, InfoRequest } from "./infoRequest";

export interface InfoRequestAPI {
  infoRequests: InfoRequest[];

  loadInfoRequests(userId: User["id"]): Promise<void>;
  answerInfoRequest(
    infoRequest: InfoRequest,
    answerInfoRequestForm: AnswerInfoRequestParams,
  ): Promise<void>;
  startWeProoveInspection(infoRequest: InfoRequest): Promise<void>;
}

export const InfoRequestContext = createContext<InfoRequestAPI | null>(null);

export function ProvideInfoRequest({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const [infoRequests, setInfoRequests] = useState<InfoRequest[]>([]);

  const loadInfoRequests: InfoRequestAPI["loadInfoRequests"] = useCallback(
    (userId) =>
      getInfoRequests(userId).then(({ data }) => setInfoRequests(data)),
    [],
  );

  const answerInfoRequest: InfoRequestAPI["answerInfoRequest"] = useCallback(
    (infoRequest, answerInfoRequestParams) =>
      apiAnswerInfoRequest(
        infoRequest,
        answerInfoRequestParams,
      ).then(({ data }) =>
        setInfoRequests((prev) => [
          ...prev.filter((infoRequest) => infoRequest.id !== data.id),
          data,
        ]),
      ),
    [],
  );

  const startWeProoveInspection: InfoRequestAPI["startWeProoveInspection"] = useCallback(
    (infoRequest) =>
      apiStartWeProoveInspection(infoRequest).then(({ data }) =>
        setInfoRequests((prev) => [
          ...prev.filter((infoRequest) => infoRequest.id !== data.id),
          data,
        ]),
      ),
    [],
  );

  return (
    <InfoRequestContext.Provider
      value={{
        infoRequests,
        loadInfoRequests,
        answerInfoRequest,
        startWeProoveInspection,
      }}
    >
      {children}
    </InfoRequestContext.Provider>
  );
}

export function useInfoRequest(): InfoRequestAPI {
  return useContext(InfoRequestContext) as InfoRequestAPI;
}
export function withProvideInfoRequest<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function withProvideInfoRequest(props: P) {
    return (
      <ProvideInfoRequest>
        <WrappedComponent {...props} />
      </ProvideInfoRequest>
    );
  }

  withProvideInfoRequest.displayName = `withProvideInfoRequest(${displayName})`;

  return withProvideInfoRequest;
}
