import { useCallback, useMemo } from "react";
import useQueryParams from "./useQueryParams";

type QueryParamType = "string" | "number" | "boolean" | "float";

export function useQueryParam<
  T extends QueryParamType,
  R extends T extends "string"
    ? string
    : T extends "float" | "number"
    ? number
    : T extends "boolean"
    ? boolean
    : unknown,
  Result extends R | null,
  Setter extends (value: Result | ((prev: Result) => Result)) => void
>(queryParam: string, type: T): [Result, Setter] {
  const [queryParams, setQueryParams] = useQueryParams();

  const value = useMemo(() => {
    const value = queryParams[queryParam];

    if (value === undefined) return null;

    switch (type) {
      case "string":
        return String(value);
      case "number":
        return parseInt(String(value), 10);
      case "float":
        return parseFloat(String(value));
      case "boolean":
        return value !== null;
      default:
        throw new Error(`Unknown query param type: ${type}`);
    }
  }, [queryParam, queryParams, type]);

  const setQueryParam = useCallback(
    (value) =>
      setQueryParams((prev) => ({
        ...prev,
        [queryParam]:
          typeof value === "function" ? value(prev[queryParam]) : value,
      })),
    [queryParam, setQueryParams],
  );

  return [value as Result, setQueryParam as Setter];
}
