import React, { useCallback } from "react";
import { useNavigate } from "react-router";
import { ADMIN_EXCHANGE_DETAIL_LINK } from "../../../routes/admin/exchange-listing";
import { EXCHANGE_DETAIL_LINK } from "../../../routes/exchange";
import { AuthAPIConnected, useAuth } from "../../../services/auth/apiProvider";
import ExchangeForm from "../../../services/exchange/components/exchangeForm/ExchangeForm";
import {
  ExchangeAPILoaded,
  useExchange,
} from "../../../services/exchange/exchangeProvider";
import generatePath from "../../../services/routing/generatePath";
import { withLoader } from "../../../services/routing/useLoader";
import { useParams } from "../../../services/routing/useParams";
import { useLocation } from "react-router-dom";

const ExchangeEdit = (): JSX.Element => {
  const { user } = useAuth() as AuthAPIConnected;
  const { exchange } = useExchange() as ExchangeAPILoaded;
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={"container-wrap page-content"}>
      <div className={"page_head"}>
        <h1 className={"page-title"}>Edition de l'annonce bourse d'échange</h1>
        <button
          className={"btn-1"}
          onClick={() =>
            navigate(
              generatePath(
                user.superAdmin
                  ? ADMIN_EXCHANGE_DETAIL_LINK
                  : EXCHANGE_DETAIL_LINK,
                {
                  exchangeId: exchange.id,
                },
              ),
              {
                state: {
                  previous: location.state
                    ? (location.state as { previous: string }).previous
                    : "/edit",
                  page: location.state
                    ? (location.state as { page: string }).page
                    : null,
                },
                replace: false,
              },
            )
          }
        >
          Annuler et revenir à l'annonce
        </button>
      </div>
      <div className={"content-block-xl"}>
        <ExchangeForm initialExchange={exchange!} />
      </div>
    </div>
  );
};

const useLoad = () => {
  const { loadExchangeById } = useExchange();
  const { exchangeId } = useParams();

  return useCallback(() => loadExchangeById(Number(exchangeId)), [
    exchangeId,
    loadExchangeById,
  ]);
};

export default withLoader(ExchangeEdit, useLoad);
