import { useState, useEffect } from "react";
import {
  ExchangeFormBrandOptionBase,
  ExchangeFormModelOptionBase,
  ExchangeFormSubModelOptionBase,
} from "./formOptions";

export function useFetchExchangeOptionToEnum(
  fetchFunction: (
    params?: unknown,
  ) => Promise<
    | ExchangeFormBrandOptionBase[]
    | ExchangeFormModelOptionBase[]
    | ExchangeFormSubModelOptionBase[]
  >,
  conditionParams?: unknown,
  hasConditionParams: boolean = false,
): [
  Record<string | number | symbol, unknown> | undefined,
  React.Dispatch<React.SetStateAction<unknown>>,
] {
  const [multiSelectorEnum, setMultiSelectorEnum] = useState<
    Record<string | number | symbol, unknown> | undefined
  >(undefined);
  useEffect(() => {
    if (
      !hasConditionParams ||
      (conditionParams !== undefined && conditionParams !== null)
    ) {
      fetchFunction(conditionParams).then(
        (exchangeFormOption) =>
          setMultiSelectorEnum(
            exchangeFormOption.reduce(
              (obj, item) => ({ ...obj, [item.id]: item.value }),
              {},
            ),
          ),
        () => {
          /* Handle error */
        },
      );
    } else {
      setMultiSelectorEnum({});
    }
  }, [conditionParams, fetchFunction, hasConditionParams]);

  return [multiSelectorEnum, setMultiSelectorEnum];
}
