import { parseISO } from "date-fns";
import { User } from "../auth/user";

export interface Plan {
  id: number;
  label: string;
  type: number;
  monthlyPrice: number | null;
  annualMonthlyPrice: number | null;
  maxExchangeOfferDailyNb: number;
  maxExchangePostNb: number;
  maxInfoRequestDailyNb: number;
  postStdPrice: number;
  postVinPrice: number;
  postEmergencyPrice: number;
  private: boolean;
  monthsOfTrial: number | null;
  Assignations?: Partial<User>[];
}

export interface FullPlanRaw extends Plan {
  Subscription: SubscriptionRaw;
}

export interface FullPlan extends Plan {
  Subscription: Subscription;
}

export interface PlanForm {
  label: string;
  monthlyPrice: number;
  maxExchangeOfferDailyNb: number;
  maxExchangePostNb: number;
  maxInfoRequestDailyNb: number;
  postVinPrice: number;
  postStdPrice: number;
  postEmergencyPrice: number;
}

export interface SubscriptionRaw {
  startDate: string | null;
  endDate: string | null;
  cancellationDate: string | null;
  isBuyer: boolean;
  isAnnual: boolean;
}

export interface Subscription
  extends Omit<SubscriptionRaw, "startDate" | "endDate" | "cancellationDate"> {
  startDate: Date | null;
  endDate: Date | null;
  cancellationDate: Date | null;
}

export enum PlanComparaison {
  isSuperior,
  isEqual,
  isLower,
}

export function mapRawFullPlanToFullPlan(plan: FullPlanRaw): FullPlan {
  return {
    ...plan,
    Subscription: {
      ...plan.Subscription,
      startDate:
        (plan.Subscription.startDate &&
          parseISO(plan.Subscription.startDate)) ||
        null,
      endDate:
        (plan.Subscription.endDate && parseISO(plan.Subscription.endDate)) ||
        null,
      cancellationDate:
        (plan.Subscription.cancellationDate &&
          parseISO(plan.Subscription.cancellationDate)) ||
        null,
    },
  };
}

export function comparePlan(
  planToCompare: FullPlan | Plan | null,
  comparaisonPlan: Plan,
  isBuyerToggled?: boolean,
  isAnnualToggled?: boolean,
): PlanComparaison {
  // If there is no plan to compare, it is considered as lower.
  if (!planToCompare) return PlanComparaison.isLower;

  // If the plan is a subscription plan, and there is no annual engagement and the annual toggler is off,
  // the plan is considered superior.
  if (
    "Subscription" in planToCompare &&
    !isAnnualToggled &&
    planToCompare.Subscription.isAnnual &&
    comparaisonPlan.type !== 0
  ) {
    return PlanComparaison.isSuperior;
  }

  // Get the price of the plans, considering monthly price if available, else annual price.
  const planToComparePrice =
    planToCompare.monthlyPrice || planToCompare.annualMonthlyPrice!;
  const comparaisonPlanPrice =
    comparaisonPlan.monthlyPrice || comparaisonPlan.annualMonthlyPrice!;

  // If both plans are of the same type.
  if (planToCompare.type === comparaisonPlan.type) {
    if (planToComparePrice !== comparaisonPlanPrice) {
      return planToComparePrice < comparaisonPlanPrice
        ? PlanComparaison.isLower
        : PlanComparaison.isSuperior;
    }

    if ("Subscription" in planToCompare) {
      if (
        isBuyerToggled !== undefined &&
        planToCompare.Subscription.isBuyer !== isBuyerToggled
      ) {
        if (planToCompare.Subscription.isBuyer) {
          return PlanComparaison.isSuperior;
        } else {
          return PlanComparaison.isLower;
        }
      }
      if (
        isAnnualToggled &&
        !planToCompare.Subscription.isAnnual &&
        comparaisonPlan.type !== 0
      ) {
        return PlanComparaison.isLower;
      }
    }
    return PlanComparaison.isEqual;
  }

  // If comparaison plan is custom
  if (comparaisonPlan.type === -1) {
    return PlanComparaison.isLower;
  }

  return planToCompare.type > comparaisonPlan.type
    ? PlanComparaison.isSuperior
    : PlanComparaison.isLower;
}

export const getSubscibedPlanPrice = (
  plan: FullPlan,
  planOptionBuyerPrice: string,
): number => {
  let price = plan.Subscription.isAnnual
    ? plan.annualMonthlyPrice!
    : plan.monthlyPrice!;
  price += plan.Subscription.isBuyer ? parseFloat(planOptionBuyerPrice) : 0;
  return price;
};

export const getPlanPriceHT = (
  plan: Plan,
  isAnnual: boolean,
  isBuyer: boolean,
  planOptionBuyerPrice: string,
): number => {
  let price = isAnnual ? plan.annualMonthlyPrice || 0 : plan.monthlyPrice || 0;
  price += isBuyer ? parseFloat(planOptionBuyerPrice) : 0;
  return price;
};
