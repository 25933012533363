import React from "react";
import withRedirectToPrivate from "../../services/auth/hocs/withRedirectToPrivate";
import {
  LOGIN_LINK,
  LOST_PASSWORD_LINK,
  RESET_PASSWORD,
} from "../../routes/public";
// eslint-disable-next-line no-restricted-imports
import Link from "../../services/routing/components/Link";
import { object, ref, string } from "yup";
import { useToastsWithIntl } from "../../services/toast-notifications";
import { useAuth } from "../../services/auth/apiProvider";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "../../services/routing/useParams";
import Form from "../../services/forms/Form";
import SubmitButton from "../../services/forms/SubmitButton";
import ValidationsErrors from "../../services/forms/ValidationsErrors";
import PasswordField from "../../services/forms/PasswordField";

const ResetPasswordSchema = object().shape({
  password: string().label("mot de passe").required().password(),
  passwordConfirmation: string()
    .label("confirmation du mot de passe")
    .required()
    .passwordConfirmation(ref("password")),
});

const ResetPassword: React.FC = () => {
  const { resetPassword } = useAuth();
  const { toastSuccess, toastError } = useToastsWithIntl(["auth"]);
  const navigate = useNavigate();

  const { guid } = useParams<typeof RESET_PASSWORD>();
  return (
    <>
      <h1 className={"page-title"}>Réinitialisation de votre mot de passe</h1>

      <p className={"--txt-center"}>Choisissez un nouveau mot de passe</p>

      <div className={"auth-form"}>
        <Form
          initialValues={{ password: "", passwordConfirmation: "" }}
          onSubmit={(values) => {
            return resetPassword(guid, values.password).then(
              () => {
                toastSuccess("auth:reset-password.SUCCESS");
                return navigate(LOGIN_LINK);
              },
              (err: AxiosError) => {
                if (err.response?.status === 406) {
                  toastError("auth:reset-password.ERROR_LINK_ALREADY_USE");
                  return navigate(LOST_PASSWORD_LINK);
                } else {
                  toastError("auth:reset-password.ERROR");
                }
              },
            );
          }}
          schema={ResetPasswordSchema}
        >
          <div className={"form-block"}>
            <label className={"input-label"}>Nouveau mot de passe *</label>
            <PasswordField
              name={"password"}
              placeholder={"**********"}
              showTogglePassword={"true"}
            />

            <p className={"input-tip"}>
              Votre mot de passe doit contenir :<br />
              - Minimum 8 caractères
              <br />
              - Au moins 1 majuscule et 1 minuscule
              <br />
              - Au moins 1 chiffre
              <br />
            </p>
          </div>

          <div className={"form-block"}>
            <label className={"input-label"}>
              Confirmation du nouveau mot de passe *
            </label>
            <PasswordField
              name={"passwordConfirmation"}
              placeholder={"Password confirmation"}
              showTogglePassword={"true"}
            />
          </div>

          <div className={"form-footer"}>
            <ValidationsErrors />
            <SubmitButton>Réinitialiser</SubmitButton>
            <Link className={"link"} to={LOGIN_LINK}>
              Retour à la connexion
            </Link>
          </div>
        </Form>
      </div>
    </>
  );
};

export default withRedirectToPrivate(ResetPassword);
