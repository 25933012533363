import { useCallback, useMemo } from "react";
import {
  ADMIN_ACCOUNTS_MANAGEMENT_LINK,
  ADMIN_EXCHANGE_LISTING_LINK,
} from "../../../routes/admin";
import { useAccounts } from "../../../services/accounts/accountsProvider";
import AccountsManagementTable from "../../../services/accounts/components/AccountsManagementTable";
import { useExchange } from "../../../services/exchange/exchangeProvider";
import Link from "../../../services/routing/components/Link";
import { withLoader } from "../../../services/routing/useLoader";

const AdminDashboard = (): JSX.Element => {
  const { accounts } = useAccounts();
  const { nbExchange } = useExchange();
  const pendingAccounts = useMemo(
    () =>
      accounts.filter(
        (account) => !account.validationDate && !account.superAdmin,
      ),
    [accounts],
  );

  return (
    <div className={"container page-content"}>
      <div className={"page_head"}>
        <h1 className={"page-title"}>Tableau de bord administrateur</h1>
      </div>

      <div className={"section-l"}>
        <div className={"card"}>
          <div className={"card-body"}>
            <h5>{nbExchange} annonces</h5>
            <Link to={ADMIN_EXCHANGE_LISTING_LINK}>
              Voir toutes les annonces {">"}
            </Link>
          </div>
        </div>
      </div>

      <div className={"section-l"}>
        <h2>Comptes en attente de validation</h2>
        <Link className={"link"} to={ADMIN_ACCOUNTS_MANAGEMENT_LINK}>
          Voir tous les comptes {">"}
        </Link>

        <div className={"section"}>
          <AccountsManagementTable accounts={pendingAccounts} />
        </div>
      </div>
    </div>
  );
};

const useLoad = () => {
  const { loadAccounts } = useAccounts();
  const { loadExchangeNumber } = useExchange();
  return useCallback(
    () => Promise.all([loadAccounts(), loadExchangeNumber()]),
    [loadAccounts, loadExchangeNumber],
  );
};

export default withLoader(AdminDashboard, useLoad);
