import React, { useEffect, useRef, useState } from "react";
import Picture from "./Picture";

const PicturesGallery = ({
  pictures,
  onShow,
}: {
  pictures: Array<{ file: string; order: number }>;
  onShow?: (index: number) => void;
}): JSX.Element => {
  const imgRef = useRef<HTMLButtonElement>(null);

  const scrollConf = {
    maxSteps: pictures.length <= 5 ? 0 : Math.ceil((pictures.length - 5) / 2),
    stepValue: imgRef && imgRef.current ? imgRef.current.offsetWidth + 15 : 0,
  };

  const [currentStep, setCurrentStep] = useState(0);
  const [currentScrollValue, setCurrentScrollValue] = useState(0);

  const onScrollLeft = () => {
    if (currentStep === 0) return;
    setCurrentStep(currentStep - 1);
  };

  const onScrollRight = () => {
    if (currentStep === scrollConf.maxSteps) return;
    setCurrentStep(currentStep + 1);
  };

  useEffect(() => {
    setCurrentScrollValue(
      -(currentStep * scrollConf.stepValue) - (currentStep !== 0 ? 15 : 0),
    );
  }, [scrollConf.stepValue, currentStep]);

  return (
    <div className={"pictures-gallery"}>
      <div
        className={"pictures-scroller"}
        style={{
          transform: `translateX(${currentScrollValue}px)`,
        }}
      >
        <Picture
          onClick={() => onShow && onShow(0)}
          pictureFile={pictures[0].file}
        />
        {!!pictures.slice(1).length && (
          <div className={"pictures-grid"}>
            {pictures.slice(1).map((pic, index) => (
              <Picture
                key={index}
                forwardedRef={index === 1 ? imgRef : undefined}
                onClick={() => onShow && onShow(index + 1)}
                pictureFile={pic.file}
              />
            ))}
          </div>
        )}
      </div>
      {currentStep !== 0 && (
        <button className={"btn-scroll --left"} onClick={onScrollLeft}></button>
      )}
      {currentStep !== scrollConf.maxSteps && (
        <button
          className={"btn-scroll --right"}
          onClick={onScrollRight}
        ></button>
      )}
    </div>
  );
};

export default PicturesGallery;
