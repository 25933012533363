import React, { useEffect, useState } from "react";
import withRedirectToPrivate from "../../services/auth/hocs/withRedirectToPrivate";
import { useToastsWithIntl } from "../../services/toast-notifications";
import useQueryParams from "../../services/routing/useQueryParams";
import LoginForm from "../../services/auth/components/LoginForm";
import { useAuth } from "../../services/auth/apiProvider";
import { useNavigate } from "react-router-dom";
import Dialog from "../../services/ui/block/Dialog";
import { User } from "../../services/auth/user";
import { resendActivationMail } from "../../services/auth/api";

const Login: React.FC = () => {
  const { login, validateUserRegistration } = useAuth();
  const { toastError, toastSuccess } = useToastsWithIntl(["auth"]);
  const [accountValidated, setAccountValidated] = useState(false);
  const [{ guid, redirectTo }] = useQueryParams();
  const navigate = useNavigate();

  const [notValidatedEmail, setNotValidatedEmail] = useState<
    null | User["email"]
  >(null);

  useEffect(() => {
    if (typeof guid === "string") {
      validateUserRegistration(guid).then(
        () => setAccountValidated(true),
        () => toastError("auth:validate-user-registration"),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guid]);

  return (
    <>
      <h1 className={"page-title"}>Connexion</h1>
      <p className={"--txt-center"}>
        Accédez à votre espace personnel pour gérer vos annonces, faire une
        offre ou effectuer une recherche.
      </p>
      {guid && accountValidated && (
        <div>
          <div className={"info info--success"}>
            Votre compte a bien été validé, vous pouvez désormais vous
            connecter.
          </div>
        </div>
      )}

      <LoginForm
        onSubmit={(loginInfos) => {
          return login(loginInfos).then(
            () => {
              if (redirectTo && typeof redirectTo === "string") {
                navigate(redirectTo);
              }
            },
            (errorCode) => {
              if (errorCode === 412) {
                setNotValidatedEmail(loginInfos.email);
              } else if (errorCode === 404) {
                toastError("auth:login.NOT_FOUND");
              } else {
                toastError("auth:login.TOAST_ERROR");
              }
            },
          );
        }}
      />

      {notValidatedEmail && (
        <Dialog onClose={() => setNotValidatedEmail(null)}>
          <div className={"card-head"}>
            <h2>Compte existant mais non activé</h2>
          </div>
          <div className={"card-body"}>
            <div className={"section"}>
              Votre compte existe bien, cependant il n'a pas encore été activé.
              Vous pouvez l'activer en cliquant sur le lien d'activation reçu
              par mail.
            </div>

            <button
              className={"btn btn-block"}
              onClick={() => {
                resendActivationMail({ email: notValidatedEmail }).then(() => {
                  toastSuccess("auth:activation-mail.TOAST_SUCCESS");
                  setNotValidatedEmail(null);
                });
              }}
            >
              Renvoyer le mail d'activation
            </button>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default withRedirectToPrivate(Login);
