import React, { useMemo, useState } from "react";
import { AuthAPIConnected, useAuth } from "../../auth/apiProvider";
import { useExchange } from "../../exchange/exchangeProvider";
import useFileAsObjectURL from "../../files/useFileAsObjectURL";
import {
  hasOnlyWeProovAndAnswered,
  InfoRequest,
} from "../../info-request/infoRequest";
import { useToastsWithIntl } from "../../toast-notifications";
import { useInfoRequest } from "../infoRequestProvider";
import AnswerInfoRequestDialog from "./AnswerInfoRequestDialog";
import ResponseInfoRequestDialog from "./ResponseInfoRequestDialog";

const InfoRequestListItem = ({
  infoRequest,
}: {
  infoRequest: InfoRequest;
}): JSX.Element => {
  const { user } = useAuth() as AuthAPIConnected;
  const { formatVehicleOptions } = useExchange();
  const { answerInfoRequest } = useInfoRequest();
  const { toastSuccess, toastError } = useToastsWithIntl(["info_request"]);
  const [showAnswerInfoRequest, setShowAnswerInfoRequest] = useState(false);
  const [showResponseInfoRequest, setShowResponseInfoRequest] = useState(false);

  const formatedExchange = useMemo(
    () => formatVehicleOptions(infoRequest.Exchange),
    [formatVehicleOptions, infoRequest.Exchange],
  );

  const objectUrl = useFileAsObjectURL({
    url: formatedExchange.VehiclePictures[0]?.file,
  });

  return (
    <div className={"exchange-item"}>
      <div className={"exchange-item_photo"}>
        <div
          className={"exchange-item_photo_image"}
          style={{
            backgroundImage: objectUrl ? `url(${objectUrl.url})` : undefined,
          }}
        />
      </div>
      <div className={"exchange_body"}>
        <div>
          <div className={"title-s"}>
            {formatedExchange.vehicleBrand} {formatedExchange.vehicleModel}{" "}
            {formatedExchange.vehicleSubModel}
          </div>
          <div className={"section-s"}>
            {infoRequest.UserId === user.id ? (
              <span className={"chip --outline"}>Demande envoyée</span>
            ) : (
              <span className={"chip --outline --pending"}>Demande reçue</span>
            )}
          </div>
        </div>
      </div>

      <div className={"lay-col --center --gap-v--xs"}>
        {infoRequest.InfoRequestDocuments.length > 0 ||
        hasOnlyWeProovAndAnswered(infoRequest) ? (
          <button
            className={"btn-1"}
            onClick={() => setShowResponseInfoRequest(true)}
          >
            Consulter le(s) document(s)
          </button>
        ) : (
          infoRequest.Exchange.UserId === user.id && (
            <button
              className={"btn-1"}
              onClick={() => setShowAnswerInfoRequest(true)}
            >
              Répondre
            </button>
          )
        )}
      </div>
      {showAnswerInfoRequest && (
        <AnswerInfoRequestDialog
          infoRequest={infoRequest}
          onClose={() => setShowAnswerInfoRequest(false)}
          onSubmit={(answerInfoRequestParams) => {
            return answerInfoRequest(infoRequest, answerInfoRequestParams).then(
              () => {
                toastSuccess("info_request:answer-info-request.SUCCESS");
                setShowAnswerInfoRequest(false);
              },
              () => {
                toastError("info_request:answer-info-request.ERROR");
              },
            );
          }}
        />
      )}
      {showResponseInfoRequest && (
        <ResponseInfoRequestDialog
          infoRequest={infoRequest}
          onClose={() => setShowResponseInfoRequest(false)}
        />
      )}
    </div>
  );
};
export default InfoRequestListItem;
