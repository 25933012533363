import Routes from "../../../services/routing/Routes";
import { Route } from "react-router-dom";
import UpdatePassword from "./UpdatePassword";
import { UPDATE_PASSWORD } from "../../../routes/profile";
import Account from "./Account";
import { useAuth } from "src/services/auth/apiProvider";
import { useToastsWithIntl } from "src/services/toast-notifications";
import { useState } from "react";

const Profile: React.FC = () => {
  const { user, sendLinkUbiflowMail } = useAuth();
  const { toastError, toastSuccess } = useToastsWithIntl(["auth"]);
  const [isMailSend, setIsMailSend] = useState(!!!user?.ubiflowMailSendDate);

  return (
    <div className={"container page-content"}>
      <h1 className={"page-title --underlined"}>Mon compte</h1>
      {isMailSend ? (
        <button
          className={"btn-1 m-top"}
          onClick={() =>
            sendLinkUbiflowMail().then(
              () => {
                setIsMailSend(true);
                toastSuccess("auth:send-link-ubiflow-mail.SUCCESS");
              },
              () => toastError("auth:send-link-ubiflow-mail.ERROR"),
            )
          }
        >
          Connecter mon CRM
        </button>
      ) : user?.advertiserId ? (
        <p>Identifiant Ubiflow : {user?.advertiserId}</p>
      ) : (
        <p>Demande de connexion CRM envoyée</p>
      )}

      <Routes>
        <Route element={<Account />} index />
        <Route element={<UpdatePassword />} path={UPDATE_PASSWORD} />
      </Routes>
    </div>
  );
};

export default Profile;
