import Form from "../../../services/forms/Form";
import { object, ref, string } from "yup";
import AutoField from "../../../services/forms/AutoField";
import Section from "../../../services/ui/block/Section";
import SectionTitle from "../../../services/ui/descriptive/SectionTitle";
import ContentBlock from "../../../services/ui/block/ContentBlock";
import ValidationsErrors from "../../../services/forms/ValidationsErrors";
import SubmitButton from "../../../services/forms/SubmitButton";
import InputTip from "../../../services/ui/descriptive/InputTip";
import { AuthAPIConnected, useAuth } from "../../../services/auth/apiProvider";
import { useToastsWithIntl } from "../../../services/toast-notifications";
import { UserPasswordForm } from "../../../services/auth/user";
import React from "react";
import Link from "../../../services/routing/components/Link";
import { useNavigate } from "react-router-dom";

const UpdatePasswordSchema = object({
  oldPassword: string().label("Mot de passe actuel").meta({ password: true }),
  newPassword: string().label("Nouveau mot de passe").password(),
  newPasswordConf: string()
    .label("Confirmation du nouveau mot de passe")
    .passwordConfirmation(ref("newPassword")),
});

const UpdatePassword = (): JSX.Element => {
  const { updateUserPassword } = useAuth() as AuthAPIConnected;
  const { toastError, toastSuccess } = useToastsWithIntl(["auth"]);
  const navigate = useNavigate();

  return (
    <div className={"container-wrap page-content"}>
      <Section size={"xs"}>
        <SectionTitle title={"Modifier mon mot de passe"} />
        <Form
          initialValues={{}}
          onSubmit={(values: UserPasswordForm) =>
            updateUserPassword(values).then(
              () => {
                toastSuccess("auth:update-user-password.SUCCESS");
                navigate("..");
              },
              (error) => {
                if (error.response?.status === 412) {
                  toastError(
                    "auth:update-user-password.ERROR_INVALID_OLD_PASSWORD",
                  );
                } else {
                  toastError("auth:update-user-password.ERROR");
                }
              },
            )
          }
          schema={UpdatePasswordSchema}
        >
          <ContentBlock size={"s"}>
            <AutoField name={"oldPassword"} />
            <AutoField name={"newPassword"}>
              <InputTip>
                8 caractères min. dont au moins une lettre, un chiffre et un
                caractère spécial.
              </InputTip>
            </AutoField>
            <AutoField name={"newPasswordConf"} />
          </ContentBlock>

          <div className={"form-footer"}>
            <ValidationsErrors />
            <div className={"lay-row --gap"}>
              <SubmitButton>Enregistrer</SubmitButton>
              <Link className={"btn-2"} to={".."}>
                Retour
              </Link>
            </div>
          </div>
        </Form>
      </Section>
    </div>
  );
};

export default UpdatePassword;
