import { useCallback } from "react";
import { useAuth } from "../../services/auth/apiProvider";
import PlansTable from "../../services/plan/components/PlansTable";
import { usePlan } from "../../services/plan/planProvider";
import { withLoader } from "../../services/routing/useLoader";

const PublicPlansTable = (): JSX.Element => {
  const { plans } = usePlan();

  return (
    <div className={"container page-content"}>
      <PlansTable plans={plans} />
    </div>
  );
};

const useLoad = () => {
  const { loadPlans } = usePlan();
  const { loadConfigs } = useAuth();

  return useCallback(() => Promise.all([loadPlans(), loadConfigs()]), [
    loadConfigs,
    loadPlans,
  ]);
};

export default withLoader(PublicPlansTable, useLoad);
