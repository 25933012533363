import { cx } from "@emotion/css";
import { FieldAttributes, useField } from "formik";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import "react-phone-number-input/style.css";

// Formik has wrong typing
// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface Props extends FieldAttributes<any> {
  name: string;
}

const PhoneNumberField = (props: Props): JSX.Element => {
  const [field, , helper] = useField(props);

  return (
    <PhoneInputWithCountrySelect
      className={"input"}
      defaultCountry={"FR"}
      disabled={props.disabled}
      numberInputProps={{
        className: cx(props.className, "input --light"),
        placeholder: props.placeholder,
      }}
      onChange={(value) => helper.setValue(value)}
      value={field.value}
      international
    />
  );
};

export default PhoneNumberField;
