import { AxiosPromise } from "axios";
import baseAPI from "../auth/api";
import { InfoRequestForm } from "../info-request/infoRequest";
import { Offer, OfferForm } from "../offer/offer";
import { User } from "../auth/user";
import {
  Exchange,
  ExchangeForm,
  ExchangeVinForm,
  ExchangeVinResult,
  FullExchangeRaw,
} from "./exchange";
import {
  ExchangeFormBrandOption,
  ExchangeFormModelOptionBase,
  ExchangeFormEngineOption,
  ExchangeFormSubModelOptionBase,
  FormOptionMultiSelector,
} from "./formOptions";

export function createExchange(
  exchange: ExchangeForm,
  userId: User["id"],
): AxiosPromise<FullExchangeRaw> {
  delete exchange.vehicleMetaModel;
  return baseAPI.post(
    "/exchanges",
    { ...exchange, userId },
    {
      timeout: 1000 * 60 * 5,
    },
  );
}

export function updateExchange(
  exchange: ExchangeForm,
  userId: User["id"],
): AxiosPromise<FullExchangeRaw> {
  delete exchange.vehicleMetaModel;
  return baseAPI.put(
    `/exchanges/${exchange.id}`,
    { ...exchange, userId },
    {
      timeout: 1000 * 60 * 5,
    },
  );
}

export function deleteExchange(
  exchangeId: Exchange["id"],
): AxiosPromise<FullExchangeRaw> {
  return baseAPI.delete(`/exchanges/${exchangeId}`);
}

export function deleteImportExchanges(
  userId: User["id"],
): AxiosPromise<FullExchangeRaw> {
  return baseAPI.delete(`/exchanges/users/${userId}`);
}

export function getAllExchanges(): AxiosPromise<FullExchangeRaw[]> {
  return baseAPI.get("/exchanges");
}

export function getNbExchange(): AxiosPromise<{ nbExchanges: number }> {
  return baseAPI.get("/exchanges/count");
}

export function getFilteredCountries(): AxiosPromise<Array<string>> {
  return baseAPI.get("/exchanges/getFilteredCountries");
}

export function getExchangeById(
  exchangeId: Exchange["id"],
): AxiosPromise<FullExchangeRaw> {
  return baseAPI.get(`/exchanges/${exchangeId}`);
}

export function getFormOptions(): AxiosPromise<ExchangeFormBrandOption[]> {
  return baseAPI.get("/dat/fetchFormOptions");
}

export function getFormOptionsBrand(): AxiosPromise<
  ExchangeFormSubModelOptionBase[]
> {
  return baseAPI.get(`/dat/manufacturers`, { params: { custom: true } });
}

export function getFormOptionsModel(
  brandId: Exchange["vehicleBrand"],
): AxiosPromise<ExchangeFormModelOptionBase[]> {
  return baseAPI.get(`/dat/manufacturers/${brandId}/models`, {
    params: { custom: true },
  });
}

export function getFormOptionsSubModel(
  modelId: Exchange["vehicleModel"],
): AxiosPromise<ExchangeFormSubModelOptionBase[]> {
  return baseAPI.get(`/dat/models/${modelId}/submodels`, {
    params: { custom: true },
  });
}

export function getFormOptionsEngine(
  subModelId: FormOptionMultiSelector["value"],
): AxiosPromise<ExchangeFormEngineOption[]> {
  return baseAPI.get(`/dat/submodels/${subModelId}/engines`, {
    params: { custom: true },
  });
}

export function getFormOptionsEngines(
  options: Array<{
    subModelId: FormOptionMultiSelector["value"];
  }>,
): AxiosPromise<ExchangeFormEngineOption[]> {
  return baseAPI.post("/dat/engines", options, { params: { custom: true } });
}

export function getVehicleDetailsByVin(
  vehicleVin: ExchangeVinForm["vehicleVin"],
  vehicleOriginCode: ExchangeVinForm["vehicleOriginCode"],
): AxiosPromise<ExchangeVinResult> {
  return baseAPI.get(`/dat/vin`, {
    params: { vin: vehicleVin, country: vehicleOriginCode },
  });
}

export function addToFavorites(
  exchangeId: Exchange["id"],
): AxiosPromise<FullExchangeRaw> {
  return baseAPI.post(`/exchanges/${exchangeId}/favorites`);
}

export function removeFromFavorites(
  exchangeId: Exchange["id"],
): AxiosPromise<FullExchangeRaw> {
  return baseAPI.delete(`/exchanges/${exchangeId}/favorites`);
}

export function getAllFavorites(): AxiosPromise<FullExchangeRaw[]> {
  return baseAPI.get("/exchanges?favorites=true");
}

export function publishExchange(
  exchangeId: Exchange["id"],
  publishWithoutPayment: boolean,
  asUserId?: User["id"],
): AxiosPromise<FullExchangeRaw> {
  return baseAPI.put(`/exchanges/${exchangeId}/publish`, {
    publish: true,
    asAdmin: publishWithoutPayment,
    userId: asUserId,
  });
}

export function unpublishExchange(
  exchangeId: Exchange["id"],
): AxiosPromise<FullExchangeRaw> {
  return baseAPI.put(`/exchanges/${exchangeId}/publish`, { publish: false });
}

export function makeOffer(
  exchangeId: Exchange["id"],
  offerForm: OfferForm,
): AxiosPromise<FullExchangeRaw> {
  return baseAPI.post(`/exchanges/${exchangeId}/offers`, offerForm);
}

export function makeNewOffer(
  exchangeId: Exchange["id"],
  offerId: Offer["id"],
  offerForm: OfferForm,
): AxiosPromise<FullExchangeRaw> {
  return baseAPI.put(`/exchanges/${exchangeId}/offers/${offerId}`, offerForm);
}

export function acceptOffer(
  exchangeId: Exchange["id"],
  offerId: Offer["id"],
): AxiosPromise<FullExchangeRaw> {
  return baseAPI.post(`/exchanges/${exchangeId}/offers/${offerId}/accept`);
}

export function declineOffer(
  exchangeId: Exchange["id"],
  offerId: Offer["id"],
): AxiosPromise<FullExchangeRaw> {
  return baseAPI.post(`/exchanges/${exchangeId}/offers/${offerId}/decline`);
}

export function createInfoRequest(
  exchangeId: Exchange["id"],
  infoRequestForm: InfoRequestForm,
): AxiosPromise<FullExchangeRaw> {
  return baseAPI.post(
    `/exchanges/${exchangeId}/info-requests`,
    infoRequestForm,
  );
}

export function manageExchangeStatus(
  exchangeId: Exchange["id"],
  sold: boolean,
): AxiosPromise<FullExchangeRaw> {
  return baseAPI.put(`/exchanges/${exchangeId}/status`, { sold });
}

export function exportExchanges(): AxiosPromise {
  return baseAPI.get("/exchanges/export", {
    responseType: "arraybuffer",
  });
}
