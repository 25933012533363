export const PRIVATE = "/private" as const;

export const PROFILE = "/profile" as const;
export const PROFILE_LINK = `${PRIVATE}${PROFILE}` as const;

export const DASHBOARD = "/dashboard" as const;
export const DASHBOARD_LINK = `${PRIVATE}${DASHBOARD}` as const;

export const EXCHANGE = "/exchange" as const;
export const EXCHANGE_LINK = `${PRIVATE}${EXCHANGE}` as const;

export const ADMIN_DASHBOARD = "/admin-dashboard" as const;
export const ADMIN_DASHBOARD_LINK = `${PRIVATE}${ADMIN_DASHBOARD}` as const;

export const USER_PLANS_PRIVATE = "/user-plans" as const;
export const USER_PLANS_PRIVATE_LINK = `${PRIVATE}${USER_PLANS_PRIVATE}` as const;

export const PRIVATE_CONTACT = "/contact" as const;
export const PRIVATE_CONTACT_LINK = `${PRIVATE}${PRIVATE_CONTACT}` as const;
