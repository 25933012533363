import React, { FunctionComponent, useMemo } from "react";
import { deleteFromArrayAndReturn } from "../../../data-structures/array";
import FileInput, { Props as FileInputProps } from "../../../files/FileInput";
import useFileAsObjectURL from "../../../files/useFileAsObjectURL";
import { readFile } from "../../../files/utils";
import { VehicleDocument, VehicleDocumentBase } from "../../exchange";
import { useField } from "formik";
import { useToastsWithIntl } from "../../../toast-notifications";

interface Props extends Omit<FileInputProps, "onChange"> {
  name: string;
  readonly?: boolean;
}

const VehicleDocumentZone: FunctionComponent<Props> = ({ name, readonly }) => {
  const { toastError } = useToastsWithIntl(["exchange"]);
  const [field, , helper] = useField<(VehicleDocument | VehicleDocumentBase)[]>(
    name,
  );

  const fileList = useMemo(() => {
    if (!field.value.length) return [];

    return field.value.map((file, index, array) => (
      <FileEdit
        key={index}
        file={file}
        onDelete={() => helper.setValue(deleteFromArrayAndReturn(array, index))}
      />
    ));
  }, [helper, field.value]);

  return (
    <div>
      {!readonly && (
        <FileInput
          accept={"image/*,application/pdf"}
          multiple={true}
          onChange={(addedFiles) => {
            if (addedFiles[0].size / 1024 > 10000)
              toastError("exchange:upload-exchange-document.TOO_LARGE");
            else
              readFile(addedFiles[0]).then(async (file) => {
                return Promise.all(
                  [...addedFiles].map((f, index) =>
                    readFile(f).then((dataUrl) => ({
                      name: f.name,
                      file: dataUrl,
                    })),
                  ),
                ).then((newFile) => {
                  helper.setValue((field.value || []).concat(newFile));
                });
              });
          }}
        />
      )}

      <div className={"section --gap--s lay-row"}>{fileList}</div>
    </div>
  );
};

interface FileEditProps {
  file: VehicleDocument | VehicleDocumentBase;
  onDelete?(): void;
}

const FileEdit: FunctionComponent<FileEditProps> = ({ file, onDelete }) => {
  const objectURL = useFileAsObjectURL({ url: file.file });

  return (
    <div className={"media-item"}>
      <div className={"file"}>
        {objectURL ? (
          <button
            className={"file-name"}
            onClick={() => {
              const win = window.open();
              if (win) {
                win.document.write(
                  '<iframe src="' +
                    objectURL.url +
                    '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>',
                );
              }
            }}
            type={"button"}
          >
            {file.name || "Document sans nom"}
          </button>
        ) : (
          <p>Erreur</p>
        )}
        {onDelete && (
          <button className={"delete-file"} onClick={onDelete} type={"button"}>
            Supprimer
          </button>
        )}
      </div>
    </div>
  );
};

export default VehicleDocumentZone;
