import ContactForm from "../../services/auth/components/ContactForm";

const PublicContact = (): JSX.Element => {
  return (
    <div className={"container-wrap page-content"}>
      <h1 className={"page-title"}>
        Entrer en <em>contact !</em>
      </h1>

      <div className={"section"}>
        <ContactForm />
      </div>
    </div>
  );
};

export default PublicContact;
