import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { object, string, number, array } from "yup";
import AutoField from "../../forms/AutoField";
import Form from "../../forms/Form";
import SubmitButton from "../../forms/SubmitButton";
import ValidationsErrors from "../../forms/ValidationsErrors";
import { useToastsWithIntl } from "../../toast-notifications";
import { sendContactMail } from "../api";
import {
  ContactForm as ContactFormType,
  ContactFormValues,
  ContactType,
  Position,
  ReverseContactCompanyActivity,
  ReverseContactType,
  ReversePosition,
} from "../user";
import { isValidPhoneNumber } from "react-phone-number-input";

const ContactForm = ({
  initialValues,
}: {
  initialValues?: Partial<ContactFormValues>;
}): JSX.Element => {
  const { t } = useTranslation(["auth"]);
  const { toastSuccess, toastError } = useToastsWithIntl(["auth"]);

  const formValues = useMemo(
    () => ({
      ...initialValues,
      lastName: initialValues?.lastName ?? null,
      firstName: initialValues?.firstName ?? null,
      mail: initialValues?.mail ?? null,
      phone: initialValues?.phone ?? null,
      message: initialValues?.message ?? null,
      companyName: initialValues?.companyName ?? null,
      jobName: initialValues?.jobName ?? null,
      companyActivity: initialValues?.companyActivity ?? null,
    }),
    [initialValues],
  );

  const companyActivityEnum = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(ReverseContactCompanyActivity).map(([value, key]) => [
          value,
          t(`auth:contact.company-activity.${key}` as any),
        ]),
      ),
    [t],
  );

  return (
    <Form
      initialValues={formValues}
      onSubmit={(values) => {
        return sendContactMail({
          ...values,
          companyActivity: values.companyActivity?.map((a) =>
            t(
              `auth:contact.company-activity.${ReverseContactCompanyActivity[a]}` as any,
            ),
          ),
          type: t(
            `auth:contact.type.${
              ReverseContactType[values.type] as any
            }` as any,
          ),
          jobName: t(
            `auth:user.position.${ReversePosition[values.jobName!]}` as any,
          ),
        } as ContactFormType).then(
          () => {
            toastSuccess("auth:send-contact-mail.SUCCESS");
          },
          () => {
            toastError("auth:send-contact-mail.ERROR");
          },
        );
      }}
      schema={object({
        lastName: string().label("Nom").nullable().required(),
        firstName: string().label("Prénom").nullable().required(),
        mail: string().label("E-mail").email().nullable().required(),
        phone: string()
          .label("Numéro de téléphone")
          .phoneNumber()
          .nullable()
          .test("isValidPhoneNumber", (value) => isValidPhoneNumber(`${value}`))
          .required(),
        message: string()
          .label("Votre message")
          .nullable()
          .multiline()
          .required(),
        companyName: string().label("Raison sociale").nullable().required(),
        jobName: number()
          .label("Votre poste")
          .meta({
            select: true,
            enum: Position,
            translate: ["auth", "auth:user.position"],
          })
          .nullable()
          .required(),
        companyActivity: array(number())
          .label("Activité")
          .autocomplete(
            companyActivityEnum,
            "auth",
            "contact.company-activity-multiselect",
            {
              active: true,
              hasSelectAll: false,
            },
          )
          .nullable()
          .required(),
        type: number()
          .label("Type de la demande")
          .meta({
            select: true,
            enum: ContactType,
            translate: ["auth", "auth:contact.type"],
          })
          .nullable()
          .required(),
        subject: string().label("Objet de la demande").nullable().required(),
      })}
    >
      <div>
        <div className={"lay-col --gap section-l --bg --light"}>
          <div className={"grid --2"}>
            <AutoField name={"lastName"} />
            <AutoField name={"firstName"} />
            <AutoField name={"mail"} />
            <AutoField name={"phone"} />
            <AutoField name={"companyName"} />
            <AutoField name={"jobName"} />
          </div>

          <div className={"--stretch-h"}>
            <AutoField name={"companyActivity"} />
          </div>

          <div className={"grid --2"}>
            <AutoField name={"type"} />
            <AutoField name={"subject"} />
          </div>

          <div className={"--stretch-h"}>
            <AutoField name={"message"} />
          </div>
        </div>
      </div>
      <div className={"form-footer"}>
        <ValidationsErrors />
        <SubmitButton>Envoyer</SubmitButton>
      </div>
    </Form>
  );
};

export default ContactForm;
