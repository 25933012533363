import { AxiosPromise } from "axios";
import baseAPI from "../auth/api";
import { User, UserRaw } from "../auth/user";

export function getAccounts(): AxiosPromise<UserRaw[]> {
  return baseAPI.get("/users");
}

export function upgradeAccount(id: User["id"]): AxiosPromise<UserRaw> {
  return baseAPI.post(`/users/${id}/upgrade`);
}

export function downgradeAccount(id: User["id"]): AxiosPromise<UserRaw> {
  return baseAPI.post(`/users/${id}/downgrade`);
}

export function validateAccount(id: User["id"]): AxiosPromise<UserRaw> {
  return baseAPI.post(`/users/${id}/validate`);
}

export function unvalidateAccount(id: User["id"]): AxiosPromise<UserRaw> {
  return baseAPI.post(`/users/${id}/unvalidate`);
}

export function invalidateAccount(id: User["id"]): AxiosPromise<UserRaw> {
  return baseAPI.post(`/users/${id}/invalidate`);
}
