import { Link, NavLink } from "react-router-dom";
import {
  LOGIN_LINK,
  PUBLIC_CONTACT_LINK,
  REGISTER_LINK,
} from "src/routes/public";
import { useState } from "react";
import { cx } from "@emotion/css";

/* IMAGES AND ICONS */
import logo from "../../assets/img/logo.svg";
import BurgerMenuIcon from "../../assets/img/icons/icon-burger-purple.svg";

const HeaderPublic = (): JSX.Element => {
  const [openedSidebar, setOpenedSidebar] = useState(false);

  const toggleSidebarMenu = (onoff: boolean) => {
    onoff
      ? document.body.classList.add("modal-open")
      : document.body.classList.remove("modal-open");
    setOpenedSidebar(onoff);
  };
  return (
    <div className={"header --public"}>
      <button className={"menu-btn"} onClick={() => toggleSidebarMenu(true)}>
        <img alt={"menu icon"} className={"icon"} src={BurgerMenuIcon} />
      </button>
      <Link to={LOGIN_LINK}>
        <img alt={"logo"} className={"logo"} src={logo} />
      </Link>
      {openedSidebar && (
        <div
          className={"modal-overlay"}
          onClick={() => toggleSidebarMenu(false)}
        />
      )}
      <nav className={cx("main-nav", !!openedSidebar && "--opened")}>
        <button
          className={"close-btn"}
          onClick={() => toggleSidebarMenu(false)}
        >
          <img alt={"menu icon"} className={"icon"} src={BurgerMenuIcon} />
        </button>
        <img alt={"logo"} className={"logo"} src={logo} />
        <div className={"section --bg --light --below-s"}>
          <NavLink
            className={"nav-link login"}
            onClick={() => toggleSidebarMenu(false)}
            to={LOGIN_LINK}
          >
            Se connecter
          </NavLink>
          <NavLink
            className={"btn-1"}
            onClick={() => toggleSidebarMenu(false)}
            to={REGISTER_LINK}
          >
            S'inscrire gratuitement
          </NavLink>
        </div>
        {/* <NavLink
          className={"nav-link"}
          onClick={() => toggleSidebarMenu(false)}
          to={"a"}
        >
          La solution
        </NavLink>
        <NavLink
          className={"nav-link"}
          onClick={() => toggleSidebarMenu(false)}
          to={"b"}
        >
          Qui sommes-nous ?
        </NavLink> */}
        <NavLink
          className={"nav-link"}
          onClick={() => toggleSidebarMenu(false)}
          to={PUBLIC_CONTACT_LINK}
        >
          Contact
        </NavLink>
      </nav>
      <nav className={"nav-right"}>
        <NavLink className={"nav-link login"} to={LOGIN_LINK}>
          Se connecter
        </NavLink>
        <NavLink className={"btn-1"} to={REGISTER_LINK}>
          S'inscrire gratuitement
        </NavLink>
      </nav>
    </div>
  );
};

export default HeaderPublic;
