import { useCallback } from "react";
import { useAccounts } from "../../../services/accounts/accountsProvider";
import { getFullName } from "../../../services/auth/user";
import ExchangeForm from "../../../services/exchange/components/exchangeForm/ExchangeForm";
import { withLoader } from "../../../services/routing/useLoader";
import { useParams } from "../../../services/routing/useParams";

const AdminCreateExchange = (): JSX.Element => {
  const { account } = useAccounts();
  return (
    <div className={"container page-content"}>
      <div className={"page_head"}>
        <div>
          <h1 className={"page-title"}>Création d'une annonce</h1>
          <h2 className={"section-title"}>
            Pour le compte: {getFullName(account!)}
          </h2>
        </div>
      </div>

      <div className={"section"}>
        <ExchangeForm userExchange={account!} />
      </div>
    </div>
  );
};

const useLoad = () => {
  const { loadAccount } = useAccounts();
  const { id } = useParams();
  return useCallback(() => {
    return loadAccount(Number(id!));
  }, [id, loadAccount]);
};

export default withLoader(AdminCreateExchange, useLoad);
