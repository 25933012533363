import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { EXCHANGE_DETAIL_LINK } from "../../../routes/exchange";
import {
  ExchangeStatus,
  FullExchange,
  getBestExchangeOffersStatus,
} from "../../exchange/exchange";
import { useExchange } from "../../exchange/exchangeProvider";
import useFileAsObjectURL from "../../files/useFileAsObjectURL";
import Link from "../../routing/components/Link";
import { OfferStatus } from "../../offer/offer";
import { useDate } from "../../date/DateContext";
import { toFixed } from "../../numbers/formatting";

const ReplaceExchangeItem = ({
  exchange,
  onClick,
}: {
  exchange: FullExchange;
  onClick: (exchange: FullExchange) => Promise<void>;
}): JSX.Element => {
  const { formatVehicleOptions } = useExchange();
  const { t } = useTranslation(["offer", "common"]);
  const { formatDate } = useDate();

  const formatedVehicle = useMemo(() => formatVehicleOptions(exchange), [
    formatVehicleOptions,
    exchange,
  ]);

  const objectUrl = useFileAsObjectURL({
    url: formatedVehicle.VehiclePictures[0]?.file,
  });

  const bestStatusOffer = useMemo(() => getBestExchangeOffersStatus(exchange), [
    exchange,
  ]);

  return (
    <div className={"offer-item"}>
      <div className={"offer-item_photo"}>
        <div
          className={"offer-item_photo_image"}
          style={{
            backgroundImage: objectUrl ? `url(${objectUrl.url})` : undefined,
          }}
        />
      </div>
      <div className={"offer-body"}>
        <div className={"offer-body_main"}>
          <Link
            className={"offer-title"}
            params={{ exchangeId: formatedVehicle.id }}
            to={EXCHANGE_DETAIL_LINK}
          >
            {formatedVehicle.vehicleBrand} {formatedVehicle.vehicleModel}{" "}
            {formatedVehicle.vehicleSubModel}
          </Link>
          {formatedVehicle.vehicleSalePrice && (
            <div className={"offer-price"}>
              <div>
                <div className={"offer-price_ttc"}>
                  {toFixed(formatedVehicle.vehicleSalePrice, 2)}€<sup>TTC</sup>
                </div>
              </div>
            </div>
          )}
          <p className={"data-row"}>
            <strong>{formatedVehicle.vehicleMileage} km</strong>
            <span>
              Mise en circulation :{" "}
              {formatedVehicle.vehicleEntryIntoService &&
                formatDate(formatedVehicle.vehicleEntryIntoService, "MM/yyyy")}
            </span>
            <span>{formatedVehicle.vehicleEnergy}</span>
            <span>{formatedVehicle.vehicleTransmission}</span>
            <span>{formatedVehicle.vehiclePower} Ch</span>
          </p>
        </div>
        <div className={"offer-status"}>
          <span>
            {bestStatusOffer === OfferStatus.STATUS_PENDING &&
              t("offer:offer_status.STATUS_PENDING")}
            {bestStatusOffer === OfferStatus.STATUS_ACCEPTED &&
              t("offer:offer_status.STATUS_ACCEPTED")}
            {bestStatusOffer === OfferStatus.STATUS_VALIDATED &&
              t("offer:offer_status.STATUS_VALIDATED")}
            {bestStatusOffer === ExchangeStatus.STATUS_SOLD &&
              t("offer:offer_status.STATUS_SOLD")}
          </span>
        </div>
      </div>
      <div className={"offer-body_separator"} />
      <div className={"offer-action"}>
        <div>
          <button className={"btn-1"} onClick={() => onClick(exchange)}>
            Remplacer
          </button>
        </div>
      </div>
    </div>
  );
};
export default ReplaceExchangeItem;
