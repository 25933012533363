import { useMemo, useState } from "react";
import { useFormikContext } from "formik";
import { ExchangeVinForm, ExchangeVinResult, vinLength } from "../../exchange";
import { useExchange } from "../../exchangeProvider";
import { useToastsWithIntl } from "../../../toast-notifications";
import { t } from "i18next";

interface VinButtonProps {
  className?: string;
  disabled: boolean;
  children?: JSX.Element | string;
  onSuccess: (vinResult: ExchangeVinResult) => void;
}

const VinSubmitButton = ({
  className,
  disabled,
  children,
  onSuccess,
}: VinButtonProps): JSX.Element => {
  const { values, setFormikState } = useFormikContext<ExchangeVinForm>();

  const { getVehicleDetailsByVin } = useExchange();
  const { toastSuccess } = useToastsWithIntl(["exchange"]);

  const [isHookedSubmitting, setIsHookedSubmitting] = useState(false);
  const [vinError, setVinError] = useState(false);

  const isOriginCodeValid = useMemo(
    () =>
      !!values.vehicleOriginCode &&
      values.vehicleOriginCode !== "" &&
      values.vehicleOriginCode !== "UK",
    [values.vehicleOriginCode],
  );
  const isSubmittable = useMemo(
    () => isOriginCodeValid && values.vehicleVin?.length === vinLength,
    [isOriginCodeValid, values.vehicleVin?.length],
  );

  const vehicleVin = useMemo(() => values.vehicleVin || "", [
    values.vehicleVin,
  ]);

  return (
    <>
      <button
        className={className || "btn-1"}
        disabled={isHookedSubmitting || !isSubmittable || disabled}
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          setVinError(false);
          const onSubmit = getVehicleDetailsByVin(
            values.vehicleVin,
            values.vehicleOriginCode,
          ).then(
            (vinDataResult) => {
              toastSuccess("exchange:get-vehicle-by-vin.SUCCESS");
              onSuccess(vinDataResult);
              setFormikState((formikState) => ({
                ...formikState,
                values: { ...formikState.values, ...vinDataResult },
              }));
            },
            () => {
              setVinError(true);
            },
          );

          if (onSubmit instanceof Promise) {
            setIsHookedSubmitting(true);
            onSubmit.finally(() => setIsHookedSubmitting(false));
          }
        }}
        type={"submit"}
      >
        {isHookedSubmitting ? "Chargement..." : children}
      </button>
      {!isOriginCodeValid && vehicleVin.length > 0 && (
        <p>Le pays d&apos;origine est requis pour la recherche par VIN.</p>
      )}
      {vehicleVin.length > 0 && vehicleVin.length !== vinLength && (
        <p>Le VIN doit contenir {vinLength} caractères.</p>
      )}
      {vinError && (
        <div className={"info info--warning"}>
          {t("exchange:get-vehicle-by-vin.ERROR")}
        </div>
      )}
    </>
  );
};

export default VinSubmitButton;
