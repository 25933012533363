import { DetailedHTMLProps, FunctionComponent, HTMLAttributes } from "react";
import { cx } from "@emotion/css";

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const Card: FunctionComponent<Props> = ({ className, ...cardProps }) => {
  return <div {...cardProps} className={cx("card", className)} />;
};

export default Card;
