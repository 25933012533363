import React, { useCallback } from "react";
import AdminOfferListItem from "../../../services/offer/components/AdminOfferListItem";
import { useOffer } from "../../../services/offer/offerProvider";
import { withLoader } from "../../../services/routing/useLoader";

const AdminOffersListing = (): JSX.Element => {
  const { offers } = useOffer();

  return (
    <div className={"container page-content"}>
      <div className={"page_head"}>
        <h1 className={"page-title"}>Toutes les offres</h1>
      </div>
      <div className={"page_body"}>
        {offers.length ? (
          <>
            {offers
              .sort((a, b) => b.id - a.id)
              .map((offer) => (
                <AdminOfferListItem key={offer.id} offer={offer} />
              ))}
          </>
        ) : (
          <div className={"info"}>Aucune offre</div>
        )}
      </div>
    </div>
  );
};

const useLoad = () => {
  const { loadAllOffers } = useOffer();

  return useCallback(() => loadAllOffers(), [loadAllOffers]);
};

export default withLoader(AdminOffersListing, useLoad);
