import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAccounts } from "../../../services/accounts/accountsProvider";
import AccountsManagementTable from "../../../services/accounts/components/AccountsManagementTable";
import {
  isUserCommercial,
  isUserMissingKyc,
} from "../../../services/auth/user";
import { useSearch } from "../../../services/data-structures/array";
import { withLoader } from "../../../services/routing/useLoader";

enum UserType {
  All = "ALL",
  Commercial = "COMMERCIAL",
  Admin = "ADMIN",
}

enum UserStatus {
  All = "ALL",
  Valid = "VALID",
  Invalid = "INVALID",
  ToValidate = "TO_VALIDATE",
  MissingKyc = "MISSING_KYC",
}

const AccountsManagement = (): JSX.Element => {
  const { accounts } = useAccounts();
  const { t } = useTranslation(["auth"]);

  const [filterByType, setFilterByType] = useState(UserType.All);
  const [filterByStatus, setFilterByStatus] = useState(UserStatus.All);

  const filteredAccounts = useMemo(() => {
    let filteredAccounts = [...accounts];

    if (filterByType !== UserType.All)
      filteredAccounts = filteredAccounts.filter(
        (account) =>
          (filterByType === UserType.Admin && account.superAdmin) ||
          (filterByType === UserType.Commercial && isUserCommercial(account)),
      );

    if (filterByStatus !== UserStatus.All)
      filteredAccounts = filteredAccounts.filter(
        (account) =>
          (filterByStatus === UserStatus.MissingKyc &&
            !account.superAdmin &&
            !account.validationDate &&
            isUserMissingKyc(account)) ||
          (filterByStatus === UserStatus.Valid && account.isValid) ||
          (filterByStatus === UserStatus.Invalid &&
            !account.isValid &&
            account.validationDate) ||
          (filterByStatus === UserStatus.ToValidate &&
            !account.superAdmin &&
            !isUserMissingKyc(account) &&
            !account.validationDate),
      );

    return filteredAccounts;
  }, [accounts, filterByStatus, filterByType]);
  const [searchedAccounts, setSearch] = useSearch(filteredAccounts);

  return (
    <div className={"container page-content"}>
      <div className={"page_head"}>
        <h1 className={"page-title"}>Tous les comptes</h1>
      </div>
      <div className={"section-l lay-col --gap-v--xs"}>
        <input
          className={"input"}
          onChange={(ev) => setSearch(ev.target.value)}
          placeholder={"Recherche (mail, nom, prénom, société...)"}
          type={"text"}
        />
        <div className={"--stretch-h"}>
          <div className={"lay-row"}>
            <div className={"--pos-r lay-row --gap-h"}>
              <div className={"spacing-l"}>
                <label className={"input-label"}>
                  {t("auth:ACCOUNT_TYPE")}
                </label>
                <select
                  className={"select"}
                  onChange={(ev) =>
                    setFilterByType(ev.target.value as UserType)
                  }
                  value={filterByType}
                >
                  <option value={UserType.All}>
                    {t("auth:account-type.ALL")}
                  </option>
                  <option value={UserType.Commercial}>
                    {t("auth:account-type.COMMERCIAL")}
                  </option>
                  <option value={UserType.Admin}>
                    {t("auth:account-type.ADMIN")}
                  </option>
                </select>
              </div>
              <div className={"form-block"}>
                <label className={"input-label"}>
                  {t("auth:ACCOUNT_STATUS")}
                </label>
                <select
                  className={"select"}
                  onChange={(ev) =>
                    setFilterByStatus(ev.target.value as UserStatus)
                  }
                  value={filterByStatus}
                >
                  <option value={UserType.All}>
                    {t("auth:account-status.ALL")}
                  </option>
                  <option value={UserStatus.Valid}>
                    {t("auth:account-status.VALID")}
                  </option>
                  <option value={UserStatus.Invalid}>
                    {t("auth:account-status.INVALID")}
                  </option>
                  <option value={UserStatus.ToValidate}>
                    {t("auth:account-status.TO_VALIDATE")}
                  </option>
                  <option value={UserStatus.MissingKyc}>
                    {t("auth:account-status.MISSING_KYC")}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className={"section --stretch-h"}>
          <AccountsManagementTable accounts={searchedAccounts} />
        </div>
      </div>
    </div>
  );
};

const useLoad = () => {
  const { loadAccounts } = useAccounts();
  return loadAccounts;
};

export default withLoader(AccountsManagement, useLoad);
