import React, { useMemo, useState } from "react";
import { TFuncKey, Trans } from "react-i18next";
import { EXCHANGE_DETAIL_LINK } from "../../../routes/exchange";
import { ExchangeStatus, getBestOfferStatus } from "../../exchange/exchange";
import { useExchange } from "../../exchange/exchangeProvider";
import useFileAsObjectURL from "../../files/useFileAsObjectURL";
import { toFixed } from "../../numbers/formatting";
import Link from "../../routing/components/Link";
import { useToastsWithIntl } from "../../toast-notifications";
import { useConfirmationWithIntl } from "../../ui/ConfirmationDialog";
import { Offer, OfferStatus, PriceOrigin } from "../offer";
import OfferDialog from "./OfferDialog";
import iconInfo from "../../../assets/img/icons/icon-info.svg";
import OfferFeeBlockDialog from "./OfferDetailDialog";
import OfferStatusDisplay from "./OfferStatusDisplay";

const ReceivedOfferListItem = ({ offer }: { offer: Offer }): JSX.Element => {
  const {
    formatVehicleOptions,
    makeNewOffer,
    acceptOffer,
    declineOffer,
  } = useExchange();
  const { confirm } = useConfirmationWithIntl("offer");
  const [showNewOffer, setShowNewOffer] = useState(false);
  const [showOfferPriceDetail, setShowOfferPriceDetail] = useState(false);
  const { toastSuccess, toastError } = useToastsWithIntl(["exchange"]);

  const formatedExchange = useMemo(() => formatVehicleOptions(offer.Exchange), [
    formatVehicleOptions,
    offer.Exchange,
  ]);

  const objectUrl = useFileAsObjectURL({
    url: formatedExchange.VehiclePictures[0]?.file,
  });

  const status = getBestOfferStatus(offer);
  const isPending =
    status === OfferStatus.STATUS_PENDING &&
    offer.Prices[0].origin === PriceOrigin.ORIGIN_BUYER;
  const isCounter =
    status === OfferStatus.STATUS_PENDING &&
    offer.Prices[0].origin === PriceOrigin.ORIGIN_SELLER;
  const isDeclined = status === OfferStatus.STATUS_DECLINED;
  const isCanceled = status === OfferStatus.STATUS_CANCELED;
  const isAccepted = status === OfferStatus.STATUS_ACCEPTED;
  const isValidated = status === OfferStatus.STATUS_VALIDATED;
  const isSold = status === ExchangeStatus.STATUS_SOLD;

  return (
    <div className={"offer-item"}>
      <div className={"offer-item_photo"}>
        <div
          className={"offer-item_photo_image"}
          style={{
            backgroundImage: objectUrl ? `url(${objectUrl.url})` : undefined,
          }}
        />
      </div>
      <div className={"offer-body"}>
        <div className={"offer-body_main"}>
          <Link
            className={"offer-title"}
            params={{ exchangeId: formatedExchange.id }}
            to={EXCHANGE_DETAIL_LINK}
          >
            {formatedExchange.vehicleBrand} {formatedExchange.vehicleModel}{" "}
            {formatedExchange.vehicleSubModel}
          </Link>
          <div className={"lay-row --center-v"}>
            <div className={"offer-price"}>
              <div>
                <div className={"offer-price_ttc"}>
                  {isCounter && "Mon offre "}
                  {toFixed(offer.Prices[0].amount, 2)}€<sup>TTC</sup>
                </div>
              </div>
            </div>
            <button
              className={"link-with-icon"}
              onClick={() => setShowOfferPriceDetail(true)}
              type={"button"}
            >
              <img alt={"info"} className={"icon"} src={iconInfo} /> Consulter
              les conditions
            </button>
          </div>
          <p className={"data-row"}>
            {(isPending || isCounter || isDeclined || isCanceled) &&
              offer.Exchange.acceptedOffersCount === 0 && (
                <>
                  <span>
                    <Trans
                      count={offer.bestOffersCount}
                      i18nKey={"offer:superior_offer_count_info" as TFuncKey}
                    />
                  </span>
                  {offer.bestOfferAmount !== null && (
                    <span>
                      Meilleure offre en cours: {offer.bestOfferAmount}€
                      <sup>TTC</sup>
                    </span>
                  )}
                </>
              )}
          </p>
        </div>

        <OfferStatusDisplay offer={offer} />
      </div>
      <div className={"offer-body_separator"} />
      <div className={"offer-action"}>
        <div>
          {isCounter && (
            <>
              <button
                className={"btn-1"}
                disabled={offer.Exchange.acceptedOffersCount !== 0}
                onClick={() => setShowNewOffer(true)}
              >
                Augmenter mon offre
              </button>
            </>
          )}
          {isPending && (
            <>
              <button
                className={"btn-1"}
                disabled={offer.Exchange.acceptedOffersCount !== 0}
                onClick={() =>
                  confirm(
                    "confirm_accept_content",
                    "confirm_accept_title",
                    () => acceptOffer(formatedExchange.id, offer.id),
                    true,
                    {
                      closable: false,
                    },
                  )
                }
              >
                Accepter l'offre
              </button>
              <button
                className={"btn-danger"}
                disabled={offer.Exchange.acceptedOffersCount !== 0}
                onClick={() =>
                  confirm(
                    "confirm_decline_content",
                    "confirm_decline_title",
                    () => declineOffer(formatedExchange.id, offer.id),
                    true,
                    {
                      closable: false,
                    },
                  )
                }
              >
                Décliner l'offre
              </button>
            </>
          )}
          {isDeclined && (
            <button
              className={"btn-1"}
              disabled={offer.Exchange.acceptedOffersCount !== 0}
              onClick={() => setShowNewOffer(true)}
            >
              Faire une offre
            </button>
          )}
          {offer.Exchange.acceptedOffersCount !== 0 &&
            (isPending || isCounter || isDeclined || isCanceled) && (
              <p className={"data-row body-s --txt-center"}>
                <span>Vous avez accepté une autre offre.</span>
              </p>
            )}
          {isAccepted && (
            <p className={"data-row body-s --txt-center"}>
              <span>
                L’offre est acceptée. Veuillez attendre que l'acheteur paye les
                frais de dossier.
              </span>
            </p>
          )}
          {isValidated && (
            <p className={"data-row body-s --txt-center"}>
              <span>
                Félicitation, l’offre est conclue. Autolity va prendre contact
                avec vous très bientôt pour finaliser la vente.
              </span>
            </p>
          )}
          {isSold && (
            <p className={"data-row body-s --txt-center"}>
              <span>Félicitation, vous avez vendu ce véhicule.</span>
            </p>
          )}
        </div>
      </div>
      {showNewOffer && (
        <OfferDialog
          exchange={{ ...offer.Exchange, Offers: [offer] }}
          isNewOffer={false}
          onClose={() => setShowNewOffer(false)}
          onSubmit={(offerForm) => {
            return makeNewOffer(offerForm, formatedExchange.id, offer.id).then(
              () => {
                toastSuccess("exchange:make-new-offer.SUCCESS");
                setShowNewOffer(false);
              },
              () => {
                toastError("exchange:make-new-offer.ERROR");
              },
            );
          }}
        />
      )}
      {showOfferPriceDetail && (
        <OfferFeeBlockDialog
          offer={offer}
          onClose={() => setShowOfferPriceDetail(false)}
        />
      )}
    </div>
  );
};
export default ReceivedOfferListItem;
