import {
  ComponentType,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { Sort } from "./components/ExchangeListWithFilters";
import {
  Exchange,
  ExchangeType,
  ExchangeVehicleColor,
  ExchangeVehicleType,
} from "./exchange";

interface BasicFilters {
  brand: Array<Exchange["vehicleBrand"]>;
  metaModel: Array<Exchange["vehicleMetaModel"]>;
  model: Array<Exchange["vehicleModel"]>;
  subModel: Array<Exchange["vehicleSubModel"]>;
  engine: Array<Exchange["vehicleEngine"]>;
  priceMin: number | null;
  priceMax: number | null;
  entryIntoServiceMin: Date | null;
  entryIntoServiceMax: Date | null;
  mileageMin: number | null;
  mileageMax: number | null;
  user: Array<string>;
  email: string | null;
  favorite: boolean;
  offerStatus: string | null;
  exchangeStatus: string | null;
}

interface AdvancedFilters {
  powerMin: number | null;
  powerMax: number | null;
  energy: string | null;
  type: ExchangeVehicleType | null;
  color: ExchangeVehicleColor | null;
  gearbox: string | null;
  transmission: string | null;
  accidented: string | null;
  originCountry: Array<string>;
  exchangeType: ExchangeType | null;
}

export interface ExchangeListFiltersAPI {
  sort: Sort;
  setSort: (sort: Sort | ((prev: Sort) => Sort)) => void;

  basicFilters: BasicFilters;
  setBasicFilters: (
    basicFilters: BasicFilters | ((prev: BasicFilters) => BasicFilters),
  ) => void;

  advancedFilters: AdvancedFilters;
  setAdvancedFilters: (
    advancedFilters:
      | AdvancedFilters
      | ((prev: AdvancedFilters) => AdvancedFilters),
  ) => void;

  resetFilters: () => void;
}

export const ExchangeListFiltersContext = createContext<ExchangeListFiltersAPI | null>(
  null,
);

export function ProvideExchangeListFilters({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const [sort, setSort] = useState<ExchangeListFiltersAPI["sort"]>(Sort.Date);
  const [basicFilters, setBasicFilters] = useState<
    ExchangeListFiltersAPI["basicFilters"]
  >({
    brand: [] as Array<Exchange["vehicleBrand"]>,
    model: [] as Array<Exchange["vehicleModel"]>,
    metaModel: [] as Array<Exchange["vehicleMetaModel"]>,
    subModel: [] as Array<Exchange["vehicleSubModel"]>,
    engine: [] as Array<Exchange["vehicleEngine"]>,
    priceMin: null,
    priceMax: null,
    entryIntoServiceMin: null,
    entryIntoServiceMax: null,
    mileageMin: null,
    mileageMax: null,
    user: [] as Array<string>,
    email: null,
    favorite: false,
    offerStatus: null,
    exchangeStatus: null,
  });
  const [advancedFilters, setAdvancedFilters] = useState<
    ExchangeListFiltersAPI["advancedFilters"]
  >({
    powerMin: null,
    powerMax: null,
    energy: null,
    type: null,
    color: null,
    gearbox: null,
    transmission: null,
    accidented: null,
    originCountry: [] as Array<string>,
    exchangeType: null,
  });

  const resetFilters: ExchangeListFiltersAPI["resetFilters"] = useCallback(() => {
    setBasicFilters({
      brand: [] as Array<Exchange["vehicleBrand"]>,
      model: [] as Array<Exchange["vehicleModel"]>,
      metaModel: [] as Array<Exchange["vehicleMetaModel"]>,
      subModel: [] as Array<Exchange["vehicleSubModel"]>,
      engine: [] as Array<Exchange["vehicleEngine"]>,
      priceMin: null,
      priceMax: null,
      entryIntoServiceMin: null,
      entryIntoServiceMax: null,
      mileageMin: null,
      mileageMax: null,
      user: [] as Array<string>,
      email: null,
      favorite: false,
      offerStatus: null,
      exchangeStatus: null,
    });
    setAdvancedFilters({
      powerMin: null,
      powerMax: null,
      energy: null,
      type: null,
      color: null,
      gearbox: null,
      transmission: null,
      accidented: null,
      originCountry: [] as Array<string>,
      exchangeType: null,
    });
  }, []);

  return (
    <ExchangeListFiltersContext.Provider
      value={{
        sort,
        setSort,
        basicFilters,
        setBasicFilters,
        advancedFilters,
        setAdvancedFilters,
        resetFilters,
      }}
    >
      {children}
    </ExchangeListFiltersContext.Provider>
  );
}

export function withProvideExchangeListFilters<
  P extends Record<string, unknown>
>(WrappedComponent: ComponentType<P>): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideExchangeListFilters(props: P) {
    return (
      <ProvideExchangeListFilters>
        <WrappedComponent {...props} />
      </ProvideExchangeListFilters>
    );
  }

  WithProvideExchangeListFilters.displayName = `withExchangeListFilters(${displayName})`;

  return WithProvideExchangeListFilters;
}

export function useExchangeListFilters(): ExchangeListFiltersAPI {
  return useContext(ExchangeListFiltersContext) as ExchangeListFiltersAPI;
}
