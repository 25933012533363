import { Link } from "react-router-dom";
import {
  LOGIN_LINK,
  LOST_PASSWORD_LINK,
  PUBLIC_CONTACT_LINK,
  REGISTER_LINK,
} from "../../routes/public";

/* images and icons */
import logoFooter from "../../assets/img/logo-white.svg";
import { useAuth } from "../../services/auth/apiProvider";
import { PRIVATE_CONTACT_LINK } from "../../routes/private";
import IconContact from "../../assets/img/icons/icon-letter-white.svg";

const Footer = (): JSX.Element => {
  const { user } = useAuth();
  return (
    <div className={"footer"}>
      <div>
        <img alt={"logo Autolity"} className={"logo"} src={logoFooter} />
      </div>
      <div className={"columns"}>
        <div className={"col-brand"}>
          <p className={"body-s"}>
            Service commercial Autolity
            <br />
            <a href={"tel:+33451410002"}>(+33) 04 51 41 00 02</a>
            <br />
            <a href={"mailto: autolity@autolity.io"}>autolity@autolity.io</a>
          </p>
        </div>
        <nav>
          {/* <Link to={""}>La solution</Link>
          <Link to={""}>Qui sommes-nous</Link> */}

          <Link to={user ? PRIVATE_CONTACT_LINK : PUBLIC_CONTACT_LINK}>
            <img alt={"contact"} className={"icon"} src={IconContact} />
            Contact
          </Link>
          {user && (
            <a
              className={"body-s"}
              href={"/docs/cgu-autolity.pdf"}
              rel={"noreferrer"}
              target={"_blank"}
            >
              Conditions Générales d'Utilisation
            </a>
          )}

          {!user && (
            <>
              <Link to={LOGIN_LINK}>Connexion</Link>
              <Link to={REGISTER_LINK}>Inscription</Link>
              <Link to={LOST_PASSWORD_LINK}>Mot de passe oublié</Link>
            </>
          )}
        </nav>
        <div>
          {" "}
          {user && (
            <a
              className={"body-s"}
              href={"https://z4s8hteorsc.typeform.com/to/xRLAfBUj"}
              rel={"noreferrer"}
              target={"_blank"}
            >
              Vous n'avez pas trouvé ce que vous cherchiez ? Cliquez ici
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Footer;
