import PaginatedList from "../../ui/PaginatedList";
import { FullExchange } from "../exchange";
import ExchangeListItem from "./ExchangeListItem";

const ExchangeList = ({
  asAdmin,
  exchanges,
  paginatedBy = 8,
}: {
  asAdmin?: boolean;
  exchanges: FullExchange[];
  paginatedBy?: number;
}): JSX.Element => {
  return (
    <PaginatedList
      items={exchanges}
      paginatedBy={paginatedBy}
      render={(exchange) => (
        <ExchangeListItem
          key={exchange.id}
          asAdmin={asAdmin}
          exchange={exchange}
        />
      )}
    />
  );
};

export default ExchangeList;
