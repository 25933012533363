import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { object, array, string } from "yup";
import Form from "../../forms/Form";
import SubmitButton from "../../forms/SubmitButton";
import ValidationsErrors from "../../forms/ValidationsErrors";
import {
  AnswerInfoRequestForm,
  AnswerInfoRequestParams,
  InfoRequest,
  InfoRequestType,
} from "../infoRequest";
import Dialog from "../../ui/block/Dialog";
import InfoDocumentZone from "./InfoDocumentZone";
import InfoPictureZone from "./InfoPictureZone";
import { useInfoRequest } from "../infoRequestProvider";
import { useToastsWithIntl } from "../../toast-notifications";

const AnswerInfoRequestDialog = ({
  onClose,
  onSubmit,
  infoRequest,
}: {
  onClose: () => void;
  onSubmit: (answerInfoRequestParams: AnswerInfoRequestParams) => Promise<void>;
  infoRequest: InfoRequest;
}): JSX.Element => {
  const { startWeProoveInspection } = useInfoRequest();
  const { t } = useTranslation(["info_request"]);
  const { toastError, toastSuccess } = useToastsWithIntl(["info_request"]);
  const [weProovSubmitLoading, setWeProovSubmitLoading] = useState(false);

  const AnswerInfoRequestSchema = useMemo(
    () =>
      object().shape({
        invoiceDocuments: array()
          .label(t("info_request:invoice_request"))
          .of(
            object()
              .shape({
                file: string().required(),
                name: string().required(),
                type: string().oneOf([InfoRequestType.invoice]).required(),
              })
              .defined(),
          )
          .defined()
          .min(infoRequest.invoice ? 1 : 0),
        pictureDocuments: array()
          .label(t("info_request:picture_request"))
          .of(
            object()
              .shape({
                file: string().required(),
                name: string().required(),
                type: string().oneOf([InfoRequestType.picture]).required(),
              })
              .defined(),
          )
          .defined()
          .min(infoRequest.picture ? 1 : 0),
        videoDocuments: array()
          .label(t("info_request:video_request"))
          .of(
            object()
              .shape({
                file: string().required(),
                name: string().required(),
                type: string().oneOf([InfoRequestType.video]).required(),
              })
              .defined(),
          )
          .defined()
          .min(infoRequest.video ? 1 : 0),
      }),
    [infoRequest.invoice, infoRequest.picture, infoRequest.video, t],
  );

  return (
    <Dialog className={"dialog-card-full"} onClose={onClose}>
      <div>
        <div className={"card-head"}>
          <h2 className={"card-title"}>Envoyer des informations</h2>
        </div>
        <div className={"card-body lay-col --gap-v"}>
          <Form
            initialValues={{
              invoiceDocuments: [],
              pictureDocuments: [],
              videoDocuments: [],
            }}
            onSubmit={(values: AnswerInfoRequestForm) =>
              onSubmit({
                InfoRequestDocuments: Object.values(values).flat(),
              })
            }
            schema={AnswerInfoRequestSchema}
          >
            {infoRequest.invoice && (
              <div className={"content-block section-xl --bg --light"}>
                <div className={"form-block"}>
                  <label className={"input-label"}>
                    {t("info_request:invoice_request")}
                  </label>
                  <InfoDocumentZone
                    documentType={InfoRequestType.invoice}
                    name={"invoiceDocuments"}
                  />
                </div>
              </div>
            )}

            {infoRequest.picture && (
              <div className={"content-block section-xl --bg --light"}>
                <div className={"form-block"}>
                  <label className={"input-label"}>
                    {t("info_request:picture_request")}
                  </label>
                  <InfoPictureZone
                    documentType={InfoRequestType.picture}
                    name={"pictureDocuments"}
                  />
                </div>
              </div>
            )}

            {infoRequest.video && (
              <div className={"content-block section-xl --bg --light"}>
                <div className={"form-block"}>
                  <label className={"input-label"}>
                    {t("info_request:video_request")}
                  </label>
                  <InfoDocumentZone
                    documentType={InfoRequestType.video}
                    name={"videoDocuments"}
                  />
                </div>
              </div>
            )}

            {infoRequest.weproov && (
              <div className={"form-block"}>
                <label className={"input-label"}>
                  Demande d'auto-inspection WeProov
                </label>
                <div>
                  <button
                    className={"btn"}
                    disabled={
                      infoRequest.weproovCode !== null || weProovSubmitLoading
                    }
                    onClick={() => {
                      setWeProovSubmitLoading(true);
                      startWeProoveInspection(infoRequest).then(
                        () =>
                          toastSuccess(
                            "info_request:request-weproov-inspection.SUCCESS",
                          ),
                        () => {
                          toastError(
                            "info_request:request-weproov-inspection.ERROR",
                          );
                          setWeProovSubmitLoading(false);
                        },
                      );
                    }}
                    type={"button"}
                  >
                    Réaliser mon inspection via WeProov
                  </button>
                  {infoRequest.weproovCode && (
                    <div className={"section"}>
                      <div className={"info"}>
                        <div>
                          Vous allez très prochainement recevoir un mail de
                          WeProov pour réaliser l’auto-inspection.
                          <br />
                          <br />
                          <div>
                            Code WeProov :{" "}
                            <strong>{infoRequest.weproovCode}</strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className={"form-footer"}>
              <ValidationsErrors />
              <SubmitButton
                disabled={
                  infoRequest.weproov && infoRequest.weproovCode === null
                }
              >
                Envoyer
              </SubmitButton>
            </div>
          </Form>
        </div>
      </div>
    </Dialog>
  );
};

export default AnswerInfoRequestDialog;
