import React, { FunctionComponent, useCallback, useEffect } from "react";
import {
  DASHBOARD,
  EXCHANGE,
  ADMIN_DASHBOARD,
  USER_PLANS_PRIVATE,
  PRIVATE_CONTACT,
  PROFILE,
  PRIVATE_CONTACT_LINK,
} from "../routes/private";
import Routes from "src/services/routing/Routes";
import { Navigate, Route, useNavigate } from "react-router-dom";

/* images and icons */
import Footer from "./components/Footer";
import HeaderPrivate from "./components/HeaderPrivate";
import Dashboard from "./private/Dashboard";
import { AuthAPIConnected, useAuth } from "../services/auth/apiProvider";
import Exchange from "./private/exchange/Exchange";
import { withProvideAccounts } from "../services/accounts/accountsProvider";
import { ADMIN_SUMMARY_LINK } from "../routes/admin";
import Admin from "./private/admin/Admin";
import { withProvideExchange } from "../services/exchange/exchangeProvider";
import { withLoader } from "../services/routing/useLoader";
import { withProvideOffer } from "../services/offer/offerProvider";
import PrivatePlansTable from "./private/PrivatePlansTable";
import PrivateContact from "./private/PrivateContact";
import Profile from "./private/profile/Profile";
import { useToastsWithIntl } from "src/services/toast-notifications";
import { EXCHANGE_LISTING_LINK } from "src/routes/exchange";

const Private: FunctionComponent = () => {
  const { user } = useAuth() as AuthAPIConnected;
  const { toastError } = useToastsWithIntl(["auth"]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.Plan && user.validationDate) {
      navigate(PRIVATE_CONTACT_LINK);
      toastError("auth:no_active_plan");
    }
  }, [toastError, navigate, user.Plan, user.validationDate]);

  return (
    <div className={"site-layout"}>
      <HeaderPrivate />
      <div className={"view"}>
        <Routes>
          {user.superAdmin ? (
            <>
              <Route
                element={<Navigate to={ADMIN_SUMMARY_LINK} replace />}
                index
              />
              <Route element={<Admin />} path={`${ADMIN_DASHBOARD}/*`} />
            </>
          ) : (
            <>
              <Route
                element={<Navigate to={EXCHANGE_LISTING_LINK} replace />}
                index
              />
              <Route element={<Dashboard />} path={`${DASHBOARD}/*`} />
              <Route element={<Exchange />} path={`${EXCHANGE}/*`} />
            </>
          )}
          <Route element={<Profile />} path={`${PROFILE}/*`} />
          <Route element={<PrivatePlansTable />} path={USER_PLANS_PRIVATE} />
          <Route element={<PrivateContact />} path={PRIVATE_CONTACT} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

const useLoad = () => {
  const { reloadUser, loadConfigs } = useAuth();
  return useCallback(() => Promise.all([loadConfigs(), reloadUser()]), [
    loadConfigs,
    reloadUser,
  ]);
};

export default withLoader(
  withProvideAccounts(withProvideOffer(withProvideExchange(Private))),
  useLoad,
);
