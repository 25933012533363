import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import iconArrowMiniLeft from "../../assets/img/icons/icon-arrow-mini-left.svg";
import iconArrowMiniRight from "../../assets/img/icons/icon-arrow-mini-right.svg";
import iconEndArrowLeft from "../../assets/img/icons/icon-end-arrow-left.svg";
import iconEndArrowRight from "../../assets/img/icons/icon-end-arrow-right.svg";
import { useQueryParam } from "../routing/useQueryParam";

const PaginatedList = <Item,>({
  items,
  render,
  paginatedBy,
}: {
  items: Item[];
  render: (item: Item) => JSX.Element | null;
  paginatedBy: number;
}): JSX.Element => {
  const [pageUrl, setPageUrl] = useQueryParam("page", "number");
  const [page, setPage] = useState(pageUrl || 0);

  // Memos
  const lastPage = useMemo(() => Math.ceil(items.length / paginatedBy), [
    items.length,
    paginatedBy,
  ]);
  const paginatedList = useMemo(() => {
    return new Map(
      [...Array(lastPage).keys()].map((page) => [
        page + 1,
        items.slice(page * paginatedBy, (page + 1) * paginatedBy),
      ]),
    );
  }, [items, lastPage, paginatedBy]);

  if (
    !pageUrl ||
    (pageUrl && paginatedList.size > 0 && paginatedList.size < pageUrl)
  ) {
    setPageUrl(1);
  }

  const currentPage = useMemo(() => paginatedList.get(page) || [], [
    paginatedList,
    page,
  ]);

  const hasPageOffset = useCallback(
    (offset: number) => {
      return offset > 0 ? page + offset <= lastPage : page + offset >= 1;
    },
    [page, lastPage],
  );
  const hasPreviousPage = useMemo(() => hasPageOffset(-1), [hasPageOffset]);
  const hasNextPage = useMemo(() => hasPageOffset(1), [hasPageOffset]);

  const listRef = useRef<null | HTMLDivElement>(null);

  // Effects
  // NOTE: When the items change, we reset the page to 1.
  useEffect(() => {
    setPage(1);
  }, [items]);

  useEffect(() => {
    setPage(pageUrl || 0);
  }, [pageUrl]);

  // Methods
  const goToOffset = useCallback(
    (offset: number) => {
      setPageUrl(page + offset);
      listRef &&
        listRef.current &&
        listRef.current.scrollIntoView({ behavior: "smooth" });
    },
    [page, setPageUrl],
  );
  const goToNextPage = useCallback(() => goToOffset(1), [goToOffset]);
  const goToPreviousPage = useCallback(() => goToOffset(-1), [goToOffset]);

  return (
    <div ref={listRef} className={"offers-list"}>
      <div>{currentPage.map((item) => render(item))}</div>

      {lastPage > 1 && (
        <div className={"pagination"}>
          <button
            className={"btn --icon"}
            disabled={!hasPreviousPage}
            onClick={() => setPageUrl(1)}
            type={"button"}
          >
            <img alt={""} className={"icon"} src={iconEndArrowLeft} />
          </button>
          <button
            className={"btn --icon"}
            disabled={!hasPreviousPage}
            onClick={() => goToPreviousPage()}
            type={"button"}
          >
            <img alt={""} className={"icon"} src={iconArrowMiniLeft} />
          </button>

          <div className={"lay-row --gap-h--xs"}>
            {!hasNextPage && hasPageOffset(-2) && (
              <button
                className={"btn"}
                onClick={() => goToOffset(-2)}
                type={"button"}
              >
                {page - 2}
              </button>
            )}
            {hasPreviousPage && (
              <button
                className={"btn"}
                onClick={() => goToPreviousPage()}
                type={"button"}
              >
                {page - 1}
              </button>
            )}
            <button className={"btn active"}>{page}</button>
            {hasNextPage && (
              <button
                className={"btn"}
                onClick={() => goToNextPage()}
                type={"button"}
              >
                {page + 1}
              </button>
            )}
            {!hasPreviousPage && hasPageOffset(2) && (
              <button
                className={"btn"}
                onClick={() => goToOffset(2)}
                type={"button"}
              >
                {page + 2}
              </button>
            )}
          </div>

          <button
            className={"btn --icon"}
            disabled={!hasNextPage}
            onClick={() => goToNextPage()}
            type={"button"}
          >
            <img
              alt={"next page arrow"}
              className={"icon"}
              src={iconArrowMiniRight}
            />
          </button>
          <button
            className={"btn --icon"}
            disabled={!hasNextPage}
            onClick={() => setPageUrl(lastPage)}
            type={"button"}
          >
            <img alt={"end arrow"} className={"icon"} src={iconEndArrowRight} />
          </button>
        </div>
      )}
    </div>
  );
};

export default PaginatedList;
