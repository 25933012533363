import {
  ConfirmPaymentData,
  PaymentIntentResult,
  SetupIntentResult,
  Stripe,
  StripeElements,
  StripeError,
} from "@stripe/stripe-js";
import {
  createContext,
  useContext,
  useCallback,
  ComponentType,
  useState,
} from "react";
import { useAuth } from "../auth/apiProvider";
import {
  createPaymentIntent as apiCreatePaymentIntent,
  createSubscriptionIntent as apiCreateSubscriptionIntent,
  unsubscribeStripePlan as apiUnsubscribeStripePlan,
  startPaidSubscriptionIntent as apiStartPaidSubscriptionIntent,
  createSubscriptionWithTrial,
} from "./api";
import {
  PaymentIntent,
  PaymentIntentForm,
  SubscriptionIntentForm,
} from "./payment";

export interface PaymentAPI {
  paymentIntent: PaymentIntent | null;
  createPaymentIntent(paymentIntent: PaymentIntentForm): Promise<void>;
  createSubscriptionIntent(
    subscriptionIntent: SubscriptionIntentForm,
  ): Promise<void>;
  confirmPayment(
    stripe: Stripe,
    elements: StripeElements,
    confirmParams?: Partial<ConfirmPaymentData>,
    isTrial?: boolean,
  ): Promise<
    SetupIntentResult | PaymentIntentResult | never | { error: StripeError }
  >;

  unsubscribeStripePlan(): Promise<void>;
  startPaidSubscriptionIntent(
    subscriptionId: NonNullable<PaymentIntent["subscriptionId"]>,
  ): Promise<void>;
}

export const PaymentContext = createContext<PaymentAPI | null>(null);

export function ProvidePayment({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const { reloadUser } = useAuth();
  const [
    subscriptionIntentForm,
    setSubscriptionIntentForm,
  ] = useState<SubscriptionIntentForm | null>(null);
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent | null>(
    null,
  );

  const createPaymentIntent: PaymentAPI["createPaymentIntent"] = useCallback(
    (paymentIntentForm) =>
      apiCreatePaymentIntent(paymentIntentForm).then(({ data }) =>
        setPaymentIntent(data),
      ),
    [],
  );

  const createSubscriptionIntent: PaymentAPI["createSubscriptionIntent"] = useCallback(
    (subscriptionIntentForm) =>
      apiCreateSubscriptionIntent(subscriptionIntentForm).then(({ data }) => {
        setPaymentIntent(data);
        setSubscriptionIntentForm(subscriptionIntentForm);
      }),
    [],
  );

  const confirmPayment: PaymentAPI["confirmPayment"] = useCallback(
    async (stripe, elements, confirmParams, isTrial) => {
      if (isTrial) {
        const stripeConfirmSetup = confirmParams?.return_url
          ? await stripe.confirmSetup({
              elements,
              redirect: "always",
              confirmParams: confirmParams as ConfirmPaymentData,
            })
          : await stripe.confirmSetup({
              elements,
              redirect: "if_required",
              confirmParams: confirmParams as Partial<ConfirmPaymentData>,
            });

        if (stripeConfirmSetup.error === undefined)
          await createSubscriptionWithTrial({
            ...subscriptionIntentForm!,
            paymentMethodId: stripeConfirmSetup.setupIntent.payment_method?.toString(),
          });

        return stripeConfirmSetup;
      } else {
        const stripeConfirmPayment = confirmParams?.return_url
          ? await stripe.confirmPayment({
              elements,
              redirect: "always",
              confirmParams: confirmParams as ConfirmPaymentData,
            })
          : await stripe.confirmPayment({
              elements,
              redirect: "if_required",
              confirmParams: confirmParams as Partial<ConfirmPaymentData>,
            });

        return stripeConfirmPayment;
      }
    },
    [subscriptionIntentForm],
  );

  const unsubscribeStripePlan: PaymentAPI["unsubscribeStripePlan"] = useCallback(async () => {
    await apiUnsubscribeStripePlan().then(reloadUser);
  }, [reloadUser]);

  const startPaidSubscriptionIntent: PaymentAPI["startPaidSubscriptionIntent"] = useCallback(
    async (subscriptionId) => {
      await apiStartPaidSubscriptionIntent(subscriptionId);
    },
    [],
  );

  return (
    <PaymentContext.Provider
      value={{
        paymentIntent,
        createPaymentIntent,
        createSubscriptionIntent,
        confirmPayment,
        unsubscribeStripePlan,
        startPaidSubscriptionIntent,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
}

export function withProvidePayment<P>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function withProvidePayment(props: P) {
    return (
      <ProvidePayment>
        <WrappedComponent {...props} />
      </ProvidePayment>
    );
  }

  withProvidePayment.displayName = `withProvidePayment(${displayName})`;

  return withProvidePayment;
}

export function usePayment(): PaymentAPI {
  return useContext(PaymentContext) as PaymentAPI;
}
