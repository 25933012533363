import React, { useEffect, useMemo, useState } from "react";
import { AuthAPIConnected, useAuth } from "../../auth/apiProvider";
import { comparePlan, Plan, PlanComparaison } from "../plan";
import { cx } from "@emotion/css";
import { useTranslation } from "react-i18next";

interface Props {
  groupedPlans: Plan[];
  planType: Plan["type"];
  onSelect: (plan: Plan) => void;
  onClickBuyer: () => void;
  showAnnualPlans: boolean;
}

const PlanItem = ({
  groupedPlans,
  planType,
  onSelect,
  onClickBuyer,
  showAnnualPlans,
}: Props): JSX.Element | null => {
  const { user, configs } = useAuth() as AuthAPIConnected;
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(0);
  const [optionPremium, setOptionPremium] = useState(false);
  const { t } = useTranslation(["plan"]);
  const selectedPlan = useMemo(() => groupedPlans[selectedPlanIndex], [
    groupedPlans,
    selectedPlanIndex,
  ]);

  useEffect(() => {
    if (user.Plan && user.Plan.type === planType) {
      setSelectedPlanIndex(
        groupedPlans.findIndex((plan) => plan.id === user.Plan!.id),
      );
      setOptionPremium(user.Plan.Subscription.isBuyer);
    }
  }, [groupedPlans, planType, user.Plan]);

  const planCompraison = comparePlan(
    user.Plan,
    selectedPlan,
    optionPremium,
    showAnnualPlans,
  );

  if (showAnnualPlans && selectedPlan.annualMonthlyPrice === null) {
    return null;
  }

  const planPrice =
    (showAnnualPlans
      ? selectedPlan.annualMonthlyPrice!
      : selectedPlan.monthlyPrice!) +
    (optionPremium
      ? parseFloat(
          showAnnualPlans
            ? configs["PLAN_OPTION_ANNUAL_BUYER_PRICE"]
            : configs["PLAN_OPTION_BUYER_PRICE"],
        )
      : 0);

  return (
    <div
      className={cx(
        "plan-card",
        planCompraison === PlanComparaison.isEqual && "active",
      )}
    >
      <div className={"card-body"}>
        <h3 className={"plan-name"}>{groupedPlans[0].label.split("_")[0]}</h3>
        <div className={"plan-price"}>
          <div>
            {planPrice}
            <sup>€ {planType !== 0 ? "HT / mois" : ""}</sup>

            <div className={"section-xs"}>
              {planPrice === 0 ? (
                <p className={"chip --outline"}>Sans engagement</p>
              ) : (
                <p className={"chip --outline"}>
                  Engagement {showAnnualPlans ? "annuel" : "mensuel"}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className={"plan-features"}>
          <div>
            {groupedPlans.length > 1 ? (
              <select
                className={"select"}
                onChange={(event) => {
                  setSelectedPlanIndex(
                    (event.target.value as unknown) as number,
                  );
                }}
                value={selectedPlanIndex}
              >
                {groupedPlans.map((plan, index) => (
                  <option key={plan.id} value={index}>
                    {plan.maxExchangeOfferDailyNb} annonces actives
                  </option>
                ))}
              </select>
            ) : (
              <>
                {groupedPlans[0].maxExchangePostNb === 0
                  ? `${groupedPlans[0].postEmergencyPrice} € / annonce`
                  : `${groupedPlans[0].maxExchangePostNb} annonces actives`}
              </>
            )}
          </div>
          <div>
            {groupedPlans[0].maxExchangeOfferDailyNb > -1
              ? `${groupedPlans[0].maxExchangeOfferDailyNb} offres / jour`
              : "offres illimitées"}
          </div>
        </div>

        <div className={"plan-actions"}>
          {planType === 2 && (
            <div className={"section-xs"}>
              <label className={"input-label"}>
                <input
                  checked={optionPremium}
                  onChange={() => {
                    onClickBuyer();
                    setOptionPremium((prevState) => !prevState);
                  }}
                  type={"checkbox"}
                />
                Option buyer (+
                {parseFloat(
                  showAnnualPlans
                    ? configs["PLAN_OPTION_ANNUAL_BUYER_PRICE"]
                    : configs["PLAN_OPTION_BUYER_PRICE"],
                )}
                € HT / mois)
              </label>
            </div>
          )}
          {groupedPlans[0].monthsOfTrial !== null &&
            groupedPlans[0].monthsOfTrial > 0 && (
              <div className={"section-xs"}>
                {showAnnualPlans ? (
                  <p className={"chip --outline --pending"}>
                    {t("plan:trial_message", {
                      count: groupedPlans[0].monthsOfTrial,
                    })}
                  </p>
                ) : (
                  <div
                    className={"section-xs --bg --light --txt-center body-xs"}
                  >
                    Engagez-vous sur 1 an pour profiter de{" "}
                    {t("plan:trial_message", {
                      count: groupedPlans[0].monthsOfTrial,
                    })}{" "}
                  </div>
                )}
              </div>
            )}
          {planCompraison === PlanComparaison.isEqual && (
            <button className={"btn-light btn-block"} type={"button"} disabled>
              Votre forfait actuel
            </button>
          )}
          {planCompraison === PlanComparaison.isSuperior && (
            <button className={"btn-light btn-block"} type={"button"} disabled>
              Votre forfait est supérieur
            </button>
          )}
          {planCompraison === PlanComparaison.isLower && (
            <button
              className={"btn-1 btn-block"}
              onClick={() => onSelect(selectedPlan)}
              type={"button"}
            >
              Souscrire
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlanItem;
