import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import StripeSubscriptionDialog from "../../payment/components/StripeSubscriptionDialog";
import { usePlan } from "../planProvider";
import CardBody from "../../ui/block/CardBody";
import CardHead from "../../ui/block/CardHead";
import Dialog from "../../ui/block/Dialog";
import PlanItem from "./PlanItem";
import { getPlanPriceHT, Plan } from "../plan";
import Link from "../../routing/components/Link";
import { PRIVATE_CONTACT_LINK } from "../../../routes/private";
import { ContactType } from "../../auth/user";
import { withLoader } from "../../routing/useLoader";
import { AuthAPIConnected, useAuth } from "../../auth/apiProvider";
import { cx } from "@emotion/css";
import PlanComparaisonTableDialog from "./PlanComparaisonTableDialog";
import { frenchTVA } from "../../payment/payment";

const PlanChoiceDialog: FunctionComponent<{
  onClose: () => void;
  redirectPath?: string;
  redirectAction?: () => void;
}> = ({ onClose, redirectPath, redirectAction }) => {
  const { plans } = usePlan();
  const { reloadUser, configs, user } = useAuth() as AuthAPIConnected;
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [isBuyer, setIsBuyer] = useState(false);
  const [showAnnualPlans, setShowAnnualPlans] = useState(false);
  const [showPlanTableComparaison, setShowPlanTableComparaison] = useState(
    false,
  );

  useEffect(
    () => setShowAnnualPlans(user.Plan?.Subscription.isAnnual || false),
    [user.Plan?.Subscription.isAnnual],
  );

  const plansGroupByType = useMemo(
    () =>
      Object.entries(
        plans.reduce((acc: Record<number, Plan[]>, subscription: Plan) => {
          if (!acc[subscription.type]) {
            acc[subscription.type] = [];
          }
          acc[subscription.type].push(subscription);
          return acc;
        }, {}),
      ),
    [plans],
  );

  return (
    <Dialog className={"dialog-card-full"} onClose={onClose}>
      <CardHead title={"Souscrire à un abonnement"} />
      <CardBody>
        <div className={"lay-row --center-v --distribute-h "}>
          <p className={"title-l"}>Forfaits et tarifs</p>
          <button
            className={cx(
              "btn-toggle title-xs",
              showAnnualPlans && "toggle-on",
            )}
            onClick={() => setShowAnnualPlans((annualPlan) => !annualPlan)}
            type={"button"}
          >
            &nbsp;Engagez-vous sur un an et économisez jusqu'à 15%
          </button>
        </div>
        <div className={"section-l grid --stretch"}>
          {plansGroupByType.map(([type, groupedPlans]) => (
            <PlanItem
              key={type}
              groupedPlans={groupedPlans}
              onClickBuyer={() => setIsBuyer((prevIsBuyer) => !prevIsBuyer)}
              onSelect={(plan) => setSelectedPlan(plan)}
              planType={parseInt(type)}
              showAnnualPlans={showAnnualPlans}
            />
          ))}
          <div className={"plan-card"}>
            <div className={"card-body"}>
              <h2 className={"plan-name"}>BUSINESS</h2>
              <div className={"plan-price"}>À LA DEMANDE</div>
              <p className={"body-s"}>
                Pour les entreprises les plus complexes.
                <br />
                <strong>Créez le plan idéal en fonction de vos besoins</strong>
              </p>
              <div className={"plan-actions"}>
                <Link
                  className={"btn-1 btn-block"}
                  queryParams={{
                    type: ContactType.SUBSCRIPTION,
                    subject: "Abonnement sur mesure",
                    message: "Je souhaite un devis sur mesure. ",
                  }}
                  to={PRIVATE_CONTACT_LINK}
                >
                  Nous contacter
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className={"section lay-col --center"}>
          <button
            className={"btn-1"}
            onClick={() => setShowPlanTableComparaison(true)}
            type={"button"}
          >
            Comparer les plans
          </button>
        </div>
      </CardBody>
      {showPlanTableComparaison && (
        <PlanComparaisonTableDialog
          onClose={() => setShowPlanTableComparaison(false)}
        />
      )}
      {selectedPlan && (
        <StripeSubscriptionDialog
          onClose={() => setSelectedPlan(null)}
          priceWithoutDiscount={
            getPlanPriceHT(
              selectedPlan,
              showAnnualPlans,
              isBuyer,
              showAnnualPlans
                ? configs["PLAN_OPTION_ANNUAL_BUYER_PRICE"]
                : configs["PLAN_OPTION_BUYER_PRICE"],
            ) * frenchTVA
          }
          redirectAction={async () => {
            await reloadUser();
            if (redirectAction) redirectAction();
          }}
          redirectPath={redirectPath}
          subscriptionIntentForm={{
            planId: selectedPlan.id,
            isBuyer,
            isAnnual: showAnnualPlans,
          }}
          trialDuration={
            showAnnualPlans && selectedPlan.monthsOfTrial
              ? selectedPlan.monthsOfTrial
              : 0
          }
        />
      )}
    </Dialog>
  );
};

const useLoad = () => {
  const { loadPlans } = usePlan();

  return useCallback(() => loadPlans(), [loadPlans]);
};

export default withLoader(PlanChoiceDialog, useLoad);
