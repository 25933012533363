import { useYupField } from "./Form";
import { AnySchema, BaseSchema } from "yup";
import { Namespace, TFuncKey, useTranslation } from "react-i18next";
import { getStringEnumEntries } from "../data-structures/enum";
import { MultiSelect, Option } from "react-multi-select-component";
import { useField } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { deepEqual } from "../data-structures/any";
import { cx } from "@emotion/css";

const FAutoComplete = ({
  name,
  disabled,
  className,
  multiselect = { active: true, hasSelectAll: true, isCreatable: false },
  placeholder,
  afterValueChanged,
  ignoreSpecialChar,
}: {
  name: string;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  multiselect?: {
    active: boolean;
    hasSelectAll?: boolean;
    isCreatable?: boolean;
  };
  afterValueChanged?(value: unknown): void;
  ignoreSpecialChar?: boolean;
}): JSX.Element => {
  const fieldSchema = useYupField(name) as AnySchema;

  const [field, , helpers] = useField<
    Array<string | number> | string | number | undefined
  >(name);
  const [selectedValues, setSelectedValues] = useState<Option[]>([]);
  const [customOptions, setCustomOptions] = useState<Option[]>([]);

  const { enum: enumList, translate } = fieldSchema.meta() as NonNullable<
    BaseSchema["metaInterface"]
  >;
  const { t } = useTranslation(translate ? ([translate[0]] as Namespace) : []);

  const options = useMemo<Option[]>(
    () =>
      enumList
        ? (getStringEnumEntries(enumList).map(([id, name]) => ({
            label: name,
            value: id,
          })) as Option[]).concat(customOptions)
        : [],
    [customOptions, enumList],
  );

  const optionsFromValues = useCallback(
    (values: Array<string | number>) =>
      options.filter((option) => values.includes(option.value)),
    [options],
  );

  const convertString = useCallback((str) => {
    const specialChar = {
      e: ["é", "è", "ê", "ë"],
      a: ["à", "å", "ã"],
      c: ["ç"],
      i: ["î"],
    };
    return str
      .toLowerCase()
      .split("")
      .map((char: string) => {
        for (const [key, value] of Object.entries(specialChar)) {
          if (value.includes(char)) {
            return key;
          }
        }
        return char;
      })
      .join("");
  }, []);

  // CHECK IF HAS TO BE RESET
  useEffect(() => {
    if (enumList) {
      if (
        selectedValues.length > 0 &&
        (field.value === undefined ||
          (Array.isArray(field.value) && field.value.length === 0))
      ) {
        setSelectedValues([]);
      }
      if (Array.isArray(field.value) && field.value.length > 0) {
        const fieldValueAsOptions = optionsFromValues(field.value);
        // CHECK IF ENUM CONTAIN SELECTED OPTIONS
        if (fieldValueAsOptions.length === 0) {
          helpers.setValue([]);
          setSelectedValues([]);
          // CHECK IF SELECTED VALUES NEEDS TO BE UPDATED
        } else if (!deepEqual(fieldValueAsOptions, selectedValues)) {
          setSelectedValues(fieldValueAsOptions);
        }
      } else if (!Array.isArray(field.value)) {
        // CHECK IF ENUM CONTAIN SELECTED OPTION
        if (
          field.value !== undefined &&
          options.every(
            (option) => String(field.value) !== String(option.value),
          )
        ) {
          helpers.setValue(undefined);
          setSelectedValues([]);
        }
        // CHECK IF SELECTED VALUE NEEDS TO BE UPDATED
        else if (
          field.value !== undefined &&
          (selectedValues[0]?.value !== field.value ||
            selectedValues[0]?.label === "")
        ) {
          setSelectedValues([
            {
              value: field.value,
              label: options.find(
                (option) => String(field.value) === String(option.value),
              )!.label,
            },
          ]);
        }
      }
    }
  }, [
    enumList,
    field.value,
    helpers,
    multiselect.active,
    options,
    optionsFromValues,
    selectedValues,
  ]);

  useEffect(() => afterValueChanged && afterValueChanged(field.value), [
    afterValueChanged,
    field.value,
  ]);

  return (
    <MultiSelect
      className={cx("multi-select", className)}
      closeOnChangedValue={!multiselect.active}
      disabled={disabled}
      filterOptions={(options, filter) => {
        if (filter === "") {
          return options;
        }
        return options.filter((option) =>
          ignoreSpecialChar
            ? convertString(option.label).includes(convertString(filter))
            : option.label.toLowerCase().includes(filter.toLowerCase()),
        );
      }}
      hasSelectAll={multiselect.hasSelectAll}
      isCreatable={multiselect.isCreatable}
      labelledBy={name}
      onChange={(selectedOptions: Option[]) => {
        const tempSelectedValues = multiselect.active
          ? selectedOptions
          : selectedOptions.filter(
              (selectedOption) => selectedOption.value !== field.value,
            );
        setSelectedValues(tempSelectedValues);
        helpers.setValue(
          multiselect.active
            ? tempSelectedValues.map((selectValue) => selectValue.value)
            : tempSelectedValues.length > 0
            ? tempSelectedValues[0].value
            : undefined,
        );
      }}
      onCreateOption={(value: string) => {
        const newOption = {
          value: value.replaceAll("-", " "),
          label: value.replaceAll("-", " "),
        };
        setCustomOptions((customOptions) => [...customOptions, newOption]);
        return newOption;
      }}
      options={options}
      overrideStrings={{
        allItemsAreSelected: t(
          `${translate![1]}.allItemsAreSelected` as TFuncKey,
        ) as string,
        clearSearch: t(`${translate![1]}.clearSearch` as TFuncKey) as string,
        clearSelected: t(
          `${translate![1]}.clearSelected` as TFuncKey,
        ) as string,
        noOptions: t(`${translate![1]}.noOptions` as TFuncKey) as string,
        search:
          (t(`${translate![1]}.search` as TFuncKey) as string) +
          (multiselect.isCreatable
            ? " " + (t(`${translate![1]}.orCreate` as TFuncKey) as string)
            : ""),
        selectAll: t(`${translate![1]}.selectAll` as TFuncKey) as string,
        selectAllFiltered: t(
          `${translate![1]}.selectAllFiltered` as TFuncKey,
        ) as string,
        selectSomeItems: t(
          `${translate![1]}.selectSomeItems` as TFuncKey,
        ) as string,
        create: t(`${translate![1]}.create` as TFuncKey) as string,
      }}
      value={selectedValues}
      valueRenderer={(selected) => {
        return selected.length
          ? selected.map(({ label }, index) => (index > 0 ? ", " : "") + label)
          : placeholder;
      }}
    />
  );
};

export default FAutoComplete;
