import { useCallback, useMemo } from "react";
import { useAccounts } from "../../../services/accounts/accountsProvider";
import { isUserCommercial } from "../../../services/auth/user";
import ExchangeListWithFilters from "../../../services/exchange/components/ExchangeListWithFilters";
import { useExchange } from "../../../services/exchange/exchangeProvider";
import { withLoader } from "../../../services/routing/useLoader";
import { useQueryParam } from "../../../services/routing/useQueryParam";
import { exportExchanges } from "src/services/exchange/api";

const AdminExchangeListing = (): JSX.Element => {
  const { accounts } = useAccounts();
  const [selectedUserId] = useQueryParam("userId", "number");

  const commercials = useMemo(() => accounts.filter(isUserCommercial), [
    accounts,
  ]);

  return (
    <div className={"container page-content"}>
      <div className={"page_head"}>
        <h1 className={"page-title"}>Bourse d'échange</h1>
        <div className={"lay-row --gap"}>
          <button
            className={"btn"}
            onClick={() =>
              exportExchanges().then((result) => {
                const blob = new Blob([result.data], {
                  type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const url = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.setAttribute("href", url);
                link.setAttribute("download", "Annonces.xlsx");
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              })
            }
          >
            Exporter les Annonces
          </button>
        </div>
      </div>
      <div className={"page_body"}>
        <ExchangeListWithFilters
          accounts={commercials}
          asAdmin={true}
          filterByUser={selectedUserId || undefined}
        />
      </div>
    </div>
  );
};

const useLoad = () => {
  const { loadAccounts } = useAccounts();
  const { exchanges, loadAll } = useExchange();

  return useCallback(() => {
    // do not reload exchanges for admin, if already fetched
    const promisesArray = exchanges.length
      ? [loadAccounts()]
      : [loadAccounts(), loadAll()];
    return Promise.all(promisesArray);
  }, [exchanges.length, loadAccounts, loadAll]);
};

export default withLoader(AdminExchangeListing, useLoad);
