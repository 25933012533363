import { useCallback, useMemo } from "react";
import ExchangeList from "../../../services/exchange/components/ExchangeList";
import { useExchange } from "../../../services/exchange/exchangeProvider";
import { withLoader } from "../../../services/routing/useLoader";
import { orderByField } from "../../../services/data-structures/array";

const Favorites = (): JSX.Element => {
  const { exchanges } = useExchange();

  const sortedExchanges = useMemo(
    () => [...exchanges].sort(orderByField("createdAt")),
    [exchanges],
  );

  const favoritesExchanges = useMemo(
    () => sortedExchanges.filter((exchange) => exchange.isFavorite),
    [sortedExchanges],
  );

  return (
    <div className={"favorites"}>
      <h2>Mes favoris</h2>
      <div className={"section"}>
        {favoritesExchanges.length ? (
          <ExchangeList exchanges={favoritesExchanges} />
        ) : (
          <div className={"info"}>Vous n'avez aucune offre favorite</div>
        )}
      </div>
    </div>
  );
};

const useLoad = () => {
  const { loadFavorites } = useExchange();
  return useCallback(() => loadFavorites(), [loadFavorites]);
};

export default withLoader(Favorites, useLoad);
