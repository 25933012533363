import React, { useCallback, useMemo } from "react";
import { AuthAPIConnected, useAuth } from "../../../services/auth/apiProvider";
import InfoRequestListItem from "../../../services/info-request/components/InfoRequestListItem";
import { useInfoRequest } from "../../../services/info-request/infoRequestProvider";
import { withLoader } from "../../../services/routing/useLoader";
import { orderByField } from "../../../services/data-structures/array";

const InfoRequestListing = (): JSX.Element => {
  const { infoRequests } = useInfoRequest();

  const sortedInfoRequests = useMemo(
    () => [...infoRequests].sort(orderByField("updatedAt")),
    [infoRequests],
  );

  return (
    <div>
      <h2>Demandes de renseignements</h2>
      <div className={"section"}>
        {sortedInfoRequests.length ? (
          <div>
            {sortedInfoRequests.map((infoRequest) => (
              <InfoRequestListItem
                key={infoRequest.id}
                infoRequest={infoRequest}
              />
            ))}
          </div>
        ) : (
          <div className={"info"}>
            Vous n'avez aucune demande de renseignement
          </div>
        )}
      </div>
    </div>
  );
};

const useLoad = () => {
  const { loadInfoRequests } = useInfoRequest();
  const { user } = useAuth() as AuthAPIConnected;

  return useCallback(() => loadInfoRequests(user.id), [
    loadInfoRequests,
    user.id,
  ]);
};

export default withLoader(InfoRequestListing, useLoad);
