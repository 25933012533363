import React, { Dispatch, SetStateAction, useState } from "react";
import { PRIVATE_CONTACT_LINK } from "../../../routes/private";
import { PUBLIC_CONTACT_LINK } from "../../../routes/public";
import { useAuth } from "../../auth/apiProvider";
import { ConfigKey } from "../../auth/config";
import { ContactType } from "../../auth/user";
import { comparePlan, Plan, PlanComparaison } from "../../plan/plan";
import Link from "../../routing/components/Link";
import { cx } from "@emotion/css";
import { toFixed } from "../../numbers/formatting";

const PlansTable = ({
  plans,
  setShowPlanChoiceDialog,
}: {
  plans: Plan[];
  setShowPlanChoiceDialog?: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const { user, configs } = useAuth();

  const [isMonthlyPrice, setIsMonthlyPrice] = useState(true);

  return (
    <div className={"section-xl"}>
      <div className={"page-title"}>Nos abonnements</div>
      <div className={"section"}>
        <button
          className={cx("btn-toggle title-xxs", !isMonthlyPrice && "toggle-on")}
          onClick={() => setIsMonthlyPrice((prev) => !prev)}
        >
          {isMonthlyPrice ? "Engagement mensuel" : "Engagement annuel"}
        </button>

        <div className={"table-container"}>
          <table className={"table"}>
            <thead>
              <tr>
                <th />
                {plans.map((plan) => (
                  <th key={`label-${plan.id}`}>
                    {plan.label.replaceAll("_", " ")}
                  </th>
                ))}
                <th>Sur mesure</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Prix mensuel (HT)</td>
                {plans.map(
                  (plan) =>
                    plan.monthlyPrice !== null &&
                    (plan.monthlyPrice > 0 ? (
                      <td key={`price-${plan.id}`}>
                        <p>
                          {isMonthlyPrice ? (
                            <>{plan.monthlyPrice} € /mois</>
                          ) : (
                            <>{plan.annualMonthlyPrice} € /mois</>
                          )}
                        </p>
                      </td>
                    ) : (
                      <td key={`free-${plan.id}`}>Gratuit</td>
                    )),
                )}
                <td>Nous contacter</td>
              </tr>

              <tr>
                <td>Consulter les annonces</td>
                {plans.map((plan) => (
                  <td key={`consult-${plan.id}`}>illimité</td>
                ))}
                <td>illimité</td>
              </tr>

              <tr>
                <td>Faire des offres</td>
                {plans.map((plan) =>
                  plan.maxExchangeOfferDailyNb < 0 ? (
                    <td key={`makeoffer-unlimited-${plan.id}`}>illimité</td>
                  ) : (
                    <td key={`makeoffer-${plan.id}`}>
                      {plan.maxExchangeOfferDailyNb} / jour
                    </td>
                  ),
                )}
                <td>sur mesure</td>
              </tr>

              <tr>
                <td>Déposer des annonces</td>
                {plans.map((plan) =>
                  plan.maxExchangePostNb === 0 ? (
                    <td key={`publish-cost-${plan.id}`}>
                      {plan.postEmergencyPrice}€ / annonce
                    </td>
                  ) : (
                    <td key={`publish-nb-${plan.id}`}>
                      {plan.maxExchangePostNb}
                    </td>
                  ),
                )}
                <td>sur mesure</td>
              </tr>

              <tr>
                <td>Annonces supplémentaires</td>
                {plans.map((plan) => (
                  <td key={`emergency-publish-cost-${plan.id}`}>
                    {plan.postEmergencyPrice}€ / annonce
                  </td>
                ))}
                <td>sur mesure</td>
              </tr>

              <tr>
                <td>Commission Autolity sur vente (0-5K€)</td>
                {plans.map((plan) => (
                  <td key={`taxe-1-${plan.id}`}>
                    {plan.type !== 0
                      ? configs![ConfigKey.SELLING_FEES_SUBSCRIPTION_RANGE1]
                      : configs![ConfigKey.SELLING_FEES_RANGE1]}{" "}
                    €
                  </td>
                ))}
                <td>
                  {configs![ConfigKey.SELLING_FEES_SUBSCRIPTION_RANGE1]} €{" "}
                </td>
              </tr>

              <tr>
                <td>Commission Autolity sur vente (5-12K€)</td>
                {plans.map((plan) => (
                  <td key={`taxe-2-${plan.id}`}>
                    {plan.type !== 0
                      ? configs![ConfigKey.SELLING_FEES_SUBSCRIPTION_RANGE2]
                      : configs![ConfigKey.SELLING_FEES_RANGE2]}{" "}
                    €
                  </td>
                ))}
                <td>
                  {configs![ConfigKey.SELLING_FEES_SUBSCRIPTION_RANGE2]} €{" "}
                </td>
              </tr>

              <tr>
                <td>Commission Autolity sur vente (12-30K€)</td>
                {plans.map((plan) => (
                  <td key={`taxe-3-${plan.id}`}>
                    {plan.type !== 0
                      ? configs![ConfigKey.SELLING_FEES_SUBSCRIPTION_RANGE3]
                      : configs![ConfigKey.SELLING_FEES_RANGE3]}{" "}
                    €
                  </td>
                ))}
                <td>
                  {configs![ConfigKey.SELLING_FEES_SUBSCRIPTION_RANGE3]} €{" "}
                </td>
              </tr>

              <tr>
                <td>Commission Autolity sur vente (30-50K€)</td>
                {plans.map((plan) => (
                  <td key={`taxe-3-${plan.id}`}>
                    {plan.type !== 0
                      ? configs![ConfigKey.SELLING_FEES_SUBSCRIPTION_RANGE4]
                      : configs![ConfigKey.SELLING_FEES_RANGE4]}{" "}
                    €
                  </td>
                ))}
                <td>
                  {configs![ConfigKey.SELLING_FEES_SUBSCRIPTION_RANGE4]} €{" "}
                </td>
              </tr>

              <tr>
                <td>Commission Autolity sur vente (50-80K€)</td>
                {plans.map((plan) => (
                  <td key={`taxe-3-${plan.id}`}>
                    {plan.type !== 0
                      ? configs![ConfigKey.SELLING_FEES_SUBSCRIPTION_RANGE5]
                      : configs![ConfigKey.SELLING_FEES_RANGE5]}{" "}
                    €
                  </td>
                ))}
                <td>
                  {configs![ConfigKey.SELLING_FEES_SUBSCRIPTION_RANGE5]} €{" "}
                </td>
              </tr>

              <tr>
                <td>Commission Autolity sur vente (80-100K€)</td>
                {plans.map((plan) => (
                  <td key={`taxe-3-${plan.id}`}>
                    {plan.type !== 0
                      ? configs![ConfigKey.SELLING_FEES_SUBSCRIPTION_RANGE6]
                      : configs![ConfigKey.SELLING_FEES_RANGE6]}{" "}
                    €
                  </td>
                ))}
                <td>
                  {configs![ConfigKey.SELLING_FEES_SUBSCRIPTION_RANGE6]} €{" "}
                </td>
              </tr>

              <tr>
                <td>Commission Autolity sur vente (100-150K€)</td>
                {plans.map((plan) => (
                  <td key={`taxe-3-${plan.id}`}>
                    {plan.type !== 0
                      ? configs![ConfigKey.SELLING_FEES_SUBSCRIPTION_RANGE7]
                      : configs![ConfigKey.SELLING_FEES_RANGE7]}{" "}
                    €
                  </td>
                ))}
                <td>
                  {configs![ConfigKey.SELLING_FEES_SUBSCRIPTION_RANGE7]} €{" "}
                </td>
              </tr>

              <tr>
                <td>Commission Autolity sur vente (+150K€)</td>
                {plans.map((plan) => (
                  <td key={`taxe-4-${plan.id}`}>
                    {plan.type !== 0
                      ? configs![ConfigKey.SELLING_FEES_SUBSCRIPTION_RANGE8]
                      : configs![ConfigKey.SELLING_FEES_RANGE8]}{" "}
                    €
                  </td>
                ))}
                <td>
                  {configs![ConfigKey.SELLING_FEES_SUBSCRIPTION_RANGE8]} €{" "}
                </td>
              </tr>
            </tbody>

            {setShowPlanChoiceDialog && (
              <tr>
                <td />
                {plans.map((plan) => {
                  const planCompraison = comparePlan(user?.Plan || null, plan);
                  if (planCompraison === PlanComparaison.isEqual) {
                    return (
                      <td key={`select-current-${plan.id}`}>
                        Votre plan actuel
                      </td>
                    );
                  }
                  if (planCompraison === PlanComparaison.isSuperior) {
                    return (
                      <td key={`select-superior-${plan.id}`}>
                        Votre forfait est supérieur
                      </td>
                    );
                  }
                  if (planCompraison === PlanComparaison.isLower) {
                    return (
                      <td key={`select-${plan.id}`}>
                        <button
                          className={"btn-1"}
                          onClick={() => setShowPlanChoiceDialog(true)}
                          type={"button"}
                        >
                          Souscrire
                        </button>
                      </td>
                    );
                  }
                  return null;
                })}
                <td>
                  <Link
                    queryParams={{
                      type: ContactType.SUBSCRIPTION,
                      subject: "Abonnement sur mesure",
                      message: "Je souhaite un devis sur mesure. ",
                    }}
                    to={user ? PRIVATE_CONTACT_LINK : PUBLIC_CONTACT_LINK}
                  >
                    Nous contacter
                  </Link>
                </td>
              </tr>
            )}
          </table>
        </div>
        <p className={"body-xs"}>*Tous les prix sont indiqués hors taxe.</p>
      </div>

      <div className={"lay-col --gap"}>
        <div className={"info"}>
          <p>
            Une option BUYER est disponible sur les abonnements DIFFUSION pour{" "}
            <strong>
              {isMonthlyPrice ? (
                <>{configs![ConfigKey.PLAN_OPTION_BUYER_PRICE]} € /mois</>
              ) : (
                <>
                  {toFixed(
                    Number(configs![ConfigKey.PLAN_OPTION_BUYER_PRICE]) / 1.15,
                    0,
                  )}{" "}
                  € /mois
                </>
              )}
            </strong>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default PlansTable;
