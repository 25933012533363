import { Exchange } from "./exchange";

export interface ExchangeFormBrandOptionBase {
  id: string;
  value: string;
}

export interface ExchangeFormBrandOption extends ExchangeFormBrandOptionBase {
  Models: ExchangeFormModelOption[];
}

export interface ExchangeFormModelOptionBase {
  id: string;
  value: string;
}

export interface ExchangeFormModelOption extends ExchangeFormModelOptionBase {
  SubModels: ExchangeFormSubModelOption[];
  metaModel: string;
}

export interface ExchangeFormSubModelOptionBase {
  id: string;
  value: string;
}

export interface ExchangeFormSubModelOption
  extends ExchangeFormSubModelOptionBase {}

export interface ExchangeFormEngineOption {
  id: string;
  value: string;
}

export interface FormOptionMultiSelector {
  label: string;
  value: string;
}

export function mapExchangeFormBrandOptionToBrandMultiSelector(
  exchangeFormBrandOptions: ExchangeFormBrandOption[],
): Array<FormOptionMultiSelector> {
  return exchangeFormBrandOptions.map((exchangeFormBrandOption) => ({
    value: exchangeFormBrandOption.id,
    label: exchangeFormBrandOption.value,
  }));
}

export function mapExchangeFormBrandOptionToModelMultiSelector(
  exchangeFormBrandOptions: ExchangeFormBrandOption[],
  brands: Array<Exchange["vehicleBrand"]>,
): Array<FormOptionMultiSelector> {
  return exchangeFormBrandOptions.flatMap((exchangeFormBrandOption) =>
    !!brands.find((brand) => brand === exchangeFormBrandOption.id)
      ? exchangeFormBrandOption.Models.map((exchangeFormModelOption) => ({
          value: `${exchangeFormModelOption.id}`,
          label: exchangeFormModelOption.value,
        }))
      : [],
  );
}

export function mapExchangeFormBrandOptionToMetaModelMultiSelector(
  exchangeFormBrandOptions: ExchangeFormBrandOption[],
  brands: Array<Exchange["vehicleBrand"]>,
): Array<FormOptionMultiSelector> {
  return exchangeFormBrandOptions.flatMap((exchangeFormBrandOption) =>
    !!brands.find((brand) => brand === exchangeFormBrandOption.id)
      ? exchangeFormBrandOption.Models.map((exchangeFormModelOption) => ({
          value: exchangeFormModelOption.metaModel,
          label: exchangeFormModelOption.metaModel,
        }))
      : [],
  );
}

export function mapExchangeFormBrandOptionToSubModelMultiSelector(
  exchangeFormBrandOptions: ExchangeFormBrandOption[],
  models: Array<Exchange["vehicleModel"]>,
): Array<FormOptionMultiSelector> {
  return exchangeFormBrandOptions.flatMap((exchangeFormBrandOption) =>
    exchangeFormBrandOption.Models.flatMap((exchangeFormModelOption) =>
      !!models.find((model) => model === exchangeFormModelOption.id)
        ? exchangeFormModelOption.SubModels.map(
            (exchangeFormSubModelOption) => ({
              value: `${exchangeFormSubModelOption.id}`,
              label: exchangeFormSubModelOption.value,
            }),
          )
        : [],
    ),
  );
}

export function mapExchangeFormEngineOptionToEngineMultiSelector(
  exchangeFormEngineOptions: ExchangeFormEngineOption[],
): Array<FormOptionMultiSelector> {
  return exchangeFormEngineOptions.map((exchangeFormEngineOption) => ({
    value: exchangeFormEngineOption.id,
    label: exchangeFormEngineOption.value,
  }));
}

export function mapExchangeFormCountryOptionToCountryMultiSelector(
  countries: string[],
  originCountry: Array<Exchange["vehicleCountryCode"]>,
  countryList: Record<string, string>,
): Array<FormOptionMultiSelector> {
  return countries.flatMap((country) =>
    !!originCountry.map((code) => country === code)
      ? countries.map((country) => {
          return {
            value: country,
            label: countryList[`${country}`],
          };
        })
      : [],
  );
}
