import { Elements } from "@stripe/react-stripe-js";
import React from "react";
import { hostBaseURL } from "../../auth/config";
import LoaderErrors from "../../routing/components/LoaderErrors";
import Loading from "../../routing/components/Loading";
import generatePath from "../../routing/generatePath";
import useLoader from "../../routing/useLoader";
import CardBody from "../../ui/block/CardBody";
import CardHead from "../../ui/block/CardHead";
import Dialog from "../../ui/block/Dialog";
import Section from "../../ui/block/Section";
import { SubscriptionIntentForm } from "../payment";
import { usePayment, withProvidePayment } from "../paymentProvider";
import { stripe } from "../stripeConfig";
import StripeForm from "./StripeForm";

interface Props {
  onClose: () => void;
  redirectPath?: string;
  redirectAction?: () => Promise<void>;
  subscriptionIntentForm: SubscriptionIntentForm;
  priceWithoutDiscount: number;
  trialDuration: number;
}

const StripeSubscriptionDialog = ({
  onClose,
  redirectPath,
  redirectAction,
  subscriptionIntentForm,
  priceWithoutDiscount,
  trialDuration,
}: Props): JSX.Element => {
  const {
    createSubscriptionIntent,
    startPaidSubscriptionIntent,
    paymentIntent,
  } = usePayment();

  const { loading, error, reload } = useLoader(
    () => createSubscriptionIntent(subscriptionIntentForm),
    [createSubscriptionIntent, subscriptionIntentForm],
  );

  return (
    <Dialog onClose={onClose}>
      <CardHead title={"Paiement abonnement"} />
      <CardBody>
        <Section>
          {loading ? (
            <Loading text={"Nous préparons votre paiement"} />
          ) : error ? (
            error === 409 ? (
              <>
                <p>
                  Vous possédez déjà cet abonnement ou le paiement est déjà en
                  cours.
                </p>
                <button onClick={onClose}>Quitter</button>
              </>
            ) : (
              <LoaderErrors error={error} reload={reload} />
            )
          ) : (
            <Elements
              options={{ clientSecret: paymentIntent!.clientSecret }}
              stripe={stripe}
            >
              <StripeForm
                actionBeforeCharge={() =>
                  startPaidSubscriptionIntent(paymentIntent!.subscriptionId!)
                }
                priceWithoutDiscount={priceWithoutDiscount}
                redirectAction={redirectAction}
                redirectUrl={
                  redirectPath
                    ? `${hostBaseURL}${generatePath(
                        redirectPath,
                        {},
                        { subscribeRedirect: "true" },
                      )}`
                    : undefined
                }
                trialDuration={trialDuration}
                isSubscription
              />
            </Elements>
          )}
        </Section>
      </CardBody>
    </Dialog>
  );
};

export default withProvidePayment(StripeSubscriptionDialog);
