import {
  InfoRequest,
  InfoRequestDocument,
  InfoRequestType,
} from "../infoRequest";
import Dialog from "../../ui/block/Dialog";
import SectionTitle from "../../ui/descriptive/SectionTitle";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import DocumentListItem from "./DocumentListItem";
import { AuthAPIConnected, useAuth } from "../../auth/apiProvider";

const ResponseInfoRequestDialog = ({
  onClose,
  infoRequest,
}: {
  onClose: () => void;
  infoRequest: InfoRequest;
}): JSX.Element => {
  const { user } = useAuth() as AuthAPIConnected;
  const { t } = useTranslation(["info_request"]);
  const [invoiceDocuments, pictureDocuments, videoDocuments] = useMemo(
    () =>
      Object.values(InfoRequestType).map((documentType) =>
        infoRequest.InfoRequestDocuments.filter(
          (document) => document.type === documentType,
        ),
      ),
    [infoRequest.InfoRequestDocuments],
  );

  const renderDocuments = useCallback((documents: InfoRequestDocument[]) => {
    return documents.map((document) => (
      <DocumentListItem key={document.id} document={document} />
    ));
  }, []);

  return (
    <Dialog onClose={onClose}>
      <div className={"card"}>
        <div className={"card-head"}>
          <h2 className={"card-title"}>Documents</h2>
        </div>
        <div className={"card-body lay-col --gap-v"}>
          {infoRequest.invoice && (
            <>
              <SectionTitle title={t("info_request:invoice_request")} />
              <div className={"documents-list"}>
                {renderDocuments(invoiceDocuments)}
              </div>
            </>
          )}
          {infoRequest.picture && (
            <>
              <SectionTitle title={t("info_request:picture_request")} />

              <div className={"documents-list"}>
                {renderDocuments(pictureDocuments)}
              </div>
            </>
          )}
          {infoRequest.video && (
            <>
              <SectionTitle title={t("info_request:video_request")} />

              <div className={"documents-list"}>
                {renderDocuments(videoDocuments)}
              </div>
            </>
          )}
          {infoRequest.weproov && (
            <>
              <SectionTitle title={t("info_request:weproov_request")} />
              {!infoRequest.Exchange.weproovReportLink &&
                infoRequest.Exchange.UserId === user.id && (
                  <p className={"body-s"}>
                    Demande en cours de traitement
                    <br />
                    Code WeProov : <strong>{infoRequest.weproovCode}</strong>
                  </p>
                )}
              <div className={"documents-list"}>
                {infoRequest.Exchange.weproovReportLink && (
                  <div className={"file"}>
                    <a
                      className={"file-name"}
                      href={infoRequest.Exchange.weproovReportLink}
                      rel={"noreferrer"}
                      target={"_blank"}
                    >
                      Rapport WeProov
                    </a>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default ResponseInfoRequestDialog;
