import { TFuncKey, useTranslation } from "react-i18next";
import Dialog from "../../ui/block/Dialog";
import { Offer } from "../offer";
import OfferFeeBlock from "./OfferFeeBlock";
import { useAuth } from "../../auth/apiProvider";
import OfferConditionsDetailsButton from "src/services/files/OfferConditionsDetailsButton";

const OfferFeeBlockDialog = ({
  onClose,
  offer,
}: {
  onClose: () => void;
  offer: Offer;
}): JSX.Element => {
  const { t } = useTranslation(["offer"]);
  const { user } = useAuth();

  return (
    <Dialog onClose={onClose}>
      <div className={"card-head"}>
        <h2>Détail de l'offre</h2>
      </div>
      <div className={"card-body lay-col --gap--s"}>
        <div>
          <p>
            Option d'achat:{" "}
            <strong>
              {t(
                `offer:buying_option.${offer.buyingOption}` as TFuncKey<
                  "offer",
                  ""
                >,
              )}
            </strong>
          </p>
          <p>
            Option de paiement:{" "}
            <strong>
              {t(
                `offer:payment_option.${offer.paymentOption}` as TFuncKey<
                  "offer",
                  ""
                >,
              )}
            </strong>
          </p>
          <div>
            <OfferConditionsDetailsButton offer={offer} />
          </div>
        </div>
        {(user?.superAdmin || offer.UserId === user?.id) && (
          <OfferFeeBlock
            amount={offer.Prices[0].amount}
            buyingOption={offer.buyingOption}
            paymentOption={offer.paymentOption}
            showExchangeTotalPrice
          />
        )}
      </div>
    </Dialog>
  );
};

export default OfferFeeBlockDialog;
