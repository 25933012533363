import React, { useCallback, useMemo, useState } from "react";
import { AuthAPIConnected, useAuth } from "../../auth/apiProvider";
import Loading from "../../routing/components/Loading";
import { withLoader } from "../../routing/useLoader";
import CardBody from "../../ui/block/CardBody";
import CardHead from "../../ui/block/CardHead";
import Dialog from "../../ui/block/Dialog";
import { useExchange } from "../exchangeProvider";
import ReplaceExchangeItem from "./ReplaceExchangeItem";

interface Props {
  onClose: () => void;
  onPublish: () => Promise<void>;
}

const ReplaceExchangeDialog = ({ onClose, onPublish }: Props): JSX.Element => {
  const { user } = useAuth() as AuthAPIConnected;
  const { unpublishExchange } = useExchange();
  const [loading, setLoading] = useState(false);

  const allowedUnpublishExchanges = useMemo(
    () =>
      user.Exchanges.filter(
        (exchange) =>
          exchange.publicationEndDate && exchange.Offers.length === 0,
      ),
    [user.Exchanges],
  );

  return (
    <Dialog className={"dialog-card-full"} onClose={onClose}>
      <CardHead title={"Remplacer une annonce"} />
      <CardBody>
        {allowedUnpublishExchanges.length === 0 && (
          <p>Vous n'avez pas d'annonce dépubliable</p>
        )}
        {allowedUnpublishExchanges.map((exchange) => (
          <ReplaceExchangeItem
            key={exchange.id}
            exchange={exchange}
            onClick={async (exchange) => {
              setLoading(true);
              try {
                await unpublishExchange(exchange.id);
                await onPublish();
              } catch (e) {}
              setLoading(false);
            }}
          />
        ))}
        {loading && <Loading />}
      </CardBody>
    </Dialog>
  );
};

const useLoad = () => {
  const { loadFormOptions } = useExchange();

  return useCallback(loadFormOptions, [loadFormOptions]);
};

export default withLoader(ReplaceExchangeDialog, useLoad);
