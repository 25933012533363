import { ADMIN_DASHBOARD_LINK } from "./private";

export const ADMIN_SUMMARY = "/summary" as const;
export const ADMIN_SUMMARY_LINK = `${ADMIN_DASHBOARD_LINK}${ADMIN_SUMMARY}` as const;

export const ADMIN_ACCOUNTS_MANAGEMENT = "/accounts" as const;
export const ADMIN_ACCOUNTS_MANAGEMENT_LINK = `${ADMIN_DASHBOARD_LINK}${ADMIN_ACCOUNTS_MANAGEMENT}` as const;

export const ADMIN_ACCOUNT_LISTING = "/account-listing" as const;
export const ADMIN_ACCOUNT_LISTING_LINK = `${ADMIN_DASHBOARD_LINK}${ADMIN_ACCOUNT_LISTING}` as const;

export const ADMIN_EXCHANGE_LISTING = "/exchange-listing" as const;
export const ADMIN_EXCHANGE_LISTING_LINK = `${ADMIN_DASHBOARD_LINK}${ADMIN_EXCHANGE_LISTING}` as const;

export const ADMIN_PLANS = "/plans" as const;
export const ADMIN_PLANS_LINK = `${ADMIN_DASHBOARD_LINK}${ADMIN_PLANS}` as const;

export const ADMIN_OFFERS = "/offers" as const;
export const ADMIN_OFFERS_LINK = `${ADMIN_DASHBOARD_LINK}${ADMIN_OFFERS}` as const;
