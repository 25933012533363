/* eslint-disable i18next/no-literal-string */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import AUTH_FR from "../auth/i18n/fr.json";
import VALIDATIONS_FR from "../validations/i18n/fr.json";
import EXCHANGE_FR from "../exchange/i18n/fr.json";
import PAYMENT_FR from "../payment/i18n/fr.json";
import OFFER_FR from "../offer/i18n/fr.json";
import COMMON_FR from "./i18n/fr.json";
import INFO_REQUEST_FR from "../info-request/i18n/fr.json";
import PLAN_FR from "../plan/i18n/fr.json";
import { setLocale } from "yup";

i18n.use(initReactI18next).init({
  ns: [
    "auth",
    "validations",
    "exchange",
    "payment",
    "offer",
    "common",
    "info_request",
    "plan",
  ],
  resources: {
    fr: {
      auth: AUTH_FR,
      validations: VALIDATIONS_FR,
      exchange: EXCHANGE_FR,
      payment: PAYMENT_FR,
      offer: OFFER_FR,
      common: COMMON_FR,
      info_request: INFO_REQUEST_FR,
      plan: PLAN_FR,
    },
  },
  lng: "fr",
  fallbackLng: "fr",

  interpolation: {
    escapeValue: false,
  },
});

export interface ValidationError {
  key: string;
  values: Record<string, unknown>;
}

function getError(key: string) {
  return (values: unknown) => ({ key, values } as ValidationError);
}

setLocale({
  mixed: {
    default: getError("invalid_field"),
    notType(props) {
      if (props.type === "date") return getError("required")(props);
      else return getError("invalid_field")(props);
    },
    required: getError("required"),
    defined: getError("defined"),
    oneOf: getError("one_of"),
  },
  string: {
    email: getError("user.email.invalid"),
    matches: getError("matches"),
    min: getError("string.min"),
    max: getError("string.max"),
  },
  number: {
    positive: getError("positive"),
    min: getError("min"),
    max: getError("max"),
  },
  date: {
    max: getError("date:GENERIC_MAX"),
    min: getError("date:GENERIC_MIN"),
  },
  array: {
    min: getError("array:min"),
  },
});

export default i18n;
