import { Link } from "react-router-dom";
import { NEW_EXCHANGE_VEHICLE_LINK } from "../../../routes/exchange";
import { useExchange } from "../../../services/exchange/exchangeProvider";
import { withLoader } from "../../../services/routing/useLoader";
import ExchangeListWithFilters from "../../../services/exchange/components/ExchangeListWithFilters";
import { AuthAPIConnected, useAuth } from "../../../services/auth/apiProvider";
import { DASHBOARD_LINK } from "src/routes/private";
import { useCallback } from "react";

const ExchangeListing = (): JSX.Element => {
  const { user } = useAuth() as AuthAPIConnected;

  return (
    <div className={"container page-content"}>
      <div className={"page_head"}>
        <h1 className={"page-title"}>Bourse d'échange</h1>
        {user.isValid && (
          <div className={"lay-row --gap"}>
            <Link className={"btn--outlined"} to={DASHBOARD_LINK}>
              Gérer mes annonces
            </Link>
            <Link className={"btn"} to={NEW_EXCHANGE_VEHICLE_LINK}>
              Déposer une annonce
            </Link>
          </div>
        )}
      </div>
      <div className={"page_body"}>
        <ExchangeListWithFilters asAdmin={false} />
      </div>
    </div>
  );
};

const useLoad = () => {
  const { exchanges, loadAll } = useExchange();
  return useCallback(() => {
    const promiseArray = exchanges.length ? [] : [loadAll()];
    return Promise.all(promiseArray);
  }, [exchanges.length, loadAll]);
};

export default withLoader(ExchangeListing, useLoad);
