import { AxiosPromise } from "axios";
import baseAPI from "../auth/api";
import { User } from "../auth/user";
import { Plan, PlanForm } from "./plan";

export function getAllPlans(): AxiosPromise<Plan[]> {
  return baseAPI.get("/plans");
}

export function createPlan(planForm: PlanForm): AxiosPromise<Plan> {
  return baseAPI.post("/plans", planForm);
}

export function assignPlan(
  planId: Plan["id"],
  userId: User["id"],
): AxiosPromise<Plan> {
  return baseAPI.post(`/plans/${planId}/assignation/${userId}`);
}

export function unassignPlan(
  planId: Plan["id"],
  userId: User["id"],
): AxiosPromise<Plan> {
  return baseAPI.delete(`/plans/${planId}/assignation/${userId}`);
}
