import React, { useCallback, useMemo } from "react";
import SentOfferListItem from "../../../services/offer/components/SentOfferListItem";
import { useOffer } from "../../../services/offer/offerProvider";
import { withLoader } from "../../../services/routing/useLoader";
import { orderByField } from "../../../services/data-structures/array";

const SentOffers = (): JSX.Element => {
  const { sentOffers } = useOffer();

  const sortedOffers = useMemo(
    () => [...sentOffers].sort(orderByField("updatedAt")),
    [sentOffers],
  );

  return (
    <div>
      <h2>Mes offres émises</h2>
      <div className={"section"}>
        {sortedOffers.length ? (
          <>
            {sortedOffers.map((offer) => (
              <SentOfferListItem key={offer.id} offer={offer} />
            ))}
          </>
        ) : (
          <div className={"info"}>Vous n'avez aucune offre émise</div>
        )}
      </div>
    </div>
  );
};

const useLoad = () => {
  const { loadSentOffers } = useOffer();

  return useCallback(() => loadSentOffers(), [loadSentOffers]);
};

export default withLoader(SentOffers, useLoad);
