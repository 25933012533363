import { AuthAPIConnected, useAuth } from "../auth/apiProvider";
import { ConfigKey } from "../auth/config";
import { UserRaw, User, mapUserRawToUser } from "../auth/user";
import {
  FullExchange,
  FullExchangeRaw,
  mapRawExchangeToExchange,
} from "../exchange/exchange";

export interface Offer {
  id: number;
  status: OfferStatus;
  bestOffersCount: number;
  bestOfferAmount: Price["amount"] | null;
  buyingOption: ConfigKey;
  paymentOption: ConfigKey;
  UserId: User["id"];
  Prices: Price[];
  Exchange: FullExchange;
  updatedAt: Date;
}

export interface OfferRaw extends Omit<Offer, "Exchange"> {
  Exchange: FullExchangeRaw;
}

export interface FullOfferRaw extends OfferRaw {
  User: UserRaw;
}

export interface FullOffer extends Offer {
  User: User;
}

export interface OfferForm {
  amount: Price["amount"];
  buyingOption: Offer["buyingOption"];
  paymentOption: Offer["paymentOption"];
}

export enum OfferStatus {
  "STATUS_PENDING" = "P",
  "STATUS_DECLINED" = "D",
  "STATUS_CANCELED" = "C",
  "STATUS_ACCEPTED" = "A",
  "STATUS_VALIDATED" = "V",
}

export interface Price {
  amount: number;
  origin: PriceOrigin;
  UserId: User["id"];
}

export enum PriceOrigin {
  ORIGIN_SELLER = "S",
  ORIGIN_BUYER = "B",
}

export interface OfferFees {
  autolityFees: number;
  fileFees: number;
  additionnalFileFees: number;
}

export const useOfferFeesFromAmount = (
  amount: number | null,
  buyingOptionKey: ConfigKey | null,
  paymentOptionKey: ConfigKey | null,
): OfferFees => {
  const { user, configs } = useAuth() as AuthAPIConnected;

  const isSubscribe = user.Plan?.type !== 0;

  let sellingFeesKey: ConfigKey | undefined;
  let fileFeesKey: ConfigKey | undefined;
  if (amount) {
    if (amount < 5000) {
      sellingFeesKey = isSubscribe
        ? ConfigKey.SELLING_FEES_SUBSCRIPTION_RANGE1
        : ConfigKey.SELLING_FEES_RANGE1;
      fileFeesKey = ConfigKey.FILE_FEES_RANGE1;
    } else if (amount < 12000) {
      sellingFeesKey = isSubscribe
        ? ConfigKey.SELLING_FEES_SUBSCRIPTION_RANGE2
        : ConfigKey.SELLING_FEES_RANGE2;
      fileFeesKey = ConfigKey.FILE_FEES_RANGE2;
    } else if (amount < 30000) {
      sellingFeesKey = isSubscribe
        ? ConfigKey.SELLING_FEES_SUBSCRIPTION_RANGE3
        : ConfigKey.SELLING_FEES_RANGE3;
      fileFeesKey = ConfigKey.FILE_FEES_RANGE3;
    } else if (amount < 50000) {
      sellingFeesKey = isSubscribe
        ? ConfigKey.SELLING_FEES_SUBSCRIPTION_RANGE4
        : ConfigKey.SELLING_FEES_RANGE4;
      fileFeesKey = ConfigKey.FILE_FEES_RANGE4;
    } else if (amount < 80000) {
      sellingFeesKey = isSubscribe
        ? ConfigKey.SELLING_FEES_SUBSCRIPTION_RANGE5
        : ConfigKey.SELLING_FEES_RANGE5;
      fileFeesKey = ConfigKey.FILE_FEES_RANGE5;
    } else if (amount < 100000) {
      sellingFeesKey = isSubscribe
        ? ConfigKey.SELLING_FEES_SUBSCRIPTION_RANGE6
        : ConfigKey.SELLING_FEES_RANGE6;
      fileFeesKey = ConfigKey.FILE_FEES_RANGE6;
    } else if (amount < 150000) {
      sellingFeesKey = isSubscribe
        ? ConfigKey.SELLING_FEES_SUBSCRIPTION_RANGE7
        : ConfigKey.SELLING_FEES_RANGE7;
      fileFeesKey = ConfigKey.FILE_FEES_RANGE7;
    } else {
      sellingFeesKey = isSubscribe
        ? ConfigKey.SELLING_FEES_SUBSCRIPTION_RANGE8
        : ConfigKey.SELLING_FEES_RANGE8;
      fileFeesKey = ConfigKey.FILE_FEES_RANGE8;
    }
  }

  const autolityFees = sellingFeesKey
    ? parseFloat(configs[sellingFeesKey] || "0")
    : 0;
  const fileFees = fileFeesKey ? parseFloat(configs[fileFeesKey] || "0") : 0;
  const additionnalFileFees =
    (buyingOptionKey ? parseFloat(configs[buyingOptionKey] || "0") : 0) +
    (paymentOptionKey ? parseFloat(configs[paymentOptionKey] || "0") : 0);

  return { autolityFees, fileFees, additionnalFileFees };
};

export function mapRawOfferToOffer(offerRaw: OfferRaw): Offer;
export function mapRawOfferToOffer(offerRaw: FullOfferRaw): FullOffer;
export function mapRawOfferToOffer(
  offerRaw: OfferRaw | FullOfferRaw,
): Offer | FullOffer {
  return {
    ...offerRaw,
    Exchange: mapRawExchangeToExchange(offerRaw.Exchange),
    User: "User" in offerRaw ? mapUserRawToUser(offerRaw.User) : undefined,
  };
}
