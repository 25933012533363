import React, { useCallback } from "react";
import { AuthAPIConnected, useAuth } from "../../../services/auth/apiProvider";
import AccountValidationInfo from "../../components/AccountValidationInfo";
import ProfileForm from "../../../services/auth/components/ProfileForm";
import AttachmentForms from "../../../services/auth/components/AttachmentForms";
import PlanCard from "../../../services/plan/components/PlanCard";
import { withLoader } from "../../../services/routing/useLoader";
import { usePlan } from "../../../services/plan/planProvider";
import { Link } from "react-router-dom";
import { UPDATE_PASSWORD_LINK } from "../../../routes/profile";
import {
  usePayment,
  withProvidePayment,
} from "../../../services/payment/paymentProvider";
import { useDate } from "../../../services/date/DateContext";
import { useConfirmationWithIntl } from "../../../services/ui/ConfirmationDialog";
import { useToastsWithIntl } from "../../../services/toast-notifications";
import FileItem from "../../../services/ui/FileItem";

const Account = (): JSX.Element => {
  const {
    user,
    updateUser,
    updateKyc,
    askAccountDeletion,
  } = useAuth() as AuthAPIConnected;
  const { unsubscribeStripePlan } = usePayment();
  const { formatDate } = useDate();
  const { confirm } = useConfirmationWithIntl(["payment", "auth"]);
  const { toastError, toastSuccess } = useToastsWithIntl(["auth"]);

  return (
    <>
      <AccountValidationInfo linkToAccount={false} user={user} />
      <div className={"grid --l"}>
        <div className={"col-2-3"}>
          {!user.superAdmin && (
            <div className={"section-l"}>
              <h2 className={"section-title"}>PIÈCES JUSTIFICATIVES</h2>
              <div className={"section"}>
                <AttachmentForms onUpdateKyc={updateKyc} user={user} />
              </div>
            </div>
          )}
          <div className={"section-l"}>
            <h2 className={"section-title"}>MES INFORMATIONS DU COMPTE</h2>
            <div>
              <Link
                className={"link --s --underlined"}
                to={UPDATE_PASSWORD_LINK}
              >
                Changer mon mot de passe
              </Link>
            </div>

            <div className={"section lay-col --center"}>
              <ProfileForm account={user} onUpdateUser={updateUser} />
            </div>
            <div>
              <button
                className={"link --s --danger"}
                onClick={() =>
                  confirm(
                    "auth:confirm_ask_account_deletion_content",
                    "auth:confirm_ask_account_deletion_title",
                    () =>
                      askAccountDeletion().then(
                        () => toastSuccess("auth:ask_account_deletion.SUCCESS"),
                        () => toastError("auth:ask_account_deletion.ERROR"),
                      ),
                    true,
                    { closable: false },
                  )
                }
                type={"button"}
              >
                Demander la suppression de mon compte
              </button>
            </div>
          </div>
        </div>
        <div className={"section-l grid"}>
          {user.Plan && (
            <div className={"--min-280"}>
              <h2 className={"section-title"}>VOTRE PLAN ACTUEL</h2>
              {user.Plan.type !== 0 && (
                <>
                  {user.Plan.Subscription.cancellationDate ? (
                    <div className={"section-s"}>
                      <div className={"info"}>
                        <p>
                          Votre abonnement ne sera pas renouvelé. Vous pouvez en
                          profiter jusqu'au{" "}
                          <strong>
                            {formatDate(user.Plan.Subscription.endDate!)}
                          </strong>
                          .
                          <p>
                            Veuillez noter que si vous aviez souscrit à un
                            abonnement avec un an d’engagement, les versements
                            continueront jusqu’à la fin de la période
                            d’engagement. 
                          </p>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <button
                      className={"link --s --danger"}
                      onClick={() =>
                        confirm(
                          user.Plan!.Subscription.isAnnual
                            ? "payment:confirm_unsubscribe_stripe_one_year_plan_content"
                            : "payment:confirm_unsubscribe_stripe_one_month_plan_content",
                          "payment:confirm_unsubscribe_stripe_plan_title",
                          unsubscribeStripePlan,
                          true,
                          { closable: false },
                        )
                      }
                    >
                      Se désabonner
                    </button>
                  )}
                </>
              )}
              <div className={"section"}>
                <PlanCard active={true} plan={user.Plan} />
              </div>
            </div>
          )}

          {!!user.Invoices?.length && (
            <div className={"--min-280"}>
              <h2 className={"section-title"}>FACTURES</h2>
              <div className={"section lay-col --gap --stretch-items"}>
                {user.Invoices.map((invoice) => (
                  <FileItem
                    key={invoice.id}
                    file={{
                      file: invoice.fileUrl,
                      name: `\u00A0${formatDate(
                        invoice.createdAt,
                      )}\u00A0\u00A0-\u00A0\u00A0${
                        invoice.price
                      }€\u00A0\u00A0-\u00A0\u00A0${invoice.label}`,
                    }}
                    isExternalUrl
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const useLoad = () => {
  const { loadPlans } = usePlan();

  return useCallback(() => loadPlans(), [loadPlans]);
};

export default withProvidePayment(withLoader(Account, useLoad));
