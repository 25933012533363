import { Plan, PlanForm } from "./plan";
import {
  getAllPlans,
  createPlan as apiCreatePlan,
  assignPlan as apiAssignPlan,
  unassignPlan as apiUnassignPlan,
} from "./api";
import { createContext, useContext, useState, useCallback } from "react";
import { User } from "../auth/user";

export interface PlanAPI {
  plans: Plan[];
  plan: Plan | null;

  loadPlans(): Promise<void>;
  loadPlanById(planId: Plan["id"]): void;
  createPlan(planForm: PlanForm): Promise<Plan>;
  assignPlan(planId: Plan["id"], userId: User["id"]): Promise<void>;
  unassignPlan(planId: Plan["id"], userId: User["id"]): Promise<void>;
}

export interface PlanApiLoaded extends PlanAPI {
  plan: NonNullable<PlanAPI["plan"]>;
}

export const PlanContext = createContext<PlanAPI | null>(null);

export function ProvidePlan({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [plan, setPlan] = useState<Plan | null>(null);

  const loadPlans: PlanAPI["loadPlans"] = useCallback(
    () => getAllPlans().then(({ data }) => setPlans(data)),
    [],
  );

  const loadPlanById: PlanAPI["loadPlanById"] = useCallback(
    (planId) => setPlan(plans.find((plan) => plan.id === planId)!),
    [plans],
  );

  const createPlan: PlanAPI["createPlan"] = useCallback(
    (exchange: PlanForm) =>
      apiCreatePlan(exchange).then(async ({ data }) => {
        setPlans((prev) => [
          ...prev.filter((exchange) => exchange.id !== data.id),
          data,
        ]);
        setPlan(data);
        return data;
      }),
    [],
  );

  const unassignPlan: PlanAPI["unassignPlan"] = useCallback(
    (planId, userId) =>
      apiUnassignPlan(planId, userId).then(async ({ data }) => {
        setPlans((prev) => [
          ...prev.filter((exchange) => exchange.id !== data.id),
          data,
        ]);
        setPlan(data);
      }),
    [],
  );

  const assignPlan: PlanAPI["assignPlan"] = useCallback(
    (planId, userId) => apiAssignPlan(planId, userId).then(loadPlans),
    [loadPlans],
  );

  return (
    <PlanContext.Provider
      value={{
        plan,
        plans,
        loadPlans,
        loadPlanById,
        createPlan,
        assignPlan,
        unassignPlan,
      }}
    >
      {children}
    </PlanContext.Provider>
  );
}

export function usePlan(): PlanAPI {
  return useContext(PlanContext) as PlanAPI;
}
