import { useExchange } from "../exchangeProvider";
import iconWhiteBell from "../../../assets/img/icons/icon-white-bell.svg";
import iconSettings from "../../../assets/img/icons/icon-settings.svg";
import { useEffect, useMemo, useState } from "react";
import {
  filterOnPrecondition,
  multiSorts,
  orderByField,
} from "../../data-structures/array";
import Form from "../../forms/Form";
import { date, number, object, string, array } from "yup";
import AutoField from "../../forms/AutoField";
import AutoSubmit from "../../forms/AutoSubmit";
import {
  ExchangeStatus,
  ExchangeType,
  ExchangeVehicleColor,
  ExchangeVehicleEnergy,
  ExchangeVehicleGearbox,
  ExchangeVehicleTransmission,
  ExchangeVehicleType,
  getBestExchangeOffersStatus,
} from "../exchange";
import { useAuth } from "../../auth/apiProvider";
import { isAfter, isBefore } from "date-fns";
import {
  mapExchangeFormBrandOptionToBrandMultiSelector,
  mapExchangeFormCountryOptionToCountryMultiSelector,
  mapExchangeFormBrandOptionToMetaModelMultiSelector,
} from "../formOptions";
import { useCountry } from "../../countries/useCountry";
import { getFullName, User } from "../../auth/user";
import ExchangeList from "./ExchangeList";
import { cx } from "@emotion/css";
import { useExchangeListFilters } from "../exchangeListFiltersProvider";
import { OfferStatus } from "../../offer/offer";

export enum Sort {
  Date = "date",
  PriceAsc = "priceAsc",
  PriceDesc = "priceDesc",
  Mileage = "mileage",
}

const ExchangeListWithFilters = ({
  asAdmin,
  accounts,
  filterByUser,
}: {
  asAdmin?: boolean;
  accounts?: User[];
  filterByUser?: User["id"];
}): JSX.Element => {
  const { user } = useAuth();
  const { exchanges, formOptions, countries } = useExchange();
  const { countriesList } = useCountry();
  const {
    basicFilters,
    advancedFilters,
    setAdvancedFilters,
    setBasicFilters,
    setSort,
    sort,
    resetFilters,
  } = useExchangeListFilters();

  // On mount, set the basic filters from the props
  useEffect(() => {
    if (filterByUser && asAdmin)
      setBasicFilters((prev) => ({ ...prev, user: [String(filterByUser)] }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isFiltersShown, setIsFiltersShown] = useState(false);
  const [favoriteFilter, setFavoriteFilter] = useState(false);

  const brandMultiSelectorEnum = useMemo(
    () =>
      mapExchangeFormBrandOptionToBrandMultiSelector(formOptions).reduce(
        (obj, item) => {
          obj[item.value] = item.label;
          return obj;
        },
        {} as Record<string, unknown>,
      ),
    [formOptions],
  );

  const metaModelMultiSelectorEnum = useMemo(
    () =>
      basicFilters.brand.length > 0
        ? mapExchangeFormBrandOptionToMetaModelMultiSelector(
            formOptions,
            basicFilters.brand.map((brand) => brand),
          ).reduce((obj, item) => {
            obj[item.value] = item.label;
            return obj;
          }, {} as Record<string, unknown>)
        : {},
    [basicFilters.brand, formOptions],
  );

  const countryMultiSelectorEnum = useMemo(
    () =>
      mapExchangeFormCountryOptionToCountryMultiSelector(
        countries,
        advancedFilters.originCountry.map((country) => country),
        countriesList,
      ).reduce((obj, item) => {
        obj[item.value] = item.label;
        return obj;
      }, {} as Record<string, unknown>),
    [advancedFilters.originCountry, countriesList, countries],
  );

  const userMultiSelectorEnum = useMemo(() => {
    if (asAdmin && accounts) {
      return accounts.reduce((obj, item) => {
        obj[item.id] = getFullName(item);
        return obj;
      }, {} as Record<User["id"], string>);
    } else {
      return {};
    }
  }, [accounts, asAdmin]);

  const sortedExchanges = useMemo(
    () =>
      [...exchanges].sort(
        sort === Sort.PriceAsc || sort === Sort.PriceDesc
          ? multiSorts(
              orderByField("vehicleSalePrice", sort === Sort.PriceDesc),
              orderByField("createdAt"),
            )
          : sort === Sort.Mileage
          ? multiSorts(
              orderByField("vehicleMileage"),
              orderByField("createdAt"),
            )
          : orderByField("createdAt"),
      ),
    [exchanges, sort],
  );

  const filteredExchanges = useMemo(() => {
    let exchangesList = [...sortedExchanges];

    // Basic filters
    exchangesList = filterOnPrecondition(
      exchangesList,
      basicFilters.brand.length > 0,
      (exchange) =>
        !!basicFilters.brand.find((brand) => brand === exchange.vehicleBrand),
    );
    exchangesList = filterOnPrecondition(
      exchangesList,
      basicFilters.metaModel.length > 0,
      (exchange) =>
        !!basicFilters.metaModel.find(
          (metaModel) => metaModel === exchange.vehicleMetaModel,
        ),
    );
    exchangesList = filterOnPrecondition(
      exchangesList,
      basicFilters.priceMin !== null,
      (exchange) =>
        !!exchange.vehicleSalePrice &&
        exchange.vehicleSalePrice >= basicFilters.priceMin!,
    );
    exchangesList = filterOnPrecondition(
      exchangesList,
      basicFilters.priceMax !== null,
      (exchange) =>
        !!exchange.vehicleSalePrice &&
        exchange.vehicleSalePrice <= basicFilters.priceMax!,
    );
    exchangesList = filterOnPrecondition(
      exchangesList,
      !!basicFilters.entryIntoServiceMin,
      (exchange) =>
        isAfter(
          exchange.vehicleEntryIntoService || new Date("1970-01-01"),
          basicFilters.entryIntoServiceMin!,
        ),
    );
    exchangesList = filterOnPrecondition(
      exchangesList,
      !!basicFilters.entryIntoServiceMax,
      (exchange) =>
        isBefore(
          exchange.vehicleEntryIntoService || new Date("1970-01-01"),
          basicFilters.entryIntoServiceMax!,
        ),
    );
    exchangesList = filterOnPrecondition(
      exchangesList,
      basicFilters.mileageMin !== null,
      (exchange) => exchange.vehicleMileage >= basicFilters.mileageMin!,
    );
    exchangesList = filterOnPrecondition(
      exchangesList,
      basicFilters.mileageMax !== null,
      (exchange) => exchange.vehicleMileage <= basicFilters.mileageMax!,
    );
    exchangesList = filterOnPrecondition(
      exchangesList,
      !!(asAdmin && basicFilters.user.length > 0),
      (exchange) => basicFilters.user.includes(String(exchange.UserId)),
    );
    exchangesList = filterOnPrecondition(
      exchangesList,
      favoriteFilter,
      (exchange) => exchange.isFavorite || false,
    );
    exchangesList = filterOnPrecondition(
      exchangesList,
      basicFilters.email !== null,
      (exchange) =>
        accounts
          ?.find((a) => a.id === exchange.UserId)
          ?.email?.includes(basicFilters.email!) || false,
    );
    exchangesList = filterOnPrecondition(
      exchangesList,
      !!asAdmin &&
        basicFilters.offerStatus !== null &&
        basicFilters.offerStatus !== "",
      (exchange) =>
        exchange.Offers.length > 0 &&
        { ...OfferStatus, STATUS_SOLD: ExchangeStatus.STATUS_SOLD }[
          basicFilters.offerStatus!
        ] === getBestExchangeOffersStatus(exchange),
    );
    exchangesList = filterOnPrecondition(
      exchangesList,
      !!asAdmin &&
        basicFilters.exchangeStatus !== null &&
        basicFilters.exchangeStatus !== "",
      (exchange) => {
        return { ...ExchangeStatus }[basicFilters.exchangeStatus!] ===
          ExchangeStatus.STATUS_PUBLISHED
          ? !!exchange.publicationEndDate &&
              exchange.publicationEndDate > new Date(Date.now())
          : { ...ExchangeStatus }[basicFilters.exchangeStatus!] ===
            ExchangeStatus.STATUS_UNPUBLISHED
          ? !exchange.publicationEndDate ||
            exchange.publicationEndDate < new Date(Date.now())
          : true;
      },
    );

    // Advanced filters
    exchangesList = filterOnPrecondition(
      exchangesList,
      advancedFilters.powerMin !== null,
      (exchange) => exchange.vehiclePower >= advancedFilters.powerMin!,
    );
    exchangesList = filterOnPrecondition(
      exchangesList,
      advancedFilters.powerMax !== null,
      (exchange) => exchange.vehiclePower <= advancedFilters.powerMax!,
    );
    exchangesList = filterOnPrecondition(
      exchangesList,
      advancedFilters.energy !== null && advancedFilters.energy !== "",
      (exchange) => exchange.vehicleEnergy === advancedFilters.energy!,
    );

    exchangesList = filterOnPrecondition(
      exchangesList,
      advancedFilters.type !== null,
      (exchange) => exchange.vehicleType === advancedFilters.type!,
    );
    exchangesList = filterOnPrecondition(
      exchangesList,
      advancedFilters.color !== null,
      (exchange) => exchange.vehicleColor === advancedFilters.color!,
    );
    exchangesList = filterOnPrecondition(
      exchangesList,
      advancedFilters.gearbox !== null && advancedFilters.gearbox !== "",
      (exchange) => exchange.vehicleGearbox === advancedFilters.gearbox!,
    );
    exchangesList = filterOnPrecondition(
      exchangesList,
      advancedFilters.transmission !== null &&
        advancedFilters.transmission !== "",
      (exchange) =>
        exchange.vehicleTransmission === advancedFilters.transmission!,
    );
    exchangesList = filterOnPrecondition(
      exchangesList,
      advancedFilters.accidented !== null && advancedFilters.accidented !== "",
      (exchange) =>
        exchange.isVehicleDamaged === (advancedFilters.accidented === "Oui"),
    );
    exchangesList = filterOnPrecondition(
      exchangesList,
      advancedFilters.originCountry.length > 0,
      (exchange) =>
        !!advancedFilters.originCountry.find(
          (originCountry) => originCountry === exchange.vehicleCountryCode,
        ),
    );
    exchangesList = filterOnPrecondition(
      exchangesList,
      advancedFilters.exchangeType !== null,
      (exchange) => exchange.type === advancedFilters.exchangeType!,
    );

    return exchangesList;
  }, [
    sortedExchanges,
    basicFilters,
    advancedFilters,
    accounts,
    favoriteFilter,
    asAdmin,
  ]);

  const isUserValidated = useMemo(() => user?.isValid, [user?.isValid]);

  return (
    <div>
      <Form
        enableReinitialize={true}
        initialValues={basicFilters}
        onSubmit={(values) => {
          setBasicFilters(values);
          return Promise.resolve();
        }}
        schema={object({
          brand: array()
            .autocomplete(brandMultiSelectorEnum, "exchange", "brand-select", {
              active: true,
              hasSelectAll: false,
            })
            .label("Marque"),
          metaModel: array()
            .meta({ disabled: basicFilters.brand.length === 0 })
            .autocomplete(
              metaModelMultiSelectorEnum,
              "exchange",
              "model-select",
              {
                active: true,
                hasSelectAll: false,
              },
            )
            .label("Modèle"),
          priceMin: number().min(0).nullable().notEditable(!isUserValidated),
          priceMax: number().min(0).nullable().notEditable(!isUserValidated),
          entryIntoServiceMin: date()
            .nullable()
            .label("Mise en circulation min."),
          entryIntoServiceMax: date()
            .nullable()
            .label("Mise en circulation max."),
          mileageMin: number().min(0).nullable(),
          mileageMax: number().min(0).nullable(),
          user: array()
            .notVisible(!asAdmin)
            .autocomplete(userMultiSelectorEnum, "exchange", "user-select", {
              active: true,
              hasSelectAll: false,
            }),
          email: string().notVisible(!asAdmin).nullable(),
          offerStatus: string()
            .notVisible(!asAdmin)
            .label("Statut des offres")
            .meta({
              select: true,
              enum: {
                STATUS_PENDING: OfferStatus.STATUS_PENDING,
                STATUS_ACCEPTED: OfferStatus.STATUS_ACCEPTED,
                STATUS_VALIDATED: OfferStatus.STATUS_VALIDATED,
                STATUS_SOLD: ExchangeStatus.STATUS_SOLD,
              },
              translate: ["offer", "offer:offer_status"],
              withoutParse: true,
            })
            .nullable(),
          exchangeStatus: string()
            .notVisible(!asAdmin)
            .label("Statut de l'annonce")
            .meta({
              select: true,
              enum: {
                STATUS_PUBLISHED: ExchangeStatus.STATUS_PUBLISHED,
                STATUS_UNPUBLISHED: ExchangeStatus.STATUS_UNPUBLISHED,
              },
              translate: ["exchange", "exchange:exchange_status"],
              withoutParse: true,
            })
            .nullable(),
        })}
      >
        <AutoSubmit minDebounce={100} />
        {asAdmin && (
          <div className={"section"}>
            <AutoField name={"user"} />
            <AutoField name={"email"} placeholder={"E-mail"} />
            <div className={"section lay-row --stretch --gap-h--xs"}>
              <AutoField name={"exchangeStatus"} />
              <AutoField name={"offerStatus"} />
            </div>
          </div>
        )}

        <div className={"exchange_filters"}>
          <div className={"grid --2"}>
            <AutoField name={"brand"} />
            <AutoField name={"metaModel"} />
            {/* <AutoField name={"subModel"} />
            <AutoField name={"engine"} /> */}
          </div>
          <div className={"grid --3"}>
            <div className={"form-block"}>
              <label className={"input-label"}>Prix</label>
              <fieldset className={"inputs-set"}>
                <AutoField name={"priceMin"} placeholder={"min"} />
                <AutoField name={"priceMax"} placeholder={"max"} />
              </fieldset>
            </div>
            <div className={"form-block"}>
              <label className={"input-label"}>Mise en circulation</label>
              <div className={"inputs-set"}>
                <AutoField
                  name={"entryIntoServiceMin"}
                  otherProps={{
                    scrollableYearDropdown: true,
                    showYearDropdown: true,
                    yearDropdownItemNumber: 150,
                  }}
                  placeholder={"min"}
                />
                <AutoField
                  name={"entryIntoServiceMax"}
                  otherProps={{
                    scrollableYearDropdown: true,
                    showYearDropdown: true,
                    yearDropdownItemNumber: 150,
                  }}
                  placeholder={"max"}
                />
              </div>
            </div>

            <div className={"form-block"}>
              <label className={"input-label"}>Kilométrage</label>
              <div className={"inputs-set"}>
                <AutoField name={"mileageMin"} placeholder={"min"} />
                <AutoField name={"mileageMax"} placeholder={"max"} />
              </div>
            </div>
          </div>
        </div>
      </Form>

      {isFiltersShown && (
        <Form
          enableReinitialize={true}
          initialValues={advancedFilters}
          onSubmit={(values) => {
            setAdvancedFilters(values);
            return Promise.resolve();
          }}
          schema={object({
            powerMin: number().min(0).nullable(),
            powerMax: number().min(0).nullable(),
            energy: string()
              .label("Energie")
              .nullable()
              .meta({
                select: true,
                enum: ExchangeVehicleEnergy,
                translate: ["exchange", "exchange:vehicle_energy"],
                withoutParse: true,
              })
              .meta({ withoutParse: true })
              .radio(),
            type: number()
              .label("Type de voiture")
              .nullable()
              .oneOfEnum(ExchangeVehicleType, "exchange", "vehicle_type", true),
            color: number()
              .label("Couleur")
              .nullable()
              .oneOfEnum(
                ExchangeVehicleColor,
                "exchange",
                "vehicle_color",
                true,
              ),
            gearbox: string()
              .label("Boîte de vitesse")
              .nullable()
              .meta({
                select: true,
                enum: ExchangeVehicleGearbox,
                translate: ["exchange", "exchange:vehicle_gearbox"],
                withoutParse: true,
              })
              .meta({ withoutParse: true })
              .radio(),
            transmission: string()
              .label("Transmission")
              .nullable()
              .meta({
                select: true,
                enum: ExchangeVehicleTransmission,
                translate: ["exchange", "exchange:vehicle_transmission"],
                withoutParse: true,
              }),
            accidented: string()
              .label("Accidenté")
              .nullable()
              .meta({
                select: true,
                enum: ["Oui", "Non"],
                withoutParse: true,
              }),
            originCountry: array()
              .label("Origine")
              .nullable()
              .autocomplete(
                countryMultiSelectorEnum,
                "exchange",
                "country-select",
                {
                  active: true,
                  hasSelectAll: false,
                },
              ),
            exchangeType: number()
              .label("Type d'annonce")
              .oneOfEnum(ExchangeType, "exchange", "type", true)
              .nullable(),
          })}
        >
          <div className={"exchange_extra-filters"}>
            <AutoSubmit minDebounce={100} />

            <div className={"form-block"}>
              <label className={"input-label"}>Puissance</label>
              <div className={"inputs-set"}>
                <AutoField name={"powerMin"} placeholder={"Puissance min"} />
                <AutoField name={"powerMax"} placeholder={"Puissance max"} />
              </div>
            </div>

            <AutoField className={"lay-row --gap"} name={"energy"} />
            <AutoField name={"type"} />
            <AutoField name={"color"} />
            <AutoField className={"lay-row --gap"} name={"gearbox"} />
            <AutoField className={"lay-row --gap"} name={"transmission"} />
            <AutoField className={"inline"} name={"accidented"} />
            <AutoField name={"originCountry"} />
            <AutoField name={"exchangeType"} />
          </div>
        </Form>
      )}

      <div className={"lay-row"}>
        {/* TODO
         * n'afficher que si un filtre au moins est sélectionné
         * afficher compteur uniquement si 30 min max de dev */}
        <div>
          <button className={"link --s --accent"} onClick={resetFilters}>
            Réinitialiser les filtres
          </button>
        </div>
        <div className={"--pos-r"}>
          <button
            className={"btn--outlined"}
            onClick={() => setIsFiltersShown((p) => !p)}
            type={"button"}
          >
            <img alt={"filters"} className={"icon --left"} src={iconSettings} />
            {isFiltersShown ? "Masquer les" : "Ajouter des"} filtres
          </button>
        </div>
      </div>

      <div className={"list_head"}>
        <div className={"results-count"}>
          {filteredExchanges.length} résultat
          {filteredExchanges.length > 1 && "s"} de recherche
        </div>
        <button
          className={cx("btn-toggle", favoriteFilter && "toggle-on")}
          onClick={() => setFavoriteFilter(!favoriteFilter)}
          type={"button"}
        >
          Favoris
        </button>
        <div className={"--pos-r"}>
          <div className={"form-block --inline"}>
            <label className={"input-label --s --secondary"}>Tri</label>
            <select
              className={"select --w-l"}
              onChange={(ev) => setSort(ev.target.value as Sort)}
              value={sort}
            >
              <option value={Sort.Date}>Date de publication</option>
              <option value={Sort.Mileage}>Kilométrage</option>
              {isUserValidated && (
                <>
                  <option value={Sort.PriceAsc}>Prix croissant</option>
                  <option value={Sort.PriceDesc}>Prix décroissant</option>
                </>
              )}
            </select>
          </div>

          {!asAdmin && (
            <button
              className={"btn"}
              style={{ display: "none" }}
              type={"button"}
            >
              <img alt={"bell"} className={"icon"} src={iconWhiteBell} />
              Créer une alerte
            </button>
          )}
        </div>
      </div>
      <ExchangeList
        asAdmin={asAdmin}
        exchanges={filteredExchanges}
        paginatedBy={15}
      />
    </div>
  );
};

export default ExchangeListWithFilters;
