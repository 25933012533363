import { useField } from "formik";
import React, { FunctionComponent, useMemo } from "react";
import { deleteFromArrayAndReturn } from "../../data-structures/array";
import { VehicleDocument, VehicleDocumentBase } from "../../exchange/exchange";
import useFileAsObjectURL from "../../files/useFileAsObjectURL";
import { useToastsWithIntl } from "../../toast-notifications";
import FileInput, { Props as FileInputProps } from "../../files/FileInput";
import { readFile } from "../../files/utils";
import { InfoRequestDocument, InfoRequestType } from "../infoRequest";

interface Props extends Omit<FileInputProps, "onChange"> {
  name: string;
  readonly?: boolean;
  documentType: InfoRequestType;
}

const InfoDocumentZone: FunctionComponent<Props> = ({
  name,
  readonly,
  documentType,
}) => {
  const { toastError } = useToastsWithIntl(["exchange"]);
  const [field, , helper] = useField<InfoRequestDocument[]>(name);

  const fileList = useMemo(() => {
    if (!field.value.length) return [];

    return field.value.map((file, index, array) => (
      <FileEdit
        key={index}
        file={file}
        onDelete={() => helper.setValue(deleteFromArrayAndReturn(array, index))}
      />
    ));
  }, [helper, field.value]);

  return (
    <div>
      {!readonly && (
        <FileInput
          accept={
            documentType !== InfoRequestType.video
              ? "image/*,application/pdf"
              : "video/*"
          }
          multiple={true}
          onChange={(addedFiles) => {
            if (
              addedFiles[0].size / 1024 >
              (documentType !== InfoRequestType.video ? 10000 : 30000)
            )
              toastError("exchange:upload-info-document.TOO_LARGE");
            else
              readFile(addedFiles[0]).then(async (file) => {
                return Promise.all(
                  [...addedFiles].map((f, index) =>
                    readFile(f).then((dataUrl) => ({
                      type: documentType,
                      name: f.name,
                      file: dataUrl,
                    })),
                  ),
                ).then((newFile) => {
                  helper.setValue((field.value || []).concat(newFile));
                });
              });
          }}
        />
      )}

      <div className={"media-grid"}>{fileList}</div>
    </div>
  );
};

interface FileEditProps {
  file: VehicleDocument | VehicleDocumentBase;
  onDelete?(): void;
}

const FileEdit: FunctionComponent<FileEditProps> = ({ file, onDelete }) => {
  const objectURL = useFileAsObjectURL({ url: file.file });

  return (
    <div className={"media-item"}>
      <div className={"file"}>
        {objectURL ? (
          <a
            className={"file-name"}
            href={objectURL?.url}
            rel={"noreferrer"}
            target={"_blank"}
          >
            {file.name || "Document sans nom"}
          </a>
        ) : (
          <p>Erreur</p>
        )}
        {onDelete && (
          <button className={"delete-file"} onClick={onDelete} type={"button"}>
            Supprimer
          </button>
        )}
      </div>
    </div>
  );
};

export default InfoDocumentZone;
