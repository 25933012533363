import Routes from "src/services/routing/Routes";
import { Navigate, Route } from "react-router-dom";
import {
  EXCHANGE_LISTING,
  EXCHANGE_LISTING_LINK,
  NEW_EXCHANGE_VEHICLE,
  EXCHANGE_DETAIL,
  EDIT_EXCHANGE,
} from "../../../routes/exchange";
import NewVehicle from "./NewVehicle";
import ExchangeListing from "./ExchangeListing";
import ExchangeDetail from "src/views/private/exchange/ExchangeDetail";
import { AuthAPIConnected, useAuth } from "../../../services/auth/apiProvider";
import ExchangeEdit from "./ExchangeEdit";
import { withProvideExchangeListFilters } from "../../../services/exchange/exchangeListFiltersProvider";

const Exchange = (): JSX.Element => {
  const { user } = useAuth() as AuthAPIConnected;
  return (
    <Routes>
      <Route element={<Navigate to={EXCHANGE_LISTING_LINK} replace />} index />
      <Route element={<ExchangeListing />} path={`${EXCHANGE_LISTING}/*`} />
      {user.isValid && (
        <Route element={<NewVehicle />} path={`${NEW_EXCHANGE_VEHICLE}/*`} />
      )}

      <Route element={<ExchangeDetail />} path={`${EXCHANGE_DETAIL}/*`} />
      <Route element={<ExchangeDetail />} path={`${EXCHANGE_DETAIL}`} />
      <Route element={<ExchangeEdit />} path={`${EDIT_EXCHANGE}`} />
    </Routes>
  );
};

export default withProvideExchangeListFilters(Exchange);
