import { AxiosPromise } from "axios";
import baseAPI from "../auth/api";
import { User } from "../auth/user";
import { AnswerInfoRequestParams, InfoRequest } from "./infoRequest";

export function getInfoRequests(
  userId: User["id"],
): AxiosPromise<InfoRequest[]> {
  return baseAPI.get(`/users/${userId}/info-requests`);
}

export function answerInfoRequest(
  infoRequest: InfoRequest,
  answerInfoRequestParams: AnswerInfoRequestParams,
): AxiosPromise<InfoRequest> {
  return baseAPI.put(
    `/exchanges/${infoRequest.Exchange.id}/info-requests/${infoRequest.id}`,
    answerInfoRequestParams,
    {
      timeout: 1000 * 60,
    },
  );
}

export function startWeProoveInspection(
  infoRequest: InfoRequest,
): AxiosPromise<InfoRequest> {
  return baseAPI.post(
    `/exchanges/${infoRequest.Exchange.id}/info-requests/${infoRequest.id}/weproov`,
  );
}
