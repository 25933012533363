import { FunctionComponent, useState } from "react";
import { FieldAttributes, useField } from "formik";
import IconHide from "../../assets/img/icons/icon-eye-crossed.svg";
import IconShow from "../../assets/img/icons/icon-eye.svg";
import Input from "../ui/reactive/Input";

// Formik has wrong typing
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PasswordField: FunctionComponent<FieldAttributes<any>> = (props) => {
  const [field, , helper] = useField(props);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={"input-with-button"}>
      <Input
        {...props}
        {...field}
        onChange={(event) =>
          helper.setValue(
            event.currentTarget.value === "" ? null : event.currentTarget.value,
          )
        }
        type={showPassword ? "text" : "password"}
        value={
          field.value === null || field.value === undefined ? "" : field.value
        }
      />
      {props.showTogglePassword && (
        <button
          onClick={() => setShowPassword((prev) => !prev)}
          type={"button"}
        >
          {showPassword ? (
            <img alt={"show password"} className={"icon"} src={IconHide} />
          ) : (
            <img alt={"hide password"} className={"icon"} src={IconShow} />
          )}
        </button>
      )}
    </div>
  );
};

export default PasswordField;
