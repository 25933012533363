// TODO: refactor user types

import { parseISO } from "date-fns";
import { getNumericEnumEntries } from "../data-structures/enum";
import {
  FullExchange,
  mapRawExchangeToExchange,
  FullExchangeRaw,
} from "../exchange/exchange";
import { FullPlan, FullPlanRaw, mapRawFullPlanToFullPlan } from "../plan/plan";
import { PartialNullable } from "../types/utility";

/* Public User returned from API */
export interface User {
  id: number;
  email: string;
  lastname: string;
  firstname: string;
  phone: string;
  companyName: string;
  position: Position;
  siret: string;
  validationDate: Date | null;
  isValid: boolean;
  superAdmin: boolean;
  dailyRequestsNb: number;
  dailyOffersNb: number;
  postsNb: number;
  Exchanges: FullExchange[];
  createdAt: Date;
  firstConnectionDate?: Date;
  lastUseDate?: Date;
  deletedAt?: Date;
  Kyc: UserKyc;
  Plan: FullPlan | null;
  Invoices?: Invoice[];
  advertiserId?: string;
  ubiflowMailSendDate?: Date;
}

export interface UserRaw {
  id: number;
  email: string;
  lastname: string;
  firstname: string;
  phone: string;
  companyName: string;
  position: Position;
  siret: string;
  validationDate: string | null;
  isValid: boolean;
  Kyc: UserKyc | null;
  superAdmin: boolean;
  dailyRequestsNb: number;
  dailyOffersNb: number;
  Plan?: FullPlanRaw;
  postsNb: number;
  Exchanges?: FullExchangeRaw[];
  createdAt: string;
  firstConnectionDate?: string;
  lastUseDate?: string;
  deletedAt?: string;
  Invoices?: InvoiceRaw[];
}

export enum Position {
  "SALESMAN",
  "SALES_MANAGER",
  "PURCHASING_MANAGER",
  "GENERAL_MANAGER",
  "BRANCH_MANAGER",
  "OPERATIONAL_MANAGER",
  "OTHER",
}
export const ReversePosition = Object.fromEntries(
  getNumericEnumEntries(Position),
);

/* User for login */
export interface UserToLogin {
  email: string;
  password: string;
}

/* User sent from login */
export interface LoggedUser extends Pick<User, "id"> {
  xsrfToken: string;
}

export interface LoggedUserRaw extends UserRaw {
  xsrfToken: string;
}

/* User register to send to api*/
export interface UserToRegister {
  email: string;
  password: string;
  lastname: string | null;
  firstname: string | null;
}

/* User register for register form*/
export interface UserToRegisterForm extends UserToRegister {
  passwordConfirmation: string;
  termsOfUse: boolean;
  securityPolicy: boolean;
  gRecaptchaToken: string;
}

export interface UserToUpdateForm extends Partial<User> {}

export interface UserPasswordForm {
  oldPwd: UserToLogin["password"];
  newPwd: UserToLogin["password"];
}

export interface UserKyc {
  kbis: string | null;
  identity: string | null;
  bank: string | null;
}

/* Contact Form */
export enum ContactType {
  INFORMATION,
  SUBSCRIPTION,
  BUG,
}
export const ReverseContactType = Object.fromEntries(
  getNumericEnumEntries(ContactType),
);

export enum ContactCompanyActivity {
  ELECTRICAL,
  GENERIC,
  LOW_COST,
  UTILITY,
  PREMIUM,
  COLLECTION,
  SPORT,
  CONVERTED,
  PRESTIGE,
}
export const ReverseContactCompanyActivity = Object.fromEntries(
  getNumericEnumEntries(ContactCompanyActivity),
);

export interface ContactFormValues
  extends PartialNullable<
    Omit<ContactForm, "jobName" | "type" | "companyActivity">
  > {
  jobName: Position;
  type: ContactType;
  companyActivity: ContactCompanyActivity[];
}
export interface ContactForm {
  lastName: string;
  firstName: string;
  mail: string;
  phone: string;
  message: string;
  companyName: string;
  jobName: string;
  companyActivity: string[];
  type: string;
  subject: string;
}

export interface InvoiceRaw {
  id: number;
  label: string;
  price: number;
  vosFacturesId: string;
  fileUrl: string;
  createdAt: string;
}

export interface Invoice extends Omit<InvoiceRaw, "createdAt"> {
  createdAt: Date;
}

export function getShortName(
  user: Pick<User, "firstname" | "lastname">,
): string {
  return user.firstname && user.lastname
    ? `${user.firstname.slice(0, 1)}. ${user.lastname}`
    : "Votre compte";
}

export function getFullName(
  user: Pick<User, "firstname" | "lastname">,
): string {
  return `${user.firstname} ${user.lastname.toUpperCase()}`;
}

export function getInitials(
  user: Pick<User, "firstname" | "lastname">,
): string {
  return user.firstname && user.lastname
    ? `${user.firstname.slice(0, 1)}${user.lastname.slice(0, 1)}`
    : "Votre compte";
}

export function mapUserRawToUser(user: UserRaw): User;
export function mapUserRawToUser(user: LoggedUserRaw): User & LoggedUser;
export function mapUserRawToUser(
  user: UserRaw | LoggedUserRaw,
): User | (User & LoggedUser) {
  return {
    ...user,
    createdAt: parseISO(user.createdAt),
    firstConnectionDate: user.firstConnectionDate
      ? parseISO(user.firstConnectionDate)
      : undefined,
    lastUseDate: user.lastUseDate ? parseISO(user.lastUseDate) : undefined,
    deletedAt: user.deletedAt ? parseISO(user.deletedAt) : undefined,
    validationDate:
      user.validationDate !== null ? parseISO(user.validationDate) : null,
    Kyc: user.Kyc || {
      kbis: null,
      identity: null,
      bank: null,
    },
    Plan: (user.Plan && mapRawFullPlanToFullPlan(user.Plan)) || null,
    Exchanges:
      (user.Exchanges &&
        user.Exchanges.map((exchangeRaw) =>
          mapRawExchangeToExchange(exchangeRaw),
        )) ||
      [],
    Invoices: user.Invoices
      ? user.Invoices.map((invoice) => ({
          ...invoice,
          createdAt: parseISO(invoice.createdAt),
        }))
      : undefined,
  };
}

export function isUserCommercial(user: User): boolean {
  return !user.superAdmin;
}

export function isUserMissingKyc(user: User): boolean {
  return (
    user.Kyc.kbis === null ||
    user.Kyc.identity === null ||
    user.Kyc.bank === null
  );
}
