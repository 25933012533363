import { useState } from "react";
import Lightbox from "./Lightbox";
import ImgBuyerConditions from "../../assets/img/buyer-offer-conditions.jpg";
import ImgSellerConditions from "../../assets/img/seller-offer-conditions.jpg";
import { Offer } from "../offer/offer";
import { useAuth } from "../auth/apiProvider";

const OfferConditionsDetailsButton = ({
  offer,
}: {
  offer: Offer | null;
}): JSX.Element => {
  const { user } = useAuth();
  const [showOptionsDetails, setShowOptionsDetails] = useState(false);
  return (
    <>
      <button
        className={"link --accent --s"}
        onClick={() => setShowOptionsDetails(true)}
        type={"button"}
      >
        En savoir plus sur les options d'achat et de paiement
      </button>
      {showOptionsDetails && (
        <Lightbox onClose={() => setShowOptionsDetails(false)}>
          {!offer || offer.UserId === user?.id ? (
            <img alt={"conditions acheteur"} src={ImgBuyerConditions} />
          ) : (
            <img alt={"conditions vendeur"} src={ImgSellerConditions} />
          )}
        </Lightbox>
      )}
    </>
  );
};

export default OfferConditionsDetailsButton;
