import { parse } from "query-string";
import { useSearchParams } from "react-router-dom";
import generatePath from "./generatePath";

export const useKeepQueryParams = (): ((path: string) => string) => {
  const [queryParams] = useQueryParams();
  return (path) => generatePath(path, {}, queryParams);
};

export interface QueryParams<T = string | number | boolean> {
  [key: string]: T | null | Array<T | null>;
}

export default function useQueryParams(): [
  QueryParams,
  (queryParams: QueryParams | ((prev: QueryParams) => QueryParams)) => void,
] {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentQueryParams = Object.fromEntries(
    Object.entries(
      parse(searchParams.toString(), {
        parseBooleans: true,
        parseNumbers: false,
      }),
    ).map(([key, value]) => [key, value === null ? true : value]),
  );

  const setQueryParams = (queryParams: QueryParams) =>
    setSearchParams(
      Object.entries(queryParams)
        .filter(
          ([_key, value]) =>
            value !== null && (typeof value !== "boolean" || value === true),
        )
        .map(([key, value]) => [
          key,
          typeof value === "boolean"
            ? ""
            : typeof value === "number"
            ? value.toString()
            : value,
        ]) as Array<[string, string]>,
    );

  return [
    currentQueryParams,
    (queryParams) =>
      typeof queryParams === "function"
        ? setQueryParams(queryParams(currentQueryParams))
        : setQueryParams(queryParams),
  ];
}
