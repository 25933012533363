import { DASHBOARD_LINK } from "./private";

export const FAVORITES = "/favorites" as const;
export const FAVORITES_LINK = `${DASHBOARD_LINK}${FAVORITES}` as const;

export const SENT_OFFERS = "/sent-offers" as const;
export const SENT_OFFERS_LINK = `${DASHBOARD_LINK}${SENT_OFFERS}` as const;

export const RECEIVED_OFFERS = "/received-offers" as const;
export const RECEIVED_OFFERS_LINK = `${DASHBOARD_LINK}${RECEIVED_OFFERS}` as const;

export const MY_EXCHANGES = "/exchanges" as const;
export const MY_EXCHANGES_LINK = `${DASHBOARD_LINK}${MY_EXCHANGES}` as const;

export const INFO_REQUESTS = "/info-requests" as const;
export const INFO_REQUESTS_LINK = `${DASHBOARD_LINK}${INFO_REQUESTS}` as const;
