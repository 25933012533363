import { useField } from "formik";
import React, { FunctionComponent, useState, useMemo } from "react";
import { deleteFromArrayAndReturn } from "../../data-structures/array";
import FileInput, { Props as FileInputProps } from "../../files/FileInput";
import LightboxCarousel from "../../files/LightboxCarousel";
import useFileAsObjectURL from "../../files/useFileAsObjectURL";
import { readFile } from "../../files/utils";
import { useToastsWithIntl } from "../../toast-notifications";
import placeholderImage from "../../../assets/img/img-placeholder.jpeg";
import { InfoRequestDocument, InfoRequestType } from "../infoRequest";

interface Props extends Omit<FileInputProps, "onChange"> {
  name: string;
  readonly?: boolean;
  documentType: InfoRequestType;
}

const InfoPictureZone: FunctionComponent<Props> = ({
  name,
  readonly,
  documentType,
}) => {
  const { toastError } = useToastsWithIntl(["auth"]);
  const [field, , helper] = useField<InfoRequestDocument[]>(name);

  const [displayedFile, setDisplayedFile] = useState<null | number>(null);

  const medias = field.value;

  const mediaList = useMemo(() => {
    if (!medias.length) return [];

    return medias.map((file, index, array) => (
      <MediaEdit
        key={index}
        file={file}
        onDelete={() =>
          helper.setValue(
            deleteFromArrayAndReturn(array, index).map((picture, index) => ({
              ...picture,
            })),
          )
        }
        onShow={() => setDisplayedFile(index)}
      />
    ));
  }, [helper, medias]);

  return (
    <div>
      {!readonly && (
        <FileInput
          accept={"image/*"}
          multiple={true}
          onChange={(addedFiles) => {
            if (addedFiles[0].size / 1024 > 10000)
              toastError("auth:update-kyc.TOO_LARGE");
            else
              readFile(addedFiles[0]).then(async (file) => {
                return Promise.all(
                  [...addedFiles].map((f, index) =>
                    readFile(f).then((dataUrl) => ({
                      file: dataUrl,
                      type: documentType,
                      name: f.name,
                    })),
                  ),
                ).then((newMedia) => {
                  helper.setValue((field.value || []).concat(newMedia));
                });
              });
          }}
        />
      )}

      <div className={"media-grid"}>{mediaList}</div>

      {displayedFile !== null && (
        <LightboxCarousel
          defaultFileDisplayed={displayedFile}
          files={medias.map((m) => ({ url: m.file }))}
          onClose={() => setDisplayedFile(null)}
        />
      )}
    </div>
  );
};

interface MediaEditProps {
  file: InfoRequestDocument;
  onDelete?(): void;
  onShow(): void;
  draggable?: {
    handleDragEnter: () => void;
    handleDragStart: () => void;
  };
}

const MediaEdit: FunctionComponent<MediaEditProps> = ({
  file,
  onDelete,
  onShow,
  draggable,
}) => {
  const objectURL = useFileAsObjectURL({ url: file.file });

  return (
    <div
      className={"media-item"}
      draggable={draggable !== undefined}
      onDragEnter={(e) => draggable?.handleDragEnter()}
      onDragOver={(e) => e.preventDefault()}
      onDragStart={() => draggable?.handleDragStart()}
    >
      <button className={"btn-lightbox"} onClick={onShow} type={"button"}>
        <canvas
          height={"3"}
          style={
            objectURL
              ? {
                  backgroundImage: `url(${objectURL.url})`,
                  backgroundSize: "cover",
                }
              : {
                  backgroundImage: `url(${placeholderImage})`,
                  backgroundSize: "contain",
                }
          }
          width={"4"}
        />
      </button>
      {onDelete && (
        <button className={"delete-img-btn"} onClick={onDelete} type={"button"}>
          -
        </button>
      )}
    </div>
  );
};

export default InfoPictureZone;
