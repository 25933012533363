import {
  ComponentType,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  Exchange,
  ExchangeForm,
  ExchangeRaw,
  ExchangeVatCode,
  ExchangeVehicleColor,
  ExchangeVehicleEnergy,
  ExchangeVehicleGearbox,
  ExchangeVehicleInnerColor,
  ExchangeVehicleSeatMaterial,
  ExchangeVehicleTransmission,
  ExchangeVehicleType,
  ExchangeVinForm,
  ExchangeVinResult,
  FormatedExchange,
  FullExchange,
  FullExchangeRaw,
  mapRawExchangeToExchange,
  ExchangeType,
  FullExchangeWithFullOffer,
} from "./exchange";
import {
  createExchange as apiCreateExchange,
  updateExchange as apiUpdateExchange,
  deleteExchange as apiDeleteExchange,
  deleteImportExchanges as apiDeleteImportExchanges,
  getAllExchanges,
  getFormOptions,
  getFormOptionsBrand as apiGetFormOptionsBrand,
  getFormOptionsModel as apiGetFormOptionsModel,
  getFormOptionsSubModel as apiGetFormOptionsSubModel,
  getFormOptionsEngine as apiGetFormOptionsEngine,
  getFormOptionsEngines as apiGetFormOptionsEngines,
  getVehicleDetailsByVin as apiGetVehicleDetailsByVin,
  publishExchange as apiPublishExchange,
  unpublishExchange as apiUnpublishExchange,
  getExchangeById,
  makeOffer as apiMakeOffer,
  makeNewOffer as apiMakeNewOffer,
  acceptOffer as apiAcceptOffer,
  declineOffer as apiDeclineOffer,
  addToFavorites as apiAddToFavorites,
  removeFromFavorites as apiRemoveFromFavorites,
  getAllFavorites,
  createInfoRequest as apiCreateInfoRequest,
  manageExchangeStatus as apiManageExchangeStatus,
  getFilteredCountries,
  getNbExchange,
} from "./api";
import {
  ExchangeFormBrandOption,
  ExchangeFormBrandOptionBase,
  ExchangeFormModelOptionBase,
  ExchangeFormEngineOption,
  ExchangeFormSubModelOptionBase,
} from "./formOptions";
import { getNumericEnumValueByStringName } from "../data-structures/enum";
import { TFuncKey, useTranslation } from "react-i18next";
import { useCountry } from "../countries/useCountry";
import { useAuth } from "../auth/apiProvider";
import { upsertElementInArray } from "../data-structures/array";
import { Offer, OfferForm } from "../offer/offer";
import { useOffer } from "../offer/offerProvider";
import { User } from "../auth/user";
import { InfoRequestForm } from "../info-request/infoRequest";

export interface ExchangeAPI {
  exchange: FullExchange | null;
  nbExchange: number;
  formatedExchange: FormatedExchange | null;
  exchanges: FullExchange[];
  formOptions: ExchangeFormBrandOption[];
  countries: Array<string>;

  createExchange(
    exchange: ExchangeForm,
    asUserId?: User["id"],
  ): Promise<Exchange>;
  updateExchange(
    exchange: ExchangeForm,
    asUserId?: User["id"],
  ): Promise<Exchange>;
  deleteExchange(exchangeId: Exchange["id"]): Promise<void>;
  deleteImportExchanges: (userId: User["id"]) => Promise<void>;
  loadAll(): Promise<void>;
  loadExchanges(): Promise<void>;
  loadExchangeNumber(): Promise<void>;
  loadExchangeById(exchangeId: Exchange["id"]): Promise<FullExchange>;
  loadFormOptions(): Promise<void>;
  getFormOptionsBrand(): Promise<ExchangeFormBrandOptionBase[]>;
  getFormOptionsModel(
    brandId: Exchange["vehicleBrand"],
  ): Promise<ExchangeFormModelOptionBase[]>;
  getFormOptionsSubModel(
    modelId: Exchange["vehicleModel"],
  ): Promise<ExchangeFormSubModelOptionBase[]>;
  getFormOptionsEngine(
    subModelId: Exchange["vehicleSubModel"],
  ): Promise<ExchangeFormEngineOption[]>;
  getFormOptionsEngines(
    options: Array<{
      subModelId: Exchange["vehicleSubModel"];
    }>,
  ): Promise<ExchangeFormEngineOption[]>;
  getVehicleDetailsByVin(
    vehicleVin: ExchangeVinForm["vehicleVin"],
    vehicleOriginCode: ExchangeVinForm["vehicleOriginCode"],
  ): Promise<ExchangeVinResult>;
  formatVehicleOptions<
    T extends FullExchange | Exchange | FullExchangeRaw | ExchangeRaw
  >(
    exchange: T,
  ): FormatedExchange;
  loadFormatedVehicleOptionsDetails(exchange: FullExchange): Promise<void>;
  addToFavorites(exchange: Pick<Exchange, "id">): Promise<void>;
  removeFromFavorites(exchange: Pick<Exchange, "id">): Promise<void>;
  loadFavorites(): Promise<void>;
  publishExchange(
    exchangeId: Exchange["id"],
    publishWithoutPayment?: boolean,
    asUserId?: User["id"],
  ): Promise<void>;
  unpublishExchange(exchangeId: Exchange["id"]): Promise<void>;

  makeOffer(offerForm: OfferForm, exchangeId: Exchange["id"]): Promise<void>;
  makeNewOffer(
    offerForm: OfferForm,
    exchangeId: Exchange["id"],
    offerId: Offer["id"],
  ): Promise<void>;
  acceptOffer(exchangeId: Exchange["id"], offerId: Offer["id"]): Promise<void>;
  declineOffer(exchangeId: Exchange["id"], offerId: Offer["id"]): Promise<void>;
  createInfoRequest(
    exchangeId: Exchange["id"],
    infoRequestForm: InfoRequestForm,
  ): Promise<void>;

  manageExchangeStatus(
    exchangeId: Exchange["id"],
    sold: boolean,
  ): Promise<void>;
  loadFilteredCountries(): Promise<void>;
}

export const ExchangeContext = createContext<ExchangeAPI | null>(null);

export interface ExchangeAPILoaded extends ExchangeAPI {
  exchange: NonNullable<FullExchangeWithFullOffer>;
  formatedExchange: NonNullable<FormatedExchange>;
}

export function ProvideExchange({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const { t } = useTranslation("exchange");
  const { countriesList } = useCountry();
  const { reloadUser, user } = useAuth();
  const { loadReceivedOffers, loadSentOffers } = useOffer();

  const [exchange, setExchange] = useState<FullExchange | null>(null);

  const [exchanges, setExchanges] = useState<FullExchange[]>([]);
  const [nbExchange, setNbExchange] = useState<number>(0);
  const [formOptions, setFormOptions] = useState<ExchangeFormBrandOption[]>([]);
  const [brandOptions, setBrandOptions] = useState<
    ExchangeFormBrandOptionBase[]
  >([]);
  const [modelOptions, setModelOptions] = useState<
    ExchangeFormModelOptionBase[]
  >([]);
  const [subModelOptions, setSubModelOptions] = useState<
    ExchangeFormSubModelOptionBase[]
  >([]);
  const [engineOptions, setEngineOptions] = useState<
    ExchangeFormEngineOption[]
  >([]);
  const [countries, setCountries] = useState<Array<string>>([]);

  const formatedExchange = useMemo(() => {
    if (exchange) {
      const brand = brandOptions.find(
        (brand) => brand.id === exchange.vehicleBrand,
      );
      const model = modelOptions.find(
        (model) => model.id === exchange.vehicleModel,
      );
      const subModel = subModelOptions.find(
        (subModel) => subModel.id === exchange.vehicleSubModel,
      );
      const engineModel = engineOptions.find(
        (engineModel) => engineModel.id === exchange.vehicleEngine,
      );
      return {
        ...exchange,
        vehicleBrand: brand?.value ?? exchange.vehicleBrand,
        vehicleModel:
          model?.value ??
          exchange.vehicleModel.split("-")[
            exchange.vehicleModel.split("-").length - 1
          ],
        vehicleSubModel:
          subModel?.value ??
          exchange.vehicleSubModel.split("-")[
            exchange.vehicleSubModel.split("-").length - 1
          ],
        vehicleEngine:
          engineModel?.value ??
          exchange.vehicleEngine.split("-")[
            exchange.vehicleEngine.split("-").length - 1
          ],
        vehicleCountry: exchange.vehicleCountryCode
          ? countriesList[exchange.vehicleCountryCode]
          : "Non Renseigné",
        vehicleOriginCountry:
          exchange.vehicleOriginCode === "UK"
            ? "Non connu"
            : countriesList[exchange.vehicleOriginCode],
        storageCountry: countriesList[exchange.storageCountryCode],
        vatPercentage: getNumericEnumValueByStringName(
          ExchangeVatCode,
          (exchange.vatCode as unknown) as string,
        )!,
        vehicleInnerColor:
          exchange.vehicleInnerColor !== null
            ? (t(
                `vehicle_inner_color.${
                  ExchangeVehicleInnerColor[exchange.vehicleInnerColor]
                }` as TFuncKey<"exchange">,
              ) as string)
            : "Non Renseigné",
        vehicleColor:
          exchange.vehicleColor !== null
            ? (t(
                `vehicle_color.${
                  ExchangeVehicleColor[exchange.vehicleColor]
                }` as TFuncKey<"exchange">,
              ) as string)
            : "Non Renseigné",
        vehicleSeatMaterial:
          exchange.vehicleSeatMaterial !== null
            ? (t(
                `vehicle_seat_material.${
                  ExchangeVehicleSeatMaterial[exchange.vehicleSeatMaterial]
                }` as TFuncKey<"exchange">,
              ) as string)
            : "Non Renseigné",
        vehicleTransmission:
          exchange.vehicleTransmission !== null
            ? (t(
                `vehicle_transmission.${
                  ExchangeVehicleTransmission[exchange.vehicleTransmission]
                }` as TFuncKey<"exchange">,
              ) as string)
            : "Non Renseigné",
        vehicleGearbox:
          exchange.vehicleGearbox !== null &&
          ExchangeVehicleGearbox[exchange.vehicleGearbox]
            ? (t(
                `vehicle_gearbox.${
                  ExchangeVehicleGearbox[exchange.vehicleGearbox]
                }` as TFuncKey<"exchange">,
              ) as string)
            : "Non Renseigné",
        vehicleEnergy:
          exchange.vehicleEnergy !== null
            ? (t(
                `vehicle_energy.${
                  ExchangeVehicleEnergy[exchange.vehicleEnergy]
                }` as TFuncKey<"exchange">,
              ) as string)
            : "Non Renseigné",
        vehicleType:
          exchange.vehicleType !== null
            ? (t(
                `vehicle_type.${
                  ExchangeVehicleType[exchange.vehicleType]
                }` as TFuncKey<"exchange">,
              ) as string)
            : "Non Renseigné",
        isVehicleDamaged: exchange.isVehicleDamaged,
        type: t(
          `type.${ExchangeType[exchange.type]}` as TFuncKey<"exchange">,
        ) as string,
      };
    } else {
      return null;
    }
  }, [
    brandOptions,
    countriesList,
    exchange,
    modelOptions,
    engineOptions,
    subModelOptions,
    t,
  ]);

  const createExchange: ExchangeAPI["createExchange"] = useCallback(
    (exchange: ExchangeForm, asUserId?: User["id"]) =>
      apiCreateExchange(exchange, asUserId || user!.id).then(
        async ({ data }) => {
          await reloadUser();
          const newExchange = mapRawExchangeToExchange(data);
          setExchanges((prev) => [
            ...prev.filter((exchange) => exchange.id !== newExchange.id),
            newExchange,
          ]);
          setExchange(newExchange);
          return newExchange;
        },
      ),
    [reloadUser, user],
  );

  const updateExchange: ExchangeAPI["updateExchange"] = useCallback(
    (exchange: ExchangeForm, asUserId?: User["id"]) =>
      apiUpdateExchange(exchange, asUserId || user!.id).then(
        async ({ data }) => {
          await reloadUser();
          const newExchange = mapRawExchangeToExchange(data);
          setExchanges((prev) => [
            ...prev.filter((exchange) => exchange.id !== newExchange.id),
            newExchange,
          ]);
          setExchange(newExchange);
          return newExchange;
        },
      ),
    [reloadUser, user],
  );

  const deleteExchange: ExchangeAPI["deleteExchange"] = useCallback(
    (exchangeId: Exchange["id"]) =>
      apiDeleteExchange(exchangeId).then(async () => {
        await reloadUser();
        setExchanges((prev) =>
          prev.filter((exchange) => exchange.id !== exchangeId),
        );
      }),
    [reloadUser],
  );

  const deleteImportExchanges: ExchangeAPI["deleteImportExchanges"] = useCallback(
    (userId) =>
      apiDeleteImportExchanges(userId).then(async () => {
        await reloadUser();
        setExchanges((prev) =>
          prev.filter((exchange) => exchange.adOrigin !== "csv"),
        );
      }),
    [reloadUser],
  );

  const loadExchanges: ExchangeAPI["loadExchanges"] = useCallback(
    () =>
      getAllExchanges().then(({ data }) =>
        setExchanges(
          data.map((fullExchange) => mapRawExchangeToExchange(fullExchange)),
        ),
      ),
    [],
  );

  const loadExchangeNumber: ExchangeAPI["loadExchangeNumber"] = useCallback(
    () =>
      getNbExchange().then(({ data }) => {
        setNbExchange(data.nbExchanges);
      }),
    [],
  );

  const loadExchangeById: ExchangeAPI["loadExchangeById"] = useCallback(
    (exchangeId) =>
      getExchangeById(exchangeId).then(({ data }) => {
        const exchange = mapRawExchangeToExchange(data);
        setExchange(exchange);
        return exchange;
      }),
    [],
  );

  const loadFormOptions: ExchangeAPI["loadFormOptions"] = useCallback(
    () =>
      getFormOptions().then(({ data }) => {
        setFormOptions(data);
      }),
    [],
  );

  const getFormOptionsBrand: ExchangeAPI["getFormOptionsBrand"] = useCallback(
    () => apiGetFormOptionsBrand().then((res) => res.data),
    [],
  );

  const getFormOptionsModel: ExchangeAPI["getFormOptionsModel"] = useCallback(
    (options) => apiGetFormOptionsModel(options).then((res) => res.data),
    [],
  );

  const getFormOptionsSubModel: ExchangeAPI["getFormOptionsSubModel"] = useCallback(
    (options) => apiGetFormOptionsSubModel(options).then((res) => res.data),
    [],
  );

  const getFormOptionsEngine: ExchangeAPI["getFormOptionsEngine"] = useCallback(
    (options) => apiGetFormOptionsEngine(options).then((res) => res.data),
    [],
  );

  const getFormOptionsEngines: ExchangeAPI["getFormOptionsEngines"] = useCallback(
    (options) => apiGetFormOptionsEngines(options).then((res) => res.data),
    [],
  );

  const getVehicleDetailsByVin: ExchangeAPI["getVehicleDetailsByVin"] = useCallback(
    (vehicleVin, vehicleCountryCode) =>
      apiGetVehicleDetailsByVin(vehicleVin, vehicleCountryCode).then(
        (res) => res.data,
      ),
    [],
  );

  const loadFilteredCountries: ExchangeAPI["loadFilteredCountries"] = useCallback(
    () => getFilteredCountries().then((res) => setCountries(res.data)),
    [],
  );

  const loadAll: ExchangeAPI["loadAll"] = useCallback(
    () =>
      Promise.all([
        loadExchanges(),
        loadFormOptions(),
        loadFilteredCountries(),
      ]).then(() => {
        // Do nothing
      }),
    [loadExchanges, loadFilteredCountries, loadFormOptions],
  );

  const formatVehicleOptions: ExchangeAPI["formatVehicleOptions"] = useCallback(
    (exchange) => {
      const brand = formOptions.find(
        (brand) => brand.id === exchange.vehicleBrand,
      );
      const model = brand?.Models.find(
        (model) => model.id === exchange.vehicleModel,
      );
      const subModel = model?.SubModels.find(
        (subModel) => subModel.id === exchange.vehicleSubModel,
      );
      return {
        ...exchange,
        vehicleBrand: brand?.value ?? exchange.vehicleBrand,
        vehicleModel: model?.value ?? exchange.vehicleModel,
        vehicleSubModel:
          subModel?.value ??
          exchange.vehicleSubModel.split("-")[
            exchange.vehicleSubModel.split("-").length - 1
          ],
        vehicleCountry: countriesList[exchange.vehicleCountryCode],
        vehicleOriginCountry:
          exchange.vehicleOriginCode === "UK"
            ? "Non connu"
            : countriesList[exchange.vehicleOriginCode],
        vatPercentage: getNumericEnumValueByStringName(
          ExchangeVatCode,
          (exchange.vatCode as unknown) as string,
        )!,
        vehicleInnerColor: t(
          `vehicle_inner_color.${
            ExchangeVehicleInnerColor[exchange.vehicleInnerColor]
          }` as TFuncKey<"exchange">,
        ) as string,
        vehicleColor: t(
          `vehicle_color.${
            ExchangeVehicleColor[exchange.vehicleColor]
          }` as TFuncKey<"exchange">,
        ) as string,
        vehicleSeatMaterial: t(
          `vehicle_seat_material.${
            ExchangeVehicleSeatMaterial[exchange.vehicleSeatMaterial]
          }` as TFuncKey<"exchange">,
        ) as string,
        vehicleTransmission: exchange.vehicleTransmission
          ? (t(
              `vehicle_transmission.${
                ExchangeVehicleTransmission[exchange.vehicleTransmission]
              }` as TFuncKey<"exchange">,
            ) as string)
          : "Non Renseigné",
        vehicleGearbox: t(
          `vehicle_gearbox.${
            ExchangeVehicleGearbox[exchange.vehicleGearbox]
          }` as TFuncKey<"exchange">,
        ) as string,
        vehicleEnergy:
          exchange.vehicleEnergy !== null
            ? (t(
                `vehicle_energy.${
                  ExchangeVehicleEnergy[exchange.vehicleEnergy]
                }` as TFuncKey<"exchange">,
              ) as string)
            : "Non Renseigné",
        vehicleType:
          exchange.vehicleType !== null
            ? (t(
                `vehicle_type.${
                  ExchangeVehicleType[exchange.vehicleType]
                }` as TFuncKey<"exchange">,
              ) as string)
            : "Non Renseigné",
        isVehicleDamaged: exchange.isVehicleDamaged,
      };
    },
    [countriesList, formOptions, t],
  );

  const loadFormatedVehicleOptionsDetails: ExchangeAPI["loadFormatedVehicleOptionsDetails"] = useCallback(
    (exchange) =>
      Promise.all([
        apiGetFormOptionsBrand().then(({ data }) => data),
        apiGetFormOptionsModel(exchange.vehicleBrand).then(({ data }) => data),
        apiGetFormOptionsSubModel(exchange.vehicleModel).then(
          ({ data }) => data,
        ),
        apiGetFormOptionsEngine(exchange.vehicleSubModel).then(
          ({ data }) => data,
        ),
      ]).then(
        ([brandOptions, modelOptions, subModelOptions, engineOptions]) => {
          setBrandOptions(brandOptions);
          setModelOptions(modelOptions);
          setSubModelOptions(subModelOptions);
          setEngineOptions(engineOptions);
        },
      ),
    [],
  );

  const addToFavorites: ExchangeAPI["addToFavorites"] = useCallback(
    (exchange) => {
      return apiAddToFavorites(exchange.id).then(async ({ data }) => {
        const newExchange = mapRawExchangeToExchange(data);
        const updatePrev = <T extends FullExchange>(prev: T[]) =>
          upsertElementInArray(
            prev,
            (el) => el?.id === data.id,
            (prevExchange) =>
              ({
                ...prevExchange,
                ...newExchange,
              } as T),
          );

        await reloadUser();
        setExchanges(updatePrev);
        setExchange((prev) => ({
          ...prev,
          ...newExchange,
        }));
      });
    },
    [reloadUser],
  );

  const removeFromFavorites: ExchangeAPI["addToFavorites"] = useCallback(
    (exchange) => {
      return apiRemoveFromFavorites(exchange.id).then(async ({ data }) => {
        const newExchange = mapRawExchangeToExchange(data);
        const updatePrev = <T extends FullExchange>(prev: T[]) =>
          upsertElementInArray(
            prev,
            (el) => el?.id === data.id,
            (prevExchange) =>
              ({
                ...prevExchange,
                ...newExchange,
              } as T),
          );

        await reloadUser();
        setExchanges(updatePrev);
        setExchange((prev) => ({
          ...prev,
          ...newExchange,
        }));
      });
    },
    [reloadUser],
  );

  const loadFavorites: ExchangeAPI["loadFavorites"] = useCallback(() => {
    return getAllFavorites().then(({ data }) => {
      setExchanges(() =>
        data.map((fullExchange) => mapRawExchangeToExchange(fullExchange)),
      );
    });
  }, []);

  const publishExchange: ExchangeAPI["publishExchange"] = useCallback(
    (exchangeId, publishWithoutPayment = false, asUserId) => {
      return apiPublishExchange(
        exchangeId,
        publishWithoutPayment,
        asUserId,
      ).then(async ({ data }) => {
        await reloadUser();
        const newExchange = mapRawExchangeToExchange(data);
        setExchanges((prev) => [
          ...prev.filter((exchange) => exchange.id !== newExchange.id),
          newExchange,
        ]);
        setExchange(newExchange);
      });
    },
    [reloadUser],
  );

  const unpublishExchange: ExchangeAPI["unpublishExchange"] = useCallback(
    (exchangeId) => {
      return apiUnpublishExchange(exchangeId).then(async ({ data }) => {
        await reloadUser();
        const newExchange = mapRawExchangeToExchange(data);
        setExchanges((prev) => [
          ...prev.filter((exchange) => exchange.id !== newExchange.id),
          newExchange,
        ]);
        setExchange(newExchange);
      });
    },
    [reloadUser],
  );

  const makeOffer: ExchangeAPI["makeOffer"] = useCallback(
    (offerForm, exchangeId) =>
      apiMakeOffer(exchangeId, offerForm).then(async ({ data }) => {
        const newExchange = mapRawExchangeToExchange(data);
        setExchange((prev) => ({
          ...prev,
          ...newExchange,
        }));
        await Promise.all([loadSentOffers(), reloadUser()]);
      }),
    [loadSentOffers, reloadUser],
  );

  const makeNewOffer: ExchangeAPI["makeNewOffer"] = useCallback(
    (offerForm, exchangeId, offerId) =>
      apiMakeNewOffer(exchangeId, offerId, offerForm).then(async ({ data }) => {
        const newExchange = mapRawExchangeToExchange(data);
        setExchange((prev) => ({
          ...prev,
          ...newExchange,
        }));
        await Promise.all([
          loadSentOffers(),
          loadReceivedOffers(),
          reloadUser(),
        ]);
      }),
    [loadReceivedOffers, loadSentOffers, reloadUser],
  );

  const acceptOffer: ExchangeAPI["acceptOffer"] = useCallback(
    (exchangeId, offerId) =>
      apiAcceptOffer(exchangeId, offerId).then(async ({ data }) => {
        const newExchange = mapRawExchangeToExchange(data);
        setExchange((prev) => ({
          ...prev,
          ...newExchange,
        }));
        await Promise.all([
          loadSentOffers(),
          loadReceivedOffers(),
          reloadUser(),
        ]);
      }),
    [loadReceivedOffers, loadSentOffers, reloadUser],
  );

  const declineOffer: ExchangeAPI["declineOffer"] = useCallback(
    (exchangeId, offerId) =>
      apiDeclineOffer(exchangeId, offerId).then(async ({ data }) => {
        const newExchange = mapRawExchangeToExchange(data);
        setExchange((prev) => ({
          ...prev,
          ...newExchange,
        }));
        await Promise.all([
          loadSentOffers(),
          loadReceivedOffers(),
          reloadUser(),
        ]);
      }),
    [loadReceivedOffers, loadSentOffers, reloadUser],
  );

  const createInfoRequest: ExchangeAPI["createInfoRequest"] = useCallback(
    (exchangeId, infoRequestForm) =>
      apiCreateInfoRequest(exchangeId, infoRequestForm).then(() =>
        reloadUser(),
      ),
    [reloadUser],
  );

  const manageExchangeStatus: ExchangeAPI["manageExchangeStatus"] = useCallback(
    (exchangeId, sold) => {
      return apiManageExchangeStatus(exchangeId, sold).then(({ data }) => {
        const newExchange = mapRawExchangeToExchange(data);
        setExchanges((prev) => [
          ...prev.filter((exchange) => exchange.id !== newExchange.id),
          newExchange,
        ]);
        setExchange(newExchange);
      });
    },
    [],
  );

  return (
    <ExchangeContext.Provider
      value={{
        exchange,
        nbExchange,
        formatedExchange,
        exchanges,
        formOptions,
        countries,
        loadAll,
        createExchange,
        updateExchange,
        deleteExchange,
        deleteImportExchanges,
        loadExchanges,
        loadExchangeNumber,
        loadExchangeById,
        loadFormOptions,
        loadFavorites,
        getFormOptionsBrand,
        getFormOptionsModel,
        getFormOptionsSubModel,
        getFormOptionsEngine,
        getFormOptionsEngines,
        getVehicleDetailsByVin,
        formatVehicleOptions,
        loadFormatedVehicleOptionsDetails,
        addToFavorites,
        removeFromFavorites,
        publishExchange,
        unpublishExchange,
        makeOffer,
        makeNewOffer,
        acceptOffer,
        declineOffer,
        createInfoRequest,
        manageExchangeStatus,
        loadFilteredCountries,
      }}
    >
      {children}
    </ExchangeContext.Provider>
  );
}

export function withProvideExchange<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideExchange(props: P) {
    return (
      <ProvideExchange>
        <WrappedComponent {...props} />
      </ProvideExchange>
    );
  }

  WithProvideExchange.displayName = `withProvideExchange(${displayName})`;

  return WithProvideExchange;
}

export function useExchange(): ExchangeAPI {
  return useContext(ExchangeContext) as ExchangeAPI;
}
