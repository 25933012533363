import { FunctionComponent } from "react";
import useFileAsObjectURL from "../files/useFileAsObjectURL";

interface FileItemProps {
  file: { file: string; name?: string };
  isExternalUrl?: boolean;
}

const FileItem: FunctionComponent<FileItemProps> = ({
  file,
  isExternalUrl,
}) => {
  const objectURL = useFileAsObjectURL({
    url: !isExternalUrl ? file.file : null,
  });

  return (
    <div className={"media-item"}>
      <div className={"file"}>
        {objectURL || isExternalUrl ? (
          <a
            className={"file-name"}
            href={isExternalUrl ? file.file : objectURL?.url}
            rel={"noreferrer"}
            target={"_blank"}
          >
            {file.name || "Document sans nom"}
          </a>
        ) : (
          <p>Erreur</p>
        )}
      </div>
    </div>
  );
};

export default FileItem;
