import React, { useEffect } from "react";
import "./services/validations/yup-init";
import "./services/i18n";
import { ProvideToast } from "./services/toast-notifications";
import { AuthContext, useProvideAuth } from "./services/auth/apiProvider";
import { ProvideConfirmation } from "./services/ui/ConfirmationDialog";
import { LOGIN_LINK, PUBLIC } from "./routes/public";
import Public from "./views/Public";
import Private from "./views/Private";
import { PRIVATE } from "./routes/private";
import { Navigate, Route } from "react-router-dom";
import Routes from "./services/routing/Routes";
import { RequiresAuth } from "./services/auth/hocs/withRedirectToPrivate";
import { ProvidePlan } from "./services/plan/planProvider";

const MainRouter = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<Navigate to={PUBLIC} />} index />
      <Route element={<Public />} path={`${PUBLIC}/*`} />

      <Route
        element={
          <RequiresAuth redirectTo={LOGIN_LINK}>
            <Private />
          </RequiresAuth>
        }
        path={`${PRIVATE}/*`}
      />
    </Routes>
  );
};

const App: React.FC = () => {
  const auth = useProvideAuth();

  useEffect(() => {
    auth.checkUserValidity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={auth}>
      <ProvideToast>
        <ProvideConfirmation>
          <ProvidePlan>
            <MainRouter />
          </ProvidePlan>
        </ProvideConfirmation>
      </ProvideToast>
    </AuthContext.Provider>
  );
};

export default App;
